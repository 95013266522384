import { createStore } from "vuex";

export default createStore({
  //数据存储属性
  state: {
    home: "首页",
    mudioSheet: "媒体中心",
    bkFamily: "百科家族",
    aboutWe: "关于我们",
    focusUs: "关注我们",
    charitable: "公益",
    charitableArray: [
      "医护",
      "助学",
      // "慈善机构信息",
      "环境保护",
      "动物保护",
      "名人慈善",
      "历史人物",
      "器官捐献",
      "动物救助",
    ],
    contactUs: "联系我们",
    timeAxis: "时间轴",
    //响应式
    resLayout: true,
    domFla: false,
    //让video重新加载
    videoTime: "",
    //关于我们
    aboutUs: "团队介绍",
    NSRN:"粤ICP备2021156789号-3",
    userAgreement:"用户协议",
    privacyPolicy:"隐私政策",
    //公共前缀链接
    publicLinks:"",
  },
  
});
