<template>
  <div id="contactDiv">
    <div class="contactDivOne">
      <div class="contactDivTitle">联系方式</div>
      <div class="contactDivBor"></div>
      <ul class="contactDivOneContent">
        <li>
          <div class="liJob">
            <div class="contactDivBor"></div>
            <span>销售经理</span>
          </div>
          <div class="liContact">
            <img
              class="contactIcon"
              src="../assets/img/Vector.svg"
              alt="图标"
            />
            <span>Wilson Wu（吴先生）</span>
          </div>
          <div class="liContact">
            <img
              class="contactIcon"
              src="../assets/img/VectorOne1.png"
              alt="图标"
            />
            <span>13662607702</span>
          </div>
          <div class="liContact">
            <img
              class="contactIcon"
              src="../assets/img/VectorTwo.svg"
              alt="图标"
            />
            <span>wilson_wu@zanbaike.com</span>
          </div>
          <img
            class="positionImg"
            src="../assets/img/Group45157.png"
            alt="职位背景图"
          />
        </li>
        <!-- <li>
          <div class="liJob">
            <div class="contactDivBor"></div>
            <span>市场运营</span>
          </div>
          <div class="liContact">
            <img
              class="contactIcon"
              src="../assets/img/Vector.svg"
              alt="图标"
            />
            <span>周先生</span>
          </div>
          <div class="liContact">
            <img
              class="contactIcon"
              src="../assets/img/VectorOne1.png"
              alt="图标"
            />
            <span>13662609197</span>
          </div>
          <div class="liContact">
            <img
              class="contactIcon"
              src="../assets/img/VectorTwo.svg"
              alt="图标"
            />
            <span>shuai_zhou@zanbaike.com</span>
          </div>
          <img
            class="positionImg"
            src="../assets/img/Group45155.png"
            alt="职位背景图"
          />
        </li> -->
        <li>
          <div class="liJob">
            <div class="contactDivBor"></div>
            <span>HR</span>
          </div>
          <div class="liContact">
            <img
              class="contactIcon"
              src="../assets/img/Vector.svg"
              alt="图标"
            />
            <span>Christin Lai</span>
          </div>
          <div class="liContact">
            <img
              class="contactIcon"
              src="../assets/img/VectorOne1.png"
              alt="图标"
            />
            <span>13723706884</span>
          </div>
          <div class="liContact">
            <img
              class="contactIcon"
              src="../assets/img/VectorTwo.svg"
              alt="图标"
            />
            <span>christin_lai@zanbaike.com</span>
          </div>
          <img
            class="positionImg"
            src="../assets/img/Group45154.png"
            alt="职位背景图"
          />
        </li>
        <li>
          <div class="liJob">
            <div class="contactDivBor"></div>
            <span>PR</span>
          </div>
          <div class="liContact">
            <img
              class="contactIcon"
              src="../assets/img/Vector.svg"
              alt="图标"
            />
            <span>李小姐</span>
          </div>
          <div class="liContact">
            <img
              class="contactIcon"
              src="../assets/img/VectorOne1.png"
              alt="图标"
            />
            <span>13683520475</span>
          </div>
          <div class="liContact">
            <img
              class="contactIcon"
              src="../assets/img/VectorTwo.svg"
              alt="图标"
            />
            <span>bernice_li@zanbaike.com</span>
          </div>
          <img
            class="positionImg"
            src="../assets/img/Group45269.png"
            alt="职位背景图"
          />
        </li>
      </ul>
    </div>
    <div class="contactDivTwo">
      <div class="contactDivTwoLeft"></div>
      <div class="contactDivTwoRight">
        <div class="contactDivTwoTitle">咱百科客服邮箱：info@zanbaike.com</div>
        <div class="contactDivTwoContent">
          咱百科地址：深圳市南山区粤海街道创维半导体设计大厦裙楼402
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

@media only screen and (min-width: 751px) {
  #contactDiv {
    margin-top: 73px;
    .contactDivOne {
      padding: 0 35px;
      padding-top: 103px;

      .contactDivTitle {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 45px;
        letter-spacing: 0.27em;
        color: #48555a;
        text-align: center;
        margin-bottom: 56px;
      }
      .contactDivBor {
        width: 46px;
        height: 6px;
        background: #56bd88;
        border-radius: 5px;
        margin: 0 auto;
        margin-bottom: 98px;
      }
      .contactDivOneContent {
        display: flex;
        justify-content: space-between;
        margin-bottom: 102px;
        li {
          position: relative;
          width: 438px;
          background: #ffffff;
          border-radius: 30px;
          border: 1.42px solid rgba(238, 238, 238, 1);
          padding-bottom: 112px;
          margin-right: 32px;
          &:last-child {
            margin-right: 0px;
          }
          .liJob {
            margin-top: 84px;
            margin-left: 47px;
            .contactDivBor {
              text-align: left;
              margin-left: 3px;
              margin-bottom: 0px;
            }
            span {
              display: inline-block;
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: 36px;
              line-height: 50px;
              letter-spacing: 0.085em;
              color: #2d2d2d;
              margin-top: 32px;
              margin-bottom: 47px;
            }
          }
          .liContact {
            display: flex;
            flex-direction: column;
            margin-left: 47px;
            .contactIcon {
              width: 18px;
              height: 18px;
            }
            span {
              display: inline-block;
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 34px;
              letter-spacing: 0.085em;
              color: #2d2d2d;
              margin-top: 4px;
              margin-bottom: 22px;
            }
          }
          .positionImg {
            position: absolute;
            top: 0;
            right: 0;
            width: 194px;
            height: 243px;
            border-top-right-radius: 31px;
          }
        }
      }
    }
    .contactDivTwo {
      display: flex;
      .contactDivTwoLeft {
        width: 1073px;
        height: 559px;
        background: url(https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/CorpMap.png);
        background-size: 100%;
        background-repeat: no-repeat;
      }
      .contactDivTwoRight {
        width: 846px;
        height: 559px;
        background: #ffffff;
        border: 0.8px solid #eaeaea;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .contactDivTwoTitle {
          width: 489px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 28px;
          line-height: 39px;
          color: #48555a;
          margin-bottom: 16px;
        }
        .contactDivTwoContent {
          width: 489px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 28px;
          line-height: 39px;
          color: #48555a;
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  #contactDiv {
    margin-top: 73px;
    width: 750px;
    .contactDivOne {
      // padding: 0 35px;
      padding-top: 74px;

      .contactDivTitle {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 45px;
        letter-spacing: 8.64px;
        color: #48555a;
        text-align: center;
        margin-bottom: 56px;
      }
      .contactDivBor {
        width: 46px;
        height: 6px;
        background: #56bd88;
        border-radius: 5px;
        margin: 0 auto;
        margin-bottom: 78px;
      }
      .contactDivOneContent {
        display: flex;
        flex-wrap: wrap;
        width: 702px;
        margin: 0 auto;
        margin-bottom: 82px;
        li {
          position: relative;
          width: 342px;
          // height: 416px;
          background: #ffffff;
          border-radius: 30px;
          border: 1px solid #eee;
          box-sizing: border-box;
          background: #fff;
          padding-bottom: 87px;
          margin-right: 18px;
          margin-bottom: 19px;
          &:nth-child(even) {
            margin-right: 0px;
          }
          .liJob {
            margin-top: 65px;
            margin-left: 19px;
            .contactDivBor {
              text-align: left;
              margin-left: 3px;
              margin-bottom: 0px;
            }
            span {
              display: inline-block;
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: 36px;
              line-height: 50px;
              letter-spacing: 3.06px;
              color: #2d2d2d;
              margin-top: 25px;
              margin-bottom: 36px;
            }
          }
          .liContact {
            display: flex;
            flex-direction: column;
            margin-left: 19px;
            .contactIcon {
              width: 14px;
              height: 14px;
            }
            span {
              display: inline-block;
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: 22px;
              // line-height: 34px;
              letter-spacing: 1.87px;
              color: #2d2d2d;
              margin-top: 3px;
              margin-bottom: 25px;
            }
            &:nth-child(4) {
              span {
                margin-bottom: 0px;
              }
            }
          }
          .positionImg {
            position: absolute;
            top: 0;
            right: 0;
            width: 151px;
            height: 189px;
            border-top-right-radius: 31px;
          }
        }
      }
    }
    .contactDivTwo {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 120px;
      .contactDivTwoLeft {
        width: 750px;
        height: 560px;
        background: url(https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/contcactTu.png);
        background-size: 750px 560px;
        background-repeat: no-repeat;
      }
      .contactDivTwoRight {
        width: 489px;
        // height: 559px;
        background: #ffffff;
        // border: 0.8px solid #eaeaea;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 24px;
        margin-bottom: 22px;
        .contactDivTwoTitle {
          width: 489px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 28px;
          line-height: normal;
          color: #48555a;
          margin-bottom: 20px;
        }
        .contactDivTwoContent {
          width: 489px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 28px;
          line-height: normal;
          color: #48555a;
        }
      }
    }
  }
}
</style>
