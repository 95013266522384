<template>
  <div id="regDiv" v-if="regFalg" @mousewheel.prevent>
    <ul id="regUl" @keydown.enter="changeIn()">
      <li class="childOne">
        <img
          src="../assets/img/btn_xiaochu.svg"
          alt="关闭按钮"
          @click="update(false)"
        />
      </li>
      <li class="childTwo">
        <span
          >咱百科<br />
          欢迎您登录/注册</span
        >
        <div class="divColor"></div>
      </li>
      <li class="childThree">{{ textError }}</li>
      <li class="childFour">
        <el-select
          clearable
          v-model="phoneValue"
          class="m-2"
          :popper-append-to-body="false"
          popper-class="select-popper"
          @visible-change="changeColor"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            {{ item.text }}
          </el-option>
        </el-select>
        <img src="../assets/img/loginXl.svg" alt="下拉" />

        <el-input
          class="inputOne"
          type="tel"
          v-model="value1"
          placeholder="请输入手机号"
          maxlength="11"
        />
      </li>
      <li class="childFive">
        <el-input
          class="inputFont"
          type="text"
          v-model="value2"
          placeholder="输入验证码"
        />
        <button v-if="show == 0" @click="chikCode">获取验证码</button>
        <button id="noChick" v-if="show == 1">{{ count }}s可再发</button>
      </li>
      <li class="childSix">
        <div id="xianyi">
          <!-- <div id="sjx">
            <div id="titleTop">请先选择同意后再进行操作</div>
          </div> -->

          <el-checkbox
            id="inputTick"
            v-model="radio1"
            @change="handleRadioClick"
            >{{ "" }}</el-checkbox
          >

          <div id="spanDiv">
            我已阅读并同意<span class="toPush" @click="toUser"
              >《用户协议》</span
            >和<span class="toPush" @click="toPrivac">《隐私政策》</span>
            ,未注册手机登录时会自动创建账号
          </div>
        </div>
      </li>
      <li class="childSever">
        <div>
          <img
            src="../assets/img/icon_weixin.svg"
            alt="咱百科"
            @click="toRegis"
          />
        </div>
        <div class="borRight"></div>
        <!-- :disabled="btnFalg" -->
        <button id="btnDisBack" type="button" @click="changeIn" ref="buttonRef">
          登录
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { ref, watch, onUnmounted } from "vue";
import QrcodeVue from "qrcode.vue";

import { getCode, signLogIn, getUserInfo } from "../api/Login/index";
import router from "../router";
import { Session, Local } from "../utils/storage";
// import { useStore } from "vuex";
import emitter from "../utils/events-bus";

export default {
  name: "Regin",
  components: { QrcodeVue },

  setup(props: any, { emit }: any) {
    async function getNumberCode() {
      await getCode({
        areaCode: phoneValue.value,
        phoneNumber: value1.value,
      });
      // console.log("data", data);
    }
    // const store = useStore();
    //控制登录注册
    const regFalg = ref(true);
    // const btnFalg = ref(true);
    const textError = ref("");

    const phoneValue = ref("+86");
    const options = [
      {
        text: "中国 (+86)",
        value: "+86",
        label: "+86",
      },
      {
        text: "香港 (中国) (+852)",
        value: "+852",
        label: "+852",
      },
      {
        text: "澳门 (中国) (+853)",
        value: "+853",
        label: "+853",
      },
    ];

    //验证码
    const show: any = ref("0");
    const count = ref(0);
    const timer = ref();

    //勾选协议
    const radio1 = ref(false);
    const radio2 = ref(false);

    //用于input的双向绑定
    const value1 = ref("");
    const value2 = ref("");
    //收集符合input输入规则的数据

    //控制微信扫码的显示隐藏
    // let timeout = null as any;

    //登录组件之间的切换
    const update = (date: any) => {
      // let login = window.confirm("确定离开登录界面！！");
      // if (login) {
      emitter.emit("showXs", date);
      // }
    };
    function timeQx() {
      clearTimeout(timeNull);
      timeNull = setTimeout(() => {
        textError.value = "";
      }, 3000);
    }
    //点击获取验证码的操作
    function chikCode() {
      // console.log("点击获取验证码的操作！！");
      var reg = /^1[3|4|5|7|8][0-9]{9}$/;
      if (reg.test(value1.value)) {
        const times = 60; // 倒计时时间
        if (!timer.value) {
          count.value = times;
          show.value = "1";
          timer.value = setInterval(() => {
            if (count.value > 0 && count.value <= times) {
              count.value--;
            } else {
              show.value = "0";
              clearInterval(timer.value);
              timer.value = null;
            }
          }, 1000);
        }
        getNumberCode();
      } else {
        textError.value = "请先输入正确的手机号";
        timeQx();
      }
    }

    const buttonRef = ref();
    const popoverRef = ref();
    //点击登录按钮执行
    async function changeIn() {
      var reg = /^1[3|4|5|7|8][0-9]{9}$/;

      if (value1.value) {
        if (!reg.test(value1.value)) {
          textError.value = "请先输入正确的手机号";
          return;
        } else if (show.value == 0) {
          textError.value = "请先获取验证码";
        } else if (!value2.value) {
          textError.value = "请输入验证码";
        }
      } else if (value2.value) {
        textError.value = "请输入手机号";
      } else {
        textError.value = "请输入手机号和验证码";
      }
      if (radio2.value == false) {
        textError.value = "请勾选下方按钮同意操作";
      } else if (value2.value) {
        // sjx.style.display = "none";
        const data = (await signLogIn({
          areaCode: phoneValue.value,
          phoneNumber: value1.value,
          captcha: value2.value,
        })) as any;
        // console.log("data", data);
        data.code !== 200 ? (textError.value = "验证码不匹配") : "";
        if (data.code == 200) {
          Session.set("userInfo", data.data);
          Local.set("userInfo", data.data);
          let userInfoData = await getUserInfo();

          userInfoData.data.timeDate = `${new Date().getFullYear()}/${
            new Date().getMonth() + 1
          }/${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`;
          // console.log(new Date());

          Session.set("reginUSer", userInfoData.data);
          Local.set("reginUSer", userInfoData.data);
          emitter.emit("showXs", false);
          // ElMessage.success("登录成功");
          location.reload();
        }
      }
      timeQx();
    }

    //控制微信二维码
    // function showDed(date: any) {
    //   const regUl = <HTMLDivElement>document.getElementById("regUl");
    //   const showFta = <HTMLDivElement>document.getElementById("showFta");
    //   const showImg = <HTMLDivElement>document.getElementById("showImg");

    //   showFta.style.height = regUl.offsetHeight + "px";
    //   showFta.style.width = regUl.offsetWidth + "px";
    //   showImg.style.height = regUl.offsetHeight + "px";
    //   showImg.style.width = regUl.offsetWidth + "px";

    //   clearTimeout(timeout);
    //   // console.log(regUl);
    //   timeout = setTimeout(() => {
    //     const show = <HTMLDivElement>document.querySelector("#showFta");
    //     if (date) {
    //       show.className = "fadein";
    //       // show.style.display = "flex";
    //     } else {
    //       show.className = "showDiv";
    //       // show.style.display = "none";
    //     }
    //   }, 100);
    // }

    //勾选点击

    function handleRadioClick() {
      // const sjx = document.getElementById("sjx") as HTMLDivElement;
      // if (radio1.value == true) {
      //   sjx.style.display = "none";
      // }
      // console.log("radio", radio1.value);
      radio2.value = radio1.value;
    }
    //用户协议
    function toUser() {
      router.push("/userAgreement");
      emitter.emit("showXs", false);
    }
    //隐私协议
    function toPrivac() {
      router.push("/PrivacyPolicy");
      emitter.emit("showXs", false);
    }

    //点击微信
    function toRegis() {
      // const sjx = document.getElementById("sjx") as HTMLDivElement;
      // if (radio2.value == false) {
      //   textError.value = "请勾选下方按钮同意操作";
      //   // sjx.style.display = "block";
      // } else {
      // sjx.style.display = "none";
      let url = window.location.href;
      location.replace(
        "https://open.weixin.qq.com/connect/qrconnect?appid=wxd02ea485fbce1ff3&redirect_uri=https%3A%2F%2Fapi.zanbaike.com%2Fapi%2Fwebsite%2Fauth%2Fqr-token&response_type=code&scope=snsapi_login&state=STATE"
      );
      window.history.pushState(null, "", url);
      // window.open(
      //   "https://open.weixin.qq.com/connect/qrconnect?appid=wxd02ea485fbce1ff3&redirect_uri=https%3A%2F%2Fapi.zanbaike.com%2Fapi%2Fwebsite%2Fauth%2Fqr-token&response_type=code&scope=snsapi_login&state=STATE",
      //   "_self"
      // );
      // update(false);
      // }
    }

    //改变选中的颜色
    function changeColor(params: boolean) {
      if (params) {
        const elementSelected = document.querySelector(".select-popper");
        const selectDropdown = elementSelected?.querySelectorAll(
          ".el-select-dropdown__item"
        );
        const selectCheck = elementSelected?.querySelector(
          ".is-selected"
        ) as HTMLElement;
        // console.log("elementSelected", elementSelected);
        // console.log("selectDropdown", selectDropdown);
        // console.log("selectCheck", selectCheck);

        selectDropdown?.forEach((ele: any) => {
          ele.style.color = "#2C2C2C";
        });
        selectCheck.style.color = "#56bd88";
      }
    }

    var timeNull: any;
    watch([() => value1.value, () => value2.value], () => {
      const ele = document.getElementById("btnDisBack");
      if (value1.value && value2.value) {
        // btnFalg.value = true;
        ele?.classList.add("btnIsCheck");
      } else if (value1.value) {
        // btnFalg.value = false;
        ele?.classList.remove("btnIsCheck");
      } else if (value2.value) {
        // btnFalg.value = false;
        ele?.classList.remove("btnIsCheck");
      } else {
        // btnFalg.value = false;
        ele?.classList.remove("btnIsCheck");
        textError.value = "";
      }
    });
    onUnmounted(() => {
      clearTimeout(timeNull);
    });
    return {
      regFalg,
      buttonRef,
      popoverRef,
      show,
      count,
      timer,
      // btnFalg,
      textError,
      toUser,
      toPrivac,
      update,
      changeIn,
      chikCode,
      // showDed,
      handleRadioClick,
      toRegis,
      changeColor,

      radio1,
      radio2,
      value1,
      value2,
      phoneValue,
      options,
    };
  },
};
</script>

<style lang="scss" scoped>
.select_bt_label1 {
  input {
    width: 10px;
    height: 10px;
  }
}
:deep(.el-input__wrapper) {
  box-shadow: none !important;
  padding: 0 !important;
}
:deep(.el-select__wrapper) {
  box-shadow: none !important;
  padding: 0 !important;
}
:deep(.el-select__suffix) {
  display: none !important;
}
:deep(.el-select__placeholder) {
  overflow: visible !important;
  text-overflow: initial !important;
}

:deep(.el-select .el-input__wrapper) {
  padding-right: 0 !important;
  border-radius: 6px 0px 0px 6px;
  border: none !important;
  // border: 1px solid #AAAAAA;
  // box-shadow: none !important;
}

:deep(.el-input__inner) {
  font-family: "PingFang SC" !important;
}
:deep(.el-input__suffix, .is-focus) {
  display: none !important;
}
:deep(.m-2) {
  width: 59px !important;
}
:deep(.inputFont) {
  font-size: 15px;
  width: 170px !important;
}
:deep(.inputFont .el-input__inner) {
  padding-right: 0 !important;
  border-radius: 6px 0px 0px 6px;
  // border: 1px solid #AAAAAA
}
:deep(.inputOne) {
  width: 200px !important;
}
:deep(.inputOne .el-input__inner) {
  padding-right: 0 !important;
  border-radius: 0px 6px 6px 0px;
  // border: 1px solid #AAAAAA
}
:deep(.el-select .el-input__wrapper.is-focus) {
  box-shadow: 0 0 0 0px !important;
}
:deep(.el-select .el-input.is-focus .el-input__wrapper) {
  box-shadow: 0 0 0 0px !important;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
}

input {
  outline: none;
  border: 1px solid;
}

button {
  border: 1px solid;
  // background-color: transparent;
  outline: none;
}
.btnIsCheck {
  background: #56bd88 !important;
}

#regDiv {
  width: 100%;
  // height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  // margin-top: 137px;

  #regUl {
    width: 592px;
    height: 700px;

    background: #ffffff;
    border-radius: 32px;
    // opacity: 1;
    position: relative;
    padding-left: 69px;
    li {
      display: flex;
      // justify-content: center;

      .fadein {
        -webkit-transition: all 0.6s;
        -moz-transition: all 0.6s;
        -ms-transition: all 0.6s;
        -o-transition: all 0.6s;
        transition: all 0.6s;
        opacity: 1;
        // width: 340px;
        // height: 446px;
        border-radius: 10px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 0;
        left: -350px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
        span {
          display: block;
          font-size: 13px;
          // font-family: PingFang SC;
          font-weight: 500;
          color: #4e4e4e;
          text-align: center;
        }
      }
      .showDiv {
        // width: 340px
        // height: 446px
        border-radius: 10px;
        -webkit-transition: all 0.6s;
        -moz-transition: all 0.6s;
        -ms-transition: all 0.6s;
        -o-transition: all 0.6s;
        transition: all 0.6s;
        opacity: 1;
        background: #ffffff;
        // margin-right: 10px;

        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 0;
        left: -350px;
        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
        span {
          display: block;
          font-size: 13px;
          // font-family: PingFang SC;
          font-weight: 500;
          color: #4e4e4e;
          text-align: center;
        }
      }
    }
    .childOne {
      width: 26px;
      height: 26px;
      margin-top: 34px;
      margin-left: 532px;
      img {
        width: 26px;
        height: 26px;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .childTwo {
      flex-direction: column;
      span {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 45px;
        color: #2c2c2c;
      }
      .divColor {
        width: 40px;
        height: 6px;
        background: #56bd88;
        border-radius: 6px;
        margin-top: 17px;
        // transform: rotate(90deg);
      }
    }
    .childThree {
      position: absolute;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: justify;
      color: #f91010;
      top: 200px;
      left: 69px;
    }
    .childFour {
      width: 456px;
      height: 21px;
      padding-top: 70px;
      align-items: center;
      :deep(.el-select) {
        // width: 43px !important;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        color: #2c2c2c;
        .el-input__inner {
          text-align: center;
        }
        // :deep(.selected) {
        //   color: #56bd88 !important;
        // }
      }

      :deep(.el-input__inner) {
        height: 21px;
        padding: 0px !important;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        // text-align: center;
        color: #2c2c2c;
        border: none;
        // border-right: 1px solid ;
        .el-input__suffix {
          background: #fff !important;
        }
      }
      .inputOne {
        border-left: 1px solid;
        padding-left: 12px !important;
        width: 100% !important;
      }
      select {
        width: 43px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        color: #2c2c2c;
        // border-radius: 6px 0px 0px 6px;
        // border: 1px solid #aaaaaa;
        // border-right: none;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }

    .childFive {
      width: 456px;
      height: 53px;
      border-top: 1px solid rgba(210, 210, 210, 0.4);
      border-bottom: 1px solid rgba(210, 210, 210, 0.4);
      // border-radius: 48px;
      margin-top: 18px;
      :deep(.el-input__inner) {
        border: none;
        width: 366px;
        height: 53px;
      }
      button {
        width: 86px;
        text-align: center;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        // text-align: right;
        color: #56bd88;
        border: none;
        background: #fff;
        // border: 1px solid #2ed6d8;
        padding: 0;
        margin: 0;
        white-space: nowrap;
        &:hover {
          cursor: pointer;
        }
      }
      #noChick {
        background: #fff;
        color: silver;
        // border: 1px solid #dcdfe6;
        border: none;
      }
      .inputFont {
        width: 366px !important;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        color: #9a9a9a;
      }
    }

    .childSix {
      display: flex;
      flex-direction: column;
      width: 456px;
      padding-top: 36px;
      // margin: 0 auto;
      #xianyi {
        display: flex;
        justify-content: center;
        align-items: start;
        position: relative;
        :deep(.el-checkbox) {
          width: 22px;
          height: 22px;
          // background: #56bd88 !important;
          :deep(.el-checkbox__input) {
            :deep(.el-checkbox__inner) {
              background-color: none !important;
              border: none;
            }
          }
          // .is-checked {
          //   background: #56bd88 !important;
          // }
        }
        :deep(.el-checkbox__inner) {
          width: 22px;
          height: 22px;
          border: 1px solid rgba(86, 189, 136, 1);
          border-radius: 4px;
          &::after {
            position: absolute;
            content: "";
            width: 12px;
            height: 12px;
            left: calc(50% - 6px);
            top: calc(50% - 6px);
            border: none;
            background: url(../assets/img/IconGg.svg);
            background-size: 12px 12px;
            background-repeat: no-repeat;
            transform: rotate(360deg);
            box-sizing: border-box;
          }
        }
        :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
          background: #56bd88 !important;
        }
        #sjx {
          display: none;
          position: absolute;
          top: -30px;
          // #titleTop {
          //   border: 1px solid #ccc;
          //   height: 35px;
          //   line-height: 35px;
          //   font-size: 12px;
          //   position: relative;
          //   left: -9px;
          //   filter: drop-shadow(0 0 2px #999);

          //   background-color: #fff;
          //   &::before {
          //     content: "";
          //     position: absolute;
          //     left: 6px;
          //     top: 30px;
          //     border-bottom: 6px solid transparent;
          //     border-right: 6px solid transparent;
          //     border-left: 6px solid transparent;
          //     border-top: 10px solid #ccc;
          //   }
          //   &::after {
          //     content: "";
          //     position: absolute;
          //     left: 6px;
          //     top: 30px;
          //     border-bottom: 6px solid transparent;
          //     border-right: 6px solid transparent;
          //     border-left: 6px solid transparent;
          //     border-top: 10px solid #fff;
          //   }
          // }
        }
        #spanDiv {
          width: 415px;
          margin-left: 18px;
          margin-top: -2px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          // text-align: justify;
          letter-spacing: 0px;
          color: #467ab7;
          .toPush {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
    .childSever {
      margin-top: 134px;
      display: flex;
      align-items: center;
      div {
        img {
          width: 69px;
          height: 69px;
          border-radius: 50%;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .borRight {
        width: 1px;
        height: 46px;
        background: #d2d2d2;
        opacity: 0.4;
        border-radius: 48px;
        margin: 0 29px;
      }
      button {
        width: 328px;
        height: 69px;
        // background: #56bd88;
        background: rgba(86, 189, 136, 0.4);

        border-radius: 8px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        color: #ffffff;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
