import request from "../../utils/request";

//获取友情链接
export function getLinksList(params: object) {
  return request({
    url: `/links/list`,
    method: "get",
    params,
  });
}

