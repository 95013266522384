<template>
  <div id="mudioDiv">
    <div id="mudImg">
      <el-carousel :interval="2000">
        <el-carousel-item v-for="item in imgs" :key="item">
          <img :src="item" alt="咱百科" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div id="mudioDivCentent">
      <div class="mudCet mudCetOne">
        <PreloadMudioSheet v-if="trailerFalg" />
        <div class="mudUl" v-else>
          <div class="mudioYx">
            <div class="mudCetit">
              <div class="mudCetitOne">
                <img src="../assets/img/mudioYX44977.svg" alt="咱百科" />
                <div>我的英雄</div>
              </div>
              <div
                class="mudCetitTwo"
                v-if="videoList.yxChildType.length > 2"
                @click="toInfoMedia('1')"
              >
                <div>查看更多</div>
                <img src="../assets/img/Group36.svg" alt="咱百科" />
              </div>
            </div>
            <ul>
              <li
                v-for="item in videoList.yxChildType.slice(0, 2)"
                :key="item"
                @click="routerTo(item.id)"
              >
                <div>
                  <video
                    :src="item.url"
                    :poster="item.cover"
                    v-if="item.url"
                    preload="none"
                  />
                  <div class="itemBackground" v-else>
                    <div>
                      <img src="../assets/img/Group44982.png" alt="" />
                      <span>更多精彩内容即将上线</span>
                    </div>
                  </div>
                </div>
                <span v-if="item.url">{{ item.name }}</span>
              </li>
            </ul>
          </div>
          <div class="mudioYx">
            <div class="mudCetit">
              <div class="mudCetitOne">
                <img src="../assets/img/mudioYX44978.svg" alt="咱百科" />
                <div>一生最爱</div>
              </div>

              <div
                class="mudCetitTwo"
                v-if="videoList.zaChildType.length > 2"
                @click="toInfoMedia('2')"
              >
                <div>查看更多</div>
                <img src="../assets/img/Group36.svg" alt="咱百科" />
              </div>
            </div>
            <ul>
              <li
                v-for="item in videoList.zaChildType.slice(0, 2)"
                :key="item"
                @click="routerTo(item.id)"
              >
                <div>
                  <video
                    :src="item.url"
                    :poster="item.cover"
                    v-if="item.url"
                    preload="none"
                  />
                  <div class="itemBackground" v-else>
                    <div>
                      <img src="../assets/img/Group44982.png" alt="" />
                      <span>更多精彩内容即将上线</span>
                    </div>
                  </div>
                </div>
                <span v-if="item.url">{{ item.name }}</span>
              </li>
            </ul>
          </div>
          <div class="mudioYx">
            <div class="mudCetit">
              <div class="mudCetitOne">
                <img src="../assets/img/mudioYX44979.svg" alt="咱百科" />
                <div>军人荣誉</div>
              </div>

              <div
                class="mudCetitTwo"
                v-if="videoList.jrChildType.length > 2"
                @click="toInfoMedia('3')"
              >
                <div>查看更多</div>
                <img src="../assets/img/Group36.svg" alt="咱百科" />
              </div>
            </div>
            <ul>
              <li
                v-for="item in videoList.jrChildType.slice(0, 2)"
                :key="item"
                @click="routerTo(item.id)"
              >
                <div>
                  <video
                    :src="item.url"
                    :poster="item.cover"
                    v-if="item.url"
                    preload="none"
                  />
                  <div class="itemBackground" v-else>
                    <div>
                      <img src="../assets/img/Group44982.png" alt="" />
                      <span>更多精彩内容即将上线</span>
                    </div>
                  </div>
                </div>
                <span v-if="item.url">{{ item.name }}</span>
              </li>
            </ul>
          </div>
          <div class="mudioYx">
            <div class="mudCetit">
              <div class="mudCetitOne">
                <img src="../assets/img/mudioYX44980.svg" alt="咱百科" />
                <div>生命斗士</div>
              </div>

              <div
                class="mudCetitTwo"
                v-if="videoList.smChildType.length > 2"
                @click="toInfoMedia('4')"
              >
                <div>查看更多</div>
                <img src="../assets/img/Group36.svg" alt="咱百科" />
              </div>
            </div>
            <ul>
              <li
                v-for="item in videoList.smChildType.slice(0, 2)"
                :key="item"
                @click="routerTo(item.id)"
              >
                <div>
                  <video
                    :src="item.url"
                    :poster="item.cover"
                    v-if="item.url"
                    preload="none"
                  />
                  <div class="itemBackground" v-else>
                    <div>
                      <img src="../assets/img/Group44982.png" alt="" />
                      <span>更多精彩内容即将上线</span>
                    </div>
                  </div>
                </div>
                <span v-if="item.url">{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mudCet">
        <div class="mudCetit">
          <div class="mudOne">
            <img src="../assets/img/GroupCes2.svg" alt="咱百科" />
            <div>模板样片</div>
          </div>
          <div
            class="mudTwo"
            v-if="videoList.data.length > 8"
            @click="toInfoMedia('5')"
          >
            <div>查看更多</div>
            <img src="../assets/img/Group36.svg" alt="咱百科" />
          </div>
        </div>
        <PreloadMudioSheet v-if="dataFalg" />

        <div class="mudUl maxHeightUl" v-else>
          <ul>
            <li
              v-for="item in videoList.data"
              :key="item"
              @click="routerTo(item.id)"
            >
              <div>
                <video :src="item.url" :poster="item.cover" preload="none" />
              </div>
              <span>{{ item.name }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="mudCet">
        <div class="mudCetit">
          <div class="mudOne">
            <img src="../assets/img/GroupCes3.svg" alt="咱百科" />
            <div>操作视频</div>
          </div>
          <div
            class="mudTwo"
            v-if="videoList.operate.length > 8"
            @click="toInfoMedia('6')"
          >
            <div>查看更多</div>
            <img src="../assets/img/Group36.svg" alt="咱百科" />
          </div>
        </div>
        <PreloadMudioSheet v-if="operayionFalg" />

        <div class="mudUl" @click="routerTo" v-else>
          <ul class="operateUl">
            <li v-for="item in videoList.operate.slice(0, 8)" :key="item">
              <div>
                <video :src="item.url" :poster="item.cover" />
              </div>
              <span>{{ item.name }}</span>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script lang="ts">
import { reactive, toRefs, onMounted, ref, onUnmounted } from "vue";
import { useStore } from "vuex";
import router from "../router/index";
// import app1 from "https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/Group44820-gigapixel.png";
// import app2 from "https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/Group44821.png";
import { getVideoList } from "../api/videoData";
// import { getBaseUrl } from "../api/apk";
import PreloadMudioSheet from "../components/loadingAssembly/preloadMudioSheet.vue";

export default {
  name: "MudioSheet",
  components: { PreloadMudioSheet },

  setup() {
    const store = useStore();
    const userInfo = store.state.userInfo;
    const videoList: any = reactive({
      data: [],
      // trailer: [],
      operate: [],
      yxChildType: [],
      zaChildType: [],
      jrChildType: [],
      smChildType: [],
    });
    const trailerFalg = ref(true);
    const dataFalg = ref(true);
    const operayionFalg = ref(true);
    async function getInfoData(type: any) {
      // const baseUrl = await getBaseUrl();

      const templateData = await getVideoList({ type });
      templateData?.data.forEach((item: any) => {
        item.cover = store.state.allWeb.publicLinks + item.cover;
        item.url = store.state.allWeb.publicLinks + item.url;
      });
      // console.log("templateData", templateData);

      return templateData;
    }
    onMounted(() => {
      const trailerData = getInfoData("PROMOTIONAL_VIDEO");
      const templateData = getInfoData("TEMPLATE_VIDEO");
      // const operayionData = getInfoData("OPERATION_TUTORIAL");
      trailerData.then((listData: any) => {
        listData?.data.forEach((item: any) => {
          switch (item.childType) {
            case "MY_HERO":
              videoList.yxChildType.push(item);
              break;
            case "LOVE_OF_MY_LIFE":
              videoList.zaChildType.push(item);
              break;
            case "MILITARY_GLORY":
              videoList.jrChildType.push(item);
              break;
            case "LIFE_FIGHTER":
              videoList.smChildType.push(item);
              break;
          }
        });
        // videoList.trailer = listData?.data;
        videoList.yxChildType.length == 1 ? videoList.yxChildType.push({}) : "";
        videoList.zaChildType.length == 1 ? videoList.zaChildType.push({}) : "";
        videoList.jrChildType.length == 1 ? videoList.jrChildType.push({}) : "";
        videoList.smChildType.length == 1 ? videoList.smChildType.push({}) : "";
        trailerFalg.value = false;
      });
      templateData.then((listData: any) => {
        videoList.data = listData?.data.slice(0, 12);
        dataFalg.value = false;
      });
      // operayionData.then((listData: any) => {
      //   videoList.operate = listData?.data;
      //   operayionFalg.value = false;
      // });

      userInfo.resLayout
        ? (imgs.app1 =
            "https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/Group44821.png")
        : "";
    });
    // const {state}=useStore()
    const docState = ref("true");
    const imgs = reactive({
      app1: "https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/Group44820-gigapixel.png",
    });

    //使用事件委托控制路由跳转传参
    function routerTo(id: any) {
      // let [title, text, src, poster] = ["", "", "", ""];
      // if (e.target.tagName === "VIDEO") {
      //   title = e.target.parentNode.nextSibling.innerText;
      //   // text = e.target.parentNode.nextSibling.nextSibling.innerText;

      //   src = e.target.getAttribute("src");
      //   poster = e.target.getAttribute("poster");
      // }
      // if (e.target.tagName === "SPAN") {
      //   title = e.target.innerText;
      //   // text = e.target.parentNode.nextSibling.lastChild.innerText;
      //   src = e.target.previousSibling.firstChild.getAttribute("src");
      //   poster = e.target.previousSibling.firstChild.getAttribute("poster");
      // }
      // console.log(e);

      const urlMediaCentent = router.resolve({
        path: "/Charitable/textVideo",
        query: {
          id,
          type: "2",
        }, //传参
      });
      // console.log("urlMediaMore", urlMediaMore);
      window.open(urlMediaCentent.href, "_blank");
      // router.push({
      //   name: "MudioCent",
      //   params: {
      //     title,
      //     // text,
      //     src,
      //     poster,
      //   },
      // });
    }

    //视频按钮的移入事件
    // function mousemOve() {
    //   clearTimeout(setTime.value);
    //   var zuLeft = document.querySelector(".zuLeft") as HTMLImageElement;
    //   var zuRight = document.querySelector(".zuRight") as HTMLImageElement;
    //   zuLeft.style.zIndex = "99";
    //   zuRight.style.zIndex = "99";
    //   zuLeft.style.opacity = "0.4";
    //   zuRight.style.opacity = "0.4";
    // }
    //视频按钮的移出事件
    // const setTime = ref();
    // function mouselEave() {
    //   var zuLeft = document.querySelector(".zuLeft") as HTMLImageElement;
    //   var zuRight = document.querySelector(".zuRight") as HTMLImageElement;
    //   setTime.value = setTimeout(() => {
    //     zuLeft.style.zIndex = "-1";
    //     zuRight.style.zIndex = "-1";
    //     zuLeft.style.opacity = "0";
    //     zuRight.style.opacity = "0";
    //   }, 2000);
    // }
    var timer: any = null;
    let falgeNum = 0;
    function backKK(key: any) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (key && falgeNum > 0) {
          falgeNum--;
        } else if (!key) {
          falgeNum++;
        }
        if (docState.value == "true") {
          docState.value = "false";
        } else {
          docState.value = "true";
        }
      }, 200);
    }

    function toInfoMedia(toFlag: number | string) {
      // console.log(111);
      const urlMediaMore = router.resolve({
        path: "/MudioSheetMore",
        query: {
          toFlag,
        }, //传参
      });
      // console.log("urlMediaMore", urlMediaMore);
      window.open(urlMediaMore.href, "_blank");
    }

    onUnmounted(() => {
      // clearTimeout(setTime.value);
      clearTimeout(timer);
      // setTime.value = null;
      timer = null;
    });

    return {
      docState,
      imgs,
      videoList,
      trailerFalg,
      dataFalg,
      operayionFalg,
      // state,
      // ...toRefs(objs),
      routerTo,
      // mousemOve,
      // mouselEave,
      backKK,
      toInfoMedia,
    };
  },
};
</script>

<style scoped lang="scss">
// @keyframes scaleDraw {
//   0% {
//     transform: scale(1);
//   }
//   50% {
//     transform: scale(1.1);
//   }

//   100% {
//     transform: scale(1);
//   }
// }
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media only screen and (min-width: 751px) {
  :deep(button.el-carousel__button) {
    border-radius: 5px;
    width: 10px;
    height: 10px;
  }
  :deep(.el-carousel__container) {
    width: 100% !important;
    height: 220px !important;
    img {
      width: 100% !important;
    }
  }

  #mudioDiv {
    width: 100%;
    padding-bottom: 110px;
    margin-top: 73px;
    // background-image: url(../assets/img/videoBack.webp);
    // background-repeat: no-repeat;
    // background-size: 100%;
    // background-position: right top;
    // background-attachment: fixed;
    a {
      text-decoration: none;
      color: #000;
      padding: 0;
      margin: 0;
      display: block;
    }
    #mudioDivCentent {
      width: 1290px;
      margin: 0 auto;
      margin-top: 31px;
      padding-top: 53px;
      padding-bottom: 42px;
      background: rgba(255, 255, 255, 0.4);
      border: 0.5px solid #dbdbdb;
      box-shadow: 0px 0px 20px 1px rgba(215, 215, 215, 0.25);
      border-radius: 20px;
      .mudCet {
        width: 1112px;
        margin: 0 auto;
        margin-top: 70px;
        margin-bottom: 22px;
        // background: rgba(255, 255, 255, 0.4);
        // border: 0.5px solid #dbdbdb;
        // border-radius: 10px;
        box-sizing: border-box;
        .mudCetit {
          display: flex;
          // align-items: center;
          justify-content: space-between;
          // padding-top: 28px;
          // padding-left: 24px;
          .mudOne {
            display: flex;
            align-items: center;
            img {
              width: 26px;
              height: 26px;
              margin-right: 11px;
            }
            div {
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 44px;
              letter-spacing: 0.29em;

              color: #48555a;
            }
          }

          .mudTwo {
            display: flex;
            align-items: center;
            // display: none;
            &:hover {
              cursor: pointer;
            }
            img {
              width: 22px;
              height: 16px;
              // margin-right: 22px;
            }
            div {
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 32px;
              letter-spacing: 0.29em;
              color: #48555a;
            }
          }
        }

        .mudUl {
          // width: 1102px;
          // height: 620px;
          // padding: 0px 90px;
          position: relative;
          .mudioYx {
            display: inline-block;
            // width: 556px;
            min-height: 275px;
            padding-right: 36px;
            // background: rgba(255, 255, 255, 0.4);
            // border: 0.5px solid #dbdbdb;
            // border-radius: 10px;
            box-sizing: border-box !important;

            &:nth-child(even) {
              // margin-right: 35px;
              padding-left: 38px;
              padding-right: 0px;
            }
            &:nth-child(1) {
              // margin-bottom: 37px;
              border-right: 0.6px solid #e4e4e4;
              border-bottom: 0.6px solid #e4e4e4;
              padding-bottom: 35px;
            }
            &:nth-child(2) {
              // padding-left: 10px;
              padding-bottom: 35px;
              border-bottom: 0.6px solid #e4e4e4;
            }
            &:nth-child(3) {
              padding-top: 50px;
              margin-bottom: 35px;
            }
            &:nth-child(4) {
              padding-top: 50px;
              margin-bottom: 35px;
              border-left: 0.6px solid #e4e4e4;
            }
            .mudCetit {
              // padding-top: 20px;
              // margin-left: 22px;
              // padding-left: 0px;
              padding: 0px;
              box-sizing: border-box;

              .mudCetitOne {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                img {
                  width: 31px;
                  height: 31px;
                  margin-right: 10px;
                }
                div {
                  font-family: "PingFang SC";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 24px;
                  line-height: 44px;

                  letter-spacing: 0.29em;
                  color: #48555a;
                }
              }
              .mudCetitTwo {
                // display: none;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                &:hover {
                  cursor: pointer;
                }
                img {
                  width: 18px;
                  height: 14px;
                  margin-right: 22px;
                }
                div {
                  font-family: "PingFang SC";
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 29px;
                  letter-spacing: 0.15em;
                  color: #5b6b71;
                }
              }
            }
            ul {
              // height: 100%;
              display: flex;
              justify-content: space-between;
              box-sizing: border-box;
              // grid-gap: 0;
              // padding: 0 22px;
              width: 517px !important;
              overflow: hidden;
              margin-left: 0px;
              li {
                width: 245px;
                margin: 0px;
                // &:first-child {
                //   // margin-right: 24px;
                // }
                div {
                  width: 245px;
                  height: 164px;
                  background: #d9d9d9;
                  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
                  border-radius: 12px;
                  margin-top: 23px;
                  margin-bottom: 12px;
                  box-sizing: border-box;
                  video {
                    width: 100%;
                    height: 100%;
                  }
                }
                span {
                  font-family: "PingFang SC";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 29px;
                  letter-spacing: 0.12em;
                  color: #48555a;
                  margin-top: 12px;
                  &:hover {
                    color: #56bd88;
                  }
                }

                .itemBackground {
                  margin: 0;
                  padding: 0;
                  width: 100%;
                  display: inline-block;

                  background: linear-gradient(
                    114.07deg,
                    rgba(222, 255, 225, 0.4) 0.16%,
                    rgba(221, 251, 255, 0.4) 99.97%
                  );
                  border: 1px solid #98d6ff;
                  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
                  border-radius: 12px;
                  box-sizing: border-box;
                  div {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    img {
                      display: inline-block;
                      width: 56px;
                      height: 56px;
                    }
                    span {
                      display: inline-block;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 26px;
                      text-align: center;
                      letter-spacing: 0.12em;
                      color: #48555a;
                      margin-top: 14px;
                    }
                  }
                }
              }
            }
          }

          ul {
            width: 1112px;
            height: 100%;
            display: grid;
            justify-content: space-evenly;
            grid-template-columns: repeat(auto-fill, 258px);
            grid-gap: 24px;
            max-height: 660px;
            overflow: hidden;
            margin-top: 14px;
            box-sizing: border-box;
            li {
              width: 258px;
              margin-bottom: 14px;
            }
            div {
              width: 258px;
              height: 172px;
              overflow: hidden;
              background: #d9d9d9;
              box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
              border-radius: 12px;
              // margin-top: 2px;
              // margin-left: 2px;
              video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
                transition: all 1s ease 0s;
                &:hover {
                  cursor: pointer;
                  transform: scale(1.2);
                }
              }
            }

            span {
              display: inline-block;
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 29px;
              letter-spacing: 0.12em;
              color: #48555a;
              margin-top: 15px;
              max-width: 257px;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              overflow: hidden;
              -webkit-box-orient: vertical;
              letter-spacing: 0.12em;
              // text-shadow: 1px 0px 0px #141414;
              // opacity: 0.85;
              &:hover {
                cursor: pointer;
                color: #56bd88;
              }
            }
            // .VideoSpanTwo {
            //   width: 281px;
            //   margin-top: 12px;
            //   font-weight: 300;
            //   font-size: 14px;
            //   letter-spacing: 0.18em;
            //   text-shadow: none;
            //   color: #48555a;
            // }
          }
          // .operateUl {
          //   display: flex;
          //   justify-content: space-between;
          //   padding-right: 5px;
          //   li {
          //     div {
          //       background: #fff;
          //       // border: ;
          //       border: 0.5px solid #dbdbdb;
          //       // video {
          //       //   // box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
          //       // }
          //     }
          //   }
          // }
          // .imgZu {
          //   -webkit-transition: all 0.7s;
          //   -moz-transition: all 0.7s;
          //   -ms-transition: all 0.7s;
          //   -o-transition: all 0.7s;
          //   transition: all 0.7s;
          //   opacity: 0;
          // }
          // .zuLeft {
          //   position: absolute;
          //   top: calc(50% - 58px);
          //   left: -81px;
          //   width: 56px;
          //   height: 56px;
          //   border-radius: 50%;
          //   z-index: -1;
          //   &:hover {
          //     cursor: pointer;
          //   }
          // }
          // .zuRight {
          //   position: absolute;
          //   top: calc(50% - 58px);
          //   right: -81px;
          //   width: 56px;
          //   height: 56px;
          //   border-radius: 50%;
          //   z-index: -1;
          //   &:hover {
          //     cursor: pointer;
          //   }
          // }
        }
        .maxHeightUl {
          max-height: 1188px;
          ul {
            max-height: 1188px;
            li {
              span {
                line-height: 32px;
              }
            }
          }
        }

        .mudCetOne {
          margin-top: 44px !important;
          display: flex;
          flex-wrap: wrap;
        }
      }
      .mudCetOne {
        margin-top: 0px !important;
        padding-bottom: 28px !important;
        border: none;

        border-bottom: 0.6px solid #e4e4e4 !important;
        border-radius: 0px;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  :deep(button.el-carousel__button) {
    border-radius: 3px;
    width: 6px;
    height: 6px;
  }
  :deep(.el-carousel__container) {
    height: 242px;
  }
  #mudioDiv {
    width: 750px;
    padding-bottom: 118px;
    margin-top: 73px;
    background: url(https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/videoBack.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    a {
      text-decoration: none;
      color: #000;
      padding: 0;
      margin: 0;
      display: block;
    }
    #mudioDivCentent {
      .mudCetOne {
        margin-top: 44px !important;
      }
      .mudCet {
        width: 100%;
        margin: 0 auto;
        margin-top: 113px;
        .mudCetit {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          padding-left: 24px;

          display: flex;
          // align-items: center;
          justify-content: space-between;
          .mudOne {
            display: flex;
            align-items: center;
            img {
              width: 49px;
              height: 49px;
              margin-right: 20px;
            }
            div {
              font-style: normal;
              font-weight: 400;
              font-size: 30px;
              line-height: 55px;

              text-align: justify;
              letter-spacing: 0.29em;

              color: #48555a;
            }
          }

          .mudTwo {
            display: flex;
            align-items: center;
            // display: none;
            img {
              width: 33px;
              height: 24px;
              margin-right: 17px;
            }
            div {
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 39px;

              text-align: justify;
              letter-spacing: 0.29em;

              color: #48555a;
            }
          }
        }

        .mudUl {
          width: 702px;
          // height: 620px;
          position: relative;
          margin: 0 auto;
          margin-top: 12px;
          .mudioYx {
            display: inline-block;
            width: 100%;
            min-height: 382px;
            box-shadow: 0px 0px 12px rgba(126, 202, 252, 0.15);
            border-radius: 10px;
            margin-bottom: 90px;

            .mudCetit {
              padding-top: 22px;
              // margin-left: 9px;
              padding-left: 12px;
              .mudCetitOne {
                display: flex;
                align-items: center;
                img {
                  width: 44px;
                  height: 44px;
                  margin-right: 16px;
                }
                div {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 32px;
                  line-height: 56px;
                  letter-spacing: 0.29em;
                  color: #48555a;
                }
              }
              .mudCetitTwo {
                display: none;
              }
            }
            ul {
              // height: 100%;
              display: flex;
              justify-content: start;
              grid-gap: 0;
              padding: 0 12px;
              width: 100%;
              overflow: hidden;
              li {
                width: 333px;
                margin-bottom: 13px;
                &:first-child {
                  margin-right: 11px;
                }
                div {
                  width: 333px;
                  height: 222px;
                  margin-top: 22px;
                  margin-bottom: 13px;
                  border-radius: 12px;
                  video {
                    width: 100%;
                    height: 100%;
                  }
                }
                span {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 28px;
                  line-height: 45px;
                  letter-spacing: 0.12em;
                  color: #48555a;
                  margin-top: 0;
                }
                .itemBackground {
                  margin: 0;
                  padding: 0;
                  width: 100%;
                  display: inline-block;

                  background: linear-gradient(
                    114.07deg,
                    rgba(222, 255, 225, 0.4) 0.16%,
                    rgba(221, 251, 255, 0.4) 99.97%
                  );
                  border: 1px solid #98d6ff;
                  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
                  border-radius: 12px;
                  box-sizing: border-box;
                  div {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    img {
                      display: inline-block;
                      width: 69px;
                      height: 69px;
                    }
                    span {
                      display: inline-block;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 20px;
                      line-height: 32px;
                      text-align: center;
                      letter-spacing: 0.12em;
                      color: #48555a;
                      margin-top: 17px;
                    }
                  }
                }
              }
            }
          }

          ul {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            // grid-template-columns: repeat(auto-fill, 281px);
            // grid-gap: 10px;
            // max-height: 660px;
            flex-wrap: wrap;
            li {
              width: 344px;
              margin-bottom: 40px;
            }
            div {
              width: 344px;
              height: 229px;
              overflow: hidden;
              border-radius: 14px;
              video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 1s ease 0s;
                &:hover {
                  cursor: pointer;
                  transform: scale(1.2);
                }
              }
            }

            span {
              display: inline-block;
              // font-style: bold;
              font-weight: 500;
              font-size: 28px;
              color: #48555a;
              margin-top: 6px;
              line-height: 32px;
              // max-width: 358px;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              overflow: hidden;
              -webkit-box-orient: vertical;
              letter-spacing: 0.12em;
              // text-shadow: 1px 0px 0px #141414;
              // opacity: 0.85;
              &:hover {
                cursor: pointer;
                color: #56bd88;
              }
            }
          }
          // .imgZu {
          //   -webkit-transition: all 0.7s;
          //   -moz-transition: all 0.7s;
          //   -ms-transition: all 0.7s;
          //   -o-transition: all 0.7s;
          //   transition: all 0.7s;
          //   opacity: 0;
          // }
          // .zuLeft {
          //   position: absolute;
          //   top: calc(50% - 58px);
          //   left: -81px;
          //   width: 56px;
          //   height: 56px;
          //   border-radius: 50%;
          //   z-index: -1;
          //   &:hover {
          //     cursor: pointer;
          //   }
          // }
          // .zuRight {
          //   position: absolute;
          //   top: calc(50% - 58px);
          //   right: -81px;
          //   width: 56px;
          //   height: 56px;
          //   border-radius: 50%;
          //   z-index: -1;
          //   &:hover {
          //     cursor: pointer;
          //   }
          // }
        }
      }
    }
  }
}
</style>
