import request from "../../utils/request";

//获取地址
export function getVideoList(params: object) {
  return request({
    url: `/video/web-list`,
    method: "get",
    params,
  });
}

//获取官网宣传片
export function getVideoLogin(id: string | number) {
  return request({
    url: `material/get-one/${id}`,
    method: "get",
  });
}
//获取官网操作展示视频
export function getVideoName(params: object) {
  return request({
    url: `video/video`,
    method: "get",
    params,
  });
}

//直播轮播列表
export function getliveBroadcast() {
  return request({
    url: `/live/list`,
    method: "get",
  });
}
//获取直播间名称

export function getliveName() {
  return request({
    url: `/live/live-room`,
    method: "get",
  });
}
