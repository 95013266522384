<template>
  <div>
    <!-- <ListLoading v-show="operayionFalg" /> -->

    <div
      id="videoHlsDiv"
      @mouseenter="moveVideo($event, true)"
      @mouseleave="moveVideo($event, false)"
      @click="clickOne($event)"
      @dblclick="addHelp($event)"
    >
      <!-- controls
      muted -->
      <video
        ref="videoPlay"
        id="mmiid"
        style="object-fit: contain; background: #000"
      ></video>
      <div id="VideoIcon">
        <div
          class="divImgTwo"
          @mouseenter="moveBlockTwo(true)"
          @mouseleave="moveBlockTwo(false)"
        >
          <ul class="imgTwoDiv" @click="changeUrl">
            <li><span>超清</span><img :src="loginImg" /></li>
            <li><span>高清</span><img :src="loginImgOne" /></li>
            <li><span class="classImg">标清</span></li>
          </ul>
          <div class="divImgTwoText">{{ videoIconText }}</div>
        </div>
        <div class="divImgThree">
          <div
            class="imgThreeDiv"
            @mouseenter="moveBlockOne(true)"
            @mouseleave="moveBlockOne(false)"
          >
            <!-- <input type="range" min="0" value="50" max="100" id="range" /> -->
            <el-slider
              v-model="inputRange"
              vertical
              :show-tooltip="showTooltip"
              @input="changeSlider"
            />
          </div>
          <img
            class="imgThree"
            :src="imgTwoUrl"
            alt="声音大小"
            @mouseenter="moveBlockOne(true)"
            @mouseleave="moveBlockOne(false)"
            @click="oparedAudio"
          />
        </div>
        <img
          class="imgFour"
          :src="imgThreeUrl"
          alt="全屏缩放"
          @click="fullScreen"
        />
      </div>
      <div class="backgroundLive"></div>
      <div id="btnLiveGif" v-if="btnLiveGifValue" @click="changeVideoGifPlay()">
        点击查看直播
      </div>
      <img class="likeLive" src="../../assets/img/likeLive.svg" alt="点赞" />
      <img class="likeLiveTwo" src="../../assets/img/likeLive.svg" alt="点赞" />
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, ref, onMounted, nextTick, onUnmounted } from "vue";
import { useStore } from "vuex";
import hlsjs from "hls.js";
import { getliveBroadcast, getliveName } from "../../api/videoData/index";
import loginToken from "../../assets/img/loginToken.svg";
import loginTokenOne from "../../assets/img/loginTokenOne.svg";
import Group45243 from "../../assets/img/Group45243.svg";
import Frame47 from "../../assets/img/Frame47.svg";
import Group45287 from "../../assets/img/Group45287.svg";
import Group45289 from "../../assets/img/Group45289.svg";
import { Session, Local } from "../../utils/storage";
// import ListLoading from "../../components/loadingAssembly/preloadVideo.vue";
import likeLive from "../../assets/img/likeLive.svg";
export default {
  name: "VideoHls",
  components: {
    // ListLoading,
  },
  props: [],
  setup(props: any, { emit }: any) {
    // //判断链接是否可行
    // function videoFlage() {
    //   const videoOne = document.createElement("video");
    //   // videoOne.src =
    //   //   "http://192.168.10.250:8000/live/hls/obs_stream_middle/index.m3u8";
    //   videoUrl.value =
    //     "rtmp://mobliestream.c3tv.com:554/live/goodtv.sdp";
    //     videoOne.src =
    //     "rtmp://mobliestream.c3tv.com:554/live/goodtv.sdp";

    //   videoOne.onload = function () {
    //     console.log(111);
    //     videoHls();
    //     emit("changeFlageVideo", true);
    //   };
    //   videoOne.onerror = function () {
    //     console.log(222);
    //     postingVideoHls();
    //     emit("changeFlageVideo", false);
    //     // don't show video element
    //   };
    // }
    const btnLiveGifValue = ref(true);
    const operayionFalg = ref(true);

    onMounted(() => {
      imgTwoUrl.value = Group45243;
      imgThreeUrl.value = Group45287;
      // console.log( import.meta.env.VITE_LIVE_URL)
      videoUrl.value =
        import.meta.env.VITE_LIVE_URL + "live/hls/stream_middle/index.m3u8";
      // videoUrl.value =
      //   "http://playertest.longtailvideo.com/adaptive/bipbop/gear4/prog_index.m3u8";
      videoHls();
      const videoDefinition = Local.get("videoDefinition");
      // console.log(videoDefinition);

      // const video = videoPlay.value;
      videoPlay.value.addEventListener("play", () => {
        // console.log("videoPlay播放中");
        videoPlay.value.volume=videoVolume/100
        btnLiveGifValue.value = false;
      });

      if (videoDefinition) {
        changeIcon(videoDefinition);
        getSpanAll(videoDefinition);
      }
      operayionFalg.value = false;
      // videoUrl.value= env.LIVE_URL

      // hlsjsOne.value ? hlsjsOne.value.destroy() : "";

      // nextTick(() => {
      // videoFlage(videoUrl.value);
      // });
    });

    const videoIconText = ref("标清");
    const showTooltip = ref(false);
    const loginImg = ref(loginToken);
    const loginImgOne = ref(loginToken);
    const inputRange = ref(50);
    var timeNullOne: any;
    var timeNullTwo: any;
    const moveBlockOne = (falg: any) => {
      const divId = document.getElementById("VideoIcon") as HTMLElement;
      const imgThreeDiv = divId
        ? (divId.querySelector(".imgThreeDiv") as HTMLElement)
        : (document.querySelector(".imgThreeDiv") as HTMLElement);
      clearTimeout(timeNullOne);
      if (falg) {
        imgThreeDiv.style.display = "block";
      } else {
        timeNullOne = setTimeout(() => {
          imgThreeDiv.style.display = "none";
        }, 500);
      }
    };
    const moveBlockTwo = (falg: any) => {
      // const divId = document.getElementById("VideoIcon") as HTMLElement;
      const imgThreeDiv = document.querySelector(".imgTwoDiv") as HTMLElement;
      clearTimeout(timeNullTwo);
      if (falg) {
        imgThreeDiv.style.display = "block";
      } else {
        timeNullTwo = setTimeout(() => {
          imgThreeDiv.style.display = "none";
        }, 500);
      }
    };
    const imgTwoUrl = ref("");
    const changeSlider = () => {
      // console.log("inputRange", inputRange.value);
      const ctIvm = document.getElementById("mmiid") as HTMLVideoElement;
      videoVolume = inputRange.value;
      ctIvm.volume = inputRange.value / 100;
      ctIvm.volume === 0
        ? (imgTwoUrl.value = Frame47)
        : (imgTwoUrl.value = Group45243);
    };
    var videoVolume: number = 50;
    var audioFlag: Boolean = true;
    const oparedAudio = () => {
      const video = videoPlay.value;
      if (video.volume !== 0) {
        videoVolume = inputRange.value;
        inputRange.value = 0;
        video.volume = 0;
        imgTwoUrl.value = Frame47;
      } else {
        inputRange.value = videoVolume;
        video.volume = inputRange.value / 100;
        imgTwoUrl.value = Group45243;
      }
    };
    const imgThreeUrl = ref("");
    var videoWidth: any;
    var videoHeight: any;
    const fullScreen = () => {
      const hearde = document.getElementById("hearde") as HTMLVideoElement;
      const videoImg = document.getElementById(
        "videoPositionBackground"
      ) as HTMLVideoElement;
      const video = document.getElementById("mmiid") as HTMLVideoElement;
      // videoIcon
      const cliWidth = window.innerWidth;

      if (video.clientWidth !== cliWidth) {
        const cliHeight = window.innerHeight;
        videoWidth = video.clientWidth;
        videoHeight = video.clientHeight;
        video.style.width = cliWidth + "px";
        video.style.height = cliHeight + "px";
        videoImg.classList.add("fullScreen");
        document.body.style.overflowY = "hidden";
        imgThreeUrl.value = Group45289;
        hearde.style.zIndex = "0";
      } else {
        video.style.width = videoWidth + "px";
        video.style.height = videoHeight + "px";
        videoImg.classList.remove("fullScreen");
        document.body.style.overflowY = "auto";
        imgThreeUrl.value = Group45287;
        hearde.style.zIndex = "1000";
      }
    };
    function getSpanAll(textName: string) {
      const imgTwoDiv = document.querySelector(".imgTwoDiv");
      const spanAll = imgTwoDiv?.querySelectorAll("span");
      spanAll?.forEach((item: any) => {
        item.classList.remove("classImg");
        item.innerText === textName ? item.classList.add("classImg") : "";
      });
    }
    function changeUrl(e: any) {
      // console.log("e", e.target.children[0]);
      getSpanAll("");
      switch (e.target.tagName) {
        case "SPAN":
          e.target.classList.add("classImg");
          changeIcon(e.target);
          break;
        case "IMG":
          e.target.parentNode.children[0].classList.add("classImg");
          changeIcon(e.target.parentNode.children[0]);
          break;
        case "LI":
          e.target.children[0].classList.add("classImg");
          changeIcon(e.target.children[0]);
          break;
      }
      // console.log("spanAll", spanAll);
    }
    //储存token
    const tokenParams = ref("");
    const LoginFlage = ref(false);
    // stream统一
    // https://zanbaike.com/
    // http://192.168.10.250:8000/live/hls/obs_stream_middle/index.m3u8
    const videoUrl: any = ref("");
    //加载VideoHls
    const hlsjsOne: any = ref("");
    const videoPlay: any = ref("");
    const customXhrSetup = (xhr: any, url: any) => {
      // 设置超时时间为 10 秒
      xhr.timeout = 10000;
      // 设置请求头信息
      // const data = url.split("?");
      // // console.log(data[1]);
      // data[1] !== "token=test" ? (url = url + "?token=test") : "";
      // console.log("url",url);
      // console.log("tokenParams.value", tokenParams.value);
      xhr.open("GET", url, true);
      // LoginFlage.value = true;
      LoginFlage.value ? xhr.setRequestHeader("token", tokenParams.value) : "";
      // xhr.responseType = "blob";
      //请求成功回调函数
      // xhr.onload = function (e: any) {
      //   console.log("e",e);
      //   // console.log("this.status",this.status);

      //   // if (this.status == 200) {
      //     //请求成功
      //     //获取blob对象
      //     // var blob = this.response;
      //     // var video = document.getElementById("mmiid");
      //     //获取blob对象地址，并把值赋给容器
      //     // video.onload = function (e) {
      //     //   window.URL.revokeObjectURL(video.src);
      //     // };
      //     // var obj_url = window.URL.createObjectURL(blob);
      //     // video.src = obj_url;
      //     //video.play();
      //   // }
      // };
      // xhr.send();
      return xhr;
    };
    // const videoLiveTitle = ref("");
    // const videoLiveFlage = ref(true);
    function videoHls() {
      // hlsjsOne.value ? hlsjsOne.value.destroy() : "";
      const video = videoPlay.value;
      // console.log("video", video);
      if (hlsjs.isSupported()) {
        hlsjsOne.value = new hlsjs({
          xhrSetup: customXhrSetup,
        });

        // console.log(hlsjsOne.value);
        hlsjsOne.value.on(
          hlsjs.Events.MANIFEST_PARSED,
          async (event: any, data: any) => {
            const liveData = await getliveName();
            // console.log(liveData.data);
            emit("childElement", liveData.data);
            // videoLiveTitle.value = liveData.data;
            video?.play();

            // console.log(event, data);
          }
        );

        hlsjsOne.value.on(hlsjs.Events.ERROR, (event: any, data: any) => {
          // console.log(event, data);
          // hlsjsOne.startLoad()
          // var errorType = data.type;
          // var errorDetails = data.details;
          // var errorFatal = data.fatal;
          // // // console.log("errorType", errorType);
          // // // console.log("errorDetails", errorDetails);
          // // // console.log("errorFatal", errorFatal);
          // // // hlsjsOne.recoverMediaError()
          // switch (errorDetails) {
          //   case hlsjsOne.value.ErrorDetails.FRAG_LOAD_ERROR:
          //     console.log("网络相关的错误");
          //     break;
          //   case hlsjsOne.value.ErrorDetails.MEDIA_ERROR:
          //     console.log("媒体/视频相关错误");
          //     break;
          //   case hlsjsOne.value.ErrorDetails.OTHER_ERROR:
          //     console.log("所有其他错误");
          //     break;
          // }
          // // 监听出错事件
          // // await getliveBroadcast()
          // videoLiveFlage.value = false;
          postingVideoHls();
          emit("changeFlageVideo", false);
          // console.log("加载失败");
        });
        // videoUrl.value="http://192.168.10.250:8000/live/hls/obs_stream_hd720/index.m3u8"
        hlsjsOne.value.loadSource(videoUrl.value); //设置播放路径
        hlsjsOne.value.attachMedia(video); //解析到video标签上
      } else {
        console.log("不支持的格式");
        return;
      }
    }

    const store = useStore();
    const userInfo = store.state.userInfo;

    async function changeIcon(params: any) {
      if (Session.get("userInfo")) {
        tokenParams.value = Session.get("userInfo");
        // console.log("userInfos", userInfo);
      } else if (Local.get("userInfo")) {
        tokenParams.value = Local.get("userInfo");
        // console.log("userInfosLocal", userInfo);
      }
      if (params.innerText === "高清" || params === "高清") {
        loginImgOne.value = loginTokenOne;
        loginImg.value = loginToken;

        await loginUserInfo(
          import.meta.env.VITE_LIVE_URL + "live/hls/stream_hd720/index.m3u8",
          "高清"
        );
        // await loginUserInfo(
        //   import.meta.env.VITE_LIVE_URL +
        //     "live/hls/obs_stream_hd720/index.m3u8",
        //   "高清"
        // );
      } else if (params.innerText === "超清" || params === "超清") {
        loginImg.value = loginTokenOne;
        loginImgOne.value = loginToken;
        await loginUserInfo(
          import.meta.env.VITE_LIVE_URL + "live/hls/stream_src/index.m3u8",
          "超清"
        );
        // await loginUserInfo(
        //   import.meta.env.VITE_LIVE_URL + "live/hls/obs_stream_src/index.m3u8",
        //   "超清"
        // );
      } else {
        postingVideoHls();
        loginImg.value = loginToken;
        loginImgOne.value = loginToken;
        LoginFlage.value = false;
        videoUrl.value =
          import.meta.env.VITE_LIVE_URL + "live/hls/stream_middle/index.m3u8";
        // videoUrl.value =
        //   import.meta.env.VITE_LIVE_URL +
        //   "live/hls/obs_stream_middle/index.m3u8";
        videoHls();
        videoIconText.value = "标清";
      }
    }
    //限载VideoHls
    function postingVideoHls() {
      if (hlsjsOne.value) {
        hlsjsOne.value.destroy();
        hlsjsOne.value.detachMedia();
      }
    }
    // const popupFlage: any = ref(false);

    function loginUserInfo(params: any, textString: string) {
      // console.log(textString);

      if (userInfo.login) {
        postingVideoHls();
        LoginFlage.value = true;
        videoUrl.value = params;
        videoHls();
        videoIconText.value = textString;
        // console.log(textString);
        Local.set("videoDefinition", textString);
      } else {
        emit("childChangePopup", { PopFalg: true, textName: textString });
      }
    }
    function changeColor(num: number, textTitle: string) {
      const imgTwoDiv = document.querySelector(".imgTwoDiv");
      const spanAll: any = imgTwoDiv?.querySelectorAll("span");
      spanAll?.forEach((item: any) => {
        item.classList.remove("classImg");
      });
      loginImg.value = loginToken;
      loginImgOne.value = loginToken;
      spanAll[num].classList.add("classImg");
      videoIconText.value = textTitle;
    }
    function changeVideoGifPlay() {
      videoPlay.value?.play();
      btnLiveGifValue.value = true;
    }

    var timeVideoAdd: any;
    function removeAdd(VideoIcon: HTMLElement, btnLiveGif: HTMLElement) {
      VideoIcon.classList.add("showDivTwo");
      VideoIcon.classList.remove("fadeinOne");
      btnLiveGif?.classList.add("showDivTwo");
      btnLiveGif?.classList.remove("fadeinOne");
    }
    function moveVideo(e: any, params: boolean) {
      clearTimeout(timeVideoAdd);
      // const videoIcon = e.target.parentNode.querySelector(
      //   ".videoIcon"
      // ) as HTMLElement;
      const btnLiveGif = document.querySelector("#btnLiveGif") as HTMLElement;
      const VideoIcon = document.querySelector("#VideoIcon") as HTMLElement;
      if (params) {
        VideoIcon.classList.add("fadeinOne");
        VideoIcon.classList.remove("showDivTwo");

        btnLiveGif?.classList.add("fadeinOne");
        btnLiveGif?.classList.remove("showDivTwo");
      } else {
        removeAdd(VideoIcon, btnLiveGif);
      }
      // console.log(e.target);
      // console.log(videoId.value);
    }

    var helpNum = 0;
    var helpTimeout: any;
    // var helpTimeoutOne: any;
    // var helpTimeoutTwo: any;
    // function determineHtml(likeLiveOne: HTMLElement) {
    //   const videoHlsDiv = document.getElementById("videoHlsDiv") as HTMLElement;
    //   if (likeLiveOne) {
    //     helpTimeoutOne = setTimeout(() => {
    //       videoHlsDiv.removeChild(likeLiveOne);
    //     }, 800);
    //   } else {
    //     const img = document.createElement("img");
    //     img.src = likeLive;
    //     img.classList.add("likeLive");
    //     videoHlsDiv.appendChild(img);
    //   }
    // }
    function changeLikeImg(e: any, likeLiveOne: HTMLElement) {
      //  console.log(likeLiveOne.style.left);
      const likeLiveTwo = document.querySelector(".likeLiveTwo") as HTMLElement;
      const likeFalg = likeLiveTwo.className.indexOf("likeLiveOne") > -1;
    //  console.log(likeFalg);   
      let nameLike: any;
      let imgLeft: any;
      let imgTop: any;
      if (likeFalg) {
        nameLike = likeLiveOne;
        imgLeft = likeLiveTwo.style.left;
        imgTop = likeLiveTwo.style.top;
        // console.log("imgTop",imgTop); 
      //    clearTimeout(helpTimeoutOne)
      // helpTimeoutOne = setTimeout(() => {
        likeLiveTwo.classList.remove("likeLiveOne");
      // }, 800);
       
      } else {
        nameLike = likeLiveTwo;
        imgLeft = likeLiveOne.style.left;
        imgTop = likeLiveOne.style.top;
        // console.log("imgTopOne",imgTop);

      //   clearTimeout(helpTimeoutTwo)
      //   helpTimeoutTwo = setTimeout(() => {
        likeLiveOne.classList.remove("likeLiveOne");
      // }, 800);
      }
      //  console.log("nameLike",nameLike);
      nameLike.style.left = e.layerX + "px";
      if (e.target.tagName === "DIV") {
        nameLike.style.top = e.layerY + e.target.clientHeight / 2 + "px";
      } else if (e.target.tagName === "VIDEO") {
        nameLike.style.top = e.layerY + "px";
      } else {
        nameLike.style.left = imgLeft;
        nameLike.style.top = imgTop;
      }

      nameLike.classList.add("likeLiveOne");
    }
    async function clickOne(e: any) {
      if (helpNum === 2) {
        clearTimeout(helpTimeout);
        // clearTimeout(helpTimeoutOne)
        const likeLiveOne = document.querySelector(".likeLive") as HTMLElement;
        // await determineHtml(likeLiveOne)
        changeLikeImg(e, likeLiveOne);
        clearTimeout(helpTimeout);
        helpTimeout = setTimeout(() => {
          helpNum = 0;
        }, 800);
      }
    }
    async function addHelp(e: any) {
       if (helpNum === 0 && !btnLiveGifValue.value) {
        const likeLiveOne = document.querySelector(".likeLive") as HTMLElement;
          // await determineHtml(likeLiveOne)
         helpNum = 2;
        changeLikeImg(e, likeLiveOne);
       
       }

      // helpNum= ++helpNum
      // // console.log(helpNum);
      // // console.log(e);
      // console.log(e);
    }
    onUnmounted(() => {
      clearTimeout(helpTimeout);
      // clearTimeout(helpTimeoutOne)
    });
    return {
      // popupFlage,
      videoPlay,
      loginImg,
      loginImgOne,
      videoIconText,
      showTooltip,
      inputRange,
      imgTwoUrl,
      imgThreeUrl,
      btnLiveGifValue,
      operayionFalg,
      moveBlockOne,
      moveBlockTwo,
      changeSlider,
      oparedAudio,
      fullScreen,
      changeUrl,
      changeColor,
      changeVideoGifPlay,
      moveVideo,
      addHelp,
      clickOne,
    };
  },
};
</script>
<style scoped lang="scss">
.fadeinOne {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1 !important;
}

.showDivTwo {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0 !important;
}
.fullScreen {
  video {
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;
    z-index: 11111 !important;
    border-radius: 0px !important;
  }
  #VideoIcon {
    position: fixed !important;
    z-index: 11111 !important;
  }
  .titleLive {
    position: fixed !important;
    z-index: 11112 !important;
  }
  .backgroundLive {
    position: fixed !important;
    z-index: 1111 !important;
  }
}
.classImg {
  color: #13d571 !important;
}
@keyframes changeLike {
  0% {
    opacity: 0;
    transform: scale(2);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
@media only screen and (min-width: 751px) {
  video::-webkit-media-controls-enclosure {
    display: none;
    // opacity: 0;
  }
  #videoHlsDiv {
    position: relative;
    width: 1152px;
    height: 576px;
    margin: 0 auto;
    #mmiid {
      display: block;
      width: 1152px;
      height: 576px;
      margin: 0px auto;
      background: #ffffff;
      border-radius: 12px;
    }
    #VideoIcon {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 22px;
      right: 44px;
      opacity: 0;
      .divImgTwo {
        position: relative;
        margin-right: 92px;
        z-index: 111;
        &:hover {
          cursor: pointer;
        }
        .imgTwoDiv {
          display: none;
          position: absolute;
          bottom: 42px;
          left: -18px;
          width: 73px;
          // height: 110px;
          background: rgba(58, 58, 58, 0.5);
          border-radius: 2px;
          padding: 9px 0px;
          li {
            position: relative;
            height: 30px;
            display: flex;
            align-items: center;
            padding-left: 19px;
            &:hover {
              cursor: pointer;
              background: rgba(247, 247, 247, 0.3);
            }
            span {
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 30px;
              text-align: justify;
              letter-spacing: 0.02em;
              color: #ffffff;
            }
            img {
              width: 11px;
              height: 11px;
            }
          }
        }
        .divImgTwoText {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          text-align: justify;
          letter-spacing: 0.06em;
          color: #ffffff;
        }
      }
      .divImgThree {
        position: relative;
        margin-right: 88px;
        z-index: 111;
        &:hover {
          cursor: pointer;
        }
        .imgThreeDiv {
          display: none;
          width: 30px;
          height: 75px;
          background: rgba(58, 58, 58, 0.5);
          border-radius: 2px;
          position: absolute;
          bottom: 46px;
          left: -6px;
          z-index: 11;
          // input {
          //   position: absolute;
          //   bottom: 34px;
          //   left: -16px;
          //   width: 56px;
          //   height: 2px;
          //   transform: rotate(270deg);
          // }
          // :deep(.el-slider) {
          //   width: 4px !important;
          //   height: 56px !important;
          // }
          :deep(.el-slider__runway) {
            width: 2px !important;
            height: 56px !important;
            // margin: 0px !important;
            margin: 8px 14px !important;
            background: rgba(255, 255, 255, 0.5);
          }
          :deep(.el-slider__bar) {
            width: 2px !important;
            background: #11c066 !important;
          }
          :deep(.el-slider__button-wrapper) {
            width: 6px !important;
            height: 6px !important;
            left: -2px;
            // margin: 0 50% !important;
            transform: none !important;
            background: #ffffff;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            &:hover {
              cursor: pointer;
            }
          }

          :deep(.el-slider__button) {
            display: none;
            // width: 6px !important;
            // height: 6px !important;
            // border: none !important;
            &:hover {
              display: none;
            }
          }
        }
        .imgThree {
          width: 24px;
          height: 24px;
          margin-top: 5px;
        }
      }
      .imgFour {
        z-index: 111;
        width: 20px;
        height: 20px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .backgroundLive {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 378px;
      background: linear-gradient(
        179.29deg,
        rgba(255, 255, 255, 0) 66.31%,
        rgba(90, 90, 90, 0.52) 96.37%
      );
      border-radius: 12px;
      // z-index: -11;
    }
    #btnLiveGif {
      position: absolute;
      // top: 230px;
      // left: 428px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      width: 335px;
      height: 68px;
      z-index: 11111;
      border-radius: 35px;
      background-color: rgba(58, 58, 58, 0.4);
      background-image: url("../../assets/img/liveDong.gif");
      background-repeat: no-repeat;
      background-size: 30px 28px;
      background-position: 62px center;
      color: #fff;
      // text-align: center;
      font-family: "PingFang SC";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 68px;
      letter-spacing: 5.76px;
      padding-left: 100px;
      box-sizing: border-box;
      &:hover {
        cursor: pointer;
      }
    }
    [class^="likeLive"] {
      position: absolute;
      top: 0;
      opacity: 0;
    }

    .likeLiveOne {
      position: absolute;
      width: 44px;
      height: 41px;
      animation: changeLike linear 0.8s ;
    }
  }
}
</style>
