<template>
  <div id="timeAllDiv" v-if="timeList.arrList.length > 0">
    <ul id="timeAxisUl">
      <li
        class="timeAxisUlLi"
        v-for="(item, index) in timeList.arrList"
        :key="index"
      >
        <div class="liDiv">
          <div class="liDivOne">
            <div class="divLeftBorder"></div>
            <span>{{ item.date }}</span>
            <img
              src="../assets/img/timeAxisBtn.svg"
              alt="咱百科"
              @click.stop="changeDisp"
            />
            <div class="menDiv">
              <div class="menDivOne" @click="goTimeShare(item.id)">分享</div>
              <div class="menDivTwo" @click="toDelete(item.id)">删除</div>
            </div>
          </div>
          <div class="liDivTwo">
            <div class="liDivTwoContent">{{ item.content }}</div>
            <div v-if="item.content?.length >= 64">
              <span v-if="changeFalg" @click.stop="changeDiv($event, item.id)"
                >更多</span
              >
              <span v-else @click.stop="changeDivFalg($event, item.id)"
                >收起</span
              >
            </div>
          </div>
          <div class="liDivThree">
            <ul
              class="liDivThreeUl"
              v-if="item.flageImg == 1 && item.Type !== 'video'"
            >
              <li v-for="element in item.files" :key="element">
                <div class="imgDivUrl">
                  <el-image
                    :src="element"
                    :preview-src-list="item.files"
                    :initial-index="0"
                    :url-list="item.files"
                    fit="cover"
                  />
                </div>
              </li>
            </ul>
            <ul
              class="liDivThreeUlTwo"
              v-if="item.flageImg == 2 && item.Type !== 'video'"
            >
              <li v-for="element in item.files" :key="element">
                <div class="imgDivUrl">
                  <el-image
                    :src="element"
                    :preview-src-list="item.files"
                    :initial-index="0"
                    :url-list="item.files"
                    fit="cover"
                  />
                </div>
              </li>
            </ul>
            <ul
              class="liDivThreeUlThree"
              v-if="item.flageImg == 3 && item.Type !== 'video'"
            >
              <li v-for="element in item.files" :key="element">
                <div class="imgDivUrl">
                  <el-image
                    :src="element"
                    :preview-src-list="item.files"
                    :initial-index="0"
                    :url-list="item.files"
                    fit="cover"
                  />
                </div>
              </li>
            </ul>
            <ul
              class="liDivThreeUlFour"
              v-if="item.flageImg == 4 && item.Type !== 'video'"
            >
              <li v-for="element in item.files" :key="element">
                <div class="imgDivUrl">
                  <el-image
                    :src="element"
                    :preview-src-list="item.files"
                    :initial-index="0"
                    :url-list="item.files"
                    fit="cover"
                  />
                </div>
              </li>
            </ul>
            <ul
              class="liDivThreeUlFive"
              v-if="item.flageImg == 5 && item.Type !== 'video'"
            >
              <li v-for="element in item.files.slice(0, 6)" :key="element">
                <div class="imgDivUrl">
                  <el-image
                    :src="element"
                    :preview-src-list="item.files"
                    :initial-index="0"
                    :url-list="item.files"
                    fit="cover"
                  />
                </div>
              </li>
              <li class="menBan">
                <div class="menBanOne">
                  <span>+</span>
                  <span>{{ item.files.length - 6 }}</span>
                </div>
                <!-- -->
                <div class="posiImg" v-if="item.numImg > 0" @click="optiyBlock">
                  <!--  -->
                  <el-image
                    id="eleImg"
                    :preview-src-list="item.files"
                    :initial-index="7"
                    fit="cover"
                    :src="item.files[5]"
                    @close="backImg"
                  />
                </div>
              </li>
            </ul>
            <!-- 视频展示 -->
            <div class="VideoList" v-if="item.Type == 'video'">
              <video
                style="box-shadow: 0 0 0 1px #dadada; box-sizing: border-box"
                :src="item.files[0]"
                :poster="item.files[item.files.length - 1]"
                controls
              ></video>
            </div>
          </div>
          <div class="liDivFour">
            <img src="../assets/img/timeAxisOne.svg" alt="咱百科" />
            <span>评论</span>
          </div>
          <div class="liDivFive" v-if="LoginFlage">
            <div class="liDivFiveOne">
              <img :src="userInfo.userImage" alt="咱百科" />
              <!-- @focus="backBacGround($event,true)" @blur="backBacGround($event,false)" -->
              <input
                type="text"
                v-model="inputOne"
                placeholder="发表你的评论"
                @input="backBacGround"
              />
              <button
                @click="
                  toAddLine({
                    timelineId: item.id,
                  })
                "
              >
                发表
              </button>
            </div>
            <div class="liDivFiveTwo" v-if="item.newestComment">
              <ul>
                <li
                  v-for="(nesItem, index) in item.newestCommentOne"
                  :key="index"
                >
                  <div class="liDivFiveTimeOne">
                    <img :src="nesItem.avatar" alt="咱百科" />
                    <div class="liDivFiveTwoDiv">
                      <div class="liDivFiveTwoDivOne">
                        <div class="liDivFiveTwotext">
                          <span>{{ nesItem.sourceUserName }}</span>
                          <span>{{ nesItem.createTime }}</span>
                        </div>
                        <!-- @click.stop="toDisplay()" -->

                        <img
                          src="../assets/img/btn_pinglun.svg"
                          @click.stop="toDisplay(nesItem)"
                          alt="咱百科"
                        />
                      </div>
                      <div class="liDivFiveTwoDivTwo">
                        <span>{{ nesItem.content }}</span>
                        <!-- <div class="liDivFiveTwoDivTwoTit" v-if="nesItem.timelineCommentListDTO?.length>0">
                        <span class="TitName">{{ userInfo.name }}:</span>
                        <span>56BD8856BD8856BD8856BD8856BD8856BD8856BD88</span>
                      </div> -->
                      </div>
                    </div>
                  </div>

                  <div
                    class="liDivFiveTimeOne"
                    v-if="nesItem.timelineCommentListDTO?.length > 0"
                    v-for="(timeDto, index) in nesItem.timelineCommentListDTO"
                    :key="index"
                  >
                    <img :src="timeDto.sourceUserAvatar" alt="咱百科" />
                    <div class="liDivFiveTwoDiv">
                      <div class="liDivFiveTwoDivOne">
                        <div class="liDivFiveTwotext">
                          <span>{{ timeDto.sourceUserName }}</span>
                          <span>{{ timeDto.createTime }}</span>
                        </div>
                        <!-- @click.stop="toDisplay()" -->

                        <img
                          src="../assets/img/btn_pinglun.svg"
                          @click.stop="toDisplay(timeDto)"
                          alt="咱百科"
                        />
                      </div>
                      <div class="liDivFiveTwoDivTwo">
                        回复
                        <span class="liDivFiveTwoDivTwoSpan"
                          >{{ timeDto.targetUserName }}：</span
                        >
                        <span>{{ timeDto.content }}</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  class="liDivFiveTwoFour"
                  v-if="toFalgOne && item.newestComment.length > 0"
                >
                  <span @click.stop="toNewestAll"
                    >查看{{ item.newestComment.length }}全部评论</span
                  >
                  <img
                    @click.stop="toNewestAll"
                    src="../assets/img/Group36One.svg"
                    alt="咱百科"
                  />
                </li>
                <li
                  class="liDivFiveTwoFour"
                  v-if="!toFalgOne && item.newestComment.length > 0"
                >
                  <div class="liDivFiveTwoFourSvg">
                    <span @click.stop="toNewestAll">点击收起</span>
                    <img
                      @click.stop="toNewestAll"
                      src="../assets/img/Union.svg"
                      alt="咱百科"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>

      <li id="timeAxisLi">
        <div id="timeAxisBtn">
          <div id="timeAxisBtnDiv">
            <div id="timeAxisBtnDivOne">
              <img
                src="../assets/img/btn_xiaoxi.svg"
                alt="咱百科"
                @click.stop="toDisplayOne"
              />
            </div>
            <div id="timeAxisBtnDivTwo">
              <input type="text" v-model="inputTwo" />
              <button @click.stop="pushAddTimeNew">发表</button>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div id="timeAllNull" v-else>
    <img src="../assets/img/timeNull.svg" alt="无数据标识" />
    <span
      >您还没有发布动态哟，您可以使用手机扫下方“下载咱百科”的二维码下载APP，在APP上发布动态</span
    >
  </div>
</template>
<script lang="ts">
import { reactive,  onMounted, ref, onUnmounted } from "vue";
import router from "../router/index";
import {
  gettimeList,
  
  postTimenewest,
  deleteTimenewest,
} from "../api/timeAxis/index";
import { getTimeInfo } from "../api/shareInfo/index";

import { getUserInfo } from "../api/Login/index";
import { useStore } from "vuex";

import { getBaseUrl } from "../api/Login/index";
import { Session, Local } from "../utils/storage";
import { ElMessage } from "element-plus";

export default {
  name: "timeAxis",
  components: {},
  setup() {
    // var newestCommentId: Array<any> = [];
    async function getTimeAxis(
      pageNum: string | number,
      pageSize: string | number
    ) {
      // const baseUrl = await getBaseUrl();
      var url = window.location.href;
      const urlList = url.split("?");
      // console.log("urlList", urlList);

      var id = urlList[urlList.length - 1];
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        router.push(`/timeShare?id=${id}`);
      }
      // console.log("id", id);

      if (!isNaN(parseFloat(id)) && isFinite(Number(id))) {
        // console.log("id", id);
        const dataList = await getTimeInfo(id);
        dataList.data.id = id;
        dataList.data.avatar =
          store.state.allWeb.publicLinks + dataList.data.avatar;
        // dataList.data.pressFlage = true;
        const list = dataList.data.uploadFiles.map((item: any) => {
          return (item = store.state.allWeb.publicLinks + item);
        });
        dataList.data.files = list;
        const timeArr = dataList.data.uploadFiles[0]
          ?.split("?")[0]
          ?.split(".")[1];

        timeArr == "mp4"
          ? (dataList.data.Type = "video")
          : (dataList.data.Type = "picture");
        dataList.data.files.length > 10
          ? (dataList.data.numImg = dataList.data.files.length - 10)
          : (dataList.data.numImg = 0);

        dataList.data.content = textWenzi({
          id: dataList.data.id,
          content: dataList.data.content,
        });
        //图片展示格式
        let num = dataList.data.files.length;
        switch (true) {
          case num == 1:
            dataList.data.flageImg = 1;
            break;
          case num == 2:
            dataList.data.flageImg = 1;
            break;
          case num == 3:
            dataList.data.flageImg = 2;
            break;
          case num == 4:
            dataList.data.flageImg = 3;
            break;
          case num == 5:
            dataList.data.flageImg = 4;
            break;
          case num == 6:
            dataList.data.flageImg = 4;
            break;
          case num > 6:
            dataList.data.flageImg = 5;
            break;
        }
        dataList.data.date = isToday(dataList.data.date);
        userInfo.userImage = dataList.data.avatar;
        userInfo.name = dataList.data.nickname;
        timeList.arrList = [dataList.data];
        // console.log("timeList.arrList", timeList.arrList);
      } else {
        const userInfoData = await getUserInfo();
        Session.set("reginUSer", userInfoData.data);
        Local.set("reginUSer", userInfoData.data);
        let userData: any = userInfoData.data;
        // Session.get("reginUSer")
        //   ? (userData = Session.get("reginUSer"))
        //   : (userData = Local.get("reginUSer"));
        const dataList = await gettimeList({ pageNum, pageSize });
        // console.log("dataList.data", dataList.data);
        pageNumber.value = dataList.data.pages;
        dataList.data.records.forEach(async (item: any) => {
          item.content = textWenzi({ id: item.id, content: item.content });
          item.date = isToday(item.date);
          if (item.files) {
            item.files[0].fileType == "VIDEO"
              ? (item.Type = "video")
              : (item.Type = "picture");
            item.files.length > 10
              ? (item.numImg = item.files.length - 10)
              : (item.numImg = 0);

            item.files.forEach((element: any) => {
              element.uri = store.state.allWeb.publicLinks + element.uri;
            });

            item.files = Array.from(item.files, ({ uri }) => uri);
            //图片展示格式
            let num = item.files.length;
            switch (true) {
              case num == 1:
                item.flageImg = 1;
                break;
              case num == 2:
                item.flageImg = 1;
                break;
              case num == 3:
                item.flageImg = 2;
                break;
              case num == 4:
                item.flageImg = 3;
                break;
              case num == 5:
                item.flageImg = 4;
                break;
              case num == 6:
                item.flageImg = 4;
                break;
              case num > 6:
                item.flageImg = 5;
                break;
            }
          }

          //————————————————————————————————————
          if (item.newestComment?.length > 0) {
            // item.newestComment.avatar = baseUrl + item.newestComment.avatar;
            item.newestComment.forEach((element: any) => {
              element.avatar = store.state.allWeb.publicLinks + element.avatar;
              element.timelineCommentListDTO.forEach((urlName: any) => {
                urlName.sourceUserAvatar =
                  store.state.allWeb.publicLinks + urlName.sourceUserAvatar;
              });
            });
            toFalgOne.value
              ? (item.newestCommentOne = item.newestComment.slice(0, 2))
              : (item.newestCommentOne = item.newestComment);
          }
        });

        timeList.arrList = dataList.data.records;
        // console.log("timeList.arrList", timeList.arrList);

        if (userData) {
          userData.avatar.slice(0, 5) == "https"
            ? ""
            : (userData.avatar =
                store.state.allWeb.publicLinks + "/" + userData.avatar);
          userInfo.userImage = userData.avatar;
          userInfo.name = userData.nickname;
        }
      }

      // console.log("textArr.arrFont", textArr.arrFont);
    }
    // async function getnewesList(numPage: number | string) {
    //   const baseUrl = await getBaseUrl();
    //   newestCommentId.forEach(async (id: number) => {
    //     const data = await gettimenewest(id, numPage, "");
    //     const arrData = data.data.records.map((avatarData: any) => {
    //       avatarData.avatar = baseUrl + avatarData.avatar;
    //       return avatarData;
    //     });
    //     // console.log(pressList.arrData);
    //   });
    // }
    //判断日期
    const isToday = (str: any) => {
      let d: any = new Date(str).setHours(0, 0, 0, 0);
      let today: any = new Date().setHours(0, 0, 0, 0);

      let obj: any = {
        "-86400000": "昨天",
        0: "今天",
        86400000: "明天",
      };

      return obj[d - today] || str;
    };
    //总体数据
    const timeList: any = reactive({
      arrList: [
        {
          content: "",
          date: "",
          files: [
            {
              uri: "",
            },
          ],
          newestComment: {
            appellation: "",
            avatar: "",
            commentCount: "",
            content: "",
            createTime: "",
            id: "",
            sourceUserId: "",
            sourceUserName: "",
            timelineCommentListVO: "",
            timelineId: "",
          },
          newestDataList: [{}],
        },
      ],
    });
    const pressList: any = reactive({
      arrData: [],
    });
    //获取的总页数
    const pageNumber = ref(1);
    //用户信息数据
    const userInfo = reactive({
      name: "",
      userImage: "",
    });

    const changeFalg = ref(true);

    const textArr = reactive({
      arrFont: [],
    }) as any;
    function textWenzi(obj: any) {
      const content = obj.content;
      var str1 = "";
      textArr.arrFont.push({ id: obj.id, content });
      str1 = content.length > 64 ? content.substring(0, 64) + "..." : content;
      // console.log(str1);
      return str1;
    }
    let pageNum: any = 1;
    let scrollTopBody: any = 0;
    async function scrollBottom() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      const footerDiv = document.querySelector("#footer") as HTMLElement;
      const footerSpan = document.querySelector("#ftSpn") as HTMLElement;
      let footerHeight = footerDiv.clientHeight + footerSpan.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - footerHeight) {
        pageNum++;
        if (pageNum <= pageNumber.value) {
          scrollTopBody = scrollTop;

          // console.log("到底啦");
          // const baseUrl = await getBaseUrl();
          const dataList = await gettimeList({ pageNum, pageSize: "" });

          // console.log(dataList.data.records);
          dataList.data.records.forEach((item: any) => {
            item.content = textWenzi({ id: item.id, content: item.content });
            if (item.files) {
              item.files[0].fileType == "VIDEO"
                ? (item.Type = "video")
                : (item.Type = "picture");
              item.files.length > 10
                ? (item.numImg = item.files.length - 10)
                : (item.numImg = 0);

              item.files.forEach((element: any) => {
                element.uri = store.state.allWeb.publicLinks + element.uri;
              });

              item.files = Array.from(item.files, ({ uri }) => uri);
              //图片展示格式

              let num = item.files.length;
              switch (true) {
                case num == 1:
                  item.flageImg = 1;
                  break;
                case num == 2:
                  item.flageImg = 1;
                  break;
                case num == 3:
                  item.flageImg = 2;
                  break;
                case num == 4:
                  item.flageImg = 3;
                  break;
                case num == 5:
                  item.flageImg = 4;
                  break;
                case num == 6:
                  item.flageImg = 4;
                  break;
                case num > 6:
                  item.flageImg = 5;
                  break;
              }
            }

            //————————————————————————————————————
            item.date = isToday(item.date);
            timeList.arrList.push(item);
          });
          window.scrollTo(0, scrollTopBody);
          // document.body.scrollTop = scrollTopBody;
          // dataList.data.records;
          // console.log(timeList.arrList);
          // console.log(textArr.arrFont);
        }
      }
    }
    // var timeNull;
    const store = useStore();
    const userInfoOne = store.state.userInfo;
    const LoginFlage = store.state.userInfo.login;
    onMounted(async () => {
      // console.log(timeFlage);
      await getTimeAxis("", "");
      // console.log(timeAllDiv);
      // console.log("timeList.arrList", timeList.arrList);
      // console.log("newestCommentId", newestCommentId);
      // getnewesList("1");
      window.addEventListener("scroll", scrollBottom, true);
      document.addEventListener("click", function (e: any) {
        if (e.target?.className != "menDiv") {
          const nameList = document.querySelectorAll(".menDiv") as any;
          nameList.forEach((element: any) => {
            element.style.display = "none";
          });
        }
      });
    });

    function changeDisp(e: any) {
      e.target.nextSibling.style.display == "block"
        ? (e.target.nextSibling.style.display = "none")
        : (e.target.nextSibling.style.display = "block");
      const nameList = document.querySelectorAll(".menDiv") as any;
      nameList.forEach((element: any) => {
        e.target.nextSibling == element ? "" : (element.style.display = "none");
      });
    }
    async function toDelete(id: number | string) {
      const dataNewest = (await deleteTimenewest(id)) as any;
      // console.log("删除", dataNewest);
      dataNewest.code == 200
        ? ElMessage.success("删除成功")
        : ElMessage.error(dataNewest.desc);
      getTimeAxis("", "");
    }
    const inputOne = ref("");
    const inputTwo = ref("");
    function backBacGround(e: any) {
      if (inputOne.value !== "") {
        e.target.nextSibling.style.background = "#56BD88";
        e.target.style.color = "#2C2C2C";
      } else {
        e.target.nextSibling.style.background = "#4ece8c49";
      }
    }
    async function toAddLine(params: any) {
      // console.log(params);
      if (inputOne.value !== "") {
        // console.log("评论", params.timelineId);
        await postTimenewest({
          timelineId: params.timelineId,
          timelineCommentId: null,
          targetUserId: null,
          content: inputOne.value,
        });
        inputOne.value = "";
        getTimeAxis("", "");
      }
    }

    function changeDiv(e: any, id: number | string) {
      const data = textArr.arrFont.filter((item: any) => {
        return item.id == id;
      });
      console.log(data[0].content);
      // console.log(e.target.parentNode.parentNode.childNodes[0]);
      e.target.parentNode.parentNode.childNodes[0].innerHTML = data[0].content;
      changeFalg.value = false;
    }
    function changeDivFalg(e: any, id: number | string) {
      e.target.parentNode.parentNode.childNodes[0].innerHTML =
        e.target.parentNode.parentNode.childNodes[0].innerHTML.substring(
          0,
          64
        ) + "...";
      changeFalg.value = true;
    }
    function optiyBlock(e: any) {
      e.target.parentNode.parentNode.style.opacity = "1";
    }
    function backImg() {
      const eleImg = document.querySelector("#eleImg") as HTMLImageElement;
      eleImg.style.opacity = "0";
    }
    const toFalgOne = ref(true);
    let timer: any;

    async function toNewestAll(e: any) {
      // const domEle = e.target.parentNode.parentNode.parentNode;
      // console.log(domEle);

      toFalgOne.value = !toFalgOne.value;
      // console.log(toFalgOne.value);

      await getTimeAxis("", "");
      // if (toFalgOne.value) {
      //   console.log(domEle);
      //   domEle.scrollTop = 0;
      // }
    }
    const timeAxisData: any = reactive({
      id: "",
      targetUserId: "",
    });
    function toDisplay(item: any) {
      const dataElement = document.getElementById("timeAxisLi") as HTMLElement;
      dataElement.style.display = "block";
      timeAxisData.id = item.id;
      timeAxisData.targetUserId = item.sourceUserId;
    }
    function toDisplayOne() {
      const dataElement = document.getElementById("timeAxisLi") as HTMLElement;
      dataElement.style.display = "none";
    }
    async function pushAddTimeNew() {
      if (inputTwo.value !== "") {
        // console.log("评论", params.timelineId);
        await postTimenewest({
          timelineId: null,
          timelineCommentId: timeAxisData.id,
          targetUserId: timeAxisData.targetUserId,
          content: inputTwo.value,
        });
        toDisplayOne();
        inputTwo.value = "";
        getTimeAxis("", "");
      }
    }

    function goTimeShare(id: string | number) {
      const url = window.location.href;
      const urlTo = `TimeAxis?${id}`;
      const urlData = url.replace(urlTo, "");
      const urlOne = urlData.replace("TimeAxis", "");
      // console.log(urlOne);

      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(`${urlOne}timeShare?id=${id}`);
        ElMessage.success("复制成功");
      } else {
        let domInput = document.createElement("input");
        domInput.value = urlOne + "timeShare?id=" + `${id}`;
        document.body.appendChild(domInput); // 添加input节点
        domInput.select(); // 选择对象;
        document.execCommand("Copy");
        domInput.remove();
        ElMessage.success("复制成功");
      }
    }

    onUnmounted(() => {
      userInfoOne.timeFlage = false;
      window.removeEventListener("scroll", scrollBottom, true);
    });

    return {
      timeList,
      changeFalg,
      userInfo,
      LoginFlage,
      pressList,
      toFalgOne,
      inputOne,
      inputTwo,
      changeDisp,
      changeDiv,
      changeDivFalg,
      optiyBlock,
      backImg,
      toNewestAll,
      toDelete,
      toAddLine,
      backBacGround,
      toDisplay,
      toDisplayOne,
      pushAddTimeNew,
      goTimeShare,
    };
  },
};
</script>
<style lang="scss" scoped>
ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

:deep(.el-image-viewer__wrapper) {
  width: auto !important;
  height: auto !important;
}
#timeAllDiv {
  width: 100%;
  height: 100%;
  background: conic-gradient(
    from 1deg at 49.51% 51.14%,
    rgba(177, 221, 252, 0.15) 64.36676681041718deg,
    rgba(255, 255, 255, 0) 180.36072492599487deg,
    rgba(146, 252, 136, 0.07) 300.52973985671997deg
  );
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: right top;
  background-attachment: fixed;
  margin-top: 73px;
  #timeAxisUl {
    width: 1120px;
    margin: 0 auto;
    padding-top: 77px;
    padding-bottom: 18px;
    .timeAxisUlLi {
      padding: 36px 44px;
      padding-bottom: 0px;
      background: rgba(255, 255, 255, 0.7);
      border: 1px solid #ffffff;
      box-sizing: border-box;
      // border: 1px solid #000;
      border-radius: 6px;
      margin-bottom: 8px;
      .liDiv {
        .liDivOne {
          display: flex;
          align-items: center;
          position: relative;
          .divLeftBorder {
            display: block;
            width: 4px;
            height: 24px;
            background: #56bd88;
            border-radius: 11px;
            // transform: rotate(90deg);
          }
          span {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
            display: flex;
            align-items: center;
            color: #000000;
            margin-left: 19px;
          }
          img {
            position: absolute;
            right: 0;
            width: 28px;
            height: 28px;
            // border: 1px solid red;
            // margin-left: 901px;
            float: right;
            &:hover {
              cursor: pointer;
            }
          }
          .menDiv {
            position: absolute;
            top: 0px;
            right: 56px;
            width: 116px;
            height: 116px;
            background: #565656;
            border-radius: 8px;
            z-index: 11;
            display: none;
            .menDivOne {
              margin: 24px 36px;
              font-style: normal;
              font-weight: 400;
              font-size: 22px;
              line-height: 22px;
              color: #ffffff;
              &:hover {
                cursor: pointer;
              }
            }
            .menDivTwo {
              margin: 24px 36px;
              margin-top: 0px;
              font-style: normal;
              font-weight: 400;
              font-size: 22px;
              line-height: 22px;
              color: #ffffff;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
        .liDivTwo {
          margin: 36px 0;
          div {
            // position: relative;
            display: inline;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 26px;
            text-align: justify;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #2c2c2c;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          span {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 26px;
            text-align: justify;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #467ab7;
            // position: absolute;
            right: 0;
            bottom: 0;
            background: #fff;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .liDivThree {
          width: 1032px;
          .liDivThreeUl {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            li {
              width: 506px;
              height: 685px;
              :deep(.el-image) {
                width: 506px;
                height: 685px;
                img {
                  border-radius: 8px !important;
                }
              }
              img {
                background: #f7aeae;
                border-radius: 8px;
                width: 506px;
                height: 685px;
              }
            }
          }
          .liDivThreeUlTwo {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            li {
              width: 338px;
              height: 338px;
              :deep(.el-image) {
                width: 338px;
                height: 338px;
                img {
                  border-radius: 8px !important;
                }
              }
              img {
                background: #f7aeae;
                border-radius: 8px;
                width: 338px;
                height: 338px;
              }
            }
          }
          .liDivThreeUlThree {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-right: 347px;
            flex-wrap: wrap;
            li {
              width: 338px;
              height: 338px;
              &:nth-child(1) {
                margin-bottom: 8px;
              }
              &:nth-child(2) {
                margin-bottom: 8px;
              }
              :deep(.el-image) {
                width: 338px;
                height: 338px;
                img {
                  border-radius: 8px !important;
                }
              }
              img {
                background: #f7aeae;
                border-radius: 8px;
                width: 338px;
                height: 338px;
              }
            }
          }
          .liDivThreeUlFour {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            li {
              // width: 336px;
              // height: 336px;
              margin-right: 8px;
              :deep(.el-image) {
                width: 338px !important;
                height: 338px !important;
                img {
                  border-radius: 8px !important;
                }
              }
              &:nth-child(1) {
                margin-bottom: 8px;
              }
              &:nth-child(2) {
                margin-bottom: 8px;
              }
              &:nth-child(3) {
                margin-bottom: 8px;
                margin-right: 0px;
              }
              &:nth-child(6) {
                margin-right: 0px;
              }
              img {
                background: #f7aeae;
                border-radius: 8px;
                width: 338px;
                height: 338px;
              }
            }
          }
          .liDivThreeUlFive {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            position: relative;
            li {
              width: 338px;
              height: 338px;
              margin-right: 8px;
              :deep(.el-image) {
                width: 338px;
                height: 338px;
                img {
                  border-radius: 8px !important;
                }
              }
              &:nth-child(1) {
                margin-bottom: 8px;
              }
              &:nth-child(2) {
                margin-bottom: 8px;
              }
              &:nth-child(3) {
                margin-bottom: 8px;
                margin-right: 0px;
              }
              &:nth-child(6) {
                margin-right: 0px;
              }
              img {
                background: #f7aeae;
                border-radius: 8px;
                width: 338px;
                height: 338px;
              }
            }
            .menBan {
              width: 338px;
              height: 338px;
              background: rgba(0, 0, 0, 0.5);
              position: absolute;
              bottom: 0px;
              right: -8px;
              border-radius: 8px;
              .menBanOne {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: 400;
                  color: #ffffff;
                  &:first-child {
                    font-size: 62px;
                  }
                  &:last-child {
                    font-size: 62px;
                  }
                }
              }
              .posiImg {
                position: absolute;
                bottom: 0px;
                width: 100%;
                height: 100%;
                opacity: 0;
              }
            }
          }

          .VideoList {
            // display: none;
            width: 1032px;
            height: 688px;
            video {
              width: 1032px;
              height: 688px;
              object-fit: fill !important;
              box-shadow: 0 0 0 1px #dadada;
              box-sizing: border-box;
            }
          }
        }
        .liDivFour {
          display: flex;
          align-items: center;
          margin-top: 38px;
          margin-bottom: 46px;
          img {
            width: 36px;
            height: 32px;
            margin-left: 16px;
            margin-right: 16px;
          }
          span {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 20px;
            text-align: justify;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #9a9a9a;
          }
        }
        .liDivFive {
          .liDivFiveOne {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 134px;
            border: 1px solid rgba(210, 210, 210, 0.3);
            border-left: none;
            border-right: none;
            img {
              width: 60px;
              height: 60px;
              border-radius: 50%;
            }
            input {
              width: 789px;
              height: 60px;
              background: #ecf3f4;
              opacity: 0.6;
              border: none;
              border-radius: 12px;
              outline-color: rgba(86, 189, 136, 1);
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 20px;
              color: #9a9a9a;
              padding-left: 32px;
            }
            button {
              width: 100px;
              height: 58px;
              background: #4ece8c49;
              border: none;
              border-radius: 12px;
              font-style: normal;
              font-weight: 500;
              font-size: 22px;
              line-height: 22px;
              color: #ffffff;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .liDivFiveTwo {
            ul {
              width: 1030px;
              li {
                display: flex;
                flex-direction: column;
                width: 100%;

                .liDivFiveTimeOne {
                  display: flex;
                  width: 100%;
                  margin-top: 36px;
                  img {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                  }
                  .liDivFiveTwoDiv {
                    margin-left: 8px;

                    .liDivFiveTwoDivOne {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      width: 944px;
                      margin-top: 4px;
                      margin-bottom: 20px;
                      margin-left: 16px;

                      .liDivFiveTwotext {
                        span {
                          &:first-child {
                            display: block;
                            font-family: "MiSans";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 20px;
                            display: flex;
                            align-items: center;
                            color: #56bd88;
                            margin-bottom: 13px;
                          }
                          &:last-child {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 16px;
                            text-align: justify;
                            color: #9a9a9a;
                          }
                        }
                      }
                      img {
                        width: 28px;
                        height: 28px;
                        border-radius: 0;
                        &:hover {
                          cursor: pointer;
                        }
                      }
                    }
                    .liDivFiveTwoDivTwo {
                      // border-left: 1px solid rgba(210, 210, 210, 0.3);
                      font-family: Arial, Helvetica, sans-serif;
                      font-style: normal;
                      padding-left: 16px;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 22px;
                      text-align: justify;
                      color: #2c2c2c;
                      span {
                        display: inline;
                        // width: 789px;
                        // font-family: Arial, Helvetica, sans-serif;
                        // font-style: normal;
                        // font-weight: 400;
                        // font-size: 16px;
                        // line-height: 22px;
                        // text-align: justify;
                        // color: #2c2c2c;
                        margin-top: -5px;
                      }
                      .liDivFiveTwoDivTwoTit {
                        margin-top: 24px;
                        .TitName {
                          display: inline;
                          font-style: normal;
                          font-weight: 400;
                          font-size: 16px;
                          line-height: 16px;
                          color: #56bd88;
                        }
                        span {
                          line-height: 16px;
                        }
                      }
                      .liDivFiveTwoDivTwoSpan {
                        // display: inline;
                        color: #56bd88;
                      }
                    }
                  }
                }
              }
              .liDivFiveTwoFour {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 65px;
                border-top: 1px solid rgba(210, 210, 210, 0.3);
                margin-top: 36px;
                span {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 20px;
                  line-height: 20px;
                  color: #56bd88;
                  &:hover {
                    cursor: pointer;
                  }
                }
                img {
                  display: inline-block;
                  width: 16px;
                  height: 16px;
                  margin-left: 6px;
                  &:hover {
                    cursor: pointer;
                  }
                }
                .liDivFiveTwoFourSvg {
                  width: 108px;
                  height: 16px;
                  padding: 0 12px;
                  background: #fff;
                  position: absolute;
                  margin-top: -65px;
                  margin-left: 54px;
                  z-index: 1111;
                  text-align: center;
                  span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 16px;
                    color: #467ab7;
                  }
                  img {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    margin-left: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
    #timeAxisLi {
      display: none;
      width: 100%;
      // height: 100%
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 1001;
      background-color: rgba(153, 153, 153, 0.8);
      #timeAxisBtn {
        position: fixed;
        top: calc(50% - 120px);
        left: calc(50% - 560px);
        width: 1120px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid #ffffff;
        border-radius: 8px;
        #timeAxisBtnDiv {
          // border: 1px solid #000;

          #timeAxisBtnDivOne {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            height: 68px;
            border-bottom: 1px solid rgba(210, 210, 210, 0.3);
            margin-bottom: 36px;
            img {
              margin-right: 35px;
              width: 28px;
              height: 28px;
              &:hover {
                cursor: pointer;
              }
            }
          }
          #timeAxisBtnDivTwo {
            display: flex;
            flex-direction: column;
            input {
              width: 1048px;
              height: 120px;
              background: #ecf3f4;
              opacity: 0.6;
              // border: none;
              border-radius: 12px;
              border: 2px solid #56bd88;
              outline-color: rgba(86, 189, 136, 1);
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 20px;
              color: #9a9a9a;
              // padding-left: 32px;
              margin: 0 auto;
            }
            button {
              width: 100px;
              height: 58px;
              background: #56bd88;
              border: none;
              border-radius: 12px;
              font-style: normal;
              font-weight: 500;
              font-size: 22px;
              line-height: 22px;
              color: #ffffff;
              margin-left: 986px;
              margin-bottom: 35px;
              margin-top: 20px;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
#timeAllNull {
  margin-top: 73px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 353px;
    height: 311px;
    padding-top: 131px;
    padding-bottom: 105px;
  }
  span {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #000000;
    margin-bottom: 154px;
  }
}
</style>
