<template>
  <div id="chariDiv">
    <div id="charCenter">
      <div id="mudImg">
        <el-carousel
          :interval="2000"
          ref="carousel"
          v-if="imgs.arrList[0].imgUrl"
        >
          <el-carousel-item v-for="item in imgs.arrList" :key="item">
            <img :src="item.imgUrl" alt="咱百科" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div id="videoDiv">
        <div id="videoPositionBackground">
          <VideoHls
            id="videoHls"
            ref="childRef"
            v-if="videoLiveFlage"
            @childElement="childElement"
            @childChangePopup="childChangePopup"
            @changeFlageVideo="changeFlageVideo"
          />
          <div id="videoComponent" v-else>
            <div id="videoComponentFlex">
              <VideoComponent
                :videoUrl="videoComponentUrl"
                poster=""
                divId="videoPositionBackgroundOne"
                videoId="liveVideoOne"
                videoClass=""
                imgId="liveIdVideo"
                imgClass=""
                :nextVideoFlag="true"
                :autoplayVideo="true"
                :loopplayVideo="false"
                :videoNumber="videoNumber"
                :listVideoNumber="listVideoNumber"
                @getTimeVideo="getTimeVideo"
              />
              <div class="videoComponentRightDiv">
                <h3 id="videoComponentRightTitle">播放列表</h3>
                <ul id="videoComponentRight">
                  <!-- <li id="videoComponentRightTitle">播放列表</li> -->
                  <li
                    v-for="(item, index) in liveVideoList.arr"
                    :index="item"
                    @click="getTimeVideo(index)"
                  >
                    <div class="videoTwoImg">
                      <video :src="item.uri" :poster="item.cover"></video>
                      <img :src="item.svgButton" alt="播放标识" />
                    </div>
                    <span>{{ item.name }}</span>
                  </li>
                  <!-- v-show="liveVideoList.arr.length>4" -->
                </ul>
              </div>
            </div>
            <span id="videoComponentText"
              >本站视频仅供观看参考,不作为商用，版权归属原作者，部分视频推送时未能及时与原作者取得联系，若来源标注错误或侵犯到您的权益烦请告知，我们将立即删除。</span
            >
          </div>

          <div class="titleLive">
            <span id="LiveVideoTitle">{{ videoLiveTitle }}</span>
            <div v-if="videoLiveFlage">
              <span id="LiveNumPeople" class="LiveNumText">观看人数：2435</span>
              <span id="LiveLike" class="LiveNumText">点赞数量：2345</span>
            </div>
            <span class="LiveNumText" v-else> 来源：咱百科 </span>
          </div>
          <!-- <div class="backgroundLive" v-if="videoLiveFlage"></div> -->
          <div id="popupDiv" v-show="popupFlage">
            <span>您需要登录才能观看此画质的视频哦。</span>
            <div class="popupDivButton">
              <button @click="cancel">取消</button>
              <button @click="changeLogon">立即登录</button>
            </div>
          </div>
        </div>
      </div>
      <div id="charDivFather">
        <div id="charDivFatherBack">
          <div class="charOne doctorsDiv">
            <div class="divBothSides">
              <div class="divBothSidesOne">
                <div class="charOneTitle">医护</div>
                <div class="charBorder"></div>
              </div>
              <div
                class="divBothSidesTwo"
                v-if="tableData.MedicalArr.length > 7"
                @click="pushToMore('MEDICAL_CARE')"
              >
                <div>查看更多</div>
                <img src="../assets/img/Group36.svg" alt="咱百科" />
              </div>
            </div>

            <div class="charCenterDiv">
              <div
                class="charCenterOne"
                @click="routerTo(tableData.MedicalArr[0])"
              >
                <div class="coverPosition">
                  <el-image
                    :src="tableData.MedicalArr[0].cover"
                    alt="咱百科"
                    fit="cover"
                  />
                  <img
                    class="coverPositionAbs"
                    src="../assets/img/Group141.svg"
                    alt="咱百科"
                    v-show="tableData.MedicalArr[0].videoUrl"
                  />
                </div>
                <span>{{ tableData.MedicalArr[0].title }}</span>
              </div>
              <ul class="charCenterTwo">
                <li
                  v-for="(item, index) in tableData.MedicalArr.slice(1, 7)"
                  :key="index"
                  @click="routerTo(item)"
                >
                  <div>
                    <el-image :src="item.cover" alt="咱百科" fit="cover" />
                    <img
                      src="../assets/img/Group141.svg"
                      alt="咱百科"
                      v-show="item.videoUrl"
                    />
                  </div>
                  <span class="charCenterTwoText">{{ item.title }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="charOne studentDiv">
            <div class="divBothSides">
              <div class="divBothSidesOne">
                <div class="charOneTitle">助学</div>
                <div class="charBorder"></div>
              </div>
              <div
                class="divBothSidesTwo"
                v-if="tableData.StudentArr.length > 7"
                @click="pushToMore('STUDENT_AID')"
              >
                <div>查看更多</div>
                <img src="../assets/img/Group36.svg" alt="咱百科" />
              </div>
            </div>

            <div class="charCenterDiv">
              <div
                class="charCenterOne"
                @click="routerTo(tableData.StudentArr[0])"
              >
                <div class="coverPosition">
                  <el-image
                    :src="tableData.StudentArr[0].cover"
                    alt="咱百科"
                    fit="cover"
                  />
                  <img
                    class="coverPositionAbs"
                    src="../assets/img/Group141.svg"
                    alt="咱百科"
                    v-show="tableData.StudentArr[0].videoUrl"
                  />
                </div>
                <span>{{ tableData.StudentArr[0].title }}</span>
              </div>
              <ul class="charCenterTwo">
                <li
                  v-for="(item, index) in tableData.StudentArr.slice(1, 7)"
                  :key="index"
                  @click="routerTo(item)"
                >
                  <div>
                    <el-image :src="item.cover" alt="咱百科" fit="cover" />
                    <img
                      src="../assets/img/Group141.svg"
                      alt="咱百科"
                      v-show="item.videoUrl"
                    />
                  </div>
                  <span class="charCenterTwoText">{{ item.title }}</span>
                </li>
              </ul>
            </div>
          </div>

          <!-- 测试轮播效果 -->

          <div id="ceShanSwiper" class="charityDiv">
            <div class="divBothSidesOne">
              <div class="charOneTitle">机构信息</div>
              <div class="charBorder"></div>
            </div>
            <div
              id="review_box"
              @mouseover="rollStop(true)"
              @mouseout="rollStart()"
            >
              <ul id="comment1">
                <li
                  v-for="(missionList, index) in tableData.missionArr"
                  :key="index"
                >
                  <div class="comment1One">
                    <img
                      class="comment1OneImg"
                      :src="missionList.agency.logo"
                      alt="慈善logo"
                      @click="openUrl(missionList.agency.url)"
                    />
                    <div
                      class="divTwo"
                      @click="routerTo(missionList.articleList[0])"
                    >
                      <img :src="missionList.articleList[0].cover" alt="公益" />
                      <span class="divTwoCenter">
                        {{ missionList.articleList[0].title }}</span
                      >
                    </div>
                    <div
                      class="divVision"
                      :style="{
                        backgroundImage:
                          'url(' + missionList.backgroundImg + ')',
                      }"
                    >
                      <div
                        class="divVisionOne"
                        @click.prevent="changeVision($event, missionList)"
                      >
                        <div class="divVisionTitle">愿景</div>
                        <img :src="missionList.visionSvg" alt="上下svg" />
                      </div>
                      <div class="divVisionTwo">
                        {{ missionList.agency.vision }}
                      </div>
                    </div>
                    <div class="divThree">
                      <div class="divThreeOneTitle">
                        <span class="titleBorder"></span>
                        <div>新闻动态</div>
                        <span class="titleBorder titleBorderTwo"></span>
                      </div>
                      <div class="divThreeContent">
                        <div
                          class="divThreeOne"
                          @click="routerTo(missionList.articleList[1])"
                        >
                          <img
                            :src="missionList.articleList[1].cover"
                            alt="新闻图片"
                          />
                          <div>{{ missionList.articleList[1].title }}</div>
                        </div>
                        <div
                          v-if="missionList.articleList[2]"
                          class="divThreeOne divThreeTwo"
                          @click="routerTo(missionList.articleList[2])"
                        >
                          <img
                            :src="missionList.articleList[2].cover"
                            alt="新闻图片"
                          />
                          <div>{{ missionList.articleList[2].title }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <ul id="comment2">
                <li
                  v-for="(missionList, index) in tableData.missionArr"
                  :key="index"
                >
                  <div class="comment1One">
                    <img
                      class="comment1OneImg"
                      :src="missionList.agency.logo"
                      alt="慈善logo"
                      @click="openUrl(missionList.agency.url)"
                    />
                    <div
                      class="divTwo"
                      @click="routerTo(missionList.articleList[0])"
                    >
                      <img :src="missionList.articleList[0].cover" alt="公益" />
                      <span class="divTwoCenter">
                        {{ missionList.articleList[0].title }}</span
                      >
                    </div>
                    <div
                      class="divVision"
                      :style="{
                        backgroundImage:
                          'url(' + missionList.backgroundImg + ')',
                      }"
                    >
                      <div
                        class="divVisionOne"
                        @click.prevent="changeVision($event, missionList)"
                      >
                        <div class="divVisionTitle">愿景</div>
                        <img :src="missionList.visionSvg" alt="上下svg" />
                      </div>
                      <div class="divVisionTwo">
                        {{ missionList.agency.vision }}
                      </div>
                    </div>
                    <div class="divThree">
                      <div class="divThreeOneTitle">
                        <span class="titleBorder"></span>
                        <div>新闻动态</div>
                        <span class="titleBorder titleBorderTwo"></span>
                      </div>
                      <div class="divThreeContent">
                        <div
                          class="divThreeOne"
                          @click="routerTo(missionList.articleList[1])"
                        >
                          <img
                            :src="missionList.articleList[1].cover"
                            alt="新闻图片"
                          />
                          <div>{{ missionList.articleList[1].title }}</div>
                        </div>
                        <div
                          v-if="missionList.articleList[2]"
                          class="divThreeOne divThreeTwo"
                          @click="routerTo(missionList.articleList[2])"
                        >
                          <img
                            :src="missionList.articleList[2].cover"
                            alt="新闻图片"
                          />
                          <div>{{ missionList.articleList[2].title }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- 测试轮播结束-->
          <div class="charOne charOneMar vibeDiv">
            <div class="divBothSides">
              <div class="divBothSidesOne">
                <div class="charOneTitle">环境保护</div>
                <div class="charBorder"></div>
              </div>
              <div
                class="divBothSidesTwo"
                v-if="tableData.EnvironmentalArr.length > 7"
                @click="pushToMore('ENVIRONMENTAL_FRIENDLY')"
              >
                <div>查看更多</div>
                <img src="../assets/img/Group36.svg" alt="咱百科" />
              </div>
            </div>
            <div class="charCenterDiv">
              <div
                class="charCenterOne"
                @click="routerTo(tableData.EnvironmentalArr[0])"
              >
                <div class="coverPosition">
                  <el-image
                    :src="tableData.EnvironmentalArr[0].cover"
                    alt="咱百科"
                    fit="cover"
                  />
                  <img
                    class="coverPositionAbs"
                    src="../assets/img/Group141.svg"
                    alt="咱百科"
                    v-show="tableData.EnvironmentalArr[0].videoUrl"
                  />
                </div>
                <span>{{ tableData.EnvironmentalArr[0].title }}</span>
              </div>
              <ul class="charCenterTwo">
                <li
                  v-for="(item, index) in tableData.EnvironmentalArr.slice(
                    1,
                    7
                  )"
                  :key="index"
                  @click="routerTo(item)"
                >
                  <div>
                    <el-image :src="item.cover" alt="咱百科" fit="cover" />
                    <img
                      src="../assets/img/Group141.svg"
                      alt="咱百科"
                      v-show="item.videoUrl"
                    />
                  </div>
                  <span class="charCenterTwoText">{{ item.title }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="charOne animalDiv">
            <div class="divBothSides">
              <div class="divBothSidesOne">
                <div class="charOneTitle">动物保护</div>
                <div class="charBorder"></div>
              </div>
              <div
                class="divBothSidesTwo"
                v-if="tableData.AnimalArr.length > 7"
                @click="pushToMore('ANIMAL_PROTECTION')"
              >
                <div>查看更多</div>
                <img src="../assets/img/Group36.svg" alt="咱百科" />
              </div>
            </div>

            <div class="charCenterDiv">
              <div
                class="charCenterOne"
                @click="routerTo(tableData.AnimalArr[0])"
              >
                <div class="coverPosition">
                  <el-image
                    :src="tableData.AnimalArr[0].cover"
                    alt="咱百科"
                    fit="cover"
                  />
                  <img
                    class="coverPositionAbs"
                    src="../assets/img/Group141.svg"
                    alt="咱百科"
                    v-show="tableData.AnimalArr[0].videoUrl"
                  />
                </div>
                <span>{{ tableData.AnimalArr[0].title }}</span>
              </div>
              <ul class="charCenterTwo">
                <li
                  v-for="(item, index) in tableData.AnimalArr.slice(1, 7)"
                  :key="index"
                  @click="routerTo(item)"
                >
                  <div>
                    <el-image :src="item.cover" alt="咱百科" fit="cover" />
                    <img
                      src="../assets/img/Group141.svg"
                      alt="咱百科"
                      v-show="item.videoUrl"
                    />
                  </div>
                  <span class="charCenterTwoText">{{ item.title }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="charOne charTwo notableDiv">
            <div class="divBothSides">
              <div class="divBothSidesOne">
                <div class="charOneTitle">名人慈善</div>
                <div class="charBorder"></div>
              </div>
              <div
                class="divBothSidesTwo"
                v-if="tableData.CelebrityArr.length > 6"
                @click="pushToMore('CELEBRITY_CHARITY')"
              >
                <div>查看更多</div>
                <img src="../assets/img/Group36.svg" alt="咱百科" />
              </div>
            </div>

            <div class="charCenterDiv">
              <ul>
                <li>
                  <div
                    class="liDiv"
                    @click="routerTo(tableData.CelebrityArr[0])"
                  >
                    <div class="coverPosition">
                      <el-image
                        :src="tableData.CelebrityArr[0].cover"
                        alt="咱百科"
                        fit="cover"
                      />
                      <img
                        class="coverPositionAbs"
                        src="../assets/img/Group141.svg"
                        alt="咱百科"
                        v-show="tableData.CelebrityArr[0].videoUrl"
                      />
                    </div>
                    <span>{{ tableData.CelebrityArr[0].title }}</span>
                  </div>
                  <div
                    class="liDiv"
                    @click="routerTo(tableData.CelebrityArrOne[0])"
                  >
                    <div class="coverPosition">
                      <el-image
                        :src="tableData.CelebrityArrOne[0].cover"
                        alt="咱百科"
                        fit="cover"
                      />
                      <img
                        class="coverPositionAbs"
                        src="../assets/img/Group141.svg"
                        alt="咱百科"
                        v-show="tableData.CelebrityArrOne[0].videoUrl"
                      />
                    </div>
                    <span>{{ tableData.CelebrityArrOne[0].title }}</span>
                  </div>
                </li>
                <li>
                  <div class="liDivOne">
                    <div
                      class="divOneImg"
                      v-for="(item, index) in tableData.CelebrityArr.slice(
                        1,
                        3
                      )"
                      :key="index"
                      @click="routerTo(item)"
                    >
                      <div class="coverPosition">
                        <el-image :src="item.cover" alt="咱百科" fit="cover" />
                        <img
                          class="coverPositionAbs"
                          src="../assets/img/Group141.svg"
                          alt="咱百科"
                          v-show="item.videoUrl"
                        />
                      </div>
                      <span>{{ item.title }}</span>
                    </div>
                  </div>
                  <div class="liDivOne">
                    <div
                      class="divOneImg"
                      v-for="(item, index) in tableData.CelebrityArrOne.slice(
                        1,
                        3
                      )"
                      :key="index"
                      @click="routerTo(item)"
                    >
                      <div class="coverPosition">
                        <el-image :src="item.cover" alt="咱百科" fit="cover" />
                        <img
                          class="coverPositionAbs"
                          src="../assets/img/Group141.svg"
                          alt="咱百科"
                          v-show="item.videoUrl"
                        />
                      </div>
                      <span>{{ item.title }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="charOne charTwo historicalDiv">
            <div class="divBothSides">
              <div class="divBothSidesOne">
                <div class="charOneTitle">优秀人物</div>
                <div class="charBorder"></div>
              </div>
              <div
                class="divBothSidesTwo"
                v-if="tableData.HistoricalArr.length > 6"
                @click="pushToMore('HISTORICAL_FIGURE')"
              >
                <div>查看更多</div>
                <img src="../assets/img/Group36.svg" alt="咱百科" />
              </div>
            </div>

            <div class="charCenterDiv">
              <ul>
                <li>
                  <div
                    class="liDiv"
                    @click="routerTo(tableData.HistoricalArr[0])"
                  >
                    <div class="coverPosition">
                      <el-image
                        :src="tableData.HistoricalArr[0].cover"
                        alt="咱百科"
                        fit="cover"
                      />
                      <img
                        class="coverPositionAbs"
                        src="../assets/img/Group141.svg"
                        alt="咱百科"
                        v-show="tableData.HistoricalArr[0].videoUrl"
                      />
                    </div>
                    <span>{{ tableData.HistoricalArr[0].title }}</span>
                  </div>
                  <div
                    class="liDiv"
                    @click="routerTo(tableData.HistoricalArrOne[0])"
                  >
                    <div class="coverPosition">
                      <el-image
                        :src="tableData.HistoricalArrOne[0].cover"
                        alt="咱百科"
                        fit="cover"
                      />
                      <img
                        class="coverPositionAbs"
                        src="../assets/img/Group141.svg"
                        alt="咱百科"
                        v-show="tableData.HistoricalArrOne[0].videoUrl"
                      />
                    </div>
                    <span>{{ tableData.HistoricalArrOne[0].title }}</span>
                  </div>
                </li>
                <li>
                  <div class="liDivOne">
                    <div
                      class="divOneImg"
                      v-for="(item, index) in tableData.HistoricalArr.slice(
                        1,
                        3
                      )"
                      :key="index"
                      @click="routerTo(item)"
                    >
                      <div class="coverPosition">
                        <el-image :src="item.cover" alt="咱百科" fit="cover" />
                        <img
                          class="coverPositionAbs"
                          src="../assets/img/Group141.svg"
                          alt="咱百科"
                          v-show="item.videoUrl"
                        />
                      </div>
                      <span>{{ item.title }}</span>
                    </div>
                  </div>
                  <div class="liDivOne">
                    <div
                      class="divOneImg"
                      v-for="(item, index) in tableData.HistoricalArrOne.slice(
                        1,
                        3
                      )"
                      :key="index"
                      @click="routerTo(item)"
                    >
                      <div class="coverPosition">
                        <el-image :src="item.cover" alt="咱百科" fit="cover" />
                        <img
                          class="coverPositionAbs"
                          src="../assets/img/Group141.svg"
                          alt="咱百科"
                          v-show="item.videoUrl"
                        />
                      </div>
                      <span>{{ item.title }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- v-if="" -->
          <div class="charThree donateDiv" v-if="!userInfo.resLayout">
            <div class="charThreeOne">
              <div class="threeTitle">器官捐献</div>
              <div class="titleBackground"></div>
            </div>
            <div class="charThreeDiv">
              <div class="charThreeUl" @click="goToEsseay">
                <img
                  id="donateDivImg"
                  src="../assets/img/Group44972.png"
                  alt="咱百科"
                />
              </div>
              <div
                class="charThreeTwo"
                @click="goToLink('http://www.savelife.org.cn/')"
              >
                <img src="../assets/img/ZYZ1.png" alt="咱百科" />
                <span>器官捐献志愿者服务网</span>
              </div>
              <div
                class="charThreeTwoImg"
                @click="goToLink('https://www.codac.org.cn/')"
              >
                <img src="../assets/img/QGJX1.png" alt="咱百科" />
                <span>中国人体器官捐献管理中心</span>
              </div>
              <div class="charThreeTwoSpan">
                <span>捐献登记通道</span>
                <div class="spanErWeiMa">
                  <img src="../assets/img/QGGJH.png" alt="咱白科" />
                </div>
              </div>
            </div>
          </div>
          <!-- 手机端 -->
          <div class="charThree" v-else>
            <div class="charThreeOne">
              <div class="threeTitle">器官捐献</div>
              <div class="titleBackground"></div>
            </div>
            <div class="charThreeDiv">
              <ul>
                <li>
                  <div class="charThreeUl" @click="goToEsseay">
                    <img
                      @click="goToEsseay"
                      id="donateDivImg"
                      src="../assets/img/Group44972.png"
                      alt="咱百科"
                    />
                  </div>
                  <div class="charThreeTwoSpan">
                    <span>捐献登记通道</span>
                    <div class="spanErWeiMa">
                      <img src="../assets/img/QGGJH.png" alt="咱白科" />
                    </div>
                  </div>
                </li>
                <li>
                  <div class="charThreeTwoImg">
                    <img src="../assets/img/QGJX1.png" alt="咱百科" />
                    <span>中国人体器官捐献管理中心</span>
                  </div>
                  <div class="charThreeTwo">
                    <img src="../assets/img/ZYZ1.png" alt="咱百科" />
                    <span>器官捐献志愿者服务网</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- v-else结束 -->
          <div class="charThree charThreeCtrl rescueDiv">
            <div class="charThreeOne">
              <div class="threeTitle">动物救助</div>
              <div class="titleBackground"></div>
            </div>
            <div class="charThreeDiv">
              <div
                class="charThreeShow"
                @click="goToLink(tableData.AnimaAdoptionArr[0].url)"
              >
                <img :src="tableData.AnimaAdoptionArr[0].cover" />
                <div>
                  <span>姓名：{{ tableData.AnimaAdoptionArr[0].name }}</span>
                  <span>年龄：{{ tableData.AnimaAdoptionArr[0].age }}</span>
                  <span>性别：{{ tableData.AnimaAdoptionArr[0].gender }}</span>
                  <span
                    >健康状态：{{
                      tableData.AnimaAdoptionArr[0].healthStatus
                    }}</span
                  >
                  <span
                    >所在地区：{{ tableData.AnimaAdoptionArr[0].area }}</span
                  >
                </div>
              </div>
              <div class="charThreeMore">
                <div id="charThreeDivOne">
                  <!-- <img src="" alt="" /> -->
                  <div
                    class="charImgPosi"
                    @click="goToLink('https://www.bobocw.com/')"
                  >
                    <img src="../assets/img/Frame7.svg" alt="咱百科" />
                    <span>动物领养</span>
                  </div>
                </div>
                <div id="charThreeDivTwo">
                  <!-- <img src="" alt="" /> -->
                  <div
                    class="charImgPosi"
                    @click="goToLink('http://www.chongyoushe.com/')"
                  >
                    <img src="../assets/img/Frame8.svg" alt="咱百科" />
                    <span>动物救助</span>
                  </div>
                </div>
                <div id="charThreeDivThree">
                  <div
                    class="charImgPosi"
                    @click="goToLink('https://www.7nua.com/chongwu/')"
                  >
                    <img src="../assets/img/Frame9.svg" alt="咱百科" />
                    <span>动物保护</span>
                  </div>
                </div>
                <div id="charThreeDivFour" v-if="!userInfo.resLayout">
                  <div
                    class="charImgPosi"
                    @mousemove="moverOpen"
                    @mouseleave="moverFalse"
                  >
                    <img src="../assets/img/Frame10.svg" alt="咱百科" />
                    <span>捐款渠道</span>
                  </div>
                  <img
                    id="fourImg"
                    src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/Group45139.png"
                    alt=""
                  />
                </div>
                <!-- 手机端 -->
                <div id="charThreeDivFour" v-else>
                  <div class="charImgPosi" @click="phoneDown">
                    <img src="../assets/img/Frame10.svg" alt="咱百科" />
                    <span>捐款渠道</span>
                  </div>
                  <img
                    id="fourImg"
                    src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/Group45139.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="charOne charTwo charYQ">
            <div class="charOneTitle">友情链接</div>
            <div class="charBorder charOneTitleOne"></div>
            <div
              id="charCenterDivOne"
              @mouseover="rollStop(false)"
              @mouseout="rollStartOne()"
            >
              <ul id="charDivUlLi">
                <li v-for="(item, index) in linksList.arr" :key="index">
                  <div
                    class="charDivUlLiOne"
                    v-for="(imgData, imgIndex) in item"
                    :key="imgIndex"
                    @click="goToWebsite(imgData.url)"
                  >
                    <img :src="imgData.imgUrl" alt="咱百科" />
                  </div>
                </li>
              </ul>
              <ul id="charDivUlLi2">
                <ul id="charDivUlLi">
                  <li v-for="(item, index) in linksList.arr" :key="index">
                    <div
                      class="charDivUlLiOne"
                      v-for="(imgData, imgIndex) in item"
                      :key="imgIndex"
                      @click="goToWebsite(imgData.url)"
                    >
                      <img :src="imgData.imgUrl" alt="咱百科" />
                    </div>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  reactive,
  onMounted,
  ref,
  onUnmounted,
  watch,
  nextTick,
  onDeactivated,
  onActivated,
} from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
// import hlsjs from "hls.js";
import route from "../router/index";
import {
  getBannerList,
  getEsseayTable,
  getanimalAdoptionList,
} from "../api/chari/index";
// import { getBaseUrl } from "../api/Login/index";
import { getLinksList } from "../api/linksFriend/index";
import { getliveBroadcast } from "../api/videoData/index";
import VisionUpper from "../assets/img/VisionUpper.svg";
import VisionBelow from "../assets/img/VisionBelow.svg";
import BJVision from "../assets/img/BJVision.png";
import Visionframe48 from "../assets/img/Visionframe48.png";
import Group45652Block from "../assets/img/Group45652Block.svg";
import Group45283Stop from "../assets/img/Group45283Stop.svg";
// import Group45243 from "../assets/img/Group45243.svg";
// import Frame47 from "../assets/img/Frame47.svg";
// import Group45287 from "../assets/img/Group45287.svg";
// import Group45289 from "../assets/img/Group45289.svg";
// import loginTokenOne from "../assets/img/loginTokenOne.svg";
// import { Session, Local } from "../utils/storage";
import VideoComponent from "../components/videoComponent.vue";
import VideoHls from "./charitableModule/videoHls.vue";
import { Local } from "../utils/storage";
import emitter from "../utils/events-bus";

export default {
  name: "Charitable",
  components: { VideoComponent, VideoHls },
  setup(props: any, { emit }: any) {
    // const videoUrl: any = ref("http://liveop.cctv.cn/hls/4KHD/playlist.m3u8");

    const popupFlage: any = ref(false);
    const videoLiveFlage = ref(true);
    const videoLiveTitle = ref("");
    const videoComponentUrl = ref("");

    function changeLogon() {
      emitter.emit("showXs", true);
      popupFlage.value = false;
      if (!userInfo.login) {
        childRef.value.changeColor(2, "标清");
      }
    }
    function cancel() {
      popupFlage.value = false;
      childRef.value.changeColor(2, "标清");
      Local.remove("videoDefinition");
    }

    const imgs: any = reactive({
      arrList: [
        {
          imgUrl: "",
        },
      ],
    });
    var timer: any;
    var timerOne: any;
    function roll() {
      // var ul1 = document.getElementById("comment1") as HTMLElement;
      // var ul2 = document.getElementById("comment2") as HTMLElement;
      var ulbox = document.getElementById("review_box") as HTMLElement;
      // ul2.innerHTML = ul1.innerHTML;
      if (ulbox) {
        ulbox.scrollLeft = 0;
        rollStart();
      }
    }
    function rollStart() {
      // console.log(111);
      clearInterval(timer);
      // if (!userInfo.resLayout) {
      const ul1 = document.getElementById("comment1") as HTMLElement;
      // const ul2 = document.getElementById("comment2") as HTMLElement;
      const ulbox = document.getElementById("review_box") as HTMLElement;
      rollStop(true);
      timer = setInterval(() => {
        // 当滚动宽度大于列表内容宽度时恢复为0
        if (ulbox.scrollLeft >= ul1.scrollWidth) {
          ulbox.scrollLeft = 0;
        } else {
          ulbox.scrollLeft = ulbox.scrollLeft + 1;
        }
      }, 20);
      // }
    }
    function rollOne() {
      // var ul1 = document.getElementById("charDivUlLi") as HTMLElement;
      // var ul2 = document.getElementById("charDivUlLi2") as HTMLElement;
      var ulbox = document.getElementById("charCenterDivOne") as HTMLElement;
      // ul2.innerHTML = ul1.innerHTML;
      if (ulbox) {
        ulbox.scrollLeft = 0;
        rollStartOne();
      }
    }
    function rollStartOne() {
      clearInterval(timerOne);
      const ul1 = document.getElementById("charDivUlLi") as HTMLElement;
      // const ul2 = document.getElementById("charDivUlLi2") as HTMLElement;
      const ulbox = document.getElementById("charCenterDivOne") as HTMLElement;
      rollStop(false);
      timerOne = setInterval(() => {
        // 当滚动宽度大于列表内容宽度时恢复为0
        if (ulbox.scrollLeft >= ul1.scrollWidth) {
          ulbox.scrollLeft = 0;
        } else {
          ulbox.scrollLeft = ulbox.scrollLeft + 1;
        }
      }, 20);
    }

    function rollStop(flage: any) {
      if (!/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        flage ? clearInterval(timer) : clearInterval(timerOne);
      }
    }

    const store = useStore();
    const userInfo = store.state.userInfo;
    function toScroll(data: string) {
      let toSeat: any;
      switch (data) {
        case "医护":
        case "醫護":
        case "MedicalCare":
          toSeat = ".doctorsDiv";
          break;
        case "助学":
        case "助學":
        case "Assistantship":
          toSeat = ".studentDiv";
          break;
        case "机构信息":
        case "機構資訊":
        case "Institutional Information":
          toSeat = ".charityDiv";
          break;
        case "环境保护":
        case "環境保護":
        case "EnvironmentalProtection":
          toSeat = ".vibeDiv";
          break;
        case "动物保护":
        case "動物保護":
        case "AnimalProtection":
          toSeat = ".animalDiv";
          break;
        case "名人慈善":
        case "CelebrityCharity":
          toSeat = ".notableDiv";
          break;
        case "优秀人物":
        case "優秀人物":
        case "Excellent":
          toSeat = ".historicalDiv";
          break;
        case "器官捐献":
        case "器官捐獻":
        case "OrganDonation":
          toSeat = ".donateDiv";
          break;
        case "动物救助":
        case "動物救助":
        case "AnimalRescue":
          toSeat = ".rescueDiv";
          break;
      }
      const mbElement = document.querySelector("#hearde") as HTMLElement;
      // console.log(mbElement.offsetHeight);

      let headerOffset = mbElement.offsetHeight;
      // console.log("headerOffset",headerOffset);
      var elementData = document.querySelector(toSeat);
      var elementPosition = elementData?.offsetTop;
      window.scrollTo({
        top: elementPosition - headerOffset,
        behavior: "smooth",
      });
    }

    const carousel = ref();

    const router: any = useRoute();
    const tableData = reactive({
      MedicalArr: [{ id: "", cover: "", title: "", videoUrl: "" }],
      StudentArr: [{ id: "", cover: "", title: "", videoUrl: "" }],
      EnvironmentalArr: [{ id: "", cover: "", title: "", videoUrl: "" }],
      AnimalArr: [{ id: "", cover: "", title: "", videoUrl: "" }],
      AnimaAdoptionArr: [
        {
          id: "",
          age: "",
          area: "",
          cover: "",
          gender: "",
          name: "",
          url: "",
          healthStatus: "",
        },
      ],
      HistoricalArr: [{ id: "", cover: "", title: "", videoUrl: "" }],
      HistoricalArrOne: [{ id: "", cover: "", title: "", videoUrl: "" }],
      CelebrityArr: [{ id: "", cover: "", title: "", videoUrl: "" }],
      CelebrityArrOne: [{ id: "", cover: "", title: "", videoUrl: "" }],
      missionArr: [] as any,
    });
    //排序
    function compare(value1: any, value2: any) {
      if (value1.recommendType < value2.recommendType) {
        return 1;
      } else if (value1.recommendType > value2.recommendType) {
        return -1;
      } else {
        return 0;
      }
    }

    const linksList: any = reactive({
      arr: [],
    });
    //子元素传递
    const videoNumber = ref(0);
    const listVideoNumber = ref(0);
    function getTimeVideo(params: any) {
      // videoNumber.value = videoNumber.value++;
      videoComponentUrl.value = liveVideoList.arr[params].uri;
      videoLiveTitle.value = liveVideoList.arr[params].name;
      listVideoNumber.value = params;
      //  const dataList= document.querySelector(".videoComponentRightDiv")
      // // console.log("videoComponentUrl.value",videoComponentUrl.value);
      // console.log("dataList", dataList);
      getRightLi();
    }
    //直播轮播列表
    const liveVideoList: any = reactive({
      arr: [],
    });
    async function liveVideo() {
      const dataList = await getliveBroadcast();
      dataList.data.forEach((item: any) => {
        item.uri = store.state.allWeb.publicLinks + item.uri;
        item.cover = store.state.allWeb.publicLinks + item.cover;
        item.svgButton = Group45283Stop;
      });
      // console.log(baseUrl.value);
      // console.log("dataList", dataList.data);
      liveVideoList.arr = dataList.data.sort((a: any, b: any) => {
        return b.id - a.id;
      });
      // console.log("liveVideoList", liveVideoList.arr);

      videoNumber.value = dataList.data.length;
      videoComponentUrl.value = liveVideoList.arr[0].uri;
      emit("childElement", liveVideoList.arr[0].name);
      // videoLiveTitle.value = liveVideoList.arr[0].name;
      // baseUrl.value
    }
    async function changeFlageVideo(params: any) {
      // console.log(params);

      if (!params) {
        await liveVideo();

        videoLiveTitle.value = liveVideoList.arr[0].name;

        // console.log( videoLiveTitle.value);
      }
      videoLiveFlage.value = params;
      videoLiveFlage.value
        ? (popupFlage.value = true)
        : (popupFlage.value = false);
    }
    const childRef = ref<any>();
    function childElement(params: any) {
      // console.log(params);
      videoLiveTitle.value = params;
    }
    function childChangePopup(params: any) {
      // console.log(params);
      popupFlage.value = params.PopFalg;
      Local.set("videoDefinition", params.textName);
    }
    const videoComponentRightLi: any = ref(null);
    function getRightLi() {
      videoComponentRightLi.value = document
        .querySelector("#videoComponentRight")
        ?.querySelectorAll("li");
      // console.log("videoComponentRightLi.value", videoComponentRightLi.value);
      if (videoComponentRightLi.value?.length > 0) {
        videoComponentRightLi.value[0].classList.add("liVideoRight");
        videoComponentRightLi.value[0].querySelector("img").src =
          Group45652Block;
      }
    }
    onMounted(async () => {
      // console.log( router.query);
      if (router.query.name) {
        await toScroll(router.query.name);
        history.replaceState(null, "", window.location.href.split("?")[0]);
      }

      // baseUrl.value = await getBaseUrl();
      const bannerList = await getBannerList();
      bannerList.data.forEach((element: any) => {
        element.imgUrl = store.state.allWeb.publicLinks + element.imgUrl;
      });

      // console.log(tableData.missionArr);

      // console.log(tableData.CelebrityArrOne);

      // console.log("tableData", tableData);
      const linksData = await getLinksList({ place: "0" });
      // console.log(linksData.data);
      linksList.arr = [];
      for (var i = 0; i < linksData.data.length; i += 3) {
        linksList.arr.push(linksData.data.slice(i, i + 3));
      }
      // linksList.arr = linksData.data;
      // console.log("linksList.arr", linksList.arr);

      !userInfo.resLayout
        ? (imgs.arrList = bannerList.data.slice(0, 8))
        : (imgs.arrList = bannerList.data.slice(9, 16)); //--测试轮播---

      // intervalOne.value = setInterval(() => {
      //   console.log("执行一次");
      //   intervalFn();
      // }, 5000);

      // console.log(
      //   "tableData.AnimaAdoptionArr[0]",
      //   tableData.AnimaAdoptionArr[0]
      // );
      // 医护
      const essayListMedical = await getEsseayTable({
        articleModuleType: "MEDICAL_CARE",
      });
      // 助学
      const essayListStudent = await getEsseayTable({
        articleModuleType: "STUDENT_AID",
      });
      // 环保
      const essayListEnvironmental = await getEsseayTable({
        articleModuleType: "ENVIRONMENTAL_FRIENDLY",
      });
      // 动保
      const essayListAnimal = await getEsseayTable({
        articleModuleType: "ANIMAL_PROTECTION",
      });
      // 历史人物
      const essayListHistorical = await getEsseayTable({
        articleModuleType: "HISTORICAL_FIGURE",
      });
      // 名人慈善
      const essayListCelebrity = await getEsseayTable({
        articleModuleType: "CELEBRITY_CHARITY",
      });
      // 慈善机构
      const missionCelebrity = await getEsseayTable({
        articleModuleType: "CHARITY_CARD",
      });

      nextTick(async () => {
        getRightLi();
        missionCelebrity.data.articleListTwo.forEach((item: any) => {
          item.visionSvg = VisionUpper;
          item.backgroundImg = BJVision;
          item.agency.logo =
            store.state.allWeb.publicLinks + "/" + item.agency.logo;
          item.articleList.forEach((valueUrl: any) => {
            valueUrl.cover = store.state.allWeb.publicLinks + valueUrl.cover;
          });
        });
        const animalData = await getanimalAdoptionList();
        // console.log("animalData", animalData.data);
        animalData.data.cover =
          store.state.allWeb.publicLinks + animalData.data.cover;
        // let textHealth = "";

        // animalData.data.healthStatus.forEach((element: any) => {
        //   textHealth = textHealth + " " + element;
        // });
        // animalData.data.textHealth = textHealth;
        tableData.AnimaAdoptionArr[0] = animalData.data;
        // console.log("慈善机构", missionCelebrity);

        // console.log("essayListMedical", essayListMedical);
        // console.log("essayListStudent", essayListStudent);
        // console.log("essayListEnvironmental", essayListEnvironmental);
        // console.log("essayListAnimal", essayListAnimal);
        // console.log("essayListHistorical", essayListHistorical);
        // console.log("essayListCelebrity", essayListCelebrity);

        tableData.MedicalArr =
          essayListMedical.data.articleListOne.sort(compare);

        tableData.StudentArr =
          essayListStudent.data.articleListOne.sort(compare);

        tableData.EnvironmentalArr =
          essayListEnvironmental.data.articleListOne.sort(compare);

        tableData.AnimalArr = essayListAnimal.data.articleListOne.sort(compare);

        tableData.HistoricalArr =
          essayListHistorical.data.articleListOne.sort(compare);
        const dataOne = tableData.HistoricalArr[0];
        tableData.HistoricalArr.splice(0, 1);
        tableData.HistoricalArr.splice(3, 0, dataOne).slice(0, 3);
        // console.log(tableData.HistoricalArr.length);

        tableData.HistoricalArrOne = tableData.HistoricalArr.slice(3, 6);
        // console.log(tableData.HistoricalArrOne.length);

        tableData.CelebrityArr =
          essayListCelebrity.data.articleListOne.sort(compare);
        const dataTwo = tableData.CelebrityArr[0];
        tableData.CelebrityArr.splice(0, 1);
        tableData.CelebrityArr.splice(3, 0, dataTwo).slice(0, 3);
        // console.log(tableData.CelebrityArr);

        tableData.CelebrityArrOne = tableData.CelebrityArr.slice(3, 6);
        tableData.missionArr = missionCelebrity.data.articleListTwo;

        roll();
        rollOne();
        // videoHls();
      });

      //--测试轮播结束---
    });
    // onUpdated(() => {
    //   if (!videoLiveFlage.value) {
    //     console.log(document.querySelector("#videoComponentRight"));
    //   }
    // });

    function routerTo(e: any) {
      // console.log("e", e);
      let path = "";
      // e.videoUrl
      //   ? (path = "/Charitable/textVideo")
      // :
      path = "/Charitable/textCentent";

      const textCentent = route.resolve({
        path: path,
        query: {
          id: e.id,
          type: "1",
        }, //传参
      });
      window.open(textCentent.href, "_blank");
    }
    async function goToEsseay() {
      const orgnData = await getEsseayTable({
        articleModuleType: "ORGAN_DONATION",
      });
      // console.log("orgnData",orgnData);
      const orgnId = orgnData.data.articleListOne.filter((item: any) => {
        return item.title == "器官捐献，让生命延续";
      });
      // console.log("orgnId",orgnId[0].id);
      const textCentent = route.resolve({
        path: "/Charitable/textCentent",
        query: {
          id: orgnId[0].id,
        }, //传参
      });
      window.open(textCentent.href, "_blank");

      // route.push({
      //   name: "TextCentent",
      //   params: {
      //     id: orgnId[0].id,
      //   },
      // });
    }
    // https://www.bobocw.com/adoption/66.html
    function goToLink(urlName: string) {
      window.open(urlName, "_blank");
    }
    function goToWebsite(link: string) {
      window.open(link, "_blank");
    }

    function moverOpen() {
      const dcmId = document.getElementById("fourImg") as HTMLElement;

      dcmId.style.zIndex = "100";
      dcmId.style.opacity = "1";

      // console.log(111);
    }
    function moverFalse() {
      const dcmId = document.getElementById("fourImg") as HTMLElement;

      // console.log(111);
      dcmId.style.zIndex = "-100";
      dcmId.style.opacity = "0";
    }
    var phoneDownFlage = true;
    function phoneDown() {
      const dcmId = document.getElementById("fourImg") as HTMLElement;
      if (phoneDownFlage) {
        dcmId.style.zIndex = "100";
        dcmId.style.opacity = "1";
        phoneDownFlage = !phoneDownFlage;
      } else {
        dcmId.style.zIndex = "-11";
        dcmId.style.opacity = "0";
        phoneDownFlage = !phoneDownFlage;
      }
    }

    function pushToMore(valueName: string) {
      const urlChariMore = route.resolve({
        path: "/CharitableMore",
        query: {
          valueName,
        }, //传参
      });

      window.open(urlChariMore.href, "_blank");
    }
    function openUrl(valueUrl: string) {
      window.open(valueUrl, "_blank");
    }
    let flagVision = true;
    function changeVision(e: any, missionList: any) {
      // console.log(e.currentTarget.parentElement);
      // console.log(e.currentTarget.parentElement.querySelector(".divVisionTwo"));
      // console.log(e.currentTarget.parentElement.nextElementSibling);
      // const divVision = e.currentTarget.parentElement;

      const divThree = e.currentTarget.parentElement.nextElementSibling;
      const divVisionTwo =
        e.currentTarget.parentElement.querySelector(".divVisionTwo");
      if (flagVision) {
        flagVision = !flagVision;
        missionList.backgroundImg = Visionframe48;
        divVisionTwo.style.display = "block";
        divThree.style.display = "none";
        missionList.visionSvg = VisionBelow;
        // rollStop(false);
        userInfo.resLayout ? clearInterval(timer) : "";
      } else {
        flagVision = !flagVision;
        missionList.backgroundImg = BJVision;
        divVisionTwo.style.display = "none";
        divThree.style.display = "flex";
        missionList.visionSvg = VisionUpper;

        userInfo.resLayout ? rollStart() : "";
      }
    }

    watch(
      () => router.query.name,
      () => {
        // console.log("变换了");
        toScroll(router.query.name);
      }
    );

    watch(
      () => listVideoNumber.value,
      () => {
        // console.log("videoComponentRightLi.value", videoComponentRightLi.value);
        if (!videoComponentRightLi.value) {
          videoComponentRightLi.value = document
            .querySelector("#videoComponentRight")
            ?.querySelectorAll("li");
          videoComponentRightLi.value[0].classList.add("liVideoRight");
          videoComponentRightLi.value[0].querySelector("img").src =
            Group45652Block;
        }
        videoComponentRightLi.value.forEach((element: any) => {
          element.classList.remove("liVideoRight");
          // console.log(element.querySelector("img"));

          element.querySelector("img")
            ? (element.querySelector("img").src = Group45283Stop)
            : "";
        });
        videoComponentRightLi.value[listVideoNumber.value].classList.add(
          "liVideoRight"
        );
        videoComponentRightLi.value[listVideoNumber.value].querySelector(
          "img"
        ).src = Group45652Block;

        const videoComponentRight = document.getElementById(
          "videoComponentRight"
        ) as HTMLElement;

        if (
          listVideoNumber.value >= 2 &&
          listVideoNumber.value < liveVideoList.arr.length - 2
        ) {
          // const videoComponentRight= document.getElementById("videoComponentRight") as HTMLElement

          videoComponentRight.scrollTo({
            top:
              videoComponentRightLi.value[0].clientHeight *
              (listVideoNumber.value - 1),
            behavior: "smooth",
          });
        } else if (listVideoNumber.value <= 2) {
          videoComponentRight.scrollTo({
            top: -videoComponentRightLi.value[0].clientHeight,
            behavior: "smooth",
          });
        }
      }
    );
    onUnmounted(() => {
      clearInterval(timer);
      clearInterval(timerOne);
      // clearInterval(intervalOne.value);
    });

    onActivated(() => {
      const videoId = document.querySelector(
        "#liveVideoOne"
      ) as HTMLVideoElement;
      videoId ? videoId.play() : "";
    });
    //--测试轮播结束---
    return {
      videoNumber,
      listVideoNumber,
      popupFlage,

      imgs,

      // imgOne,
      // imgTwo,
      // imgThree,
      // imgFour,
      // imgFive,
      carousel,
      tableData,
      userInfo,
      linksList,
      childRef,
      videoLiveFlage,
      videoComponentUrl,
      videoLiveTitle,
      liveVideoList,
      // visionSvg,
      // backgroundImg,
      // ---测试轮播----
      cancel,
      changeLogon,

      rollStop,
      rollStart,
      rollStartOne,
      routerTo,
      goToEsseay,
      goToLink,
      goToWebsite,
      moverOpen,
      moverFalse,
      phoneDown,
      pushToMore,
      openUrl,
      changeVision,

      getTimeVideo,
      childElement,
      childChangePopup,
      changeFlageVideo,
      // scrollMove,
      //----测试结束-----
    };
  },
};
</script>
<style lang="scss" scoped>
ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}
p {
  margin: 0;
}
li:hover {
  cursor: pointer;
}
button {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: none;
}
.fullScreen {
  video {
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;
    z-index: 11111 !important;
    border-radius: 0px !important;
  }
  #VideoIcon {
    position: fixed !important;
    z-index: 11111 !important;
  }
  .titleLive {
    position: fixed !important;
    z-index: 11112 !important;
  }
  .backgroundLive {
    position: fixed !important;
    z-index: 1111 !important;
  }
}
.classImg {
  color: #13d571 !important;
}
.liVideoRight {
  position: relative;
  background-image: url("../assets/img/Rectangle1404Block.svg") !important;
  span {
    color: #fff !important;
  }
  &:hover {
    background: rgba(255, 255, 255, 0) !important;
    background-image: url("../assets/img/Rectangle1404Block.svg") !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 362px;
    height: 128px;
    background: rgba(0, 0, 0, 0.3);
    z-index: -1;
  }
}
@media only screen and (min-width: 751px) {
  video::-webkit-media-controls-enclosure {
    display: none;
    // opacity: 0;
  }
  #videoDiv {
    position: relative;
    width: 100%;
    height: 729px;
    background: linear-gradient(
      134deg,
      rgba(168, 227, 147, 0.5) 0%,
      rgba(189, 220, 233, 0.5) 100%
    );
    padding-top: 99px;
    box-sizing: border-box;

    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    // padding: 36px 0px;

    &::after {
      content: "";
      position: absolute;
      top: 38px;
      left: 0;
      width: 1920px;
      height: 729px;
      background: url(../assets/img/Group45539.svg);
      background-size: 100%;
      background-repeat: no-repeat;
      padding-top: 99px;
      box-sizing: border-box;
    }

    #videoPositionBackground {
      position: relative;
      width: 1150px;
      height: 612px;
      margin: 0 auto;
      z-index: 11;
      // #videoHls {
      //   width: 1150px;
      //   height: 612px;
      //   margin: 0 auto;
      // }
      #mmiid {
        display: block;
        width: 1150px;
        height: 612px;
        margin: 0px auto;
        background: #ffffff;
        border-radius: 12px;
      }
      #VideoIcon {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 22px;
        right: 44px;
        .divImgTwo {
          position: relative;
          margin-right: 92px;
          z-index: 111;
          &:hover {
            cursor: pointer;
          }
          .imgTwoDiv {
            display: none;
            position: absolute;
            bottom: 42px;
            left: -18px;
            width: 73px;
            // height: 110px;
            background: rgba(58, 58, 58, 0.5);
            border-radius: 2px;
            padding: 9px 0px;
            li {
              position: relative;
              height: 30px;
              display: flex;
              align-items: center;
              padding-left: 19px;
              &:hover {
                cursor: pointer;
                background: rgba(247, 247, 247, 0.3);
              }
              span {
                font-family: "PingFang SC";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 30px;
                text-align: justify;
                letter-spacing: 0.02em;
                color: #ffffff;
              }
              img {
                width: 11px;
                height: 11px;
              }
            }
          }
          .divImgTwoText {
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            text-align: justify;
            letter-spacing: 0.06em;
            color: #ffffff;
          }
        }
        .divImgThree {
          position: relative;
          margin-right: 88px;
          z-index: 111;
          &:hover {
            cursor: pointer;
          }
          .imgThreeDiv {
            display: none;
            width: 30px;
            height: 75px;
            background: rgba(58, 58, 58, 0.5);
            border-radius: 2px;
            position: absolute;
            bottom: 46px;
            left: -6px;
            z-index: 11;
            // input {
            //   position: absolute;
            //   bottom: 34px;
            //   left: -16px;
            //   width: 56px;
            //   height: 2px;
            //   transform: rotate(270deg);
            // }
            :deep(.el-slider) {
              width: 4px !important;
              height: 56px !important;
            }
            :deep(.el-slider__runway) {
              width: 2px !important;
              height: 56px !important;
              // margin: 0px !important;
              margin: 8px 14px !important;
              background: rgba(255, 255, 255, 0.5);
            }
            :deep(.el-slider__bar) {
              width: 2px !important;
              background: #11c066 !important;
            }
            :deep(.el-slider__button-wrapper) {
              width: 6px !important;
              height: 6px !important;
              left: -2px;
              // margin: 0 50% !important;
              transform: none !important;
              background: #ffffff;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              &:hover {
                cursor: pointer;
              }
            }

            :deep(.el-slider__button) {
              display: none;
              // width: 6px !important;
              // height: 6px !important;
              // border: none !important;
              &:hover {
                display: none;
              }
            }
          }
          .imgThree {
            width: 24px;
            height: 24px;
            margin-top: 5px;
          }
        }
        .imgFour {
          z-index: 111;
          width: 20px;
          height: 20px;
          &:hover {
            cursor: pointer;
          }
        }
      }
      #videoComponent {
        width: 1170px;
        #videoComponentFlex {
          display: flex;
          align-items: center;
          .videoComponentRightDiv {
            display: flex;
            flex-direction: column;
            #videoComponentRightTitle {
              position: absolute;
              top: -36px;
              align-self: flex-start;
              color: #48555a;
              text-align: center;
              font-family: "PingFang SC";
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 32px;
              letter-spacing: 4.8px;
              width: auto !important;
              height: auto !important;
              padding: 0;
              margin: 0;
              margin-left: 17px;
            }
            #videoComponentRight {
              position: relative;
              width: 362px;
              height: 520px;
              margin-left: 17px;
              border-radius: 12px;
              border: 2px solid #fff;
              background: rgba(255, 255, 255, 0.35);
              overflow: hidden;

              &:hover {
                overflow-y: auto;

                &::-webkit-scrollbar {
                  width: 6px;

                  background: none;
                }

                &::-webkit-scrollbar-thumb {
                  background: rgba(52, 52, 52, 0.5);
                  border-radius: 12px;
                  border-right: 4px solid rgba(0, 0, 0, 0);
                  box-sizing: border-box;
                }
              }

              li {
                display: flex;
                align-items: center;

                margin-bottom: 2px;
                width: 362px;
                height: 128px;
                padding-left: 6px;
                box-sizing: border-box;
                background-repeat: no-repeat;
                background-size: 362px 128px;

                .videoTwoImg {
                  position: relative;
                  height: 109px;
                  video {
                    width: 162px;
                    height: 109px;
                    background: #d9d9d9;
                    border-radius: 12px;
                    object-fit: fill;
                  }
                  img {
                    position: absolute;
                    top: calc(50% - 18px);
                    left: calc(50% - 18px);
                    width: 36px;
                    height: 36px;
                  }
                }
                span {
                  width: 162px;
                  margin-left: 8px;
                  font-family: "PingFang SC";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 28px;
                  letter-spacing: 1.6px;
                  color: #48555a;
                  opacity: 0.85;
                  text-overflow: -o-ellipsis-lastline;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 4;
                  line-clamp: 4;
                  -webkit-box-orient: vertical;
                  align-self: flex-start;
                  margin-top: 16px;
                }
                &:hover {
                  cursor: pointer;
                  background: rgba(0, 0, 0, 0.1);
                  // border-radius: 12px;
                }
                // &:nth-child(2):hover {
                //   border-top-left-radius: 12px;
                //   border-top-right-radius: 12px;
                // }

                &:last-child {
                  margin-bottom: 0px;
                  // &:hover {
                  //   border-bottom-left-radius: 12px;
                  //   border-bottom-right-radius: 12px;
                  // }
                }
              }
              // #scrollImg {
              //   display: none;
              //   position: absolute;
              //   top: 0px;
              //   right: 4px;
              //   width: 6px;
              //   height: 82px;
              //   border-radius: 6px;
              //   background: rgba(52, 52, 52, 0.5);
              //   z-index: 1111;

              //   &:hover {
              //     background: rgba(52, 52, 52, 0.5);
              //   }
              //   // img {
              //   //   width: 6px;
              //   //   height: 82px;
              //   // }
              // }
            }
          }
        }
        #videoComponentText {
          display: inline-block;
          width: 973px;
          margin-top: 14px;
          color: #48555a;
          font-family: "PingFang SC";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 3.84px;
        }
      }

      .titleLive {
        display: flex;
        // align-content: center;
        align-items: center;
        position: absolute;
        top: -35px;
        left: 0px;

        // mix-blend-mode: darken;
        // border-radius: 12px 12px 0px 0px;
        // img {
        //   width: 40px;
        //   height: 30px;
        //   margin-right: 8px;
        //   margin-left: 22px;
        //   margin-top: 12px;
        // }
        span {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          text-align: justify;
          // padding-top: 4px;
          color: #393f41;
        }

        #LiveVideoTitle {
          font-size: 20px;
          font-weight: 400;
          line-height: 32px; /*  */
          letter-spacing: 4.8px;
        }
        #LiveNumPeople {
          background: url("../assets/img/livePeople.svg") no-repeat 11px 11px;
          background-position: left center;
          margin-left: 16px;
        }
        #LiveLike {
          background: url("../assets/img/liveLike.svg") no-repeat 11px 11px;
          background-position: left center;
          margin-left: 23px;
        }
        .LiveNumText {
          padding-left: 16px;
          font-size: 13px;
          line-height: 20.868px;
          letter-spacing: 1.495px;
        }
      }
      .backgroundLive {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 378px;
        background: linear-gradient(
          179.29deg,
          rgba(255, 255, 255, 0) 66.31%,
          rgba(90, 90, 90, 0.52) 96.37%
        );
        border-radius: 12px;
        // z-index: -11;
      }
      #popupDiv {
        position: absolute;
        // top: 205px;
        // left: 359px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 432px;
        height: 146px;
        background: #ffffff;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.4);
        border-radius: 12px;
        z-index: 11111;
        span {
          display: block;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: justify;
          letter-spacing: 0.1em;
          color: #000000;
          margin-left: 51px;
          margin-top: 29px;
        }
        .popupDivButton {
          margin-top: 30px;
          margin-left: 230px;
          button {
            width: 81px;
            height: 33px;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.1em;
            color: #000000;
            white-space: nowrap;
            &:hover {
              cursor: pointer;
            }
            &:first-child {
              border: 1px solid #56bd88;
              border-radius: 5px;
              background: #fff;
              margin-right: 20px;
            }
            &:last-child {
              background: #56bd88;
              border-radius: 5px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  :deep(button.el-carousel__button) {
    border-radius: 6px;
    width: 9px;
    height: 9px;
    background: #feffff;
  }

  :deep(.el-carousel__indicators, .el-carousel__indicators--horizontal) {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    // width: 123px !important;
    height: 25px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 23px;
    margin-bottom: 6px;
    overflow: hidden;
    .is-active {
      width: 28px !important;
      button {
        width: 28px !important;
        height: 9px !important;
      }
    }
    li {
      border-radius: 6px !important;
      width: 9px;
      height: 9px;
      padding: 0;
      margin: 8px 0px;
      margin-right: 14px;
      &:first-child {
        margin-left: 13px;
      }
      &:last-child {
        margin-right: 13px;
      }
    }
    // li:not(.is-active) {
    //   width: 9px !important;
    //   height: 9px !important;
    //   animation: width 1s !important;
    // }
  }
  :deep(.el-carousel__container) {
    height: 220px !important;
    background: conic-gradient(
      from 0.59deg at 50.36% 54.21%,
      rgba(146, 252, 136, 0.08) -59.47deg,
      rgba(177, 221, 252, 0.17) 64.37deg,
      rgba(146, 252, 136, 0.08) 300.53deg,
      rgba(177, 221, 252, 0.17) 424.37deg
    );
  }
  :deep(.el-carousel__item) {
    img {
      width: 1920px !important;
      height: 220px !important;
    }
  }
  #chariDiv {
    margin-top: 73px;
    #charCenter {
      width: 100%;
      // height: 1000px;

      #charDivFather {
        // background: url(../assets/img/videoBack.webp);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: right top;
        background-attachment: fixed;
        #charDivFatherBack {
          width: 1148px;
          margin: 0 auto;
          padding-top: 85px;
          padding-bottom: 72px;
          .charOne {
            width: 1148px;
            // height: 378px;
            min-height: 378px;
            margin-bottom: 85px;
            scroll-behavior: smooth;

            span {
              &:hover {
                color: #56bd88 !important;
              }
            }
            .divBothSides {
              display: flex;
              justify-content: space-between;
              // .divBothSidesOne {
              // }
              .divBothSidesTwo {
                display: flex;
                align-items: center;

                &:hover {
                  cursor: pointer;
                }
                img {
                  width: 20px;
                  height: 15px;
                  // margin-right: 19px;
                }
                div {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 29px;
                  text-align: center;
                  letter-spacing: 0.29em;
                  color: #48555a;
                }
              }
            }
            .charOneTitle {
              display: inline-block;
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 39px;
              letter-spacing: 0.24em;
              color: #48555a;
              padding-bottom: 2px;
              border-bottom: 2px solid;
              border-image: linear-gradient(
                  96.85deg,
                  rgba(126, 202, 252, 0.74) 2.46%,
                  #acd9b3 95.3%
                )
                2 2 2 2;
              border-radius: 12px 0px;
              margin-bottom: 14px;
            }

            // .charBorder {
            //   width: 65px;
            //   height: 2px;
            //   background: linear-gradient(
            //     96.85deg,
            //     rgba(126, 202, 252, 0.74) 2.46%,
            //     #acd9b3 95.3%
            //   );
            //   border-radius: 12px 0px;
            //   margin-bottom: 14px;
            // }
            .charOneTitleOne {
              width: 127px;
            }
            .charCenterDiv {
              display: flex;

              .charCenterOne {
                margin-right: 13px;
                width: 448px;
                height: 428px;
                background: rgba(255, 255, 255, 0.6);
                border: 0.5px solid #dbdbdb;
                box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
                border-radius: 12px;
                &:hover {
                  cursor: pointer;
                }
                .coverPosition {
                  width: 100%;
                  position: relative;
                  .coverPositionAbs {
                    position: absolute;
                    top: calc(50% - 36px);
                    left: calc(50% - 36px);
                    width: 72px;
                    height: 72px;
                  }
                }
                :deep(.el-image) {
                  display: block;
                  width: 448px;
                  height: 352px;
                  background: #f1f1f1;
                  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.25));
                  border-radius: 12px 12px 0px 0px;
                  margin-bottom: 5px;
                }

                span {
                  width: 419px;
                  margin: 10px auto;
                  margin-bottom: 14px;
                  text-overflow: -o-ellipsis-lastline;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  line-clamp: 2;
                  -webkit-box-orient: vertical;
                  font-family: "PingFang SC";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 26px;
                  letter-spacing: 0.115em;
                  color: #2f2f2f;
                }
              }
              .charCenterTwo {
                display: flex;
                flex-wrap: wrap;
                // width: 647px;
                // margin-left: 5px;
                li {
                  width: 216px;
                  height: 205px;
                  margin-right: 16px;
                  border: 0.5px solid #dbdbdb;
                  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
                  border-radius: 12px;
                  box-sizing: border-box;
                  &:nth-child(-n + 3) {
                    margin-bottom: 17px;
                  }
                  &:nth-child(3) {
                    margin-right: 0px;
                  }
                  &:nth-child(6) {
                    margin-right: 0px;
                  }
                  div {
                    position: relative;
                    :deep(.el-image) {
                      display: block;
                      width: 216px;
                      height: 129px;
                      filter: drop-shadow(
                        0px 0px 5px rgba(255, 255, 255, 0.25)
                      );
                      border-radius: 12px 12px 0px 0px;
                    }
                    img {
                      // &:first-child {
                      //   width: 209px;
                      //   height: 117px;
                      //   border-radius: 12px;
                      // }
                      // &:last-child {
                      position: absolute;
                      top: calc(50% - 20.5px);
                      left: calc(50% - 20.5px);
                      width: 41px;
                      height: 41px;
                      // }
                    }
                  }
                  span {
                    display: inline-block;
                    width: 194px;
                    margin: 10px auto;
                    margin-bottom: 14px;
                    font-family: "PingFang SC";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: 0.115em;
                    color: #2f2f2f;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                }
              }
            }
            #charCenterDivOne {
              width: 100%;
              overflow: hidden;
              display: flex;
              //---
              #charDivUlLi {
                display: flex;
                li {
                  display: flex !important;
                  flex-direction: column !important;
                  width: 279px;
                  margin-right: 11px;
                  -webkit-overflow-scrolling: touch;
                  // background: #ffffff;

                  // height: 534px;
                  // height: 500px;
                  // border: 1px solid #000;
                  div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 279px;
                    height: 97px;
                    border: 1px solid #abdabb;
                    border-radius: 6px;
                    background: #ffffff;
                  }
                  .charDivUlLiOne {
                    margin-bottom: 11px;
                    img {
                      max-width: 254px;
                      max-height: 67px;
                    }
                  }
                  .charDivUlLiTwo {
                    img {
                      max-width: 254px;
                      max-height: 67px;
                    }
                  }
                  .charDivUlLiThree {
                    margin-top: 11px;

                    img {
                      max-width: 254px;
                      max-height: 67px;
                    }
                  }
                }
              }
            }
          }
          .charTwo {
            height: auto;
            .charCenterDiv {
              // ----名人慈善
              ul {
                display: flex;
                li {
                  display: flex;
                  // height: 424px;

                  .coverPosition {
                    width: 100%;
                    position: relative;

                    .coverPositionAbs {
                      position: absolute;
                      top: calc(50% - 40.5px);
                      left: calc(50% - 40.5px);
                      width: 81px;
                      height: 81px;
                    }
                  }
                  // &:first-child {
                  //   margin-right: 15px;
                  // }
                  .liDiv {
                    width: 340px;
                    // height: 424px;
                    margin-right: 17px;
                    background: rgba(255, 255, 255, 0.6);
                    border: 0.5px solid #dbdbdb;
                    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
                    border-radius: 12px;
                    :deep(.el-image__inner) {
                      display: inline-block;
                      width: 340px;
                      height: 340px;
                      background: #e3ebee;
                      border-radius: 12px 12px 0px 0px;
                      // border-radius: 12px !important;
                    }
                    span {
                      display: inline-block;
                      width: 322px;
                      font-family: "PingFang SC";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 18px;
                      line-height: 22px;
                      letter-spacing: 0.115em;
                      color: #48555a;
                      text-overflow: -o-ellipsis-lastline;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      line-clamp: 2;
                      -webkit-box-orient: vertical;
                      // margin-top: 8px;
                      margin: 10px auto;
                      margin-bottom: 15px;
                    }
                  }
                  .liDivOne {
                    .coverPosition {
                      position: relative;
                      width: 208px;
                      height: 138px;
                      .coverPositionAbs {
                        width: 41px;
                        height: 41px;
                        top: calc(50% - 20.5px);
                        left: calc(50% - 20.5px);
                      }
                    }

                    .divOneImg {
                      width: 208px;
                      // height: 202px;
                      background: rgba(255, 255, 255, 0.6);
                      border: 0.5px solid #dbdbdb;
                      box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
                      border-radius: 12px;
                      // box-sizing: border-box;
                      :deep(.el-image__inner) {
                        display: block;
                        width: 208px;
                        height: 138px;
                        background: #f1f1f1;
                        box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
                        border-radius: 12px 12px 0px 0px;
                      }
                      span {
                        display: block;
                        width: 187px;
                        min-height: 44px;
                        font-family: "PingFang SC";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22px;
                        letter-spacing: 0.115em;
                        color: #48555a;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                        margin: 13px auto;
                        margin-bottom: 10px;
                      }
                      &:nth-child(1) {
                        margin-bottom: 18px;
                      }

                      // &:last-child {
                      //   span {
                      //     // margin-top: 8px;
                      //     margin-bottom: 0px;
                      //   }
                      // }
                    }
                    &:first-child {
                      margin-right: 17px;
                    }
                  }
                }
              }
              // -----名人慈善结束
            }
          }
          .charYQ {
            // height: 200px;
            min-height: 150px;
          }
          .charOneMar {
            margin-top: 85px;
          }
          // ---------开始------------

          #ceShanSwiper {
            scroll-behavior: smooth;
            .charOneTitle {
              display: inline-block;
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 39px;
              letter-spacing: 0.24em;
              color: #48555a;
              padding-bottom: 2px;
              border-bottom: 2px solid;
              border-image: linear-gradient(
                  96.85deg,
                  rgba(126, 202, 252, 0.74) 2.46%,
                  #acd9b3 95.3%
                )
                2 2 2 2;
              border-radius: 12px 0px;
              margin-bottom: 14px;
            }
            // background: #8d8c8c;
            #review_box {
              display: flex;
              overflow: hidden;
              width: 100%;
              min-height: 407px;

              ul {
                display: flex;

                // white-space: nowrap;
                li {
                  display: inline-block;
                  width: 241px;
                  margin-right: 8px;
                  -webkit-overflow-scrolling: touch;
                  // height: 534px;

                  // border: 1px solid #000;
                  font-family: "PingFang SC";
                  .comment1One {
                    background: #ffffff;
                    border: 1px solid #abdabb;
                    border-radius: 12px;
                    // height: 440px;
                    min-height: 462px;
                    .comment1OneImg {
                      display: block;
                      margin: 6px auto;
                      // width: 182px;
                      max-width: 206px;
                      max-height: 38px;
                      height: 38px;
                    }
                    .divTwo {
                      img {
                        display: block;
                        width: 220px;
                        height: 140px;
                        margin: 0 auto;
                        border-radius: 6px;
                        border: 1px solid rgba(0, 0, 0, 0.05);
                      }
                      .divTwoCenter {
                        width: 216px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 19px;
                        letter-spacing: 0.175em;
                        color: #48555a;
                        margin: 0 auto;
                        margin-top: 4px;
                        margin-bottom: 14px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                      }
                    }
                    .divVision {
                      width: 220px;

                      margin: 0 auto;
                      background: url(../assets/img/BJVision.png);
                      // background: url(../assets/img/Visionframe48.png);
                      background-size: 100% 100%;
                      background-repeat: no-repeat;
                      margin-bottom: 10px;
                      padding-top: 9px;
                      padding-bottom: 13px;
                      .divVisionOne {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0px 9px;
                        .divVisionTitle {
                          font-family: "PingFang SC";
                          font-style: normal;
                          font-weight: 400;
                          font-size: 24px;
                          line-height: 30px;
                          letter-spacing: 0.24em;
                          color: #56bd88;
                        }
                        img {
                          width: 16px;
                          height: 13px;
                        }
                      }
                      .divVisionTwo {
                        display: none;
                        // padding: 0px 9px;
                        width: 202px;
                        height: 142px;
                        overflow-x: hidden;
                        overflow-y: scroll;
                        // overflow: visible;
                        font-family: "PingFang SCRegular";
                        // font-style: normal;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 13px;
                        text-align: justify;
                        letter-spacing: 0.125em;
                        color: #101112;
                        margin: 4px auto;
                        margin-top: 6px;
                        // margin-bottom: 4px;
                      }
                      .divVisionTwo::-webkit-scrollbar {
                        display: none;
                      }
                    }
                    .divThree {
                      width: 220px;
                      margin: 0 auto;
                      display: flex;
                      flex-direction: column;
                      // display: none;

                      .divThreeOneTitle {
                        display: flex;
                        align-items: center;
                        margin: 0 auto !important;
                        div {
                          font-style: normal;
                          font-weight: 400;
                          font-size: 16px;
                          line-height: 20px;
                          letter-spacing: 0.115em;
                          color: #000000;
                          margin: 0 !important;
                        }
                        .titleBorder {
                          width: 18px;
                          height: 2px;
                          background: #87e5a7;
                          margin-right: 4px;
                        }
                        .titleBorderTwo {
                          margin-left: 4px;
                          margin-right: 0px;
                        }
                      }
                      .divThreeContent {
                        width: 220px;
                        display: flex;
                        justify-content: space-between;
                        margin-right: 10px;
                        .divThreeOne {
                          // width: 105px;
                          // margin-right: 11px;
                          img {
                            display: block;
                            width: 105px;
                            height: 70px;
                            margin-top: 13px;
                            border-radius: 6px;
                            border: 1px solid rgba(0, 0, 0, 0.05);
                          }
                          div {
                            width: 102px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            letter-spacing: 0.115em;
                            color: #48555a;
                            opacity: 0.85;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                            margin-top: 2px;
                            margin-bottom: 9px;
                            // &:last-child {
                            //   margin-top: 0px;
                            //   margin-bottom: 12px;
                            // }
                          }
                        }
                        .divThreeTwo {
                          margin-right: 0px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          // ---------测式结束------------

          .charThree {
            width: 1148px;
            // height: 240px;
            min-height: 240px;

            background: #f0f7f4;
            border: 1px solid #abdabb;
            border-radius: 12px;
            margin-top: 85px;
            position: relative;
            .charThreeOne {
              width: 100%;
              position: absolute;
              display: flex;
              .threeTitle {
                width: 132px;
                height: 45px;
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 39px;
                letter-spacing: 0.165em;
                text-align: center;
                color: #ffffff;
                background: linear-gradient(
                  90deg,
                  rgba(126, 202, 252, 0.75) 0%,
                  rgba(145, 204, 152, 0.75) 100%
                );
                border-radius: 12px 0px;
              }
              .titleBackground {
                width: 1020px;
                height: 36px;
                background: linear-gradient(
                  90deg,
                  rgba(126, 202, 252, 0.75) 0%,
                  rgba(145, 204, 152, 0.75) 100%
                );
                opacity: 0.6;
                border-radius: 0px 12px 0px 0px;
              }
            }
            .charThreeDiv {
              display: flex;
              width: 100%;
              // justify-content: space-between;
              .charThreeShow {
                display: flex;
                align-items: start;
                margin-top: 26px;
                padding-left: 16px;
                padding-right: 22px;
                padding-top: 31px;
                // width: 570px;
                // height: 221px;
                background: url(../assets/img/Group45503LyBack.png);
                background-repeat: no-repeat;
                background-size: 100% 221px;
                img {
                  width: 194px;
                  height: 176px;
                  border-radius: 8px;
                  margin-right: 13px;
                }
                div {
                  display: flex;
                  flex-direction: column;
                  // margin-top: 0px;
                  span {
                    width: 333px;
                    font-family: "PingFang SCRegular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;

                    line-height: 19px;
                    letter-spacing: 0.1em;
                    text-align: justify;
                    color: #000000;
                    margin-bottom: 20px;
                    &:last-child {
                      margin-bottom: 0px;
                    }
                  }
                }
                &:hover {
                  cursor: pointer;
                }
              }
              .charThreeMore {
                // width: 570px;
                margin-top: 36px;
                // height: 210px;

                #charThreeDivOne {
                  display: inline-block;
                  width: 285px;
                  height: 105px;
                  // background-size: 100% 100%;
                  // background-repeat: no-repeat;

                  // margin-left: 90px;
                  // padding-right: 90px;
                  border: 1px solid #c4e4d4;
                  border-top: none;
                  box-sizing: border-box;
                  background-image: url("../assets/img/dog-gf.png");
                  background-repeat: no-repeat;
                  background-size: 100% 100%;
                }
                #charThreeDivTwo {
                  width: 285px;
                  display: inline-block;
                  height: 105px;
                  // margin-left: 90px;
                  // padding-right: 90px;
                  // border-right: 2px solid #fff;
                  border-bottom: 1px solid #c4e4d4;
                  box-sizing: border-box;
                  background-image: url("../assets/img/vet-g.png");
                  background-repeat: no-repeat;
                  background-size: 100% 100%;
                }
                #charThreeDivThree {
                  display: inline-block;
                  width: 285px;
                  height: 105px;
                  // margin-top: 36px;
                  // border-right: 2px solid #fff;
                  border: 1px solid #c4e4d4;
                  border-top: none;
                  border-bottom: none;
                  box-sizing: border-box;
                  background: url(../assets/img/sparrow1.png);
                  background-repeat: no-repeat;
                  background-size: 100% 100%;
                  // .charImgPosi {
                  //   width: 295px;
                  //   height: 143px;

                  //   img {
                  //     width: 58px;
                  //     height: 58px;
                  //     margin-top: 27px;
                  //   }
                  //   span {
                  //     display: inline-block;
                  //   }
                  // }
                }
                #charThreeDivFour {
                  display: inline-block;
                  width: 285px;
                  height: 105px;
                  // margin-top: 36px;
                  position: relative;
                  background: url(../assets/img/Group45136.png);
                  background-repeat: no-repeat;
                  background-size: 100% 100%;
                  // .charImgPosi {
                  //   width: 221px;
                  //   height: 139px;

                  //   margin: 0 auto;
                  //   img {
                  //     width: 58px;
                  //     height: 58px;
                  //     margin-top: 27px;
                  //   }
                  //   span {
                  //     display: inline-block;
                  //   }
                  // }
                  box-sizing: border-box;
                  #fourImg {
                    position: absolute;
                    top: -203px;
                    left: -208px;
                    width: 467px;
                    height: 224px;
                    opacity: 0;
                    z-index: -111;
                  }
                }
              }
              .charThreeUl {
                width: 450px;
                display: flex;

                // .charThreeUlOne {
                //   padding-top: 64px;
                //   padding-left: 19px;
                //   li {
                //     width: 251px;
                //     font-style: normal;
                //     font-weight: 400;
                //     font-size: 16px;
                //     line-height: 20px;
                //     letter-spacing: 0.115em;
                //     color: #48555a;
                //     margin-bottom: 24px;
                //     text-overflow: -o-ellipsis-lastline;
                //     overflow: hidden;
                //     text-overflow: ellipsis;
                //     display: -webkit-box;
                //     -webkit-line-clamp: 2;
                //     line-clamp: 2;
                //     -webkit-box-orient: vertical;
                //     &:last-child {
                //       margin-bottom: 0;
                //     }
                //   }
                // }
                // .charThreeUlTwo {
                //   padding-top: 64px;
                //   margin-left: 23px;
                //   margin-right: 23px;
                //   li {
                //     width: 251px;
                //     font-style: normal;
                //     font-weight: 400;
                //     font-size: 16px;
                //     line-height: 20px;
                //     letter-spacing: 0.115em;
                //     color: #48555a;
                //     margin-bottom: 24px;
                //     text-overflow: -o-ellipsis-lastline;
                //     overflow: hidden;
                //     text-overflow: ellipsis;
                //     display: -webkit-box;
                //     -webkit-line-clamp: 2;
                //     line-clamp: 2;
                //     -webkit-box-orient: vertical;
                //     &:last-child {
                //       margin-bottom: 0;
                //     }
                //   }
                // }
                &:hover {
                  cursor: pointer;
                }
                #donateDivImg {
                  width: 369px;
                  height: 111px;
                  margin-top: 85px;
                  margin-left: 81px;
                }
              }
              .charThreeTwo {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 154px;
                padding-top: 86px;
                // padding-left: 19px;
                // padding-right: 9px;
                padding-left: 36px;
                padding-right: 36px;
                border-left: 2px solid #ffffff;
                border-right: 2px solid #ffffff;
                &:hover {
                  cursor: pointer;
                }
                img {
                  width: 171px;
                  height: 43px;
                  margin-bottom: 16px;
                }
                span {
                  width: 144px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 29px;
                  letter-spacing: 0.165em;
                  color: #48555a;
                  text-align: center;
                }
              }
              .charThreeTwoImg {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 163px;
                padding-top: 77px;
                // padding-left: 11px;
                // padding-right: 12px;
                padding-left: 39px;
                padding-right: 39px;
                border-right: 2px solid #ffffff;
                margin-right: 27px;
                &:hover {
                  cursor: pointer;
                }
                img {
                  width: 120px;
                  height: 55px;
                  margin-bottom: 16px;
                }
                span {
                  width: 165px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 23px;
                  letter-spacing: 0.165em;
                  color: #48555a;
                  text-align: center;
                }
              }
              .charThreeTwoSpan {
                display: flex;
                padding-top: 70px;
                span {
                  width: 16px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 23px;
                  letter-spacing: 0.165em;
                  color: #48555a;
                  opacity: 0.8;
                  margin-right: 6px;
                }
                .spanErWeiMa {
                  width: 136px;
                  height: 136px;
                  background: #ffffff;
                  border: 1px solid #abdabb;
                  border-radius: 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    width: 118px;
                    height: 118px;
                  }
                }
              }

              //---
              .charImgPosi {
                width: 94px;
                height: 68px;
                display: flex;
                flex-direction: column;
                align-items: center;
                // margin-right: 90px;
                // justify-content: center;
                margin: 0 auto;

                &:hover {
                  cursor: pointer;
                }

                img {
                  width: 41px;
                  height: 38px;
                  margin-top: 22px;
                }
                span {
                  width: 94px;
                  font-family: "PingFang SCRegular";
                  font-weight: 400;
                  font-size: 20px;
                  line-height: 32px;
                  letter-spacing: 0.165em;
                  color: #48555a;
                }
              }
            }
          }
          .charThreeCtrl {
            height: 248px;
            min-height: 248px;
            margin-bottom: 85px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  :deep(button.el-carousel__button) {
    border-radius: 6px;
    width: 9px;
    height: 9px;
    background: #feffff;
  }

  :deep(.el-carousel__indicators, .el-carousel__indicators--horizontal) {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    // width: 123px !important;
    height: 25px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 23px;
    margin-bottom: 6px;
    overflow: hidden;
    .is-active {
      width: 28px !important;
      button {
        width: 28px !important;
        height: 9px !important;
      }
    }
    li {
      border-radius: 6px !important;
      width: 9px;
      height: 9px;
      padding: 0;
      margin: 8px 0px;
      margin-right: 14px;
      &:first-child {
        margin-left: 13px;
      }
      &:last-child {
        margin-right: 13px;
      }
    }
    // li:not(.is-active) {
    //   width: 9px !important;
    //   height: 9px !important;
    //   animation: width 1s !important;
    // }
  }
  :deep(.el-carousel__container) {
    height: 310px !important;
    background: conic-gradient(
      from 0.59deg at 50.36% 54.21%,
      rgba(146, 252, 136, 0.08) -59.47deg,
      rgba(177, 221, 252, 0.17) 64.37deg,
      rgba(146, 252, 136, 0.08) 300.53deg,
      rgba(177, 221, 252, 0.17) 424.37deg
    );
  }
  #videoDiv {
    display: none;
  }

  #chariDiv {
    margin-top: 84px;
    width: 750px;

    #charCenter {
      // height: 1000px;
      width: 750px;
      :deep(.el-carousel__item) {
        img {
          width: 750px !important;
          height: 310px !important;
        }
      }
      #charDivFather {
        width: 750px;
        margin: 0 auto;
        background: url(../assets/img/videoBack.webp);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: right top;
        background-attachment: fixed;
        #charDivFatherBack {
          width: 702px;
          margin: 0 auto;
          padding-top: 46px;
          padding-bottom: 72px;
          .charOne {
            width: 100%;
            // height: 378px;
            margin-bottom: 85px;
            span {
              &:hover {
                color: #56bd88 !important;
              }
            }
            .divBothSides {
              display: flex;
              justify-content: space-between;
              // .divBothSidesOne {
              //   display: inline-block;
              // }
              .divBothSidesTwo {
                display: flex;
                align-items: center;

                &:hover {
                  cursor: pointer;
                }
                img {
                  width: 20px;
                  height: 15px;
                  // margin-right: 19px;
                }
                div {
                  display: inline-block;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 24px;
                  // line-height: 39px;
                  text-align: center;
                  letter-spacing: 0.29em;
                  color: #48555a;
                }
              }
            }
            .charOneTitle {
              display: inline-block;
              font-style: normal;
              font-weight: 400;
              font-size: 32px;
              // line-height: 51px;
              letter-spacing: 0.24em;
              color: #48555a;
              padding-bottom: 3px;
              border-bottom: 3px solid;
              border-image: linear-gradient(
                  96.85deg,
                  rgba(126, 202, 252, 0.74) 2.46%,
                  #acd9b3 95.3%
                )
                3 3 3 3;
              border-radius: 12px 0px;
              margin-bottom: 14px;
            }

            // .charBorder {
            //   width: 91px;
            //   height: 3px;
            //   background: linear-gradient(
            //     96.85deg,
            //     rgba(126, 202, 252, 0.74) 2.46%,
            //     #acd9b3 95.3%
            //   );
            //   border-radius: 12px 0px;
            // }
            .charOneTitleOne {
              width: 183px;
            }
            .charCenterDiv {
              display: flex;
              flex-direction: column;
              .charCenterOne {
                background: rgba(255, 255, 255, 0.6);
                border: 0.5px solid #dbdbdb;
                box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
                border-radius: 12px;
                &:hover {
                  cursor: pointer;
                }
                :deep(.el-image) {
                  display: block;
                  width: 100%;
                  height: 552px;
                  background: #f1f1f1;
                  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
                  border-radius: 12px 12px 0px 0px;
                  margin-bottom: 5px;
                }

                span {
                  margin: 22px auto;
                  margin-top: 16px;
                  width: 656px !important;
                  text-overflow: -o-ellipsis-lastline;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  line-clamp: 2;
                  -webkit-box-orient: vertical;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 28px;
                  line-height: 41px;
                  letter-spacing: 0.115em;
                  color: #2f2f2f;
                }
                .coverPosition {
                  width: 100%;
                  position: relative;
                  .coverPositionAbs {
                    position: absolute;
                    top: calc(50% - 75px);
                    left: calc(50% - 75px);
                    width: 150px;
                    height: 150px;
                  }
                }
              }
              .charCenterTwo {
                display: flex;
                flex-wrap: wrap;
                // width: 647px;
                // margin-left: 5px;
                margin-top: 38px;
                li {
                  width: 340px !important;
                  margin-right: 22px;
                  background: rgba(255, 255, 255, 0.6);
                  border: 0.5px solid #dbdbdb;
                  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
                  box-sizing: border-box;
                  border-radius: 12px;
                  margin-bottom: 40px;
                  &:nth-of-type(even) {
                    margin-right: 0px;
                  }
                  &:nth-child(even) {
                    margin-right: 0px;
                  }

                  div {
                    position: relative;
                    :deep(.el-image) {
                      width: 340px;
                      height: 202px;
                      border-radius: 12px 12px 0px 0px;
                    }
                    img {
                      position: absolute;
                      top: calc(50% - 38px);
                      left: calc(50% - 38px);
                      width: 76px;
                      height: 76px;
                    }
                    // img {
                    //   &:first-child {
                    //     width: 344px;
                    //     height: 192px;
                    //     border-radius: 12px;
                    //   }
                    //   &:last-child {
                    //     position: absolute;
                    //     top: calc(50% - 23.5px);
                    //     left: calc(50% - 23.5px);
                    //     width: 47px;
                    //     height: 47px;
                    //   }
                    // }
                  }
                  span {
                    display: inline-block;
                    width: 306px !important;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 41px;
                    letter-spacing: 0.115em;
                    color: #2f2f2f;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    margin: 0 auto;
                    margin-bottom: 22px;
                  }
                }
              }
            }
            #charCenterDivOne {
              width: 100%;
              // height: 500px;
              overflow: hidden;
              display: flex;
              //---
              #charDivUlLi {
                display: flex;
                li {
                  display: flex !important;
                  flex-direction: column !important;
                  width: 308.88px;
                  // height: 105px;
                  margin-right: 11px;
                  -webkit-overflow-scrolling: touch;
                  background: #ffffff;

                  // height: 534px;
                  // height: 500px;
                  // border: 1px solid #000;
                  div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 308.88px;
                    height: 105px;
                    border: 1px solid #abdabb;
                    border-radius: 6px;
                    img {
                      width: 302px;
                      height: 92px;
                    }
                  }
                  .charDivUlLiOne {
                    margin-bottom: 11px;
                  }
                  // .charDivUlLiTwo {
                  //   img {
                  //   }
                  // }
                }
              }
            }
          }
          .charTwo {
            height: auto;
            .charCenterDiv {
              // ----名人慈善
              ul {
                display: flex;
                flex-direction: column;
                li {
                  display: flex;
                  margin-bottom: 38px;

                  // &:first-child {
                  //   margin-right: 15px;
                  // }
                  .liDiv {
                    margin-right: 22px;
                    background: rgba(255, 255, 255, 0.6);
                    border: 0.5px solid #dbdbdb;
                    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
                    border-radius: 12px;
                    :deep(.el-image) {
                      display: block;
                      width: 340px;
                      height: 340px;
                      background: #e3ebee;
                      border-radius: 12px 12px 0px 0px;
                    }
                    span {
                      display: block;
                      width: 306px;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 28px;
                      line-height: 41px;
                      letter-spacing: 0.115em;
                      color: #2f2f2f;
                      text-overflow: -o-ellipsis-lastline;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      line-clamp: 2;
                      -webkit-box-orient: vertical;
                      margin: 22px auto;
                      margin-top: 14px;
                    }
                  }
                  .liDivOne {
                    &:first-child {
                      .divOneImg {
                        margin-right: 22px;
                      }
                    }
                    .divOneImg {
                      background: rgba(255, 255, 255, 0.6);
                      border: 0.5px solid #dbdbdb;
                      box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.25);
                      border-radius: 12px;
                      :deep(.el-image) {
                        display: block;
                        width: 340px;
                        height: 202px;
                        background: #f1f1f1;
                        border-radius: 12px 12px 0px 0px;
                      }
                      span {
                        display: block;
                        width: 306px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 28px;
                        line-height: 41px;
                        letter-spacing: 0.115em;
                        color: #2f2f2f;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                        margin: 22px auto;
                        margin-top: 16px;
                      }
                      &:nth-child(1) {
                        margin-bottom: 40px;
                      }

                      // &:nth-child(odd) {
                      //  margin-right: 22px;
                      // }
                    }
                  }
                }
              }
              // -----名人慈善结束
            }
          }
          .charOneMar {
            margin-top: 85px;
          }
          // ---------开始------------

          #ceShanSwiper {
            // background: #8d8c8c;
            .charOneTitle {
              display: inline-block;
              font-style: normal;
              font-weight: 400;
              font-size: 32px;
              // line-height: 51px;
              letter-spacing: 0.24em;
              color: #48555a;
              padding-bottom: 3px;
              border-bottom: 3px solid;
              border-image: linear-gradient(
                  96.85deg,
                  rgba(126, 202, 252, 0.74) 2.46%,
                  #acd9b3 95.3%
                )
                3 3 3 3;
              border-radius: 12px 0px;
              margin-bottom: 14px;
            }
            #review_box {
              width: 100%;
              // height: 500px;
              overflow: hidden;
              display: flex;
              ul {
                display: flex;
                // height: 500px;
                // white-space: nowrap;
                li {
                  display: inline-block;
                  width: 340px;
                  margin-right: 14px;
                  -webkit-overflow-scrolling: touch;
                  // height: 534px;
                  // height: 500px;
                  // border: 1px solid #000;

                  .comment1One {
                    background: #ffffff;
                    border: 1px solid #abdabb;
                    border-radius: 12px;
                    .comment1OneImg {
                      display: block;
                      margin: 10px auto;
                      max-width: 280px;
                      max-height: 54px;
                    }
                    .divTwo {
                      img {
                        display: block;
                        width: 310px;
                        height: 198px;
                        margin: 0 auto;
                        border-radius: 6px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                      }
                      .divTwoCenter {
                        width: 305px;
                        font-family: "PingFang SC";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 26px;
                        line-height: 31px;
                        letter-spacing: 0.135em;
                        color: #48555a;
                        margin: 9px auto;
                        margin-bottom: 19px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                      }
                    }
                    .divVision {
                      width: 310px;

                      margin: 0 auto;
                      background: url(../assets/img/BJVision.png);
                      // background: url(../assets/img/Visionframe48.png);
                      background-size: 100% 100%;
                      background-repeat: no-repeat;
                      margin-bottom: 10px;
                      padding-top: 9px;
                      padding-bottom: 13px;
                      .divVisionOne {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0px 14px;
                        .divVisionTitle {
                          font-family: "PingFang SC";
                          font-style: normal;
                          font-weight: 400;
                          font-size: 26px;
                          line-height: 32px;
                          letter-spacing: 0.12em;
                          color: #56bd88;
                        }
                        img {
                          width: 17px;
                          height: 16px;
                        }
                      }
                      .divVisionTwo {
                        display: none;
                        // padding: 0px 9px;
                        width: 286px;
                        height: 188px;
                        font-size: 18px;
                        line-height: 24px;
                        letter-spacing: 0.125em;
                        color: #101112;
                        overflow-x: hidden;
                        overflow-y: scroll;
                        // overflow: visible;
                        font-family: "PingFang SCRegular";
                        // font-style: normal;
                        font-weight: 400;
                        text-align: justify;
                        margin: 13px auto;
                        margin-top: 9px;
                        // margin-bottom: 4px;
                      }
                      .divVisionTwo::-webkit-scrollbar {
                        display: none;
                      }
                    }
                    .divThree {
                      width: 310px;
                      margin: 0 auto;
                      display: flex;
                      flex-direction: column;
                      .divThreeOneTitle {
                        display: flex;
                        align-items: center;
                        margin: 0 auto;
                        margin-bottom: 13px;

                        div {
                          font-family: "PingFang SC";
                          font-style: normal;
                          font-weight: 400;
                          font-size: 24px;
                          line-height: 35px;
                          letter-spacing: 0.115em;
                          color: #000000;
                        }
                        .titleBorder {
                          width: 25px;
                          height: 3px;
                          background: #87e6a7;
                          margin-right: 6px;
                        }
                        .titleBorderTwo {
                          margin-left: 6px;
                          margin-right: 0px;
                        }
                      }
                      .divThreeContent {
                        display: flex;
                        .divThreeOne {
                          margin-right: 13px;
                          img {
                            display: block;
                            width: 149px;
                            height: 98px;
                            margin-top: 4px;
                            border-radius: 6px;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                          }
                          div {
                            width: 128px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 22px;
                            letter-spacing: 0.065em;
                            color: #48555a;
                            opacity: 0.85;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                            margin-top: 11px;
                            margin-bottom: 6px;
                          }
                        }
                        .divThreeTwo {
                          margin-right: 0px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          // ---------测式结束------------

          .charThree {
            width: 100%;
            // height: 40px;
            background: #f0f7f4;
            border: 1px solid #abdabb;
            border-radius: 12px;
            margin-top: 85px;
            position: relative;

            .charThreeOne {
              position: absolute;
              display: flex;

              .threeTitle {
                width: 194px;
                height: 56px;
                font-style: normal;
                font-weight: 400;
                font-size: 32px;
                line-height: 51px;
                padding-top: 12px;
                letter-spacing: 0.165em;
                text-align: center;
                color: #ffffff;
                background: linear-gradient(
                  90deg,
                  rgba(126, 202, 252, 0.75) 0%,
                  rgba(145, 204, 152, 0.75) 100%
                );
                border-radius: 12px 0px;
              }
              .titleBackground {
                width: 508px;
                height: 60px;
                background: linear-gradient(
                  90deg,
                  rgba(126, 202, 252, 0.75) 0%,
                  rgba(145, 204, 152, 0.75) 100%
                );
                opacity: 0.6;
                border-radius: 0px 12px 0px 0px;
              }
            }
            .charThreeDiv {
              display: flex;
              flex-wrap: wrap;
              .charThreeUl {
                width: 450px;
                display: flex;
                &:hover {
                  cursor: pointer;
                }
                #donateDivImg {
                  width: 369px;
                  height: 111px;
                  margin-top: 85px;
                  margin-left: 81px;
                }
              }
              .charThreeTwo {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 154px;
                padding-top: 86px;
                // padding-left: 19px;
                // padding-right: 9px;
                padding-left: 36px;
                padding-right: 36px;
                border-left: 2px solid #ffffff;
                border-right: 2px solid #ffffff;
                img {
                  width: 171px;
                  height: 43px;
                  margin-bottom: 16px;
                }
                span {
                  width: 144px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 29px;
                  letter-spacing: 0.165em;
                  color: #48555a;
                  text-align: center;
                }
              }
              .charThreeTwoImg {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 163px;
                padding-top: 77px;
                // padding-left: 11px;
                // padding-right: 12px;
                padding-left: 39px;
                padding-right: 39px;
                border-right: 2px solid #ffffff;
                margin-right: 27px;

                img {
                  width: 120px;
                  height: 55px;
                  margin-bottom: 16px;
                }
                span {
                  width: 165px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 23px;
                  letter-spacing: 0.165em;
                  color: #48555a;
                  text-align: center;
                }
              }
              .charThreeTwoSpan {
                display: flex;
                padding-top: 70px;
                span {
                  width: 16px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 23px;
                  letter-spacing: 0.165em;
                  color: #48555a;
                  opacity: 0.8;
                  margin-right: 6px;
                }
                .spanErWeiMa {
                  width: 136px;
                  height: 136px;
                  background: #ffffff;
                  border: 1px solid #abdabb;
                  border-radius: 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    width: 118px;
                    height: 118px;
                  }
                }
              }
              .charThreeShow {
                // height: 253px;
                width: 100%;
                margin-top: 45px;
                padding-top: 37px;
                padding-left: 16px;
                padding-bottom: 13px;
                display: flex;
                border-bottom: 1px solid #c4e4d4;
                background: url(../assets/img/Group45503LyBack.png);
                background-repeat: no-repeat;
                background-size: 100%;
                img {
                  width: 225px;
                  height: 203px;
                  border-radius: 8px;
                  margin-right: 15px;
                }
                div {
                  display: flex;
                  flex-direction: column;
                  margin-top: -4px;
                  span {
                    font-family: "PingFang SCRegular";
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 32px;
                    letter-spacing: 0.165em;
                    color: #000000;
                    margin-bottom: 14px;
                    &:last-child {
                      margin-bottom: 0px;
                    }
                  }
                }
              }
              //---
              .charImgPosi {
                width: 351px;
                height: 131px;
                display: flex;
                flex-direction: column;
                align-items: center;
                // margin-right: 90px;
                justify-content: center;
                margin: 0 auto;
                background-image: url("../assets/img/dog-gf.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
                // border-right: 1px solid #C4E4D4;

                img {
                  width: 51px;
                  height: 46px;
                  margin-bottom: 12px;
                }
                span {
                  font-family: "PingFang SCRegular";
                  font-weight: 400;
                  font-size: 20px;
                  line-height: 32px;
                  letter-spacing: 0.165em;
                  color: #48555a;
                }
              }
              .charThreeMore {
                display: flex;
                flex-wrap: wrap;
                #charThreeDivOne {
                  width: 351px;
                  height: 131px;
                  // background-size: 100% 100%;
                  // background-repeat: no-repeat;
                  // padding-top: 60px;
                  // margin-left: 90px;
                  // padding-right: 90px;
                  border-right: 1px solid #c4e4d4;
                  box-sizing: border-box;
                  border-bottom: 1px solid #c4e4d4;
                }
                #charThreeDivTwo {
                  width: 351px;
                  height: 131px;
                  // margin-left: 90px;
                  // padding-right: 90px;
                  // padding-top: 60px;
                  box-sizing: border-box;
                  border-bottom: 1px solid #c4e4d4;
                  .charImgPosi {
                    background-image: url("../assets/img/vet-g.png");
                  }
                }
                #charThreeDivThree {
                  width: 351px;
                  height: 131px;
                  // margin-left: 90px;
                  // padding-right: 90px;

                  // border-bottom: 2px solid #fff;
                  .charImgPosi {
                    background: url(../assets/img/sparrow1.png);
                  }
                }
                #charThreeDivFour {
                  width: 351px;
                  height: 131px;
                  // margin-left: 90px;
                  // padding-right: 90px;
                  // border-bottom: 2px solid #fff;
                  box-sizing: border-box;
                  border-left: 1px solid #c4e4d4;

                  .charImgPosi {
                    background: url(../assets/img/Group45136Jk.png);
                    background-size: 100% 100%;
                  }
                  #fourImg {
                    position: absolute;
                    top: 190px;
                    left: 130px;
                    width: 540px;
                    height: 266px;
                    opacity: 0;
                    z-index: -10;
                  }
                }
              }

              ul {
                li {
                  width: 100%;
                  display: flex;
                  &:first-child {
                    border-bottom: 2px solid #ffffff;
                  }
                  .charThreeUl {
                    width: 410px;
                    display: flex;
                    padding-bottom: 71px;
                    &:hover {
                      cursor: pointer;
                    }
                    #donateDivImg {
                      width: 359px;
                      height: 111px;
                      margin-top: 135px;
                      margin-left: 81px;
                    }
                  }
                  .charThreeTwo {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 154px;
                    padding-top: 33px;
                    // padding-left: 19px;
                    // padding-right: 9px;
                    padding-left: 36px;
                    padding-right: 36px;
                    // border-left: 2px solid #ffffff;
                    border: none;
                    img {
                      width: 267px;
                      height: 67px;
                      margin-bottom: 16px;
                    }
                    span {
                      width: 225px;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 28px;
                      line-height: 45px;
                      letter-spacing: 0.165em;
                      color: #48555a;
                      text-align: center;
                    }
                  }
                  .charThreeTwoImg {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    // height: 163px;
                    padding-top: 33px;
                    // padding-left: 11px;
                    // padding-right: 12px;
                    padding-left: 44px;
                    padding-right: 48px;
                    padding-bottom: 33px;
                    border-right: none;
                    margin-right: 27px;
                    border-right: 2px solid #ffffff;

                    img {
                      width: 186px;
                      height: 85px;
                      margin-bottom: 16px;
                    }
                    span {
                      width: 258px;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 28px;
                      line-height: 35px;
                      letter-spacing: 0.165em;
                      color: #48555a;
                      text-align: center;
                    }
                  }
                  .charThreeTwoSpan {
                    display: flex;
                    margin-top: 60px;
                    padding-top: 43px;
                    padding-right: 24px;
                    padding-bottom: 40px;
                    border-left: 2px solid #ffffff;

                    span {
                      width: 16px;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 26px;
                      line-height: 32px;
                      letter-spacing: 0.165em;
                      color: #48555a;
                      opacity: 0.8;
                      margin-right: 21px;
                      margin-left: 25px;
                    }
                    .spanErWeiMa {
                      width: 202.58px;
                      height: 201px;
                      background: #ffffff;
                      border: 1px solid #abdabb;
                      border-radius: 12px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      img {
                        width: 176px;
                        height: 176px;
                      }
                    }
                  }
                }
              }
            }
          }
          .charThreeCtrl {
            // height: 180px;
            margin-bottom: 85px;
          }
        }
      }
    }
  }
}
</style>
