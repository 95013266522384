<template>
  <header v-if="!userInfo.resLayout">
    <nav id="hearde" style="min-height: 42px">
      <h1><img id="img1" src="../assets/img/Frame.png" alt="咱百科" /></h1>
      <ul id="hearul">
        <li class="viBack hearderStyleViBack">
          <router-link to="/">{{ state.home }}</router-link>
        </li>
        <li class="viBack">
          <router-link to="/MudioSheet">{{ state.mudioSheet }}</router-link>
        </li>
        <li
          class="viBack hearderLi"
          @mouseenter="changNavMargin(true)"
          @mouseleave="changNavMargin(false)"
        >
          <div class="dropdown">
            <span>
              <router-link to="/Charitable">{{ state.charitable }}</router-link>
            </span>
            <div class="dropdown-content">
              <ul>
                <li
                  v-for="(item, index) in charList.arr"
                  :key="item"
                  @click="dropTo(item)"
                >
                  <span class="dropdownYuan"></span>

                  <span
                    class="dropdownText"
                    v-if="index == 2 && languageNum == '3'"
                  >
                    {{ item.slice(0, 14) }} <br />
                    {{ item.slice(14, item.length) }}
                  </span>
                  <span
                    class="dropdownText"
                    v-else-if="index == 3 && languageNum == '3'"
                  >
                    {{ item.slice(0, 13) }} <br />
                    {{ item.slice(13, item.length) }}
                  </span>
                  <span class="dropdownText" v-else> {{ item }} </span>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="viBack">
          <router-link to="/AboutWe">{{ state.aboutWe }}</router-link>
        </li>
        <li class="viBack">
          <router-link to="/ContactUs">{{ state.contactUs }}</router-link>
        </li>
        <li class="viBack" v-if="userInfo.login || userInfo.timeFlage">
          <router-link to="/TimeAxis">{{ state.timeAxis }}</router-link>
        </li>
        <!-- <li class="viBack" >
        <router-link to="/TimeAxis">{{ state.timeAxis }}</router-link>
      </li> -->
      </ul>
      <!-- <div class="search" v-if="state.domFla">
      <input 
        type="text"
        v-model="searchInput"
        placeholder="输入关键字"
        @keydown.enter="dowRed"
      />
    </div> -->
      <div id="hadright">
        <div class="adRdiv" v-if="userInfo.login">
          <el-image id="img2" :src="userInfo.avatar" alt="咱百科" />
          <ul id="rigul">
            <li>
              {{ userInfo.nickname }}
              <!-- <span>{{ tet }}</span> -->
            </li>
          </ul>
          <button id="rigbtn" @click="signOut">退出</button>
        </div>
        <button class="ulBtn" v-else @click="update(true)">登录</button>

        <div id="ftbtn">
          <div id="ftbtnOne" @click="changeDivDisplay">
            <span class="btnqh">
              {{ langName }}
            </span>
            <img
              class="ftbtnOneImg"
              src="../assets/img/UnionHead.svg"
              alt="语言切换SVG"
            />
          </div>

          <div class="dropdown-contentTwo">
            <ul>
              <li @click="addinfor(langNameOne)">
                <span> {{ langNameOne }}</span>
              </li>
              <li class="lineBorder"></li>
              <li @click="addinfor(langNameTwo)">
                <span>{{ langNameTwo }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>

  <header v-else>
    <nav id="phoneHear">
      <div class="phoHearDiv">
        <div class="hearLeft">
          <img src="../assets/img/zu160.png" alt="咱百科" @click="changDis" />
          <ul id="ulOpty" @click="ckDis">
            <li>
              <router-link to="/">首页</router-link>
            </li>
            <li>
              <router-link to="/MudioSheet">媒体中心</router-link>
            </li>
            <li>
              <router-link to="/Charitable">公益</router-link>
            </li>
            <li>
              <router-link to="/AboutWe">关于我们</router-link>
            </li>
            <li>
              <router-link to="/ContactUs">联系我们</router-link>
            </li>
            <!-- <li>
            <router-link to="/TimeAxis">时间轴</router-link>
          </li> -->
          </ul>
        </div>
        <div class="hearCent">
          <h1><img src="../assets/img/logo.webp" alt="咱百科" /></h1>
        </div>
        <div class="hearRight"></div>
      </div>
    </nav>
  </header>
</template>

<script lang="ts">
import { reactive, toRefs, onMounted, ref, watch, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Session, Local } from "../utils/storage";
import { getBaseUrl } from "../api/Login/index";
// import { getUserInfo } from "../api/Login/index";
import emitter from "../utils/events-bus";

export default {
  name: "Hearde",
  //setup第二个参数是context(执行上下文),{emit}是把它解构出来了
  setup(props: any, { emit }: any) {
    // 跳转公益
    const router = useRouter();
    const route = useRoute();
    const charList = reactive({
      arr: [
        "医护",
        "助学",
        "机构信息",
        "环境保护",
        "动物保护",
        "名人慈善",
        "优秀人物",
        "器官捐献",
        "动物救助",
      ],
    });
    function dropTo(data: string) {
      if (route.path !== "/Charitable") {
        router.push({
          name: "Charitable",
          query: {
            name: data,
          },
        });
      } else {
        router.push(`${route.path}?name=${data}`);
      }
    }

    //用户信息
    async function userData() {
      // const userInfoData = await getUserInfo();
      // console.log(userInfoData);
      // Session.set("reginUSer", userInfoData.data);
      // Local.set("reginUSer", userInfoData.data);
      if (Session.get("timeFlage") !== null) {
        const timeFlage = Session.get("timeFlage");
        userInfo.timeFlage = timeFlage;
      } else if (Local.get("timeFlage") !== null) {
        const timeFlage = Local.get("timeFlage");
        userInfo.timeFlage = timeFlage;
      }
      let userData: any;
      Session.get("reginUSer")
        ? (userData = Session.get("reginUSer"))
        : (userData = Local.get("reginUSer"));
      // console.log(userData);

      let date1 = userData?.timeDate;
      let date2 = new Date();
      let date3 = date2.getTime() - new Date(date1).getTime(); //时间差的毫秒数
      let days = Math.floor(date3 / (24 * 3600 * 1000));
      if (days > 2) {
        userData = null;
        Session.remove("reginUSer");
        Local.remove("reginUSer");
      }
      // userData ? "" : ;
      // const baseUrl = await getBaseUrl();
      store.state.allWeb.publicLinks = await getBaseUrl();
      // Session.set("baseUrl",baseUrl)
      // console.log("baseUrl", baseUrl);
      // console.log("userData", userData);

      // console.log(
      //   `${new Date().getFullYear()}/${
      //     new Date().getMonth() + 1
      //   }/${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`
      // );

      // console.log(days);

      if (userData) {
        // console.log("userData.avatarOne", userData.avatar);
        // console.log(
        //   " store.state.allWeb.publicLinks",
        //   store
        // );

        userData.avatar.slice(0, 5) === "https"
          ? ""
          : (userData.avatar =
              store.state.allWeb.publicLinks + "/" + userData.avatar);
        userInfo.avatar = userData.avatar;
        userInfo.nickname = userData.nickname;
        userInfo.login = true;
        const hadright = document.querySelector("#hadright") as HTMLElement;
        hadright.classList.add("hadrightAdd");

        // console.log(111);
      } else {
        userInfo.login = false;
      }
    }
    onMounted(async () => {
      // const time: number | string = new Date()
      //   .getHours()
      //   .toString()
      //   .padStart(2, "0");
      // Number(time) < 12
      //   ? (dataLogin.tet = "上午好")
      //   : (dataLogin.tet = "下午好");

      userData();
      document.addEventListener("click", function (e: any) {
        const nameList = document.querySelector(".dropdown-contentTwo") as any;
        if (
          nameList &&
          e.target?.className != "ftbtnOneImg" &&
          e.target?.className != "btnqh" &&
          e.target.id !== "ftbtnOne"
        ) {
          nameList.style.opacity = 0;
          nameList.style.zIndex = -11;
        }
      });
    });

    const store = useStore();
    const state = store.state.allWeb;
    const userInfo = store.state.userInfo;
    const searchInput = ref("");
    // const router = useRouter();
    // let dataLogin = reactive({
    //   tet: "",
    // });
    // //登录组件之间的切换
    const update = (date: any) => {
      emitter.emit("showXs", date);
    };
    const languageNum = ref("1");
    const langName = ref("中文");
    const langNameOne = ref("繁体");
    const langNameTwo = ref("EN");
    function addClass(hadright: any) {
      if (userInfo.login) {
        hadright.classList.add("addHadrightTrue");
      } else {
        hadright.classList.add("addHadright");
      }
    }
    function MoveClass(hadright: any, hearul: any) {
      hearul.classList.remove("addEngUlLIRight");
      if (userInfo.login) {
        hadright.classList.remove("addHadrightTrue");
      } else {
        hadright.classList.remove("addHadright");
      }
    }
    let addinfor = (data: string) => {
      switch (data) {
        case "中文":
          languageNum.value = "1";
          langName.value = "中文";
          langNameOne.value = "繁体";
          langNameTwo.value = "EN";
          break;
        case "繁体":
          languageNum.value = "2";
          langName.value = "繁体";
          langNameOne.value = "中文";
          langNameTwo.value = "EN";
          break;
        case "EN":
          languageNum.value = "3";
          langName.value = "EN";
          langNameOne.value = "中文";
          langNameTwo.value = "繁体";
          break;
      }

      const hearul = document.getElementById("hearul") as HTMLLIElement;
      const hadright = document.querySelector("#hadright") as HTMLElement;

      switch (languageNum.value) {
        case "1":
          state.home = "首页";
          state.mudioSheet = "媒体中心";
          state.aboutWe = "关于我们";
          state.focusUs = "关注我们";
          state.charitable = "公益";
          state.timeAxis = "时间轴";
          state.aboutUs = "团队介绍";
          state.contactUs = "联系我们";
          (state.NSRN = "粤ICP备2021156789号"),
            (state.userAgreement = "用户协议"),
            (state.privacyPolicy = "隐私政策"),
            (state.charitableArray = [
              "医护",
              "助学",
              "机构信息",
              "环境保护",
              "动物保护",
              "名人慈善",
              "优秀人物",
              "器官捐献",
              "动物救助",
            ]);
          MoveClass(hadright, hearul);
          // hadright.classList.remove("addHadright");

          break;
        case "2":
          state.home = "首頁";
          state.mudioSheet = "媒體中心";
          state.aboutWe = "關於我們";
          state.focusUs = "關注我們";
          state.charitable = "公益";
          state.timeAxis = "時間軸";
          state.aboutUs = "團隊介紹";
          state.contactUs = "聯繫我們";
          (state.NSRN = "粤ICP备2021156789号"),
            (state.userAgreement = "使用者協定"),
            (state.privacyPolicy = "隱私政策"),
            (state.charitableArray = [
              "醫護",
              "助學",
              "機構資訊",
              "環境保護",
              "動物保護",
              "名人慈善",
              "優秀人物",
              "器官捐獻",
              "動物救助",
            ]);
          MoveClass(hadright, hearul);

          break;
        case "3":
          state.home = "Home";
          state.mudioSheet = "MediaCenter";
          state.aboutWe = "AboutUs";
          state.focusUs = "Focus on us";
          state.charitable = "PublicWelfare";
          state.timeAxis = "TimeAxis";
          state.aboutUs = "Team introduction";
          state.contactUs = "ContactUs";
          (state.NSRN = "粤ICP备2021156789号"),
            (state.userAgreement = "User Agreement"),
            (state.privacyPolicy = "Privacy Policy"),
            (state.charitableArray = [
              "MedicalCare",
              "Assistantship",
              "Institutional Information",
              "EnvironmentalProtection",
              "AnimalProtection",
              "CelebrityCharity",
              "Excellent",
              "OrganDonation",
              "AnimalRescue",
            ]);
          hearul.classList.add("addEngUlLIRight");
          addClass(hadright);
          break;
      }
      charList.arr = state.charitableArray;
      const div = document.getElementsByClassName("dropdown-contentTwo") as any;
      div[0].style.opacity = 0;
      div[0].style.zIndex = -11;
    };
    function hearDisply() {
      var ulOpty = document.getElementById("ulOpty") as HTMLElement;
      var hearDis = document.querySelector("body") as HTMLBodyElement;
      const videoAll = document.querySelectorAll("video") as any;

      if (ulOpty.style.display === "block") {
        hearDis.style.overflow = "hidden";
        videoAll?.forEach((element: HTMLElement) => {
          element.style.display = "none";
        });
      } else {
        hearDis.style.overflow = "scroll";
        videoAll?.forEach((element: HTMLElement) => {
          element.style.display = "block";
        });
      }

      // console.log("hearDis.style.overflow", hearDis.style.overflow);
    }
    function changDis() {
      var ulOpty = document.getElementById("ulOpty") as HTMLElement;
      ulOpty.style.display === "block"
        ? (ulOpty.style.display = "none")
        : (ulOpty.style.display = "block");
      hearDisply();
      state.videoTime = new Date();

      // video ? (video.style.display = "block") : "";
      // ctVideo ? (ctVideo.style.display = "block") : "";
      // mudioVideo ? (mudioVideo.style.display = "block") : "";
    }

    function ckDis() {
      var ulOpty = document.getElementById("ulOpty") as HTMLElement;
      ulOpty.style.display = "none";
      hearDisply();
      const video = document.querySelector(
        "#phoneVovieVideo"
      ) as HTMLVideoElement;
      const ctVideo = document.querySelector("#ctVideo") as HTMLVideoElement;
      const mudioVideo = document.querySelector(
        "#mudioVideo"
      ) as HTMLVideoElement;
      // console.log("video", video);
      // console.log("ctVideo", ctVideo);
      // console.log("mudioVideo", mudioVideo);

      video ? (video.style.display = "block") : "";
      ctVideo ? (ctVideo.style.display = "block") : "";
      mudioVideo ? (mudioVideo.style.display = "block") : "";
    }

    //搜索字体高亮
    // let storage = window.localStorage;
    // function find(searchVal: any) {
    //   let fotText = <HTMLDivElement>document.querySelector("#fotText");
    //   var reg1 = /<script[^>]*>(.|\n)*<\/script>/gi;

    //   let htmlText: any =
    //     storage.getItem("htText") == null
    //       ? fotText.innerHTML.replace(reg1, "")
    //       : storage.getItem("htText");

    //   storage.getItem("htText") == null
    //     ? storage.setItem("htText", htmlText)
    //     : "";

    //   let re = new RegExp(searchVal, "ig");
    //   searchVal
    //     ? (fotText.innerHTML = htmlText.replace(
    //         re,
    //         `<span class="innerCol" style="color:blue;">${searchVal}</span>`
    //       ))
    //     : (fotText.innerHTML = htmlText);
    // }
    // function dowRed() {
    //   var regEnglish = new RegExp("[A-Za-z]+");
    //   !regEnglish.test(searchInput.value) ? find(searchInput.value) : "";
    //   searchInput.value = "";
    // }
    //-----

    function changNavMargin(paramsFlag: boolean) {
      const elementUl = document.querySelector(
        ".dropdown-content"
      ) as HTMLElement;

      if (languageNum.value == "3") {
        elementUl.classList.add("textEnglish");
        elementUl.classList.remove("textChina");
      } else {
        elementUl.classList.add("textChina");
        elementUl.classList.remove("textEnglish");
      }
    }

    const flageDiv = ref(true) as any;
    function changeDivDisplay() {
      const div = document.getElementsByClassName("dropdown-contentTwo") as any;
      if (flageDiv) {
        div[0].style.opacity = 1;
        div[0].style.zIndex = 11;
        flageDiv.value = !flageDiv.value;
      } else {
        div[0].style.opacity = 0;
        div[0].style.zIndex = -11;
        flageDiv.value = !flageDiv.value;
      }
    }

    watch(
      () => userInfo.login,
      () => {
        userData();
        addinfor(langName.value);
      }
    );
    watch(
      () => userInfo.timeFlage,
      () => {
        userData();
        addinfor(langName.value);
      }
    );

    //退出登录
    function signOut() {
      Session.clear();
      Local.clear();
      location.reload();
      // const hadright = document.querySelector("#hadright") as HTMLElement;
      // hadright.classList.add("hadrightAdd");
    }

    return {
      // ...toRefs(dataLogin),
      state,
      userInfo,
      charList,
      languageNum,
      searchInput,
      langName,
      langNameOne,
      langNameTwo,
      update,
      changeDivDisplay,
      addinfor,
      changDis,
      ckDis,
      // dowRed,
      signOut,
      dropTo,
      changNavMargin,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.addEngUlLIRight {
  li {
    margin-right: 73px !important ;
  }
}

a {
  text-decoration: none;
  color: #4e4e4e;
  // font-family: Arial, Helvetica, sans-serif !important;
}
.hadrightAdd {
  margin-left: 346px !important;
}
.addHadright {
  margin-left: 455px !important;
}
.addHadrightTrue {
  margin-left: 200px !important;
}
// =====下拉菜单=====
// 切换文字样式
.textChina {
  left: -66px !important;
  li {
    letter-spacing: none;
    padding: 6px 36px;
  }
}
.textEnglish {
  left: -50px !important;
  li {
    &:first-child {
      letter-spacing: 0.15em;
    }
    &:nth-child(3) {
      padding: 16px 36px;
      letter-spacing: 0.19em;
    }
    &:nth-child(4) {
      padding: 16px 36px;
      letter-spacing: 0.19em;
    }
    &:nth-child(5) {
      letter-spacing: 0.1em;
    }
    &:nth-child(8) {
      letter-spacing: 0.17em;
    }
    &:nth-child(9) {
      letter-spacing: 0.21em;
    }
  }
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  top: 72px;
  left: -66px;
  color: #4e4e4e;
  min-width: 166px;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 4px rgba(126, 202, 252, 0.6);
  border-radius: 0px 0px 12px 12px;
  padding: 8px 3px;
  box-sizing: border-box;
}
.dropdown-content li {
  display: flex;
  align-items: center;
  margin: 0 0 !important;
  // margin-bottom: 13px !important;
  padding: 6px 36px;

  // &:last-child {
  //   margin-bottom: 0px !important;
  // }

  .dropdownYuan {
    width: 4px;
    height: 4px;
    background: #ffffff;
    border-radius: 50%;
    margin-right: 6px;
  }
  .dropdownText {
    font-family: Arial, Helvetica, sans-serif !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #ffffff;
    white-space: nowrap;
    // min-width: 54px !important;
    // letter-spacing: 0.04em;
  }
  &:hover {
    background: linear-gradient(91.75deg, #8ddde3 0%, #b2e9bf 100%);
  }
}
.dropdown:hover .dropdown-content {
  display: block;
}

// =====下拉菜单结束====

.hearderStyle {
  background: rgba(255, 255, 255, 0.5) !important;
  a {
    color: #ffffff;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.35);
    &:hover {
      text-shadow: none;
    }
  }

  .hearderStyleViBack {
    text-shadow: none !important;
  }
}
.btnFontColor {
  color: #ffffff !important;
  text-shadow: 3px -3px 2px rgba(0, 0, 0, 0.25);
}
.ftbtnStyle {
  background: rgba(255, 255, 255, 0) !important;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  border: none;
  background-color: #41d6da;
  outline: none;
}
// .dropdownTwo {
//   background: rgba(86, 189, 136, 0.8);
// }

@media screen and (min-width: 751px) {
  // header{
  //   height: 72px;
  //   width: 100%;
  // }
  #hearde {
    position: fixed;
    top: 0;
    z-index: 1000;
    height: 72px;
    width: 1920px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-items: start;
    // justify-content: space-between;

    align-items: center;
    background: #ffff;
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -11;
      background: linear-gradient(
        90deg,
        rgba(193, 232, 180, 0.5) 0%,
        rgba(164, 208, 214, 0.5) 100%
      );
    }
    h1 {
      display: flex;
      align-content: flex-end;
      margin: 0px;
      padding: 0px;
      margin-bottom: -6px;
    }
    #img1 {
      width: 153px;
      height: 47px;
      margin-left: 37px;
    }

    #hearul {
      // min-width:730px;
      display: flex;
      justify-items: center;
      align-items: center;
      margin-left: 107px;
      height: 100%;
      min-height: 42px;
      letter-spacing: 2px;
      color: #48555a;
      white-space: nowrap;
      .viBack {
        .router-link-active {
          padding-bottom: 11px;
          border-bottom: 2px solid #56bd88 !important;
          color: #56bd88;
          text-shadow: none;
          font-family: "PingFang SCBold";
        }
        &:hover {
          cursor: pointer;
          a {
            color: #56bd88 !important;
          }
        }
      }
      // =====下拉菜单=====
      .hearderLi {
        height: 72px !important;
        line-height: 72px !important;
        // margin-top: 28px;
      }
      .hearderLi:hover .dropdown-content {
        display: block;
      }
      // ====下拉菜单结束====
      li {
        height: 30px;
        // margin-top: 12px;
        margin-right: 87px;
        font-size: 16px;
        font-family: "PingFang SC";
        font-weight: 500;
        line-height: 30px;
        &:last-child {
          margin-right: 73px;
        }
      }
    }

    // .search {
    //   display: flex;
    //   align-items: center;
    //   position: relative;
    //   // margin-left: 20px;
    //   img {
    //     width: 25px;
    //     height: 25px;
    //     position: absolute;
    //     left: 20px;
    //     top: 10px;
    //   }

    //   input {
    //     width: 483px;
    //     height: 40px;
    //     // border: none;
    //     border-radius: 40px;
    //     border-color: #79bbff;
    //     border-style: solid;
    //     outline: none;
    //     padding-left: 80px;
    //     font-size: 18px;
    //     opacity: 0.8;
    //   }
    // }

    #hadright {
      height: 100%;
      display: flex;
      justify-items: start;
      align-items: center;
      // margin-left: 500px;
      margin-left: 585px;
      // margin-right: 31px;
      // position: absolute;
      // right: 0px;

      .adRdiv {
        display: flex;
        justify-items: start;
        align-items: center;
        margin-right: 64px;
        :deep(.el-image) {
          display: flex;
          justify-content: center;
        }
        // #img2 {
        //   height: 54px;
        //   width: 54px;
        //   border-radius: 50%;
        // }
        :deep(.el-image__inner) {
          height: 40px;
          width: 40px;
          border-radius: 50%;
        }

        #rigul {
          display: flex;
          justify-items: start;
          align-items: center;
          li {
            margin-left: 12px;
            // letter-spacing: 1px;
            box-sizing: border-box;
            font-size: 16px;
            font-family: "PingFang SC";
            // font-weight: 900;
            color: #2d2d2d;
            white-space: nowrap;
          }
        }

        #rigbtn {
          // width: 34px;
          // height: 16px;
          line-height: 16px;
          margin-left: 40px;
          padding: 0;
          color: #56bd88;
          text-align: center;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border: none;
          background: rgba(0, 0, 0, 0);
          white-space: nowrap;
          &:hover {
            cursor: pointer;
          }
        }
      }

      .ulBtn {
        width: 90px;
        height: 40px;
        background: #56bd88;
        border-radius: 8px;
        font-size: 16px;
        font-family: "PingFang SC";
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 2px;
        margin-right: 50px;
        white-space: nowrap;
        &:hover {
          cursor: pointer;
        }
        a {
          color: #ffffff;
        }
      }

      #ftbtn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        // right: 31px;
        margin-right: 110px;
        #ftbtnOne {
          // display:inline-block;
          display: flex;
          justify-items: center;
          align-items: center;

          &:hover {
            cursor: pointer;
          }
          span {
            width: 34px !important;
            // height: 16px;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #2d2d2d;
            // letter-spacing: 2px;
            margin-right: 12px;
            white-space: nowrap;
            // &:first-child {
            //   // font-family: PingFang SC
            //   font-weight: 500;
            //   color: #4f4f4f;
            //   border-radius: 5px 0px 0px 5px;
            //   margin-right: 12px;
            // }
          }
          img {
            width: 14px;
            height: 8px;
            color: #2d2d2d;
          }
        }
        .dropdown-contentTwo {
          position: absolute;
          top: 33px;
          opacity: 0;
          z-index: -11;
          ul {
            width: 70px;
            // height: 55px;
            background: rgba(140, 140, 140, 0.6);

            border-radius: 8px;
            padding: 2px 2px;
            li {
              width: 66px;
              height: 23px;
              border-radius: 7px;
              margin: 0 auto;
              &:hover {
                cursor: pointer;
                background: rgba(86, 189, 136, 0.8);
              }
              // background: rgba(86, 189, 136, 0.8);
              // &:first-child {
              //   background: rgba(86, 189, 136, 0.8);
              // }
              // &:last-child {
              //   margin-bottom: 2px;
              // }
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 23px;
              padding-left: 4px;
              color: #f0f0f0;
            }
            .lineBorder {
              width: 62px;
              height: 1px;
              background: rgba(225, 225, 225, 0.6);
              margin: 2px auto;
            }
          }
        }

        // .btnqh {
        //   background: #5c8dde;
        // }
        // .btnFt {
        //   margin-right: 19px;
        // }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  #phoneHear {
    width: 750px;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    .phoHearDiv {
      display: flex;
      justify-content: space-between;
      width: 702px;
      height: 90px;
      margin: 0 auto;

      .hearLeft {
        width: 51px;
        display: flex;
        align-items: center;

        img {
          width: 37px;
          height: 33px;
        }
        #ulOpty {
          // border: 1px solid #5c8dde;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 750px;
          height: 10000px;
          position: absolute;
          top: 69px;
          left: 0;
          display: none;
          z-index: 100;
          background: #fff;
          padding: 20px 0;
          li {
            margin-left: 23px;
            // margin-bottom: 56px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            font-size: 32px;
            letter-spacing: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #9297a3;
            a {
              display: block;
              width: 100%;
              height: 100%;
              padding-top: 30px;
              padding-bottom: 30px;
            }
          }
        }
      }
      .hearCent {
        display: flex;
        align-items: center;
        width: 51px;
        padding-top: 10px;
        img {
          width: 64px;
          height: 55px;
        }
      }
      .hearRight {
        width: 51px;
      }
    }
  }
}
</style>
