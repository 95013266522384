import { createApp } from "vue";
import App from "./App.vue";
import "amfe-flexible/index.js";
import ElementPlus from "element-plus";
// import "element-plus/dist/index.css";
import router from "./router/index";
import store from "./store/index";
import "./rem";
const app = createApp(App);
app.use(router);
app.use(store);
app.use(ElementPlus);
app.mount("#app");

router.afterEach((to: any, from: any, next: any) => {
  // console.log("to", to);
  // console.log("from", from);
  // console.log("next", next);
  from.path === to.path ? "" : window.scrollTo(0, 0);
});
