import request from "../../utils/request";

//获取时间轴详情
export function getTimeInfo(id: number | string) {
  return request({
    url: `/share/timeline/${id}`,
    method: "get",
  });
}
//获取作品详情
export function getWorkInfo(id: number | string) {
  return request({
    url: `/share/work/${id}`,
    method: "get",
  });
}
//获取模板详情
export function getTemInfo(id: number | string) {
  return request({
    url: `/share/template/${id}`,
    method: "get",
  });
}
//获取推荐详情
export function getRecommendInfo() {
  return request({
    url: `/share/template-recommend`,
    method: "get",
  });
}
