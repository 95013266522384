<template>
  <footer id="footerDiv" v-if="!userInfo.resLayout">
    <div id="footer">
      <div id="fthed">
        <div id="ftleft">
          <div id="ftlDiv">
            <img class="img" src="../assets/img/logo.svg" alt="咱百科" />
            <!-- <div class="img"></div> -->
            <div>
              <img
                class="imgSpan"
                src="../assets/img/zbk16.svg"
                alt="咱百科文字"
              />
              <span>我们的故事百科</span>
            </div>
          </div>
        </div>

        <div id="ftrigh">
          <div id="ftrone">
            <ul id="ftrulone" @click="routerTo">
              <li>
                <a>{{ state.home }}</a>
              </li>
              <li>
                <a>{{ state.mudioSheet }}</a>
              </li>
              <li>
                <a>{{ state.charitable }}</a>
              </li>
              <li>
                <a>{{ state.aboutWe }}</a>
              </li>
              <li>
                <a>{{ state.contactUs }}</a>
              </li>

              <!-- <li v-if="userInfo.login">
                <a>{{ state.timeAxis }}</a>
              </li> -->
            </ul>
            <!-- <ul id="ftrultwo">
              <li>{{ state.focusUs }}</li>
              <li>
               
                <img
                  id="erweim"
                  src="../assets/img/erweima.webp"
                  alt="咱百科"
                />
              </li>
            </ul> -->
          </div>
          <div id="ftSpn">
            <div id="hide">
              <ul>
                <li>
                  <router-link to="/PrivacyPolicy" class="ftA">{{
                    state.privacyPolicy
                  }}</router-link>
                </li>
                <li class="ftB">
                  <router-link to="/userAgreement" class="ftA">{{
                    state.userAgreement
                  }}</router-link>
                </li>
                <li class="ftWeight ftB">
                  <div>
                    <a
                      class="ftA"
                      href="https://beian.miit.gov.cn/"
                      target="_blank"
                      >{{ state.NSRN }}</a
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="ftrtwo">
          <div id="ftrDiv">
            <div class="ftrDivOne">
              <div class="qrFd">
                <div>
                  <img
                    class="ewmImg"
                    src="../assets/img/zbkAPPewm1.png"
                    alt="公众号二维码"
                  />
                </div>
                <div class="logo shadowed">
                  <img src="../assets/img/logo.png" alt="咱百科" />
                </div>
              </div>
              <span style="min-width: 80px">关注咱百科</span>
            </div>
            <div class="ftrDivTwo">
              <div class="qrFd">
                <div>
                  <qrcode-vue
                    :value="'https://zanbaike.com/#/DownPro'"
                    id="ftrImg"
                    render-as="svg"
                    level="H"
                  />
                </div>
                <div class="logo shadowed">
                  <img src="../assets/img/logo.png" alt="咱百科" />
                </div>
              </div>
              <span style="min-width: 80px">下载咱百科</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <footer v-else>
    <div id="phoneFooter">
      <div id="phoFooterDiv">
        <div id="phoFooterUl">
          <ul>
            <li><router-link to="/">首页</router-link></li>
            <li>
              <router-link to="/MudioSheet">媒体中心</router-link>
            </li>
            <li>
              <router-link to="/Charitable">公益</router-link>
            </li>
            <li>
              <router-link to="/AboutWe">关于我们</router-link>
            </li>
            <li>
              <router-link to="/ContactUs">联系我们</router-link>
            </li>
            <!-- <li>
                <router-link to="/TimeAxis">时间轴</router-link>
              </li> -->
          </ul>
        </div>
      </div>

      <div id="phoFooterThree">
        <div id="footerCenter">
          <div id="footerTop">
            <div id="phoFooterThreeOne">
              <img class="img" src="../assets/img/logo.svg" alt="咱百科" />
              <div class="phoFooterimgSpan">
                <img
                  class="imgSpan"
                  src="../assets/img/zbk16.svg"
                  alt="咱百科文字"
                />
                <span>我们的故事百科</span>
              </div>
            </div>
            <div id="phoFooterAppButton" @click="downApp()">
              <img src="../assets/img/phoAppBtn.svg" alt="下载SVG" />
              <span>下载APP</span>
            </div>
          </div>
          <div id="footerBootom">
            <div id="phoFooterFour">
              <div id="hide">
                <ul>
                  <li>
                    <router-link to="/PrivacyPolicy">隐私政策</router-link>
                  </li>
                  <li class="ftA">
                    <router-link to="/userAgreement">用户协议</router-link>
                  </li>
                  <li class="ftB">
                    <a
                      class="ftLinke"
                      href="https://beian.miit.gov.cn/"
                      target="_blank"
                      >{{ state.NSRN }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div id="phoFooterTwo">
          <div id="ftrtwo">
            <div id="ftrDiv">
              <div class="ftrDivOne">
                <div class="qrFd">
                  <div>
                    <img
                      class="ewmImg"
                      src="../assets/img/zbkAPPewm1.png"
                      alt="公众号二维码"
                    />
                  </div>
                  <div class="logo shadowed">
                    <img src="../assets/img/logo.png" alt="咱百科" />
                  </div>
                </div>
                <span style="min-width: 80px">关注咱百科</span>
              </div>
              <div class="ftrDivTwo">
                <div class="qrFd">
                  <div>
                    <qrcode-vue
                      :value="'https://zanbaike.com/#/DownPro'"
                      id="ftrImg"
                      render-as="svg"
                      level="H"
                    />
                  </div>
                  <div class="logo shadowed">
                    <img src="../assets/img/logo.png" alt="咱百科" />
                  </div>
                </div>
                <span style="min-width: 80px">下载咱百科</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { onMounted, ref, watch } from "vue";
// import VueQrcode from "@chenfengyuan/vue-qrcode";
import QrcodeVue from "qrcode.vue";
import { useStore } from "vuex";
import router from "../router/index";
import { Session } from "../utils/storage";
import { downloadApp } from "../utils/downloadApp";

export default {
  name: "Footer",
  components: {
    QrcodeVue,
    // VueQrcode,
  },
  setup() {
    // function test(dataUrl: any) {
    //   console.log(dataUrl);
    // }

    onMounted(() => {
      const userData = Session.get("reginUSer");
      if (userData) {
        userInfo.login = true;
      }
    });

    const onOff = ref(true);
    const store = useStore();
    const state = store.state.allWeb;
    const userInfo = store.state.userInfo;

    //底部文字显示跳转
    function routerTo(e: any) {
      const Target = e.target.innerText;
      // console.log("目标元素", e);
      let toName = null;
      switch (Target) {
        case "首页":
        case "首頁":
        case "Home":
          toName = "HomePage";
          break;
        case "媒体中心":
        case "媒體中心":
        case "Media Center":
          toName = "MudioSheet";
          break;

        case "关于我们":
        case "關於我們":
        case "About us":
          toName = "AboutWe";
          break;
        case "联系我们":
        case "聯繫我們":
        case "ContactUs":
          toName = "ContactUs";
          break;
        case "公益":
        case "Public Welfare":
          toName = "Charitable";
          break;
        case "时间轴":
        case "時間軸":
        case "TimeAxis":
          toName = "TimeAxis";
          break;
      }
      router.push({
        name: toName as string,
      });
    }

    //微信图标的移入事件
    // function mousemOve(e: any) {
    //   var erweim = e.target.nextSibling;
    //   erweim.style.zIndex = "99";
    //   erweim.style.opacity = "1";
    //   // console.log(erweim);
    // }
    // //微信图标的移出事件
    // function mouselEave(e: any) {
    //   var erweim = e.target.nextSibling;
    //   erweim.style.zIndex = "-1";
    //   erweim.style.opacity = "0";
    // }
    function downApp() {
      downloadApp();
    }

    watch(
      () => {
        state.home;
      },
      () => {
        // console.log(state.home);
        var pattern_En = new RegExp("[A-Za-z]");
        const dataList = document
          .getElementById("ftrulone")
          ?.querySelectorAll("li");
        const dataListOne = document
          .getElementById("hide")
          ?.querySelectorAll("li");
        const elementEn = document.getElementById("fthed") as any;
        console.log(elementEn);
        if (pattern_En.test(state.home)) {
          dataList?.forEach((ele: any) => {
            ele.style.letterSpacing = "0px";
          });
          dataListOne?.forEach((ele: any) => {
            ele.style.letterSpacing = "0px";
          });
          elementEn.classList.add("elementEnStyle");
        } else {
          dataList?.forEach((ele: any) => {
            ele.style.letterSpacing = "0.29em";
          });
          dataListOne?.forEach((ele: any, index: number) => {
            index === dataListOne?.length - 1
              ? ""
              : (ele.style.letterSpacing = "0.29em");
          });
          elementEn.classList.remove("elementEnStyle");
        }
      },
      {
        deep: true,
      }
    );

    return {
      onOff,
      state,
      userInfo,
      routerTo,
      downApp,
      // mousemOve,
      // mouselEave,
      // test,
    };
  },
};
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-items: start;
  align-items: center;
}
a {
  color: #9297a3;
  text-decoration: none;
}
.elementEnStyle {
  #ftrigh {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: start !important;
    margin: 0 auto !important;
    #ftSpn {
      margin-left: 0;
      margin-top: 13px;
    }
  }
}
@media screen and (min-width: 751px) {
  #footer {
    // height: 220px;
    width: 100%;
    margin: 0 auto;
    background: #464954;
    z-index: 2;
    // margin-top: 60px;
    #fthed {
      width: 100%;
      height: 186px;
      display: flex;
      justify-items: start;
      position: relative;

      #ftleft {
        // width: 53%;
        height: 100%;
        display: flex;
        justify-items: start;
        align-items: center;
        // margin-right: 125px;
        position: absolute;
        left: 112px;

        #ftlDiv {
          // margin-left: 112px;
          display: flex;
          justify-items: start;

          .img {
            width: 121px;
            height: 80px;
            margin-top: -8px;
          }

          div {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            padding-left: 6px;
            .imgSpan {
              width: 130px;
              height: 36px;
              margin-bottom: 8px;
            }

            span {
              text-align: center;
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 19px;
              letter-spacing: 0.105em;
              color: #ffffff;
            }
          }
        }
      }

      #ftrigh {
        // width: 47%;
        display: flex;
        align-items: center;
        // margin: 0 auto;
        // color: #9297a3;
        margin-left: 424px;
        #ftrone {
          // width: 75%;
          margin: 0 auto;
          box-sizing: border-box;

          // margin-right: 125px;
          #ftrulone {
            li {
              height: 32px;
              cursor: pointer;
              border-right: 1px solid rgba(255, 255, 255, 0.3);
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 32px;
              letter-spacing: 0.29em;

              z-index: 2;
              white-space: nowrap;
              a {
                width: 100%;
                margin: 0 32px;
                color: #ffffff;
              }

              &:first-child {
                a {
                  margin-left: 0px;
                }
              }

              &:last-child {
                a {
                  margin-right: 0px;
                }
                border: none;
              }
            }
          }

          // #ftrultwo {
          //   padding-top: 64px;
          //   #erweim {
          //     position: absolute;
          //     left: -40px;
          //     top: -105px;
          //     width: 100px;
          //     height: 100px;
          //     padding-top: 0;
          //     -webkit-transition: all 0.5s;
          //     -moz-transition: all 0.5s;
          //     -ms-transition: all 0.5s;
          //     -o-transition: all 0.5s;
          //     transition: all 0.5s;
          //     opacity: 0;
          //   }

          //   li {
          //     margin-right: 50px;
          //     font-size: 20px;
          //     &:nth-child(2) {
          //       position: relative;
          //       cursor: pointer;
          //     }
          //     &:first-child {
          //       margin-left: 45px;
          //     }
          //     img {
          //       display: block;
          //       margin: 0 auto;
          //       height: 23px;
          //       width: 30px;
          //       padding-top: 5px;
          //     }
          //   }
          // }
        }
      }
      #ftrtwo {
        // width: 25%;
        height: 186px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 112px;

        #ftrDiv {
          display: flex;
          flex-direction: row;

          .ftrDivOne {
            margin-right: 33px;
            .qrFd {
              position: relative;
              div {
                display: flex;
                width: 102px;
                height: 102px;
                justify-content: center;
                align-items: center;
                background: #fff;
                border: 2px solid #56bd88;
                border-radius: 6px;
                margin: 0 auto;
                .ewmImg {
                  width: 102px;
                  height: 102px;
                }
              }
              .logo {
                width: 30px;
                height: 30px;
                overflow: hidden;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #fff;
                border: 2px solid #fff;
                border-radius: 6px;
                box-sizing: border-box;
                img {
                  width: 30px;
                  height: 30px;
                }
              }
              .shadowed {
                box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
              }
            }

            span {
              margin-top: 10px;
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 18px;
              letter-spacing: 0.29em;
              color: #ffffff;
              white-space: nowrap;
              text-align: center;
            }

            #ftrImg {
              width: 102px !important;
              height: 102px !important;
            }
          }
          .ftrDivTwo {
            .qrFd {
              position: relative;
              div {
                display: flex;
                width: 102px;
                height: 102px;
                justify-content: center;
                align-items: center;
                background: #fff;
                border: 2px solid #56bd88;
                border-radius: 8px;
                margin: 0 auto;
              }
              .logo {
                width: 30px;
                height: 30px;
                overflow: hidden;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #fff;
                border: 2px solid #fff;
                border-radius: 6px;
                box-sizing: border-box;
                img {
                  width: 30px;
                  height: 30px;
                }
              }
              .shadowed {
                box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
              }
            }

            span {
              margin-top: 10px;
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 18px;
              text-align: center;
              letter-spacing: 0.29em;
              color: #ffffff;
              white-space: nowrap;
            }

            #ftrImg {
              width: 88px !important;
              height: 88px !important;
            }
          }
        }
      }
    }
  }
  //备案号

  #ftSpn {
    // width: 100%;
    height: 31px;
    display: flex;
    font-size: 16px;
    color: #9297a3;
    line-height: 81px;
    font-family: "PingFang SC";
    font-style: normal;
    line-height: 31px;
    letter-spacing: 0.29em;
    margin-left: 87px;
    .ftA {
      color: #ffffff;
      text-decoration: none;
    }
    .ftWeight {
      font-size: 16px;
      letter-spacing: 0px;
    }
    .ftB {
      height: 31px;
      line-height: 32px;
      cursor: pointer;
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      margin-left: 10px;
      padding-left: 10px;
    }
    #hide {
      display: flex;
      justify-content: center;
      // padding-left: 136px;
    }
  }
}
@media screen and (max-width: 750px) {
  #phoneFooter {
    width: 750px;
    background: #464954;

    #phoFooterDiv {
      display: flex;
      #phoFooterUl {
        width: 750px;
        ul {
          width: 750px;
          height: 94px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          li {
            text-align: center;
            font-size: 20px;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 400;
            line-height: 33px;
            letter-spacing: 0.1em;
            border-right: 1px solid rgba(255, 255, 255, 0.3);
            box-sizing: border-box;

            a {
              display: inline-block;
              padding: 0 43px;
              white-space: nowrap;
              color: #fff;

              // width: 100% !important;
            }
            &:first-child {
              a {
                padding-left: 24px;
              }
            }

            &:last-child {
              border-right: 0px;
              a {
                padding-right: 24px;
              }
            }
          }
        }
      }
    }

    #phoFooterThree {
      display: flex;
      align-items: start;
      height: 177px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      padding-top: 28px;
      box-sizing: border-box;

      #footerCenter {
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        box-sizing: border-box;
        #footerTop {
          display: flex;
          flex-direction: row;
          #phoFooterThreeOne {
            display: flex;
            align-items: center;
            .img {
              width: 104px;
              height: 69px;
            }
            .phoFooterimgSpan {
              display: flex;
              flex-direction: column;
              margin-left: 5px;
              .imgSpan {
                width: 111px;
                height: 30px;
                margin-bottom: 5px;
              }
              span {
                color: #fff;
                font-size: 13px;
                font-family: "PingFang SC";
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.105em;
                text-align: center;
              }
            }
          }
          #phoFooterAppButton {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 128px;
            height: 48px;
            border-radius: 7px;
            border: 1.5px solid #56bd88;
            background: rgba(86, 189, 136, 0.8);
            margin: 0 54px;
            img {
              width: 19px;
              height: 25px;
              margin-right: 5px;
            }
            span {
              color: #fff;
              text-align: center;
              font-size: 19px;
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              letter-spacing: 0.35px;
            }
          }
        }
        #footerBootom {
          #phoFooterFour {
            display: flex;
            align-items: center;
            height: 72px;

            // margin-top: 31px;
            text-align: center;
            font-size: 14px;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.1px;
            li {
              height: 25px;
              line-height: 25px;
              a {
                color: #fff !important;
              }
            }
            .ftA {
              border-left: 1px solid #9297a3;
              border-right: 1px solid #9297a3;
              padding: 0 9px;
              margin: 0 9px;
            }
          }
        }
      }

      #phoFooterTwo {
        #ftrtwo {
          // width: 25%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          // width: 240px;

          #ftrDiv {
            .ftrDivOne {
              display: inline-block;
              width: 96px;
              margin-right: 16px;
              .qrFd {
                position: relative;
                width: 96px;
                height: 96px;
                div {
                  display: flex;
                  width: 96px;
                  height: 96px;
                  justify-content: center;
                  align-items: center;
                  background: #fff;
                  border: 2px solid #56bd88;
                  border-radius: 6px;
                  .ewmImg {
                    width: 96px;
                    height: 96px;
                  }
                }
                .logo {
                  width: 30px;
                  height: 30px;
                  overflow: hidden;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  background: #fff;
                  border: 2px solid #fff;
                  border-radius: 6px;
                  img {
                    width: 30px;
                    height: 30px;
                  }
                }
                .shadowed {
                  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
                }
              }

              span {
                display: block;
                // margin-top: 12px;
                font-family: "PingFang SC";
                color: #fff;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.29em;
                margin-top: 6px;
                white-space: nowrap;
                text-align: center;
              }

              #ftrImg {
                width: 110px !important;
                height: 110px !important;
              }
            }
            .ftrDivTwo {
              display: inline-block;
              width: 96px;
              .qrFd {
                position: relative;
                width: 96px;
                height: 96px;
                div {
                  display: flex;
                  width: 96px;
                  height: 96px;
                  justify-content: center;
                  align-items: center;
                  background: #fff;
                  border: 2px solid #56bd88;
                  border-radius: 8px;
                }
                .logo {
                  width: 30px;
                  height: 30px;
                  overflow: hidden;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  background: #fff;
                  border: 2px solid #fff;
                  border-radius: 6px;
                  img {
                    width: 30px;
                    height: 30px;
                  }
                }
                .shadowed {
                  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
                }
              }

              span {
                display: block;
                // margin-top: 12px;
                font-family: "PingFang SC";
                color: #fff;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.29em;
                margin-top: 6px;
                white-space: nowrap;
                text-align: center;
              }

              #ftrImg {
                width: 86px !important;
                height: 86px !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
