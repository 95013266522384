import request from "../../utils/request";

//获取banner
export function getBannerList() {
  return request({
    url: "/banner",
    method: "get",
  });
}
//获取文章列表
export function getTable(params: object) {
  return request({
    url: "/article-web/get-all",
    method: "get",
    params,
  });
}
//获取慈善文章列表
export function getEsseayTable(params: object) {
  return request({
    url: "/article-web/articles",
    method: "get",
    params,
  });
}
//获取文章详情
export function getChariId(id: number | string) {
  return request({
    url: `/article-web/${id}-getOne`,
    method: "get",
  });
}

//获取随机视频
export function getEsseayVideo(params: object) {
  return request({
    url: "/video/web-random",
    method: "get",
    params,
  });
}

//获取视频详情
export function getVideoIdType(params: object) {
  return request({
    url: "/video/video-one",
    method: "get",
    params,
  });
}

//获取慈善动物救助信息
export function getanimalAdoptionList() {
  return request({
    url: "/animal-adoption/getOne",
    method: "get",
  });
}