<template>
  <div :id="divId" class="videoImg">
    <video
      :id="videoId"
      :class="videoClass"
      :src="videoUrl"
      :poster="poster"
      :autoplay="autoplayVideo"
      :muted="autoplayVideo"
      oncontextmenu="return false"
      controls
      controlslist="nodownload"
      :loop="loopplayVideo"
      @timeupdate="getCurrentInfo"
      @mouseenter="moveVideo($event, true)"
      @mouseleave="moveVideo($event, false)"
    ></video>
    <img
      :id="imgId"
      :class="[imgClass, 'imgClass']"
      src="../assets/img/Group141.svg"
      alt="播放标识"
      @click="changeImg"
    />
    <!-- <img id="btnXIAOSvg" src="../assets/img/btn_xiaochu.svg" alt="取消" /> -->
    <!-- @click="$emit('clickName')" -->

    <div
      class="videoIcon"
      @mouseenter="moveVideo($event, true)"
      @mouseleave="moveVideo($event, false)"
    >
      <div class="videoIconDiv">
        <div class="videoIconOne">
          <div class="flexLeft">
            <img
              class="imgOne"
              :src="imgOneUrl"
              alt="播放按钮"
              @click="pausedVideo"
            />
            <img
              v-if="nextVideoFlag"
              id="nextVideo"
              src="../assets/img/nextVideo.svg"
              alt="下一集SVG"
              @click="playNextVideo"
            />

            <div class="videoTextIcon">
              {{ videoTextOne }}/{{ videoTextTwo }}
            </div>
          </div>
          <div class="flexRight">
            <div class="divImgTwo">
              <ul
                class="imgTwoUl"
                @mouseenter="moveBlock(true)"
                @mouseleave="moveBlock(false)"
                @click="clickVideoPlay"
              >
                <li>3.0x</li>
                <li>2.0x</li>
                <li>1.5x</li>
                <li class="hover">1.0x</li>
                <li>0.5x</li>
              </ul>
              <span @mouseenter="moveBlock(true)" @mouseleave="moveBlock(false)"
                >倍速</span
              >
            </div>
            <div class="divImgThree">
              <div
                class="imgThreeDiv"
                @mouseenter="moveBlockOne(true)"
                @mouseleave="moveBlockOne(false)"
              >
                <!-- <input type="range" min="0" value="50" max="100" id="range" /> -->
                <el-slider
                  v-model="inputRange"
                  vertical
                  :show-tooltip="showTooltip"
                  @input="changeSlider"
                />
              </div>
              <img
                class="imgThree"
                :src="imgTwoUrl"
                alt="声音大小"
                @mouseenter="moveBlockOne(true)"
                @mouseleave="moveBlockOne(false)"
                @click="oparedAudio"
              />
            </div>
            <img
              class="imgFour"
              :src="imgThreeUrl"
              alt="全屏缩放"
              @click="fullScreen"
            />
          </div>
        </div>
        <div class="videoIconTwo">
          <!--  -->
          <div
            id="control"
            ref="control"
            class="control"
            @click="setProgress"
            @touchmove="controlMove"
            @touchend="controlEnd"
          >
            <!-- 进度条本条 -->
            <div
              id="progressVideo"
              @mouseenter="moveEnt(true)"
              @mouseleave="moveEnt(false)"
            ></div>
            <!-- 滑块 -->
            <div
              class="slider_circle"
              :style="{ left: progressWidth + 'px' }"
              @touchstart="sliderStart"
              @mouseenter="moveEnt(true)"
              @mouseleave="moveEnt(false)"
            />
            <!-- :style="{left: (progressWidth - 25)+'px' }" @touchstart="sliderStart"  -->
            <div
              class="slider_circle_large"
              :style="{ width: progressWidth + 'px' }"
              @mouseenter="moveEnt(true)"
              @mouseleave="moveEnt(false)"
            />
          </div>
        </div>
        <!-- <el-progress :percentage="50" /> -->
      </div>
      <div class="posiImg" @click="pausedVideo"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, nextTick } from "vue";
import Frame46 from "../assets/img/Frame46.svg";
import Frame47 from "../assets/img/Frame47.svg";
import Group45243 from "../assets/img/Group45243.svg";
import Group45287 from "../assets/img/Group45287.svg";
import Group45290 from "../assets/img/Group45290.svg";
import Group45289 from "../assets/img/Group45289.svg";
// import { Session } from "../utils/storage";

export default {
  name: "videoComponent",
  props: [
    "videoUrl",
    "divId",
    "poster",
    "videoId",
    "videoClass",
    "imgId",
    "imgClass",
    "autoplayVideo",
    "loopplayVideo",
    "videoNumber",
    "listVideoNumber",
    "nextVideoFlag",
  ],
  setup(props, { emit }: any) {
    const imgOneUrl = ref("");
    const imgTwoUrl = ref("");
    const imgThreeUrl = ref("");
    const videoUrl = ref("");
    const videoId = ref("");
    const videoClass = ref("");

    const inputRange = ref(50);
    const showTooltip = ref(false);
    const videoTextOne = ref("00:00");
    const videoTextTwo = ref("00:01");
    const videoTExt = ref(0);
    const nextVideoFlag = ref(true);
    const posterUrl = ref("");
    function videoTime(time: any) {
      var minute: any = time / 60;
      var minutes: any = parseInt(minute);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      //秒
      var second: any = time % 60;
      var seconds: any = Math.floor(second);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return {
        minutes,
        seconds,
      };
    }

    var imgTextWidth: any;
    var imgTextHeight: any;
    onMounted(() => {
      // console.log(111);
      videoUrl.value = props.videoUrl;
      videoId.value = props.videoId;
      videoClass.value = props.videoClass;
      posterUrl.value = props.poster;
      nextVideoFlag.value = props.nextVideoFlag ? true : false;
      // console.log(nextVideoFlag.value);

      imgOneUrl.value = Frame46;
      imgTwoUrl.value = Group45243;
      imgThreeUrl.value = Group45287;
      // console.log("props.listVideoNumber",props.listVideoNumber);

      if (props.listVideoNumber === 0) {
        emit("getTimeVideo", props.listVideoNumber);
      }
      // var vid = document.getElementById("textVideoId") as HTMLVideoElement;
      nextTick(() => {
        const videoImg = document.querySelector(".videoImg") as any;
        const ctIvm = document.getElementById(
          props.videoId
        ) as HTMLVideoElement;
        const imgText = document.getElementById(props.imgId) as any;
        // console.log(ctIvm);
        // ctIvm.controls=false;
        // const eleWidth = document.getElementById("progressVideo")
        //   ?.clientWidth as number;
        // const ele = document.querySelector(".slider_circle_large") as HTMLElement;
        ctIvm.volume = 0.5;

        videoImg?.addEventListener("mousemove", () => {
          ctIvm.muted = false;
        });
        ctIvm?.addEventListener("play", () => {
          imgText.style.display = "none";
          imgOneUrl.value = Group45290;
        });

        ctIvm?.addEventListener("pause", () => {
          imgText.style.display = "block";
          imgOneUrl.value = Frame46;
          imgTextWidth = imgText?.clientWidth;
          imgTextHeight = imgText?.clientHeight;
          const cliWidth = window.innerWidth;
          const cliHeight = window.innerHeight;
          if (ctIvm.clientWidth == cliWidth) {
            imgText.style.left = cliWidth / 2 - imgText.clientWidth / 2 + "px";
            imgText.style.top = cliHeight / 2 - imgText.clientHeight / 2 + "px";
          } else {
            const widthOne = ctIvm.clientWidth / 2;
            const widthTwo = imgTextWidth / 2;
            const heightOne = ctIvm.clientHeight / 2;
            const heightTwo = imgTextHeight / 2;
            imgText.style.left = widthOne - widthTwo + "px";
            imgText.style.top = heightOne - heightTwo + "px";
          }
        });
        ctIvm?.addEventListener("loadedmetadata", function () {
          var time = ctIvm.duration;
          const { minutes, seconds } = videoTime(time);
          videoTextTwo.value = minutes + ":" + seconds;
          videoTExt.value = Number(minutes + seconds);
        });
        // ctIvm.onloadedmetadata = function () {

        // };

        ctIvm?.addEventListener("timeupdate", throttleUpdate(ctIvm, 1000));
      });
    });
    function throttleUpdate(params: any, t: number) {
      let timeUpdate: any = null;
      return function () {
        if (!timeUpdate) {
          timeUpdate = setTimeout(() => {
            const { minutes, seconds } = videoTime(params.currentTime);
            videoTextOne.value = minutes + ":" + seconds;
            // console.log("videoTextTwo.value", videoTextTwo.value);
            // console.log("videoTextOne.value ", videoTextOne.value);
            // console.log("videoTextOne.value===videoTextTwo.value",videoTextOne.value===videoTextTwo.value);
            if (
              props.videoId === "liveVideoOne" &&
              videoTextOne.value === videoTextTwo.value
            ) {
              // console.log("执行一次");
              videoNum(numVideoLength, false);
            }
            timeUpdate = null;
          }, t);
        }
      };
    }

    var numVideoLength: number = 0;

    function videoNum(num: number, nextFalg: Boolean) {
      if (
        (props.videoId === "liveVideoOne" &&
          videoTextOne.value === videoTextTwo.value) ||
        nextFalg
      ) {
        // console.log("num", num);
        // console.log("props.listVideoNumber",props.listVideoNumber);
        num = props.listVideoNumber;
        numVideoLength = props.listVideoNumber;
        if (num >= props.videoNumber - 1) {
          numVideoLength = 0;
        } else {
          numVideoLength = numVideoLength + 1;
        }
        // console.log(numVideoLength);
        emit("getTimeVideo", numVideoLength);
      }
    }

    const changeImg = (e: any) => {
      const video = document.getElementById(props.videoId) as HTMLVideoElement;
      video.controls = true;
      if (video.paused) {
        video.play();
      }
    };

    const pausedVideo = () => {
      const video = document.getElementById(props.videoId) as HTMLVideoElement;

      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    };
    //开关音量
    // const openOrSlient = () => {
    //   // console.log("muted1:"+homeVideo.value.muted);
    //   const video = document.querySelector("#textVideoId") as HTMLVideoElement;
    //   if (video.muted == true) {
    //     video.muted = false;
    //     videoVolume = inputRange.value;
    //     inputRange.value = 0;
    //     video.volume = 0;
    //     imgTwoUrl.value = Frame47;
    //   } else {
    //     video.muted = true;
    //     inputRange.value = videoVolume;
    //     video.volume = inputRange.value / 100;
    //     imgTwoUrl.value = Group45243;
    //   }
    // };

    //全屏
    var videoWidth: any;
    var videoHeight: any;
    var videoIconTwoWidth: any;

    const fullScreen = () => {
      const hearde = document.getElementById("hearde") as HTMLVideoElement;
      const video = document.getElementById(props.videoId) as HTMLVideoElement;
      const videoImg = document.getElementById(props.divId)
        ? (document.getElementById(props.divId) as HTMLElement)
        : (document.querySelector(".videoImg") as HTMLElement);
      const imgText = document.getElementById(props.imgId) as any;
      // console.log("imgText", imgText);

      const videoIconTwo = videoImg
        ? (videoImg.querySelector(".videoIconTwo") as HTMLElement)
        : (document.querySelector(".videoIconTwo") as HTMLElement);
      // videoIcon
      const cliWidth = window.innerWidth;

      if (video.clientWidth !== cliWidth) {
        const cliHeight = window.innerHeight;
        videoWidth = video.clientWidth;
        videoHeight = video.clientHeight;
        imgTextWidth = imgText.clientWidth;
        imgTextHeight = imgText.clientHeight;
        videoIconTwoWidth = videoIconTwo.clientWidth + "px";
        video.style.width = cliWidth + "px";
        video.style.height = cliHeight + "px";
        document.body.style.overflowY = "hidden";
        videoIconTwo.style.width = cliWidth + "px";
        videoImg.classList.add("fullScreen");
        imgThreeUrl.value = Group45289;
        imgText.style.left = cliWidth / 2 - imgText.clientWidth / 2 + "px";
        imgText.style.top = cliHeight / 2 - imgText.clientHeight / 2 + "px";
        hearde.style.zIndex = "0";
      } else {
        imgText.style.left = videoWidth / 2 - imgTextWidth / 2 + "px";
        imgText.style.top = videoHeight / 2 - imgTextHeight / 2 + "px";

        video.style.width = videoWidth + "px";
        video.style.height = videoHeight + "px";
        document.body.style.overflowY = "auto";
        videoIconTwo.style.width = videoIconTwoWidth;
        videoImg.classList.remove("fullScreen");
        imgThreeUrl.value = Group45287;
        hearde.style.zIndex = "1000";
      }
    };
    //滑动到位置的宽度
    const progressWidth = ref(0);
    //得到视频当前播放到哪里的信息
    const getCurrentInfo = () => {
      const video = document.getElementById(props.videoId) as any;
      // console.log(video);
      if (video) {
        // 视频已经播放时长
        const { minutes, seconds } = videoTime(video.currentTime);
        videoTextOne.value = minutes + ":" + seconds;

        //计算得到宽度:
        let ratio = video.currentTime / video.duration;
        // console.log("ratio:" + ratio);
        // console.log(
        //   document.getElementById(props.divId)?.querySelector(".control")
        // );

        let allWidth = document
          .getElementById(props.divId)
          ?.querySelector(".control")
          ?.getBoundingClientRect().width as any;
        //console.log("allwidth:"+allWidth);
        progressWidth.value = allWidth * ratio;
        //console.log("progressWidth:"+progressWidth.value);
      }
    };
    //设置进度条的长度
    const setProgress = (e: any) => {
      e.preventDefault();
      //
      // const video = document.getElementById(props.videoId) as any;
      const { left, width } = document
        .getElementById(props.divId)
        ?.querySelector(".control")
        ?.getBoundingClientRect() as any;
      // left: 进度条容器control到最左侧的距离
      //15: 按钮宽度的一半
      const proWidth = e.clientX - left;
      progressWidth.value = proWidth;
      // console.log("setProgress", progressWidth.value);
      // console.log("setProgress width", width);

      updadteCurrentTime(progressWidth.value, width);
    };
    //设置视频播放到指定的长度
    const updadteCurrentTime = (progressWidth: any, width: any) => {
      const video = document.getElementById(props.videoId) as HTMLVideoElement;
      // console.log("progressWidth.value", progressWidth.value);
      // console.log(" width", width);
      // console.log("video", video);
      // console.log("progressWidth / width", progressWidth / width);

      let dest = (progressWidth / width) * video.duration;
      // console.log("dest:" + dest);
      video.currentTime = Math.floor(dest);
    };
    //移动的类型，用来标明是否在拖动进度条
    const moveType = ref("");
    //拖动开始时点击的位置
    const moveOffsetX = ref(0);
    //拖动开始时进度条的宽度
    const curWidth = ref(0);
    //当开始触摸开始在圆点按下时
    const sliderStart = (e: any) => {
      e.preventDefault();
      moveOffsetX.value = e.touches[0].clientX;
      moveType.value = "slider";
      curWidth.value = progressWidth.value;
    };
    //当触摸在controls上移动时
    const controlMove = (e: any) => {
      if (moveType.value !== "slider") {
        return false;
      }
      e.preventDefault();
      // 滑动距离可视区域左侧的距离
      const X = e.touches[0].clientX;
      //得到本次拖动已经过的距离
      const cl = X - moveOffsetX.value;
      //容器的宽度
      // const video = document.getElementById(props.videoId) as any;
      // console.log(e);

      const { width } = document
        .getElementById(props.divId)
        ?.querySelector(".control")
        ?.getBoundingClientRect() as any;
      //得到已拖动到的宽度
      const ml = curWidth.value + cl;
      let proWidth;
      if (ml <= 0) {
        //进度条长度最小和最大值的界定
        proWidth = 0;
      } else if (ml >= width) {
        proWidth = width;
      } else {
        proWidth = ml;
      }
      progressWidth.value = proWidth;
      // console.log("controlMove", progressWidth.value);

      // 更新当前时间
      updadteCurrentTime(progressWidth.value, width);
    };
    //滑动结束
    const controlEnd = () => {
      moveType.value = "";
    };
    const moveEnt = (falg: any) => {
      let divId = document.getElementById(props.divId) as HTMLElement;
      const posiEle = divId
        ? (divId.querySelector(".slider_circle") as HTMLElement)
        : (document.querySelector(".slider_circle") as HTMLElement);
      const ele = divId
        ? (divId.querySelector(".slider_circle_large") as HTMLElement)
        : (document.querySelector(".slider_circle_large") as HTMLElement);
      const eleHeight = divId
        ? (divId.querySelector("#progressVideo") as HTMLElement)
        : (document.querySelector("#progressVideo") as HTMLElement);
      if (falg) {
        posiEle.style.display = "block";
        ele.style.height = "4px";
        eleHeight.style.height = "4px";
      } else {
        posiEle.style.display = "none";
        ele.style.height = "2px";
        eleHeight.style.height = "2px";
      }
    };
    var timeNull: any;
    var timeNullOne: any;
    const moveBlock = (falg: any) => {
      const divId = document.getElementById(props.divId) as HTMLElement;
      const imgTwoUl = divId
        ? (divId.querySelector(".imgTwoUl") as HTMLElement)
        : (document.querySelector(".imgTwoUl") as HTMLElement);
      clearTimeout(timeNull);
      if (falg) {
        imgTwoUl.style.display = "block";
      } else {
        timeNull = setTimeout(() => {
          imgTwoUl.style.display = "none";
        }, 500);
      }
    };
    const moveBlockOne = (falg: any) => {
      const divId = document.getElementById(props.divId) as HTMLElement;
      const imgThreeDiv = divId
        ? (divId.querySelector(".imgThreeDiv") as HTMLElement)
        : (document.querySelector(".imgThreeDiv") as HTMLElement);
      clearTimeout(timeNullOne);
      if (falg) {
        imgThreeDiv.style.display = "block";
      } else {
        timeNullOne = setTimeout(() => {
          imgThreeDiv.style.display = "none";
        }, 500);
      }
    };
    const changeSlider = () => {
      // console.log("inputRange", inputRange.value);
      const ctIvm = document.getElementById(props.videoId) as HTMLVideoElement;
      ctIvm.volume = inputRange.value / 100;
      ctIvm.volume === 0
        ? (imgTwoUrl.value = Frame47)
        : (imgTwoUrl.value = Group45243);
    };
    let videoVolume: any;
    const oparedAudio = () => {
      const video = document.getElementById(props.videoId) as HTMLVideoElement;
      if (video.volume !== 0) {
        videoVolume = inputRange.value;
        inputRange.value = 0;
        video.volume = 0;
        imgTwoUrl.value = Frame47;
      } else {
        inputRange.value = videoVolume;
        video.volume = inputRange.value / 100;
        imgTwoUrl.value = Group45243;
      }
    };

    const clickVideoPlay = (e: any) => {
      // console.log(e.target.innerText);
      const textVideoId = document.getElementById(
        props.videoId
      ) as HTMLVideoElement;
      const imgTwoUl = document
        .querySelector(".imgTwoUl")
        ?.querySelectorAll("li");
      imgTwoUl?.forEach((ele: any) => {
        ele.classList.remove("hover");
      });
      e.target.classList.add("hover");

      // let playValue: number;
      switch (e.target.innerText) {
        case "3.0x":
          // playValue = 3;
          textVideoId.playbackRate = 3;
          break;
        case "2.0x":
          textVideoId.playbackRate = 2;
          break;
        case "1.5x":
          textVideoId.playbackRate = 1.5;
          break;
        case "1.0x":
          textVideoId.playbackRate = 1;
          break;
        case "0.5x":
          textVideoId.playbackRate = 0.5;
          break;
      }
    };
    var timeVideoAdd: any;
    function removeAdd(videoIcon: HTMLElement, svgBtn: HTMLElement) {
      videoIcon.classList.add("showDivTwo");
      videoIcon.classList.remove("fadeinOne");
      if (svgBtn) {
        svgBtn.classList.add("showDivTwo");
        svgBtn.classList.remove("fadeinOne");
      }
    }
    function moveVideo(e: any, params: boolean) {
      clearTimeout(timeVideoAdd);
      const videoIcon = e.target.parentNode.querySelector(
        ".videoIcon"
      ) as HTMLElement;
      const svgBtn = document.querySelector("#btnXIAOSvg") as HTMLElement;
      if (params) {
        videoIcon.classList.add("fadeinOne");
        videoIcon.classList.remove("showDivTwo");
        if (svgBtn) {
          svgBtn.classList.add("fadeinOne");
          svgBtn.classList.remove("showDivTwo");
        }
      } else {
        removeAdd(videoIcon, svgBtn);
      }
      // console.log(e.target);
      // console.log(videoId.value);

      if (e.target.id === videoId.value) {
        timeVideoAdd = setTimeout(() => {
          removeAdd(videoIcon, svgBtn);
        }, 2000);
      }
    }
    var nextVideoNum: number = 0;
    function playNextVideo() {
      // console.log(111);

      videoNum(numVideoLength, true);
    }

    return {
      imgOneUrl,
      imgTwoUrl,
      imgThreeUrl,
      videoId,
      videoClass,
      posterUrl,
      Frame46,
      Frame47,
      Group45243,
      Group45287,
      Group45290,
      inputRange,
      showTooltip,
      videoTextOne,
      videoTextTwo,
      progressWidth,
      nextVideoFlag,
      pausedVideo,
      changeImg,
      // openOrSlient,
      fullScreen,
      getCurrentInfo,
      setProgress,
      //拖动
      sliderStart,
      controlMove,
      controlEnd,
      // moveLev,
      moveEnt,
      moveBlock,
      moveBlockOne,
      changeSlider,
      oparedAudio,
      clickVideoPlay,
      moveVideo,
      playNextVideo,
    };
  },
};
</script>
<style scoped lang="scss">
.fadeinOne {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1 !important;
}

.showDivTwo {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0 !important;
}

// video::-webkit-media-controls-timeline {
// }
ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.hover {
  color: #00f577 !important;
}

.fullScreen {
  video {
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;
    z-index: 11111 !important;
    border-radius: 0px !important;
  }
  .imgClass {
    position: fixed !important;
    width: 200px !important;
    height: 200px !important;
    z-index: 1111111 !important;
  }
  .videoIcon {
    position: fixed !important;
    // bottom: 16px !important;
    // padding-bottom: 16px !important;
    left: 0px !important;
    background-size: 100% !important;
    @media only screen and (min-width: 751px) {
      width: 100% !important;
    }

    z-index: 1111111 !important;
    // padding:0 49px !important;
  }
  .videoIconDiv {
    padding: 0 49px !important;
    padding-bottom: 16px !important;
  }
  #progressVideo {
    width: calc(100% - 98px) !important;
  }
  .videoIconTwo {
  }
  .videoTextIcon {
    font-size: 16px !important;
  }
  .videoTextIcon {
    margin-left: 28px !important;
  }
  .divImgTwo {
    margin-right: 40px !important;
  }
  .divImgThree {
    margin-right: 40px !important;
  }
  .imgThreeDiv {
    :deep(.el-slider__button-wrapper) {
      left: -1.7px !important;
    }
  }
}

@media only screen and (min-width: 751px) {
  video::-webkit-media-controls-enclosure {
    display: none;
    // opacity: 0;
  }
  .videoImg {
    margin-top: 10px;
    position: relative;
    height: 520px;
    #textVideoId {
      // width: var(--innerWidth);
      // height: var(--innerHeight);
      width: 787px;
      height: 520px;
      background: #e1e1e1;
      border-radius: 12px;
      object-fit: fill;
    }
    .imgClass {
      position: absolute;
      top: calc(50% - 70px);
      left: calc(50% - 50px);
      width: 100px;
      height: 100px;
      &:hover {
        cursor: pointer;
      }
    }
    // #btnXIAOSvg {
    //   position: absolute;
    //   top: 40px;
    //   right: 40px;
    //   width: 32px;
    //   height: 32px;
    //   // opacity: 0.6;
    //   &:hover {
    //     cursor: pointer;
    //   }
    // }

    .videoIcon {
      // display: none;
      width: 100%;
      height: 260px;
      position: absolute;
      opacity: 0;
      bottom: 0px;
      // background: url(../assets/img/Rectangle1195.svg);
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      background: linear-gradient(
        179.29deg,
        rgba(255, 255, 255, 0) 52.85%,
        rgba(90, 90, 90, 0.47) 99.39%
      );
      border-radius: 12px;
      // z-index: -1;
      .videoIconDiv {
        margin-top: 192px;
        .videoIconOne {
          display: flex;
          // justify-items: center;
          justify-content: space-between;
          align-items: center;
          // width: 747px;
          height: 22px;
          .flexLeft {
            display: flex;
            flex-direction: row;
            align-items: center;

            .imgOne {
              width: 18px;
              height: 23px;
              margin-left: 27px;
              &:hover {
                cursor: pointer;
              }
            }
            #nextVideo {
              width: 26px;
              height: 25px;
              margin-left: 21px;
              &:hover {
                cursor: pointer;
              }
            }
            .videoTextIcon {
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.06em;
              color: #ffffff;
              // margin-right: 473px;
              margin-left: 10px;
            }
          }
          .flexRight {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 27px;
            .divImgTwo {
              position: relative;
              margin-right: 28px;
              font-size: 18px;
              &:hover {
                cursor: pointer;
              }
              .imgTwoUl {
                display: none;
                position: absolute;
                // width: 37px;
                background: rgba(58, 58, 58, 0.5);
                border-radius: 2px;
                bottom: 36px;
                left: -3px;
                z-index: 11;
                // padding: 6px 0px;
                li {
                  // text-align: center;
                  padding: 6px 10px;
                  font-family: "PingFang SC";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 8px;
                  line-height: 12px;
                  letter-spacing: 0.1em;
                  color: #ffffff;
                  // margin-bottom: 8px;
                  &:hover {
                    background: rgba(247, 247, 247, 0.3);
                  }

                  &:last-child {
                    margin-bottom: 0px;
                  }
                }
              }
              span {
                display: inline-block;
                // width: 38px;
                // height: 18px;
                font-family: "PingFang SC";
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                // line-height: 26px;
                letter-spacing: 0.06em;
                color: #ffffff;
              }
            }
            .divImgThree {
              position: relative;
              margin-right: 28px;
              &:hover {
                cursor: pointer;
              }
              .imgThreeDiv {
                display: none;
                width: 30px;
                height: 72px;
                background: rgba(58, 58, 58, 0.5);
                border-radius: 2px;
                position: absolute;
                bottom: 38px;
                left: -3px;
                z-index: 11;
                box-sizing: border-box;
                // input {
                //   position: absolute;
                //   bottom: 34px;
                //   left: -16px;
                //   width: 56px;
                //   height: 2px;
                //   transform: rotate(270deg);
                // }
                // :deep(.el-slider) {
                //   // width: 2px !important;
                //   // height: 56px !important;
                //   // background-color: #00f577;

                // }
                :deep(.el-slider__runway) {
                  width: 2px !important;
                  height: 56px !important;
                  // margin: 0px !important;
                  margin: 8px 14px !important;
                  background: rgba(255, 255, 255, 0.5);
                }
                :deep(.el-slider__bar) {
                  width: 2px !important;
                  background: #11c066 !important;
                }
                :deep(.el-slider__button-wrapper) {
                  width: 6px !important;
                  height: 6px !important;
                  left: -2px;
                  // margin: 0 50% !important;
                  transform: none !important;
                  background: #ffffff;
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                  &:hover {
                    cursor: pointer;
                  }
                }

                :deep(.el-slider__button) {
                  display: none;
                  // width: 6px !important;
                  // height: 6px !important;
                  // border: none !important;
                  &:hover {
                    display: none;
                  }
                }
              }
              .imgThree {
                width: 24px;
                height: 22px;
                margin-top: 5px;
              }
            }
            .imgFour {
              width: 22px;
              height: 22px;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
        .videoIconTwo {
          width: calc(100% - 40px);
          margin: 0 auto;
          margin-top: 19px;

          // height: 2px;
          position: relative;
          .control {
            position: relative;
            margin: 0 auto;
            #progressVideo {
              width: 100%;
              height: 2px;
              position: absolute;
              top: 0px;
              left: 0px;
              background: rgba(255, 255, 255, 0.5);
              border-radius: 5px;

              &:hover {
                cursor: pointer;
              }
            }
            .slider_circle {
              display: none;
              position: absolute;
              top: -2px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              border: 1px;
              background: #ffffff;
            }
            .slider_circle_large {
              position: absolute;
              height: 2px;
              background: #ffffff;
              border-radius: 5px;
              width: auto;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      .posiImg {
        position: absolute;
        bottom: 70px;
        width: 100%;
        height: 194px;
      }
    }
  }
  #cenTxtOneDivImg {
    position: relative;
    width: 1094px;
    height: 602px;
    border: 8px solid #2d2d2d;
    border-radius: 20px;
    margin-top: 20px;
    #cenDivTwoImg {
      width: 1150px;
      height: 624px;
      // margin-top: 107px;
      // margin-left: -395px;
      object-fit: cover;
    }
    #ctImgSvg {
      width: 85px;
      height: 85px;
      position: absolute;
      top: calc(50% - 52.5px);
      left: calc(50% - 42.5px);
      opacity: 0.8;
      &:hover {
        cursor: pointer;
      }
    }
    .videoIcon {
      background-size: 1094px 260px !important;
      border-radius: 20px;
    }
  }
  #missionDivTwo {
    display: flex;

    .cenTwoBackDivImg {
      #missionDivTwoOne,
      #missionDivTwoAgain {
        display: flex;
        width: 562px;
          height: 372px;
        border-radius: 20px;
        position: relative;
        margin-top: 0px;
        #ctVideo {
          width: 562px;
          height: 372px;
          object-fit: cover;
          border-radius: 20px;
        }
        #ctImg {
          width: 91px;
          height: 91px;
          position: absolute;
          top: calc(50% - 45.5px);
          left: calc(50% - 45.5px);
          opacity: 0.8;
          z-index: 11;
          &:hover {
            cursor: pointer;
          }
        }
        #ctVideoNext {
          width: 562px;
          height: 372px;
          object-fit: cover;
          border-radius: 20px;
        }
        #ctImgNext {
          width: 91px;
          height: 91px;
          position: absolute;
          top: calc(50% - 45.5px);
          left: calc(50% - 45.5px);
          opacity: 0.8;
          z-index: 11;
          &:hover {
            cursor: pointer;
          }
        }
        &:first-child {
          margin-right: 26px;
        }
        .videoIcon {
          border-radius: 20px;
        }
      }
    }
  }
  #videoPosi {
    position: relative;
    width: 1239px;
    height: 689px;
    margin: 0 auto;
    margin-top: 102px;
    border-radius: 20px;
    #carouseVideo {
      width: 1239px;
      height: 689px;
      border-radius: 20px;
      // object-fit: fill;
      object-fit: scale-down !important;
      // object-fit: contain;
      background: #e1e1e1;
    }

    .videoIcon {
      border-radius: 20px;
    }
  }
  #videoPositionBackgroundOne {
    width: 787px;
    height: 520px;
    margin: 0px;
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 250px;
    }
    #liveVideoOne {
      display: block;
      width: 787px;
      height: 520px;
      margin: 0px auto;
      background: #ffffff;
      border-radius: 12px;
      object-fit: fill;
    }
  }
}
@media only screen and (max-width: 750px) {
  // video::-webkit-media-controls-enclosure {
  //   display: none !important;
  //   // opacity: 0;
  // }
  .imgClass {
    display: none !important;
  }
  .videoImg {
    font-size: 16px;
    margin-top: 24px;
    position: relative;
    z-index: 1111 !important;
    #textVideoId {
      // width: var(--innerWidth);
      // height: var(--innerHeight);
      width: 702px;
      height: 464px;
      background: #e1e1e1;
      border-radius: 12px;
      object-fit: fill;
    }
    .imgClass {
      display: none !important;
      position: absolute;
      top: calc(50% - 70px);
      left: calc(50% - 50px);
      width: 100px;
      height: 100px;
      &:hover {
        cursor: pointer;
      }
      z-index: 1111 !important;
    }
    // #btnXIAOSvg {
    //   position: absolute;
    //   top: 40px;
    //   right: 40px;
    //   width: 32px;
    //   height: 32px;
    //   // opacity: 0.6;
    //   &:hover {
    //     cursor: pointer;
    //   }
    // }

    .videoIcon {
      display: none;
      width: 100%;
      height: 260px;
      position: absolute;
      opacity: 0;
      bottom: 4px;
      // background: url(../assets/img/Rectangle1195.svg);
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      background: linear-gradient(
        179.29deg,
        rgba(255, 255, 255, 0) 52.85%,
        rgba(90, 90, 90, 0.47) 99.39%
      );
      border-radius: 12px;
      // z-index: -1;
      .videoIconDiv {
        margin-top: 192px;
        .videoIconOne {
          display: flex;
          // justify-items: center;
          justify-content: space-between;
          align-items: center;
          // width: 747px;
          height: 22px;
          .flexLeft {
            display: flex;
            flex-direction: row;
            align-items: center;

            .imgOne {
              width: 18px;
              height: 23px;
              margin-left: 27px;
              &:hover {
                cursor: pointer;
              }
            }
            .videoTextIcon {
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.06em;
              color: #ffffff;
              // margin-right: 473px;
              margin-left: 10px;
            }
          }
          .flexRight {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 27px;
            height: 22px;

            .divImgTwo {
              position: relative;
              margin-right: 28px;
              &:hover {
                cursor: pointer;
              }
              .imgTwoUl {
                display: none;
                position: absolute;
                // width: 37px;
                background: rgba(58, 58, 58, 0.5);
                border-radius: 2px;
                bottom: 36px;
                left: -3px;
                z-index: 11;
                // padding: 6px 0px;
                li {
                  // text-align: center;
                  padding: 6px 10px;
                  font-family: "PingFang SC";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 8px;
                  line-height: 12px;
                  letter-spacing: 0.1em;
                  color: #ffffff;
                  // margin-bottom: 8px;
                  &:hover {
                    background: rgba(247, 247, 247, 0.3);
                  }

                  &:last-child {
                    margin-bottom: 0px;
                  }
                }
              }
              span {
                // display: inline-block;
                // width: 38px;
                // height: 18px;
                font-family: "PingFang SC";
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                // line-height: 26px;
                letter-spacing: 0.06em;
                color: #ffffff;
              }
            }
            .divImgThree {
              position: relative;
              margin-right: 28px;
              &:hover {
                cursor: pointer;
              }
              .imgThreeDiv {
                display: none;
                width: 30px;
                height: 72px;
                background: rgba(58, 58, 58, 0.5);
                border-radius: 2px;
                position: absolute;
                bottom: 38px;
                left: -3px;
                z-index: 11;
                // input {
                //   position: absolute;
                //   bottom: 34px;
                //   left: -16px;
                //   width: 56px;
                //   height: 2px;
                //   transform: rotate(270deg);
                // }
                :deep(.el-slider) {
                  width: 2px !important;
                  height: 56px !important;
                }
                :deep(.el-slider__runway) {
                  width: 2px !important;
                  height: 56px !important;
                  // margin: 0px !important;
                  margin: 8px 14px !important;
                  background: rgba(255, 255, 255, 0.5);
                }
                :deep(.el-slider__bar) {
                  width: 2px !important;
                  background: #11c066 !important;
                }
                :deep(.el-slider__button-wrapper) {
                  width: 6px !important;
                  height: 6px !important;
                  left: -2px;
                  // margin: 0 50% !important;
                  transform: none !important;
                  background: #ffffff;
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                  &:hover {
                    cursor: pointer;
                  }
                }

                :deep(.el-slider__button) {
                  display: none;
                  // width: 6px !important;
                  // height: 6px !important;
                  // border: none !important;
                  &:hover {
                    display: none;
                  }
                }
              }
              .imgThree {
                width: 24px;
                height: 22px;
                margin-top: 5px;
              }
            }
            .imgFour {
              width: 22px;
              height: 22px;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
        .videoIconTwo {
          width: calc(100% - 40px);
          margin: 0 auto;
          margin-top: 19px;

          // height: 2px;
          position: relative;
          .control {
            position: relative;
            #progressVideo {
              width: 100%;
              height: 2px;
              position: absolute;
              top: 0px;
              left: 0px;
              background: rgba(255, 255, 255, 0.5);
              border-radius: 5px;
              &:hover {
                cursor: pointer;
              }
            }
            .slider_circle {
              display: none;
              position: absolute;
              top: -2px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              border: 1px;
              background: #ffffff;
            }
            .slider_circle_large {
              position: absolute;
              height: 2px;
              background: #ffffff;
              border-radius: 5px;
              width: auto;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      .posiImg {
        position: absolute;
        bottom: 70px;
        width: 100%;
        height: 194px;
      }
    }
  }
  // #cenTxtOneDivImg {
  //   position: relative;
  //   width: 1094px;
  //   height: 602px;
  //   border: 8px solid #2d2d2d;
  //   border-radius: 20px;
  //   margin-top: 20px;
  //   #cenDivTwoImg {
  //     width: 1094px;
  //     height: 602px;
  //     // margin-top: 107px;
  //     // margin-left: -395px;
  //     object-fit: cover;
  //   }
  //   #ctImgSvg {
  //     width: 85px;
  //     height: 85px;
  //     position: absolute;
  //     top: calc(50% - 52.5px);
  //     left: calc(50% - 42.5px);
  //     opacity: 0.8;
  //   }
  //   .videoIcon {
  //     background-size: 1094px 260px !important;
  //     border-radius: 20px;
  //   }
  // }
  // #missionDivTwo {
  //   display: flex;

  //   .cenTwoBackDivImg {
  //     #missionDivTwoOne,
  //     #missionDivTwoAgain {
  //       display: flex;
  //       width: 734px;
  //       height: 492px;
  //       border-radius: 20px;
  //       position: relative;
  //       margin-top: 0px;
  //       #ctVideo {
  //         width: 734px;
  //         height: 492px;
  //         object-fit: cover;
  //         border-radius: 20px;
  //       }
  //       #ctImg {
  //         width: 91px;
  //         height: 91px;
  //         position: absolute;
  //         top: calc(50% - 45.5px);
  //         left: calc(50% - 45.5px);
  //         opacity: 0.8;
  //       }
  //       #ctVideoNext {
  //         width: 734px;
  //         height: 492px;
  //         object-fit: cover;
  //         border-radius: 20px;
  //       }
  //       #ctImgNext {
  //         width: 91px;
  //         height: 91px;
  //         position: absolute;
  //         top: calc(50% - 45.5px);
  //         left: calc(50% - 45.5px);
  //         opacity: 0.8;
  //       }
  //       &:first-child {
  //         margin-right: 52px;
  //       }
  //       .videoIcon {
  //         border-radius: 20px;
  //       }
  //     }
  //   }
  // }
  // #videoPosi {
  //   position: relative;
  //   width: 1239px;
  //   height: 689px;
  //   margin: 0 auto;
  //   margin-top: 102px;
  //   border-radius: 20px;
  //   #carouseVideo {
  //     width: 1239px;
  //     height: 689px;
  //     border-radius: 20px;
  //     // object-fit: fill;
  //     object-fit: scale-down !important;
  //     // object-fit: contain;
  //     background: #e1e1e1;
  //   }
  //   .videoIcon {
  //     border-radius: 20px;
  //   }
  // }
}
</style>
