<template>
  <div id="appDiv" style="width: 100%; overflow: hidden">
    <Hearde v-if="falgHeadFooter" />
    <router-view v-slot="{ Component }">
      <KeepAlive :max="6">
        <component :is="Component" />
      </KeepAlive>
    </router-view>

    <Regin v-if="xs" />
    <Footer v-if="falgHeadFooter" />
  </div>
</template>
<script lang="ts">
import { ref, onMounted, onBeforeUnmount, watch, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import Hearde from "./components/Hearde.vue";
import Footer from "./components/Footer.vue";
import Regin from "./components/Regin.vue";
import getElement from "./utils/querySelectorMore";
import { Local, Session } from "./utils/storage";
import emitter from "./utils/events-bus";

// import { getBaseUrl } from "./api/apk";
export default {
  name: "App",
  components: {
    Hearde,
    Regin,
    Footer,
  },
  setup() {
    const store = useStore();
    // const router = useRouter();
    const route = useRoute();
    const falgHeadFooter = ref(true);
    let hearde: HTMLElement;
    function win_scroll() {
      const top_left = document.documentElement.scrollLeft as number;
      // console.log(top_left);
      hearde = document.getElementById("hearde") as HTMLElement;
      hearde ? (hearde.style.left = -top_left + "px") : "";
    }

    onMounted(async () => {
      route.path === "/Register" ? (falgHeadFooter.value = false) : "";
      route.path === "/DownPro" || route.path === "/DownloadApp"
        ? (falgHeadFooter.value = false)
        : "";
      // console.log(emitter);
      emitter.on("showXs", showXsData);

      var layWidth = window.innerWidth;
      // console.log(window.innerWidth);
      // console.log(document.body.offsetWidth );

      changWidth(layWidth);
      window?.addEventListener("resize", () => {
        layWidth = window.innerWidth;
        changWidth(layWidth);
        // console.log(window.innerWidth);
        hearde = document.getElementById("hearde") as HTMLElement;
        // console.log("layWidth", layWidth);
        // location.reload();
      });
      window?.addEventListener("scroll", win_scroll);
      // store.state.allWeb.publicLinks = await getBaseUrl();
      // console.log(store.state.allWeb.publicLinks);

      // window.addEventListener("popstate", goBack, false);
    });
    // function goBack() {
    //   // console.log("点击了浏览器的返回按钮");
    //   // router.push("/Register");
    //   // router.go(-1)
    // }

    function changWidth(layWidth: number) {
      // console.log("layWidth", layWidth);
      var appDiv: any = document.getElementById("appDiv");

      if (layWidth > 750) {
        store.state.userInfo.resLayout = false;
        // appDiv.style.minWidth = 1180 + "px";
        if (Session.get("userInfo") || Local.get("userInfo")) {
          const hadright = document.querySelector("#hadright") as HTMLElement;
          hadright?.classList.add("hadrightAdd");
        }
      } else {
        store.state.userInfo.resLayout = true;
        appDiv.style.minWidth = 0 + "px";
        xs.value = false;
      }
    }

    const xs = ref(false);
    function showXsData(kk: any) {
      xs.value = kk;
    }
    onUnmounted(() => {
      let storage = window.localStorage;
      storage.setItem("TT", null as any);
      // clearTimeout();
      Local.clear();
      window.removeEventListener("scroll", win_scroll);
      emitter.all.clear();
    });

    //获取元素
    // function getElement(valueNum: number) {
    //   const classAll = document
    //     .getElementById("hearul")
    //     ?.querySelectorAll(".viBack") as any;
    //   classAll
    //     ? classAll[valueNum]?.firstChild.classList.add("router-link-active")
    //     : "";
    // }
    watch(
      () => route.path,
      () => {
        //   router.beforeResolve((to: any, from, next) => {
        //   console.log("to", to);
        //   console.log("from", from);
        // });
        // console.log("router",router);

        route.path === "/Register"
          ? (falgHeadFooter.value = false)
          : (falgHeadFooter.value = true);
        route.path === "/DownPro" || route.path === "/DownloadApp"
          ? (falgHeadFooter.value = false)
          : (falgHeadFooter.value = true);

        route.path === "/MudioSheetMore" ? getElement(1, true) : "";
        route.path === "/CharitableMore" || route.name === "TextCentent"
          ? getElement(2, true)
          : "";
      }
    );
    watch(
      () => window.location.href,
      () => {
        location.reload();
      }
    );
    return {
      xs,
      falgHeadFooter,
      // showXsData,
    };
  },
};
</script>

<style lang="scss">
body {
  width: 100%;
}
#app {
  color: #2c3e50;
  margin: 0;
  padding: 0;
  font-family: "PingFang SC";
}

// @media screen and (max-width: 750px) {
//   // html,
//   // body {
//   //   width: 750px !important;
//   //   // overflow: hidden;
//   // }
//   // #app{
//   //   width: 750px !important;
//   // }
//   // #appDiv{
//   //   width: 750px !important;
//   // }
// }
</style>
