<template>
  <!-- @mousewheel="scrollCendiv" -->
  <div class="Cendiv" v-if="!userInfo.resLayout">
    <div id="zsImg">
      <video id="movieVideo" :src="videoListOne" autoplay loop muted></video>
      <img
        id="iconVideo"
        :src="soundIcon"
        @click="videoMuted(iconFalg)"
        alt="咱百科"
      />
      <div id="downApp">
        <span id="downAppOne">咱百科</span>
        <span id="downAppTwo">我们的故事百科</span>
        <div id="downAppContent">
          <div class="downAppContentOne">
            <div
              class="downImgSpan"
              @mousemove="downOve(true)"
              @mouseleave="downEave(true)"
            >
              <img src="../assets/img/VectorAndroid.svg" alt="咱百科" />
              <span>Android</span>
            </div>
            <!-- <img id="imgOpcityOne" src="../assets/img/app1.png" alt="咱百科" /> -->
            <div class="qrFd" id="qrFdOne">
              <div>
                <qrcode-vue
                  :value="qrCodeUrl"
                  id="ftrImg"
                  render-as="svg"
                  level="H"
                />
              </div>
              <div class="logo shadowed">
                <img src="../assets/img/logo.png" alt="咱百科" />
              </div>
            </div>
          </div>
          <div class="downAppContentOne">
            <div
              class="downImgSpan"
              @mousemove="downOve(false)"
              @mouseleave="downEave(false)"
            >
              <img
                class="AppleSvg"
                src="../assets/img/VectorAppStore.svg"
                alt="咱百科"
              />
              <span>AppStore</span>
            </div>
            <!-- <img id="imgOpcityTwo" src="../assets/img/app1.png" alt="咱百科" /> -->
            <div class="qrFd" id="qrFdTwo">
              <div>
                <!-- 'http://192.168.10.24:3000/#/DownPro' -->
                <qrcode-vue
                  :value="qrCodeUrl"
                  id="ftrImg"
                  render-as="svg"
                  level="H"
                />
              </div>
              <div class="logo shadowed">
                <img src="../assets/img/logo.png" alt="咱百科" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 测试 -->
    <!-- @mousewheel 鼠标滚轮滚动 -->
    <!-- @wheel.prevent="scrollBarWheel" -->
    <!--  @mouseout.stop="mouseMove" -->
    <div id="carouselUlLiTitle">
      <h3>咱百科APP</h3>
    </div>
    <ul id="carouselUl">
      <li class="carouselUlLi rotate">
        <div class="introduceOne">
          <ul class="oneUl">
            <li>时间轴</li>
            <li>
              智能时间轴发布<br />事件记录形式<br />海量内容发布<br />过往回顾便捷浏览
            </li>
            <li @click="blockVideoLiDiv('时间轴功能')">
              <!-- <img src="../assets/img/LoginBf.svg" alt="播放SVG" /> -->
              <span>查看使用方法</span>
            </li>
          </ul>
        </div>
        <img
          src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/LoginGroup45304.png"
          alt="时间轴展示"
        />
      </li>
      <li class="carouselUlLi">
        <div class="introduceOne">
          <ul class="oneUl">
            <li>人生故事</li>
            <li>
              故事型视频模板<br />
              补充海量回忆素材<br />
            </li>
            <li @click="blockVideoLiDiv('人生故事功能')">
              <!-- <img src="../assets/img/LoginBf.svg" alt="播放SVG" /> -->
              <span>查看使用方法</span>
            </li>
          </ul>
        </div>
        <img
          src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/LoginGroup45306.png"
          alt="人生故事展示"
        />
      </li>
      <li class="carouselUlLi">
        <div class="introduceOne">
          <ul class="oneUl">
            <li>家族圈</li>
            <li>
              共享家族成员时间轴<br />
              智能完善家族成员关系<br />
              为家族成员创建成员故事<br />
              家族重要事件提醒功能<br />
            </li>
            <li @click="blockVideoLiDiv('家族功能')">
              <!-- <img src="../assets/img/LoginBf.svg" alt="播放SVG" /> -->
              <span>查看使用方法</span>
            </li>
          </ul>
        </div>
        <img
          src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/LoginGroup45305.png"
          alt="家族圈展示"
        />
      </li>
    </ul>
    <div id="videoLIDiv" class="expandUp" @mousewheel.prevent>
      <div id="videoPosiDiv">
        <VideoComponent
          :videoUrl="videoLiUrl"
          poster=""
          divId="videoPosi"
          videoId="carouseVideo"
          videoClass=""
          imgId="carouseSvg"
          imgClass=""
          :autoplayVideo="autoplayVideoOne"
          :loopplayVideo="autoplayVideoOne"
        />
        <img
          id="btnXIAOSvg"
          src="../assets/img/btn_xiaochu.svg"
          alt="取消"
          @mouseenter="blockBtn"
          @click="changeVideoLI"
        />
      </div>
    </div>
    <div id="productVideo">
      <h3>产品发布会</h3>
      <!-- <div id="cenTxtOneDivImg"> -->
      <VideoComponent
        videoUrl="https://wepedias.oss-cn-shenzhen.aliyuncs.com/public/DC%E5%88%86%E4%BA%AB/2023.01.11%E5%92%B1%E7%99%BE%E7%A7%91%E5%8F%91%E5%B8%83%E4%BC%9A%281%29.mp4"
        poster="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/filmOne.png"
        divId="cenTxtOneDivImg"
        videoId="cenDivTwoImg"
        videoClass=""
        imgId="ctImgSvg"
        imgClass=""
        :autoplayVideo="autoplayVideo"
        :loopplayVideo="autoplayVideo"
      />
      <!-- <video
          id="cenDivTwoImg"
          src="https://wepedias.oss-cn-shenzhen.aliyuncs.com/public/DC%E5%88%86%E4%BA%AB/2023.01.11%E5%92%B1%E7%99%BE%E7%A7%91%E5%8F%91%E5%B8%83%E4%BC%9A%281%29.mp4"
          poster="../assets/img/filmOne.png"
          @click="changeVideo"
        /> -->

      <!-- </div> -->
      <p class="productInt">
        咱百科的使命是潜心打造故事百科全书平台，使每个人可以随时轻松记录、保存自己和家人的人生故事，连接过去、现在与未来
      </p>
    </div>
    <div id="missionVideo">
      <h3>公司使命</h3>
      <div id="missionDivTwo">
        <div class="cenTwoBackDivImg">
          <VideoComponent
            :videoUrl="videoList"
            poster="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/zblLogin.png"
            divId="missionDivTwoOne"
            videoId="ctVideo"
            videoClass=""
            imgId="ctImg"
            imgClass=""
            :autoplayVideo="autoplayVideo"
            :loopplayVideo="autoplayVideo"
          />
          <!-- <video
            id="ctVideo"
            :src="videoList"
            poster="../assets/img/zblLogin.png"
            @click="changeVideo"
          ></video>
          -->
        </div>
        <div class="cenTwoBackDivImg cenTwoBackDivImgNext">
          <div class="cenTwoBackDivImg">
            <VideoComponent
              videoUrl="https://wepedias.oss-cn-shenzhen.aliyuncs.com/public/DC%E5%88%86%E4%BA%AB/7%E5%88%8630%E7%A7%92%7E15%EF%BC%88%E4%B8%8B%E9%9B%86%EF%BC%89%E5%88%86%E9%92%9F.mp4"
              poster="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/zcVideo.png"
              divId="missionDivTwoAgain"
              videoId="ctVideoNext"
              videoClass=""
              imgId="ctImgNext"
              imgClass=""
              :autoplayVideo="autoplayVideo"
              :loopplayVideo="autoplayVideo"
            />
            <!-- <video
              id="ctVideoNext"
              src="https://wepedias.oss-cn-shenzhen.aliyuncs.com/public/DC%E5%88%86%E4%BA%AB/7%E5%88%8630%E7%A7%92%7E15%EF%BC%88%E4%B8%8B%E9%9B%86%EF%BC%89%E5%88%86%E9%92%9F.mp4"
              poster="../assets/img/zcVideo.png"
              @click="changeVideo"
            ></video>
            -->
          </div>
        </div>
      </div>
      <p class="textMission">
        咱百科团队本着探索创新和求实奉献的企业精神，共同构建编辑、分享、保存于一体的故事平台，先服务于个人及家庭，进而服务于全社会。
      </p>
    </div>
    <div id="huangWeiDiv">
      <h3 id="h3HuangWei">华为云联合咱百科打造人生故事及家庭云储存系统</h3>
      <img
        id="huangWei"
        src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/GroupHuangWei.png"
        alt="咱百科储存"
      />
    </div>
    <img
      id="cooperateImg"
      src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/Group45127.png"
      alt="合作单位logo"
    />
    <!-- 测试结束 -->
  </div>
  <div id="phoneCendiv" v-else>
    <div class="phoneImg">
      <video
        id="phoneVovieVideo"
        :src="videoListOne"
        autoplay
        loop
        muted
      ></video>
      <img
        id="phoneIconVideo"
        :src="phoneIcon"
        @click="phoneVideoMuted(phoneFalg)"
        alt="咱百科"
      />
    </div>
    <div id="phoneCenTxt">
      <div id="phoneCenTxtOne">
        <div class="phoneCenTxtBrief">
          <div class="phoneCenTxtBriefTitle">咱百科APP功能</div>
          <div class="phoneTimeCentent">
            <div class="phoneTimeOne">时间轴</div>
            <div class="phoneTimeTwo">
              <div class="phoneTimeTwoText">
                智能时间轴发布<br />
                事件记录形式<br />
                海量内容发布<br />
                过往回顾便捷浏览<br />
              </div>
              <div
                class="phoneTimeTwoImg"
                @click.stop="phoneChangeVideo('时间轴功能')"
              >
                <!-- <img src="../assets/img/LoginBf.svg" alt="播放SVG" /> -->
                <span>查看使用方法</span>
              </div>
            </div>
          </div>
          <img
            v-if="timeVideoFlag"
            class="phoneTimeFooter"
            src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/phoneTime.png"
            alt="时间轴png"
          />
          <div class="videoPhone" v-else>
            <img
              src="../assets/img/btn_xiaochu.svg"
              alt="取消SVG"
              @click="phoneVidoeUpdate()"
            />
            <video :src="videoLiUrl" controls autoplay></video>
          </div>
        </div>
        <div class="phoneCenTxtBrief">
          <!-- <div class="phoneCenTxtBriefTitle">咱百科APP功能</div> -->
          <div class="phoneTimeCentent">
            <div class="phoneTimeOne">人生故事</div>
            <div class="phoneTimeTwo">
              <div class="phoneTimeTwoText phoneTimeThreeText">
                故事型视频模板<br />
                补充海量回忆素材<br />
              </div>
              <div
                class="phoneTimeTwoImg"
                @click.stop="phoneChangeVideo('人生故事功能')"
              >
                <!-- <img src="../assets/img/LoginBf.svg" alt="播放SVG" /> -->
                <span>查看使用方法</span>
              </div>
            </div>
          </div>
          <img
            v-if="lifeVideoFlag"
            class="phoneTimeFooter"
            src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/phoneLife.png"
            alt="人生故事png"
          />
          <div class="videoPhone" v-else>
            <img
              src="../assets/img/btn_xiaochu.svg"
              alt="取消SVG"
              @click="phoneVidoeUpdate()"
            />
            <video :src="videoLiUrl" controls autoplay></video>
          </div>
        </div>
        <div class="phoneCenTxtBrief">
          <!-- <div class="phoneCenTxtBriefTitle">咱百科APP功能</div> -->
          <div class="phoneTimeCentent">
            <div class="phoneTimeOne">家族圈</div>
            <div class="phoneTimeTwo">
              <div class="phoneTimeTwoText">
                共享家族成员时间轴<br />
                智能完善家族成员关系<br />
                为家族成员创建成员故事<br />
                家族重要事件提醒功能<br />
              </div>
              <div
                class="phoneTimeTwoImg"
                @click.stop="phoneChangeVideo('家族功能')"
              >
                <!-- <img src="../assets/img/LoginBf.svg" alt="播放SVG" /> -->
                <span>查看使用方法</span>
              </div>
            </div>
          </div>
          <img
            v-if="familyVideoFlag"
            class="phoneTimeFooter"
            src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/phoneFamaly.png"
            alt="家族圈png"
          />
          <div class="videoPhone" v-else>
            <img
              src="../assets/img/btn_xiaochu.svg"
              alt="取消SVG"
              @click="phoneVidoeUpdate()"
            />
            <video :src="videoLiUrl" controls autoplay></video>
          </div>
        </div>
      </div>
      <div id="phoneCenTxtTwo">
        <div class="phoneCenTxtBriefTitle">产品发布会</div>
        <div class="phoneCenTxtTwoBackground">
          <video
            id="cenDivTwoImg"
            class="phoneCenTxtTwoVideo"
            src="https://wepedias.oss-cn-shenzhen.aliyuncs.com/public/DC%E5%88%86%E4%BA%AB/2023.01.11%E5%92%B1%E7%99%BE%E7%A7%91%E5%8F%91%E5%B8%83%E4%BC%9A%281%29.mp4"
            poster="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/filmOne.png"
            @click="changeVideo"
          ></video>
          <img
            id="ctImgSvg"
            class="phoneCenTxtTwoImg"
            src="../assets/img/Group141.svg"
            alt="咱百科"
            @click="changeImg"
          />
        </div>
        <p class="phoneCenTxtTwoSpan">
          咱百科的使命是潜心打造故事百科全书平台，使每个人可以随时轻松记录、保存自己和家人的人生故事，连接过去、现在与未来
        </p>
      </div>
      <div id="phoneCenTxtThree">
        <div class="phoneCenTxtBriefTitle">公司使命</div>
        <div class="phoneCenTxtThreeVideo">
          <div class="phoneCenTxtThreeVideoOne">
            <video
              id="ctVideo"
              :src="videoList"
              poster="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/zblLogin.png"
            ></video>
            <img
              id="ctImg"
              class="phoneCenTxtThreeVideoOneImg"
              src="../assets/img/Group141.svg"
              alt="咱百科"
              @click="changeImg"
            />
          </div>
          <div class="phoneCenTxtThreeVideoTwo">
            <video
              id="ctVideoNext"
              src="https://wepedias.oss-cn-shenzhen.aliyuncs.com/public/DC%E5%88%86%E4%BA%AB/7%E5%88%8630%E7%A7%92%7E15%EF%BC%88%E4%B8%8B%E9%9B%86%EF%BC%89%E5%88%86%E9%92%9F.mp4"
              poster="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/zcVideo.png"
            ></video>
            <img
              id="ctImgNext"
              class="phoneCenTxtThreeVideoTwoImg"
              src="../assets/img/Group141.svg"
              alt="咱百科"
              @click="changeImg"
            />
          </div>
        </div>
        <p class="phoneCenTxtThreeSpan">
          咱百科团队本着探索创新和求实奉献的企业精神，共同构建编辑、分享、保存于一体的故事平台，先服务于个人及家庭，进而服务于全社会。
        </p>
      </div>

      <div id="huangWeiPhone">
        <h3 id="haungWeiTitle">华为云联合咱百科打造人生故事及家庭云储存系统</h3>
        <img src="../assets/img/huangWei314.png" class="huangWeiImgOne" />
        <h5 id="haungWeiH5">咱百科，我们的故事百科</h5>
        <img
          src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/huangWei313.png"
          class="huangWeiImgTwo"
        />
        <img
          src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/huangWei312.png"
          class="huangWeiImgThree"
        />
        <img
          src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/huangWei311.png"
          class="huangWeiImgFour"
        />
      </div>
      <div id="phoneCenTxtFour">
        <ul id="phoneCenTxtFourId" class="phoneCenTxtFourClass">
          <li>
            <img src="../assets/interactLogo/image257.png" />
            <img src="../assets/interactLogo/image261.png" />
            <img src="../assets/interactLogo/Group45588.png" />
            <img src="../assets/interactLogo/image258.png" />
          </li>
          <li>
            <img src="../assets/interactLogo/Group45599.png" />
            <img src="../assets/interactLogo/image260.png" />
            <img src="../assets/interactLogo/image254.png" />
            <img src="../assets/interactLogo/Group45594.png" />
          </li>
          <li>
            <img src="../assets/interactLogo/Group45597.png" />
            <img src="../assets/interactLogo/Group45591.png" />
            <img src="../assets/interactLogo/Group45587.png" />
            <img src="../assets/interactLogo/Group45596.png" />
          </li>
          <li>
            <img src="../assets/interactLogo/Group45598.png" />
            <img src="../assets/interactLogo/image255.png" />
            <img src="../assets/interactLogo/Group45600.png" />
            <img src="../assets/interactLogo/Group45593.png" />
          </li>
          <li>
            <img src="../assets/interactLogo/Group45589.png" />
            <img src="../assets/interactLogo/Group45590.png" />
            <img src="../assets/interactLogo/image259.png" />
            <img src="../assets/interactLogo/Group45595.png" />
          </li>
        </ul>
        <ul id="phoneCenTxtFourIdOne" class="phoneCenTxtFourClass">
          <li>
            <img src="../assets/interactLogo/image257.png" />
            <img src="../assets/interactLogo/image261.png" />
            <img src="../assets/interactLogo/Group45588.png" />
            <img src="../assets/interactLogo/image258.png" />
          </li>
          <li>
            <img src="../assets/interactLogo/Group45599.png" />
            <img src="../assets/interactLogo/image260.png" />
            <img src="../assets/interactLogo/image254.png" />
            <img src="../assets/interactLogo/Group45594.png" />
          </li>
          <li>
            <img src="../assets/interactLogo/Group45597.png" />
            <img src="../assets/interactLogo/Group45591.png" />
            <img src="../assets/interactLogo/Group45587.png" />
            <img src="../assets/interactLogo/Group45596.png" />
          </li>
          <li>
            <img src="../assets/interactLogo/Group45598.png" />
            <img src="../assets/interactLogo/image255.png" />
            <img src="../assets/interactLogo/Group45600.png" />
            <img src="../assets/interactLogo/Group45593.png" />
          </li>
          <li>
            <img src="../assets/interactLogo/Group45589.png" />
            <img src="../assets/interactLogo/Group45590.png" />
            <img src="../assets/interactLogo/image259.png" />
            <img src="../assets/interactLogo/Group45595.png" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, onUnmounted, nextTick, onActivated } from "vue";
import { useStore } from "vuex";
import jy from "../assets/img/jy.svg";
import sy from "../assets/img/sy.svg";
import router from "../router";
import { getUserInfo } from "../api/Login/index";
import { getVideoLogin, getVideoName } from "../api/videoData/index";
// import { getBaseUrl } from "../api/apk";
import QrcodeVue from "qrcode.vue";
import { ElMessage } from "element-plus";
import { Session, Local } from "../utils/storage";
// import { rollOne, rollStop } from "../utils/rollStart";

// import { useRoute } from "vue-router";
import VideoComponent from "../components/videoComponent.vue";
// import filmOne from "https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/filmOne.png";
// import zblLogin from "https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/zblLogin.png";
// import zcVideo from "https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/zcVideo.png";

defineOptions({
  name: "HomePage",
});

const qrCodeUrl = ref("");
const store = useStore();
const state = store.state.allWeb;
const userInfo = store.state.userInfo;
const videoListOne = ref("");
const videoList = ref("");
const autoplayVideo = ref(false);
const autoplayVideoOne = ref(true);

onMounted(async () => {
  qrCodeUrl.value = window.location.href + "DownPro";

  const dataLoginOne = await getVideoLogin("1");
  const dataLogin = await getVideoLogin("3");
  // console.log("data", baseUrl + "/" + dataLogin.data.materialUri);
  videoListOne.value =
    store.state.allWeb.publicLinks + "/" + dataLoginOne.data.materialUri;

  videoList.value =
    store.state.allWeb.publicLinks + "/" + dataLogin.data.materialUri;

  if (!userInfo.resLayout) {
    // window.addEventListener("scroll", scrollWindow);
    const url = window.location.href;
    const info = url.split("?")[1] ? url.split("?")[1] : "";
    if (info) {
      var infoArr = info.split("&");
      var cs: any = {};
      infoArr.forEach((item: any) => {
        cs[item.split("=")[0]] = item.split("=")[1];
      });
      // console.log("cs.bindStatus", cs.bindStatus);
      // console.log("cs.token", cs.token);
      // console.log("cs.unionId", cs.unionId);
      if (cs.bindStatus == "true") {
        // console.log("login");
        Session.set("userInfo", cs.token);
        Local.set("userInfo", cs.token);
        const userInfoData = await getUserInfo();
        Session.set("reginUSer", userInfoData.data);
        Local.set("reginUSer", userInfoData.data);
        ElMessage.success("登录成功");
        userInfo.login = true;
        router.push("/");
      } else {
        // console.log("Register");
        router.push({
          name: "Register",
          params: {
            unionId: cs.unionId,
          },
        });
      }
    }
    // styleClass("#ctVideo", 2, 0);
  } else {
    const videoList = document.querySelectorAll("video");
    videoList.forEach((item: any) => {
      // console.log("item.paused", item.paused);
      item.currentTime = 0;
    });
    // console.log("videoList", videoList);
    // videoElement.currentTime
    // rollOne("phoneCenTxtFourId", "phoneCenTxtFour", "phoneCenTxtFourIdOne");
  }
  // carouselUlLiElements = Array.from(
  //   document.getElementsByClassName("carouselUlLi")
  // );
  nextTick(() => {
    const ctIvm = document.querySelector("#ctVideo") as any;
    const ctIvmNext = document.querySelector("#ctVideoNext") as any;
    const FourctVideo = document.querySelector("#FourctVideo") as any;
    const towVideo = document.querySelector("#cenDivTwoImg") as any;
    const img = document.querySelector("#ctImg") as any;
    const imgNext = document.querySelector("#ctImgNext") as any;
    const FourctImg = document.querySelector("#FourctImg") as any;
    const towImg = document.querySelector("#ctImgSvg") as any;
    //  console.log("ctIvm",ctIvm);
    //  console.log("ctIvmNext",ctIvmNext);
    //  console.log("FourctVideo",FourctVideo);
    //  console.log("towVideo",towVideo);
    //  console.log("img",img);
    //  console.log("imgNext",imgNext);
    //  console.log("FourctImg",FourctImg);
    //  console.log("towImg",towImg);

    if (userInfo.resLayout) {
      const phoneVideo = document.querySelector("#phoneVovieVideo") as any;
      phoneVideo.play();
    }
    // console.log("ctIvm", ctIvm);
    // console.log("img", img);
    // ---测试---

    // window.addEventListener("scroll", () => {
    // document.querySelector("body")?.addEventListener("", () => {
    //   console.log(111);
    // });
    // });

    // ----测试结束---

    ctIvm?.addEventListener("play", () => {
      ctIvmNext?.pause();
      FourctVideo?.pause();
      towVideo?.pause();
      img.style.display = "none";
      mutedVideo();
    });
    ctIvm?.addEventListener("pause", () => {
      img.style.display = "block";
    });
    ctIvmNext?.addEventListener("play", () => {
      ctIvm?.pause();
      FourctVideo?.pause();
      towVideo?.pause();
      imgNext.style.display = "none";
      mutedVideo();
    });
    ctIvmNext?.addEventListener("pause", () => {
      imgNext.style.display = "block";
    });
    FourctVideo?.addEventListener("play", () => {
      ctIvm?.pause();
      ctIvmNext?.pause();
      towVideo?.pause();
      FourctImg.style.display = "none";
      mutedVideo();
    });
    FourctVideo?.addEventListener("pause", () => {
      FourctImg.style.display = "block";
    });
    towVideo?.addEventListener("play", () => {
      FourctVideo?.pause();
      ctIvmNext?.pause();
      ctIvm?.pause();
      towImg.style.display = "none";
      mutedVideo();
    });
    towVideo?.addEventListener("pause", () => {
      towImg.style.display = "block";
      mutedVideo();
    });
  });
});

const soundIcon = ref(jy);
const phoneIcon = ref(jy);
const iconFalg = ref(false);
const phoneFalg = ref(false);

//按钮静音控制
function mutedVideo() {
  if (!userInfo.resLayout) {
    var videoPlayer = document.getElementById("movieVideo") as HTMLVideoElement;
    videoPlayer.muted = true;
    soundIcon.value = jy;
    iconFalg.value = false;
  } else {
    var videoPlayer = document.getElementById(
      "phoneVovieVideo"
    ) as HTMLVideoElement;
    videoPlayer.muted = true;
    phoneIcon.value = jy;
    phoneFalg.value = false;
  }
}

//点击视频播放
function changeVideo(e: any) {
  mutedVideo();
  const video = e.target;
  if (e.target.paused) {
    video.controls = true;
    // console.log("e", e.target.nextSibling);
    // e.target.nextSibling.style.zIndex = "-1";
    // if (!userInfo.resLayout) {
    // var ctWzimg = document.querySelector("#ctWzImg") as HTMLElement;
    // console.log("ctWzimg", ctWzimg);
    // ctWzimg.style.zIndex = "-1";
    // var posiWz = document.getElementById("position") as HTMLElement;
    // posiWz.style.display = "none";
    // }
  }
}

//点击播放暂停按钮
function changeImg(e: any) {
  if (!userInfo.resLayout) {
    var videoPlayer = document.getElementById("movieVideo") as HTMLVideoElement;
    videoPlayer.muted = true;
    soundIcon.value = jy;
    iconFalg.value = false;
  }

  // console.log("e.target.previousSibling", e.target.previousSibling);

  var video = e.target.previousSibling;
  //  var video = !userInfo.resLayout
  // ? e.target.previousSibling.previousSibling
  // : e.target.previousSibling;

  video.controls = true;

  if (video.paused && !userInfo.resLayout) {
    // var ctWz = <HTMLElement>document.querySelector("#ctWz");
    // var ctWzimg = <HTMLElement>ctWz.firstChild;
    // var ctWzimg = document.querySelector("#ctWzImg") as HTMLElement;
    // // console.log("ctWzimg", ctWzimg);

    // ctWzimg.style.zIndex = "-1";
    // var posiWz = document.getElementById("position") as HTMLElement;
    // posiWz.style.display = "none";

    e.target.previousSibling.controls = true;
    video.play();
  }
  if (video.paused) {
    video.play();
  }
}

//下载图标移入移出
function downOve(params: any) {
  // console.log("qrCodeUrl.value",qrCodeUrl.value);

  if (params) {
    const qrFdOne = document.getElementById("qrFdOne") as any;
    qrFdOne.classList.add("fadeinOne");
    qrFdOne.classList.remove("showDivTwo");
  } else {
    const qrFdTwo = document.getElementById("qrFdTwo") as any;
    qrFdTwo.classList.add("fadeinOne");
    qrFdTwo.classList.remove("showDivTwo");
  }
}
function downEave(params: any) {
  if (params) {
    const qrFdOne = document.getElementById("qrFdOne") as any;
    qrFdOne.classList.add("showDivTwo");
    qrFdOne.classList.remove("fadeinOne");
  } else {
    const qrFdTwo = document.getElementById("qrFdTwo") as any;
    qrFdTwo.classList.add("showDivTwo");
    qrFdTwo.classList.remove("fadeinOne");
  }
}

//点击静音和不静音
function videoMuted(falg: any) {
  var videoPlayer = document.getElementById("movieVideo") as HTMLVideoElement;
  if (!falg) {
    var ctVideoPlayer = document.getElementById("ctVideo") as HTMLVideoElement;
    ctVideoPlayer.pause();
    videoPlayer.muted = false;
    soundIcon.value = sy;
    iconFalg.value = !falg;
    videoPlayer.play();
  } else {
    videoPlayer.muted = true;
    soundIcon.value = jy;
    iconFalg.value = !falg;
    videoPlayer.play();
  }
}

function phoneVideoMuted(falg: any) {
  var videoPlayer = document.getElementById(
    "phoneVovieVideo"
  ) as HTMLVideoElement;
  if (!falg) {
    var ctVideoPlayer = document.getElementById("ctVideo") as HTMLVideoElement;
    ctVideoPlayer.pause();

    videoPlayer.muted = false;
    phoneIcon.value = sy;
    phoneFalg.value = !falg;
    videoPlayer.play();
  } else {
    videoPlayer.muted = true;
    phoneIcon.value = jy;
    phoneFalg.value = !falg;
    videoPlayer.play();
  }
}

function blockBtn(e: any) {
  const blockNull = e.target.getAttribute("class");
  if (blockNull === "showDivTwo") {
    e.target.classList.add("fadeinOne");
    e.target.classList.remove("showDivTwo");
  } else {
    e.target.classList.add("showDivTwo");
    e.target.classList.remove("fadeinOne");
  }
}
const videoLiUrl = ref("");
function changeVideoLI() {
  const videoLIDiv = document.querySelector("#videoLIDiv") as HTMLVideoElement;
  videoLIDiv.style.display = "none";
  videoLIDiv.querySelector("video")?.pause();
  // scollNum === 0 || scollNum === 3 ? Move() : "";
}
async function blockVideoLiDiv(params: string) {
  const videoLIDiv = document.querySelector("#videoLIDiv") as HTMLElement;
  videoLIDiv.style.display = "block";

  // stopMove();
  const data = await getVideoName({
    name: params,
  });
  videoLiUrl.value = store.state.allWeb.publicLinks + data.data;

  // console.log("videoLiUrl.value", videoLiUrl.value);
}
// ----测试结束
const timeVideoFlag = ref(true);
const lifeVideoFlag = ref(true);
const familyVideoFlag = ref(true);
async function phoneChangeVideo(params: string) {
  // console.log(e.target.tagName);
  phoneVidoeUpdate();
  const data = await getVideoName({
    name: params,
  });
  videoLiUrl.value = store.state.allWeb.publicLinks + data.data;
  switch (params) {
    case "时间轴功能":
      timeVideoFlag.value = false;
      break;
    case "人生故事功能":
      lifeVideoFlag.value = false;
      break;
    case "家族功能":
      familyVideoFlag.value = false;
      break;
  }
}
function phoneVidoeUpdate() {
  timeVideoFlag.value = true;
  lifeVideoFlag.value = true;
  familyVideoFlag.value = true;
}

onUnmounted(() => {
  !state.resLayout ? location.reload() : "";
  state.domFla = false;
});
onActivated(() => {
  const videoId = document.querySelector("#movieVideo") as HTMLVideoElement;
  videoId ? videoId.play() : "";
});
</script>
<style lang="scss" scoped>
//控制透明效果
// .fadein {
//   -webkit-transition: all 0.6s;
//   -moz-transition: all 0.6s;
//   -ms-transition: all 0.6s;
//   -o-transition: all 0.6s;
//   transition: all 0.6s;
//   opacity: 1;
// }

// .showDiv {
//   -webkit-transition: all 0.6s;
//   -moz-transition: all 0.6s;
//   -ms-transition: all 0.6s;
//   -o-transition: all 0.6s;
//   transition: all 0.6s;
//   opacity: 0.8;
// }

.fadeinOne {
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
  opacity: 1 !important;
}

.showDivTwo {
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
  opacity: 0 !important;
}

.opy {
  opacity: 0;
  pointer-events: none;
}
//......................

ul {
  display: flex;
  justify-items: start;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}
.expandUp {
  animation-name: expandUp;
  -webkit-animation-name: expandUp;

  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;

  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;

  visibility: visible !important;
}

@keyframes expandUp {
  0% {
    transform: scaleY(0);
  }
  // 60% {
  //   transform: translateY(7%) scaleY(1.12);
  // }
  // 75% {
  //   transform: translateY(3%);
  // }
  100% {
    transform: scaleY(1);
  }
}

@-webkit-keyframes expandUp {
  0% {
    -webkit-transform: translateY(100%) scale(0.6) scaleY(0.5);
  }
  // 60% {
  //   -webkit-transform: translateY(-7%) scaleY(1.12);
  // }
  // 75% {
  //   -webkit-transform: translateY(3%);
  // }
  100% {
    -webkit-transform: translateY(0%) scale(1) scaleY(1);
  }
}

@keyframes moveReview {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-100%);
  }
}
@media only all and (min-width: 751px) {
  .Cendiv {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin-top: 40px;
    -webkit-overflow-scrolling: touch;
    #zsImg {
      position: relative;
      // height: 1080px;
      background: #d9d9d9;
      #iconVideo {
        position: absolute;
        top: 80px;
        right: 80px;
        width: 40px;
        height: 40px;
        &:hover {
          cursor: pointer;
        }
      }
      // img {
      //   width: 1918px;
      //   height: 868px;
      // }
      video {
        display: block;
        margin: 0 auto;
        object-fit: fill;
        width: 100%;
        height: 1080px;
      }

      #downApp {
        display: flex;
        flex-direction: column;
        width: 531px;
        position: absolute;
        top: 248px;
        left: 80px;

        #downAppOne {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 600;
          font-size: 62px;
          line-height: 114px;
          letter-spacing: 0.03em;
          color: #ffffff;
        }
        #downAppTwo {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 500;
          font-size: 40px;
          text-align: justify;
          color: #ffffff;
        }
        #downAppContent {
          display: flex;
          margin-top: 63px;
          .downAppContentOne {
            display: flex;
            flex-direction: column;
            align-items: center;
            &:first-child {
              margin-right: 31px;
            }

            .downImgSpan {
              display: flex;
              align-items: center;
              width: 248px;
              height: 64px;
              background: rgba(0, 0, 0, 0.3);
              border: 0.5px solid #ffffff;
              border-radius: 60px;
              // opacity: 1;

              &:hover {
                cursor: pointer;
              }

              img {
                width: 44px;
                height: 44px;
                margin-left: 34px;
                margin-right: 16px;
              }
              .AppleSvg {
                margin-left: 24px;
                margin-right: 14px;
              }
              span {
                font-family: "PingFang SC";
                font-style: normal;
                font-weight: 500;
                font-size: 29px;
                letter-spacing: 0.06em;
                color: #ffffff;
              }
            }
            .qrFd {
              position: relative;
              opacity: 0;
              div {
                display: flex;
                width: 196px;
                height: 196px;
                border-radius: 11px;
                margin-top: 31px;

                justify-content: center;
                align-items: center;
                background: #fff;
                // border: 2px solid #56bd88;
                // border-radius: 6px;
                // .ewmImg {
                //   display: inline-block;
                //   width: 202px;
                //   height: 202px;
                // }
                #ftrImg {
                  width: 166px;
                  height: 166px;
                }
              }
              .logo {
                width: 30px;
                height: 30px;
                overflow: hidden;
                position: absolute;
                // left:50%;
                // top:50%;
                top: calc(50% - 2px);
                left: calc(50% - 16px);
                // transform: translate(-50%, -50%);
                background: #fff;
                border: 2px solid #fff;
                border-radius: 6px;
                margin: 0;
                img {
                  width: 30px;
                  height: 30px;
                }
              }
              .shadowed {
                box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
              }
            }
            #imgOpcityOne {
              width: 202px;
              height: 202px;
              border-radius: 11px;
              margin-top: 31px;
              opacity: 0;
            }
            #imgOpcityTwo {
              width: 202px;
              height: 202px;
              border-radius: 11px;
              margin-top: 31px;
              opacity: 0;
            }
          }
        }
      }

      .searchPst {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: -180px;

        .search {
          display: flex;
          align-items: center;
          position: relative;
          // left: 530px;
          // bottom: 142px;

          img {
            width: 30px;
            height: 30px;
            position: absolute;
            left: 20px;
            top: 10px;
          }

          input {
            width: 600px;
            height: 48px;
            border: none;
            border-bottom-left-radius: 100px;
            border-top-left-radius: 100px;
            outline: none;
            padding-left: 80px;
            font-size: 18px;
            opacity: 0.8;
          }

          button {
            width: 120px;
            height: 50px;
            border: none;
            border-bottom-right-radius: 100px;
            border-top-right-radius: 100px;
            // position: absolute;
            // top: 0;
            background-color: #5c8dde;
            color: #ffffff;
            font-size: 18px;
            padding: 0;
            text-indent: -8px;
          }
        }

        .fileinput-button {
          // position: absolute;
          // bottom: 142px;
          margin-left: 66px;

          img {
            width: 30px;
            height: 25px;
            display: block;
            margin-left: 30px;
            padding-top: 12px;
          }

          .fileinput {
            position: absolute;
            bottom: 0px;
            margin-left: 1450px;
            width: 150px;
            height: 50px;
            opacity: 0;
            padding: 0;
            margin: 0;
          }

          .filefont {
            width: 150px;
            height: 50px;
            margin-top: -36px;
            display: block;
            line-height: 50px;
            background-color: #5c8dde;
            text-align: center;
            font-size: 18px;
            color: #ffffff;
            text-indent: 25px;
          }
        }
      }
    }

    // --测试----
    #carouselUlLiTitle {
      width: 1150px;
      margin: 0px auto;
      h3 {
        width: 100%;
        display: inline-block;
        color: #000;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 6.8px;
        margin: 0px;
        // padding-left: 845px;
        margin-top: 142px;
        margin-bottom: 155px;
      }
    }
    #carouselUl {
      // display: inline-block;
      display: flex;
      flex-direction: column;
      width: 1150px;
      // height: 1180px;
      overflow: hidden;
      // white-space: nowrap;
      // border: 1px solid red;
      // margin-top: 152px;
      margin: 0 auto;
      margin-bottom: 377px;

      .carouselUlLi {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        // height: 1180px;
        // padding: 0px 202px;
        padding-top: 15px;
        // opacity: 0;
        margin-bottom: 267px;

        .introduceOne {
          // margin-left: 268px;
          .oneUl {
            display: flex;
            flex-direction: column;
            align-items: start;

            // margin-right: 171px;
            li {
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 500;
              text-align: justify;
              color: #000;

              &:first-child {
                font-size: 84px;
                font-weight: 600;
                letter-spacing: 17px;
                color: #000000;
              }
              &:nth-child(2) {
                font-size: 26px;
                // line-height: 110px;
                letter-spacing: 7.8px;
                color: #2d2d2d;
                margin-top: 106px;
                margin-bottom: 170px;
              }

              &:last-child {
                display: flex;
                align-items: center;
                // margin-top: 200px;
                // img {
                //   width: 35px;
                //   height: 35px;
                //   margin-right: 20px;
                // }
                span {
                  display: inline-block;
                  width: 233px;
                  height: 48px;
                  background: url(../assets/img/LoginBf.svg);
                  background-repeat: no-repeat;
                  background-size: 35px;
                  background-position: center left;
                  padding-left: 54px;
                  font-family: "PingFang SC";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 26px;
                  line-height: 48px;
                  text-align: justify;
                  letter-spacing: 4.42px;
                  color: #56bd88;
                }
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
        img {
          display: inline-block;
          width: 670px;
          height: 562px;
          // margin-right: 268px;
        }
        // &:first-child {
        //   img {
        //   }
        // }
        // &:nth-child(2) {
        //   // background: #f08b5f;
        // }
        &:last-child {
          // background: greenyellow;
          margin-bottom: 0px;
        }
      }
    }
    #videoLIDiv {
      display: none;
      width: 100%;
      height: 900px;
      position: fixed;
      top: 73px;
      left: 0;
      // transform: translate(-50%, -50%);
      background: #ffffff;
      // border: 1px solid red;
      z-index: 1111111;
      #videoPosiDiv {
        position: relative;
        width: 1239px;
        height: 689px;
        margin: 0 auto;
        margin-top: 102px;
        border-radius: 20px;
        #btnXIAOSvg {
          opacity: 0;
          position: absolute;
          top: 40px;
          right: 40px;
          width: 32px;
          height: 32px;
          // opacity: 0.6;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    #productVideo {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      width: 1150px;
      // height: 981px;
      // border: 1px solid rebeccapurple;
      margin: 0 auto;
      margin-top: 200px;
      padding-bottom: 149px;
      font-weight: 500;

      h3 {
        font-family: "PingFang SC";
        font-style: normal;
        font-size: 40px;
        // line-height: 117px;
        text-align: justify;
        letter-spacing: 0.17em;
        color: #000000;
        // margin-top: 60px;
      }
      #cenTxtOneDivImg {
        position: relative;
        width: 1150px;
        height: 624px;
        border: 8px solid #2d2d2d;
        border-radius: 20px;
        margin-top: 28px;
        #cenDivTwoImg {
          width: 1150px;
          height: 624px;
          // margin-top: 107px;
          // margin-left: -395px;
          object-fit: cover;
        }
        #ctImgSvg {
          width: 85px;
          height: 85px;
          position: absolute;
          top: calc(50% - 42.5px);
          left: calc(50% - 42.5px);
          opacity: 0.8;
        }
      }
      .productInt {
        width: 1150px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        text-align: justify;
        letter-spacing: 3.4px;
        line-height: 183%;
        color: #48555a;
        margin-top: 30px;
      }
    }
    #missionVideo {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1150px;
      // height: 1098px;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 500;
      margin: 0 auto;
      padding-top: 120px;
      h3 {
        font-size: 40px;
        text-align: center;
        letter-spacing: 0.17em;
        color: #000000;
        margin-bottom: 20px;
      }
      #missionDivTwo {
        display: flex;

        .cenTwoBackDivImg {
          width: 562px;
          height: 372px;
          &:first-child {
            margin-right: 26px;
          }
          //   width: 734px;
          //   height: 492px;
          //   border-radius: 20px;
          //   position: relative;
          //   #ctVideo {
          //     width: 734px;
          //     height: 492px;
          //     object-fit: cover;
          //     border-radius: 20px;
          //   }
          //   #ctImg {
          //     width: 91px;
          //     height: 91px;
          //     position: absolute;
          //     top: calc(50% - 45.5px);
          //     left: calc(50% - 45.5px);
          //     opacity: 0.8;
          //   }
          //   #ctVideoNext {
          //     width: 734px;
          //     height: 492px;
          //     object-fit: cover;
          //     border-radius: 20px;
          //   }
          //   #ctImgNext {
          //     width: 91px;
          //     height: 91px;
          //     position: absolute;
          //     top: calc(50% - 45.5px);
          //     left: calc(50% - 45.5px);
          //     opacity: 0.8;
          //   }
          //   &:first-child {
          //     margin-right: 52px;
          //   }
        }
      }
      .textMission {
        width: 1150px;
        font-size: 20px;
        text-align: justify;
        letter-spacing: 0.17em;
        line-height: 183%;
        color: #48555a;
        margin-top: 32px;
      }
    }
    #huangWeiDiv {
      #h3HuangWei {
        color: #222;
        text-align: center;
        font-size: 30px;
        font-style: bold;
        letter-spacing: 6.8px;
        padding-top: 178px;
        padding-bottom: 30px;
        margin: 0;
      }
      #huangWei {
        display: block;
        width: 1150px;
        height: 506px;
        margin: 0 auto;
        // padding-bottom: 216px;
      }
    }

    #cooperateImg {
      display: block;
      width: 1150px;
      height: 578px;
      margin: 0 auto;
      padding-bottom: 116px;
      padding-top: 250px;
    }

    // ---测试结束----

    // #centTxt {
    //   width: 1202px;
    //   // padding-top: 101px;
    //   margin: 0 auto;
    //   .cenTextOneTit {
    //     width: 216px;
    //     position: relative;
    //     margin: 0 auto;
    //     .oneText {
    //       font-size: 32px;
    //       font-family: Arial, Helvetica, sans-serif;
    //       font-weight: bold;
    //       color: rgba(135, 181, 224, 0.3);
    //       line-height: 59px;
    //       letter-spacing: 15px;
    //     }
    //     .oneContent {
    //       position: absolute;
    //       top: 0;
    //       left: 68px;
    //       display: inline-block;
    //       font-size: 32px;
    //       font-family: Arial, Helvetica, sans-serif;
    //       font-weight: bold;
    //       color: #48555a;
    //       line-height: 59px;
    //       letter-spacing: 15px;
    //     }
    //     .cenTextOneTitLine {
    //       width: 107px;
    //       height: 0px;
    //       opacity: 1;
    //       border-bottom: 1px solid #48555a;
    //       position: absolute;
    //       bottom: 0;
    //       left: 88px;
    //       height: 1px;
    //       background: #000;
    //     }
    //     .cenTextTwoTitLine {
    //       width: 107px;
    //       height: 0px;
    //       opacity: 1;
    //       border-bottom: 1px solid #48555a;
    //       position: absolute;
    //       bottom: -9px;
    //       left: 31px;
    //       height: 1px;
    //       background: #000;
    //     }
    //   }
    //   #cenTxtOne {
    //     position: relative;
    //     height: 1120px;
    //     margin-bottom: 150px;
    //     #cenTextOneBack {
    //       width: 1152px;
    //       margin: 0 auto;
    //       // display: flex;
    //       // flex-direction: column;
    //       // justify-content: center;
    //       #cenTextTitle {
    //         font-family: Arial, Helvetica, sans-serif;
    //         text-align: center;
    //         font-weight: 400;
    //         font-size: 20px;
    //         line-height: 24px;
    //         letter-spacing: 0.165em;
    //         color: #48555a;
    //         margin-top: 88px;
    //       }
    //       #cenDivTwo {
    //         display: flex;
    //         flex-direction: row;
    //         margin-top: 23px;

    //         #cenDivTwoOne {
    //           margin-top: 107px;
    //           .cenDivTwoSpan {
    //             width: 66px;
    //             font-size: 36px;
    //             font-family: Arial, Helvetica, sans-serif;
    //             // font-weight: 500;
    //             color: rgba(134, 200, 236, 0.5);
    //             letter-spacing: 33px;
    //             writing-mode: tb-rl;
    //           }
    //           .cenDivTwoJiLu {
    //             display: block;
    //             font-size: 65px;
    //             font-family: Arial, Helvetica, sans-serif;
    //             font-weight: 400;
    //             color: #48555a;
    //             line-height: 119px;
    //             letter-spacing: 31px;
    //             margin-top: -160px;
    //             margin-left: 16px;
    //           }
    //           .cenDivTwoRec {
    //             display: inline-block;
    //             font-size: 56px;
    //             font-family: Arial, Helvetica, sans-serif;
    //             font-weight: 500;
    //             color: #48555a;
    //             line-height: 103px;
    //             letter-spacing: 12px;
    //             border-bottom: 1px solid #000;
    //             margin-left: 16px;
    //           }
    //           .cenDivTwoContent {
    //             display: block;
    //             width: 768px;
    //             font-size: 20px;
    //             font-family: Arial, Helvetica, sans-serif;
    //             font-weight: 300;
    //             color: #48555a;
    //             line-height: 37px;
    //             // letter-spacing: 3px;
    //             text-justify: justify;

    //             margin-left: 16px;
    //             margin-top: 23px;
    //             text-indent: 2em;
    //           }
    //         }
    //         #cenTxtOneDivImg {
    //           position: relative;
    //           #cenDivTwoImg {
    //             width: 758px;
    //             height: 505px;
    //             margin-top: 107px;
    //             margin-left: -395px;
    //             object-fit: cover;
    //           }
    //           #ctImgSvg {
    //             width: 85px;
    //             height: 85px;
    //             position: absolute;
    //             top: calc(50% - 96px);
    //             left: calc(50% - 240px);
    //             opacity: 0.8;
    //           }
    //         }
    //       }
    //     }
    //     #cenTextImg {
    //       position: absolute;
    //       top: 330px;
    //       left: -350px;
    //       height: 860px;
    //       z-index: -11;
    //     }
    //   }
    //   #cenTxtTwo {
    //     width: 1152px;
    //     margin: 0 auto;
    //     position: relative;
    //     // height: 1120px;
    //     padding-bottom: 163px;
    //     #cenTextTwoBack {
    //       width: 1152px;
    //       margin: 0 auto;
    //       // display: flex;
    //       // flex-direction: column;
    //       // justify-content: center;
    //       #cenTwoBackDiv {
    //         display: flex;
    //         flex-direction: row;
    //         position: relative;
    //         #cenTwoBackDivImg {
    //           width: 855px;
    //           position: relative;
    //           #ctVideo {
    //             width: 855px;
    //             height: 569px;
    //             object-fit: cover;
    //           }
    //           #ctImg {
    //             width: 91px;
    //             height: 91px;
    //             position: absolute;
    //             top: calc(50% - 45.5px);
    //             left: calc(50% - 45.5px);
    //             opacity: 0.8;
    //           }
    //           #ctVideoNext {
    //             width: 855px;
    //             height: 569px;
    //             object-fit: cover;
    //           }
    //           #ctImgNext {
    //             width: 91px;
    //             height: 91px;
    //             position: absolute;
    //             top: calc(50% - 45.5px);
    //             left: calc(50% - 45.5px);
    //             opacity: 0.8;
    //           }
    //         }
    //         .cenTwoBackDivImgNext {
    //           margin-left: 238px;
    //         }
    //         #cenTwoBackDivOne {
    //           display: flex;
    //           flex-direction: column;
    //           justify-content: right;

    //           .cenDivTwoDivSpan {
    //             position: absolute;
    //             top: 0;
    //             left: 1050px;
    //             width: 66px;
    //             font-family: Arial, Helvetica, sans-serif;
    //             // font-weight: 500;
    //             color: rgba(134, 200, 236, 0.5);
    //             writing-mode: tb-rl;
    //             font-style: normal;
    //             font-weight: 400;
    //             font-size: 48px;
    //             line-height: 88px;
    //             text-align: justify;
    //             letter-spacing: 1.005em;
    //             color: rgba(134, 200, 236, 0.5);
    //             // margin-left: 328px;
    //           }
    //           .cenDivTwoDivJiLu {
    //             position: absolute;
    //             top: 161px;
    //             left: 933px;
    //             display: inline-block;
    //             width: 97px;
    //             font-family: Arial, Helvetica, sans-serif;
    //             // margin-left: 231px;
    //             // padding-top: 49px;
    //             font-style: normal;
    //             font-weight: 400;
    //             font-size: 96px;
    //             line-height: 176px;
    //             text-align: justify;
    //             letter-spacing: 0.485em;
    //             color: #48555a;
    //           }

    //           .cenDivTwoDivJiLu:after {
    //             display: inline-block;
    //             width: 100%;
    //             content: "";
    //           }
    //           .cenDivTwoDivRec {
    //             display: inline-block;
    //             width: 333px;
    //             height: 375px;
    //             background: rgba(219, 233, 246, 0.8);
    //             border-radius: 17px;
    //             content: "";
    //             position: absolute;
    //             top: -91px;
    //             left: 741px;
    //             z-index: -11;
    //           }
    //         }
    //         .cenTwoBackDivNext {
    //           .cenDivTwoDivSpan {
    //             top: 0;
    //             left: 0 !important;
    //             letter-spacing: 0.755em;
    //           }
    //           .cenDivTwoDivJiLu {
    //             top: 164px;
    //             left: 63px !important;
    //           }

    //           .cenDivTwoDivJiLu:after {
    //             display: inline-block;
    //             width: 100%;
    //             content: "";
    //           }
    //           .cenDivTwoDivRec {
    //             top: -91px;
    //             left: 18px !important;
    //           }
    //         }
    //       }
    //       .cenDivTwoDivContent {
    //         display: block;
    //         width: 1016px;
    //         font-size: 20px;
    //         font-family: Arial, Helvetica, sans-serif;
    //         font-weight: 300;
    //         color: #48555a;
    //         line-height: 37px;
    //         // letter-spacing: 3px;
    //         margin-left: 89px;
    //         margin-top: 50px;
    //         text-indent: 2em;
    //         text-align: justify;
    //       }
    //     }
    //     #cenTwoImg {
    //       position: absolute;
    //       top: -180px;
    //       left: 160px;
    //       height: 860px;
    //       z-index: -11;
    //     }
    //     .cenTwoImgNext {
    //       top: -180px;
    //       left: -122px !important;
    //     }
    //   }
    //   #cenTxtThree {
    //     width: 1152px;
    //     margin: 0 auto;
    //     position: relative;
    //     padding-bottom: 222px;
    //     img {
    //       width: 1152px;
    //       height: 412px;
    //     }
    //     span {
    //       display: inline-block;
    //       width: 775px;
    //       font-size: 20px;
    //       font-family: Arial, Helvetica, sans-serif;
    //       font-weight: 500;
    //       color: #ffffff;
    //       line-height: 46px;
    //       letter-spacing: 3px;
    //       position: absolute;
    //       top: 102px;
    //       left: 92px;
    //     }
    //   }
    //   #cenTxtFour {
    //     #cenTextFourBody {
    //       margin-bottom: 111px;
    //       .FourBodyDiv {
    //         width: 1152px;
    //         margin: 0 auto;
    //         display: flex;
    //         flex-direction: column;
    //         position: relative;

    //         .FourBody {
    //           display: flex;
    //           justify-content: space-between;
    //           margin-top: 186px;
    //           .FourBodyOne {
    //             .FourBodyOneTitle {
    //               font-size: 65px;
    //               font-family: Arial, Helvetica, sans-serif;
    //               font-weight: bold;
    //               color: #48555a;
    //               line-height: 119px;
    //               letter-spacing: 31px;
    //               padding-bottom: 64px;
    //             }
    //             .FourBodyOneSpan {
    //               font-size: 20px;
    //               font-family: Arial, Helvetica, sans-serif;
    //               font-weight: bold;
    //               color: #48555a;
    //               line-height: 29px;
    //               letter-spacing: 9px;
    //               margin-bottom: 48px;
    //             }
    //             .FourBodyOneEnglish {
    //               display: block;
    //               writing-mode: tb-rl;
    //               font-size: 24px;
    //               font-family: Arial, Helvetica, sans-serif;
    //               font-weight: 400;
    //               color: #dbe9f6;
    //               line-height: 44px;
    //               letter-spacing: 11px;
    //               margin-top: 114px;
    //             }
    //             .FourBodyOneEnglishFooter {
    //               position: absolute;
    //               height: 398px;
    //               margin-top: -125px;
    //               z-index: -10;
    //             }
    //           }
    //           .FourBodyOneLiNian {
    //             display: flex;
    //             flex-direction: column;
    //             justify-content: end;
    //             text-align: right;
    //             margin-right: 9px;
    //             margin-top: 101px;
    //             .FourBodyOneTitleLiNian {
    //               margin-right: -31px;
    //             }
    //             .FourBodyOneSpanLiNian {
    //               padding-right: 28px;
    //             }
    //             .FourBodyOneEnglishLiNian {
    //               width: 44px;
    //               margin-left: 320px;
    //               margin-right: -9px;
    //             }
    //           }
    //           .FourBodyTwo {
    //             display: flex;
    //             .FourBodyTwoImgOne {
    //               width: 312px;
    //               height: 624px;
    //               background: #dbe9f6;
    //               border-radius: 49px 49px 49px 49px;
    //             }
    //             .FourBodyTwoImgOneLiNian {
    //               margin-left: -208px;
    //             }
    //             .FourBodyTwoImgTwo {
    //               display: block;
    //               width: 574px;
    //               height: 482px;
    //               margin-left: -243px;
    //               z-index: -9;
    //               margin-top: 42px;
    //             }
    //             .FourBodyTwoImgTwoLiNian {
    //               margin-left: 0px;
    //               margin-top: 60px;
    //             }
    //             .FourBodyTwoImgTwoFooter {
    //               margin-top: 85px;
    //             }
    //           }
    //         }
    //         .posiImg {
    //           width: 752px;
    //           height: 305px;
    //           background: #dbe9f6;
    //           position: absolute;
    //           top: 590px;
    //           left: 146px;
    //           z-index: -11;
    //         }
    //         .posiImgLiNian {
    //           top: 590px;
    //           left: 270px;
    //         }
    //       }
    //       #FourBodyVideo {
    //         display: flex;
    //         width: 1150px;
    //         height: 464px;
    //         align-items: center;
    //         margin: 0 auto;
    //         margin-top: 306px;
    //         background: linear-gradient(
    //           90.36deg,
    //           rgba(238, 248, 255, 0.8) 0.35%,
    //           rgba(245, 255, 249, 0.8) 112.93%
    //         );
    //         border-radius: 12px;
    //         #FourBodyVideoImg {
    //           width: 483px;
    //           position: relative;
    //           border-radius: 10px 10px 0px 0px;
    //           margin-left: 26px;
    //           #FourctVideo {
    //             width: 483px;
    //             height: 322px;
    //             object-fit: cover;
    //             border-radius: 10px 10px 0px 0px;
    //           }
    //           #FourctImg {
    //             width: 42px;
    //             height: 42px;
    //             position: absolute;
    //             top: calc(50% - 21px);
    //             left: calc(50% - 21px);
    //             // margin-left: -32.5px;
    //             // margin-top: -32.5px;
    //           }
    //         }
    //         #FourBodyVideoSpan {
    //           width: 557px;
    //           font-style: normal;
    //           font-weight: 400;
    //           font-size: 20px;
    //           line-height: 37px;
    //           text-align: justify;
    //           letter-spacing: 0.17em;
    //           color: #48555a;
    //           margin-left: 45px;
    //           text-indent: 2em;
    //         }
    //       }
    //     }
    //   }
    //   #cenTxtFooter {
    //     // display: none;
    //     width: 1150px;
    //     height: 657px;
    //     margin: 0 auto;
    //     margin-top: 111px;
    //     padding-bottom: 73px;
    //     img {
    //       width: 1150px;
    //       height: 657px;
    //       margin: 0 auto;
    //     }
    //   }

    //   #ctOne {
    //     width: 100%;
    //     text-align: center;
    //     margin: 0 auto;
    //     .ctTit {
    //       font-size: 32px;
    //       font-weight: 300;
    //       color: #9297a3;
    //     }

    //     img {
    //       width: 100%;
    //       height: 184px;
    //       padding-top: 57px;
    //     }
    //   }

    //   .ctIvm {
    //     // border: 1px solid #000;

    //     width: 100%;
    //     margin: 0 auto;
    //     display: flex;
    //     align-items: center;
    //     position: relative;
    //     video {
    //       width: 909px;
    //       height: 509px;
    //     }

    //     #position {
    //       position: absolute;
    //       width: 439px;
    //       height: 135px;
    //       bottom: 0;
    //       right: 0px;
    //       opacity: 0;
    //       z-index: 10;
    //     }

    //     img {
    //       position: absolute;
    //       right: 909 * 0.5-65 * 0.5px;
    //       width: 66px;
    //       height: 66px;
    //       border-radius: 50%;
    //     }

    //     span {
    //       width: 40px;
    //       font-size: 32px;
    //       font-family: Arial, Helvetica, sans-serif;
    //       font-weight: 400;
    //       color: #4e4e4e;
    //       padding-left: 103px;
    //     }

    //     .ctXux {
    //       height: 321px;
    //       border-right: 1px #f08b5f solid;
    //       padding-left: 26px;
    //       margin-right: 129px;
    //     }
    //   }

    //   #ctWz {
    //     // border: 1px solid #000;
    //     width: 1202px;
    //     display: flex;
    //     // align-items: center;
    //     margin: -1px;
    //     margin-left: 20px;

    //     img {
    //       margin-top: -115px;
    //       width: 313px;
    //       height: 478px;
    //       z-index: 2;
    //     }

    //     span {
    //       // border: 1px solid #000;

    //       width: 646px;
    //       margin-top: 100px;
    //       margin-right: 208px;
    //       font-size: 18px;
    //       // font-family: PingFang SC
    //       font-weight: 400;
    //       color: #4e4e4e;
    //       text-align: justify;
    //       line-height: 30px;
    //       letter-spacing: 1px;
    //     }
    //   }

    //   #ctFot {
    //     // border: 1px solid #000;
    //     width: 1202px;
    //     display: flex;
    //     // align-items: center;
    //     flex-direction: column;
    //     // padding-left: 127px;
    //     margin-top: 29px;
    //     margin-bottom: 182px;

    //     box-sizing: border-box;
    //     #fotOne {
    //       display: flex;
    //       flex-direction: column;
    //       align-items: center;
    //       width: 887px;
    //       margin-left: 315px;

    //       span {
    //         display: block;
    //         width: 887px;
    //         // text-align: center;
    //         line-height: 30px;
    //         font-size: 18px;
    //         font-family: Arial, Helvetica, sans-serif;
    //         font-weight: 400;
    //         color: #4e4e4e;
    //         letter-spacing: 1px;
    //         margin-top: 55px;
    //         text-indent: 2em;
    //         text-align: justify;
    //         // &:first-child {
    //         //   width: 457px;
    //         //   margin: 0 auto;
    //         //   line-height: 48px;
    //         //   font-size: 28px;
    //         //   // font-family: PingFang SC
    //         //   font-weight: 400;
    //         //   color: #4e4e4e;
    //         //   // letter-spacing: 0;
    //         // }

    //         &:nth-child(2) {
    //           margin-top: 28px;
    //         }
    //         // &:nth-child(3) {
    //         //   padding-top: 66px;
    //         // }
    //         // &:nth-child(4) {
    //         //   padding-top: 66px;
    //         //   padding-bottom: 194px;
    //         // }
    //       }
    //     }
    //     #fotTwo {
    //       display: flex;
    //       align-items: center;
    //       img {
    //         width: 600px;
    //         height: 900px;
    //       }

    //       div {
    //         height: 321px;
    //         border-left: 1px #f08b5f solid;
    //         margin-left: 110px;
    //         margin-right: 25px;
    //       }

    //       span {
    //         width: 30px;
    //         font-size: 32px;
    //         line-height: 48px;
    //         // font-family: PingFang SC
    //         font-weight: 400;
    //         color: #4e4e4e;
    //         padding-right: 343px;
    //         z-index: 22;
    //       }
    //       #fotTwoSpan {
    //         display: flex;
    //         align-items: center;
    //         border: none;
    //         margin: 0;
    //         width: 91px;
    //         span {
    //           width: auto;
    //           font-size: 37px;
    //           font-family: Arial, Helvetica, sans-serif;
    //           font-weight: 500;
    //           color: #c7c7c7;
    //         }
    //         #fotTwoSpanOne {
    //           padding-right: 25px;
    //         }
    //         #fotTwoSpanTwo {
    //           padding-right: 0;
    //         }
    //       }
    //     }
    //   }

    //   #splGs {
    //     width: 100%;
    //     padding-top: 261px;
    //     #gsHed {
    //       width: 532px;
    //       margin: 0 auto;
    //       text-align: center;
    //       span {
    //         &:first-child {
    //           font-size: 80px;
    //           // font-family: PingFang SC
    //           font-weight: 400;
    //           color: #4e4e4e;
    //         }
    //       }

    //       #gsBk {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         padding-top: 21px;
    //         span {
    //           display: inline-block;
    //           font-size: 25px;
    //           // font-family: PingFang SC
    //           font-weight: 400;
    //           color: #9297a3;
    //           margin: 0 10px;

    //           &:first-child {
    //             width: 106px;
    //             height: 2px;
    //             border-bottom: 1px solid #f08b5f;
    //           }

    //           &:last-child {
    //             width: 106px;
    //             height: 2px;
    //             border-bottom: 1px solid #f08b5f;
    //           }
    //         }
    //       }
    //     }
    //     #gsUl {
    //       width: 100%;
    //       display: flex;
    //       justify-content: center;
    //       ul {
    //         padding: 100px 0;
    //         li {
    //           font-size: 25px;
    //           // font-family: PingFang SC
    //           font-weight: 400;
    //           padding-right: 50px;
    //           &:last-child {
    //             padding: 0;
    //           }
    //         }

    //         a {
    //           color: #9297a3;
    //         }
    //       }
    //     }

    //     #gsBoy {
    //       width: 1202px;
    //       margin: 0 auto;

    //       position: relative;

    //       .byUl {
    //         width: 100%;
    //         height: 1237px;
    //         display: flex;
    //         justify-content: space-between;
    //         flex-flow: wrap;
    //         margin: 0 auto;

    //         li {
    //           display: inline-block;
    //           width: 382px;
    //           height: 382px;
    //         }

    //         img {
    //           width: 382px;
    //           height: 382px;
    //         }
    //       }
    //       #fugai {
    //         width: 382px;
    //         height: 382px;
    //         background-color: #6e717a;
    //         opacity: 0.9;
    //         color: #ffffff;
    //         position: absolute;
    //         top: 50.1%;
    //         left: 50.6%;
    //         transform: translate(-52%, -50.5%);
    //         div {
    //           width: 100%;
    //           position: absolute;
    //           top: 50%;
    //           left: 50%;
    //           transform: translate(-50%, -50%);
    //           span {
    //             display: block;
    //             // font-family: PingFang SC
    //             font-weight: 400;
    //             color: #ffffff;
    //             text-align: center;
    //             &:first-child {
    //               font-size: 50px;
    //               padding: 30px;
    //             }

    //             &:last-child {
    //               font-size: 25px;
    //             }
    //           }
    //         }
    //       }
    //     }
    //     #gdHre {
    //       width: 100%;
    //       height: 242px;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       a {
    //         display: block;
    //         width: 197px;
    //         height: 67px;
    //         background: #5d8ddf;
    //         border-radius: 5px;
    //         text-align: center;
    //         line-height: 67px;
    //         font-size: 25px;
    //         // font-family: PingFang SC
    //         font-weight: 400;
    //         color: #ffffff;
    //       }
    //     }
    //     #adtTsgn {
    //       width: 100%;
    //       margin-top: 248px;
    //       .tsgnTit {
    //         width: 100%;
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: center;
    //         span {
    //           font-size: 36px;
    //           font-weight: bold;
    //           color: #363535;
    //           text-align: center;
    //           letter-spacing: 4px;
    //         }

    //         img {
    //           width: 100%;
    //           height: 1px;
    //           margin-top: -22px;
    //         }
    //         .imgText {
    //           display: flex;
    //           width: 100%;
    //           height: 22px;
    //           justify-content: center;
    //           color: #f08b5f;
    //           font-size: 26px;
    //           font-weight: 200;
    //           padding-top: 30px;
    //         }
    //       }
    //       .tsOne {
    //         width: 100%;
    //         display: flex;
    //         justify-items: start;
    //         // border: 1px solid #000;
    //         #tsTxt {
    //           // padding-left: 210px;
    //           div {
    //             display: flex;
    //             flex-direction: column;
    //             padding-top: 100px;
    //             img {
    //               width: 140px;
    //               height: 60px;
    //             }

    //             span {
    //               font-size: 30px;
    //               font-weight: 200;
    //               color: #f08b5f;
    //             }

    //             .tsTit {
    //               width: 189px;
    //               // letter-spacing: 28px;
    //               font-size: 72px;
    //               line-height: 117px;
    //               text-align: justify;
    //               // font-family: ShiShangZhongHeiJianTi;
    //               font-weight: 400;
    //               color: #414141;
    //               margin: 126px 0;
    //             }
    //           }
    //           .tsSpn {
    //             display: block;
    //             width: 522px;
    //             font-size: 24px;
    //             line-height: 38px;
    //             font-weight: 400;
    //             color: #5d5d5d;
    //             // padding-top: 64px;
    //             letter-spacing: 3px;
    //           }
    //         }
    //         #tsImg {
    //           // border: 1px solid #000;
    //           width: 648px;
    //           height: 593px;
    //           margin-left: 75px;
    //           margin-top: 235px;
    //         }
    //       }
    //     }
    //     #adtCp {
    //       width: 100%;
    //       margin: 185px 0;
    //       // border: 1px solid #000;
    //       margin-left: -139px;
    //       .cpOne {
    //         width: 100%;
    //         display: flex;
    //         justify-items: start;
    //         #cpImg {
    //           width: 736px;
    //           height: 768px;
    //           // margin-top: -20px;
    //         }

    //         #cpTxt {
    //           padding-left: 79px;
    //           div {
    //             width: 522px;
    //             display: flex;
    //             flex-direction: column;
    //             align-items: flex-end;
    //             // margin-left: 104px;
    //             img {
    //               width: 140px;
    //               height: 60px;
    //             }
    //             span {
    //               font-size: 30px;
    //               font-weight: 200;
    //               color: #f08b5f;
    //             }
    //             .tsTit {
    //               width: 200px;
    //               letter-spacing: 25px;
    //               font-size: 72px;
    //               font-family: ShiShangZhongHeiJianTi;
    //               font-weight: 400;
    //               color: #414141;
    //               text-align: right;
    //               margin: 121px 0;
    //               margin-right: -22px;
    //             }
    //           }
    //           .cpSpn {
    //             display: block;
    //             width: 522px;
    //             font-size: 24px;
    //             font-weight: 500;
    //             line-height: 38px;
    //             color: #5d5d5d;
    //             letter-spacing: 3px;
    //           }
    //         }
    //       }
    //     }
    //     // #adtJz {
    //     //   width: 100%;
    //     //   padding-top: 285px;
    //     //   padding-bottom: 195px;
    //     //   .jzOne {
    //     //     width: 100%;
    //     //     display: flex;
    //     //     justify-items: start;
    //     //     #jzTxt {
    //     //       padding-left: 210px;
    //     //       div {
    //     //         display: flex;
    //     //         padding-top: 100px;
    //     //         flex-direction: column;
    //     //         img {
    //     //           width: 140px;
    //     //           height: 60px;
    //     //         }
    //     //         span {
    //     //           font-size: 30px;
    //     //           font-weight: 200;
    //     //           color: #f08b5f;
    //     //         }

    //     //         .tsTit {
    //     //           width: 200px;
    //     //           letter-spacing: 25px;
    //     //           font-size: 72px;
    //     //           font-family: ShiShangZhongHeiJianTi;
    //     //           font-weight: 400;
    //     //           color: #414141;
    //     //           padding-top: 200px;
    //     //         }
    //     //       }
    //     //       .jzSpn {
    //     //         display: block;
    //     //         width: 624px;
    //     //         font-size: 24px;
    //     //         font-weight: 500;
    //     //         color: #5d5d5d;
    //     //         padding-top: 30px;
    //     //         letter-spacing: 3px;
    //     //       }
    //     //     }
    //     //     #jzImg {
    //     //       width: 1033px;
    //     //       height: 1077px;
    //     //       margin-left: -130px;
    //     //       margin-top: -20px;
    //     //     }
    //     //   }
    //     // }
    //   }
    // }
  }
}

@media only all and (max-width: 750px) {
  .cenTextOneTit {
    width: 216px;
    height: 59px;
    // display: flex;
    // flex-direction: column;
    position: relative;
    margin: 0 auto;
    margin-top: 39px;
    margin-bottom: 37px;
    .oneText {
      position: absolute;
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 59px;
      text-align: justify;
      letter-spacing: 0.485em;
      color: rgba(135, 181, 224, 0.3);
    }
    .oneContent {
      position: absolute;
      left: 68px;
      font-weight: 600;
      font-size: 36px;
      line-height: 59px;
      text-align: justify;
      letter-spacing: 0.485em;
      color: #48555a;
    }
    .cenTextOneTitLine {
      width: 107px;
      height: 0px;
      opacity: 1;
      border-bottom: 1px solid #48555a;
      position: absolute;
      bottom: 0;
      left: 88px;
      height: 1px;
      background: #000;
    }
    .cenTextTwoTitLine {
      width: 107px;
      height: 0px;
      opacity: 1;
      border-bottom: 1px solid #48555a;
      position: absolute;
      bottom: -9px;
      left: 31px;
      height: 1px;
      background: #000;
    }
  }
  #phoneCendiv {
    display: block;
    width: 750px;
    margin-top: 90px;
    .phoneSearch {
      width: 702px;
      // height: 51px;
      margin: 0 auto;
      // margin: 10px auto;
      // margin-bottom: 27px;
      // .phSearch {
      //   display: flex;
      //   align-items: center;
      //   position: relative;
      //   img {
      //     width: 38px;
      //     height: 38px;
      //     position: absolute;
      //     left: 13px;
      //     top: 7px;
      //   }

      //   input {
      //     width: 570px;
      //     height: 45px;
      //     // border: none;
      //     border: 2px solid #5c8dde;
      //     border-bottom-left-radius: 100px;
      //     border-top-left-radius: 100px;
      //     outline: none;
      //     padding-left: 80px;
      //     font-size: 18px;
      //     opacity: 0.8;
      //   }

      //   button {
      //     width: 133px;
      //     height: 53px;
      //     border: none;
      //     border-bottom-right-radius: 100px;
      //     border-top-right-radius: 100px;
      //     // position: absolute;
      //     // top: 0;
      //     background-color: #5c8dde;
      //     color: #ffffff;
      //     font-size: 18px;
      //     padding: 0;
      //     text-indent: -8px;
      //   }
      // }
    }
    .phoneImg {
      position: relative;
      width: 750px;
      height: 422px;

      #phoneIconVideo {
        position: absolute;
        top: 20px;
        right: 40px;
        width: 40px;
        height: 40px;
        &:hover {
          cursor: pointer;
        }
      }

      video {
        display: block;
        margin: 0 auto;
        object-fit: fill;
        width: 750px;
        height: 422px;
      }
    }
    #phoneCenTxt {
      width: 702px;
      margin: 0 auto;
      margin-top: 116px;
      #phoneCenTxtOne {
        .phoneCenTxtBrief {
          margin-bottom: 184px;
          .phoneCenTxtBriefTitle {
            color: #000;
            font-size: 40px;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 500;
            line-height: 40px;
            letter-spacing: 6.8px;
            text-align: center;
          }
          .phoneTimeCentent {
            display: flex;
            justify-content: space-between;
            margin-top: 117px;
            padding: 0 26px;
            .phoneTimeOne {
              color: #2d2d2d;
              text-align: justify;
              font-size: 60px;
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 500;
              letter-spacing: 10.2px;
            }
            .phoneTimeTwo {
              .phoneTimeTwoText {
                color: #2d2d2d;
                text-align: justify;
                font-size: 26px;
                font-family: "PingFang SC";
                font-style: normal;
                font-weight: 500;
                letter-spacing: 7.8px;
              }
              .phoneTimeThreeText {
                letter-spacing: 7.7px;
              }
              .phoneTimeTwoImg {
                margin-top: 22px;
                // display: flex;
                // // justify-content: center;
                // align-items: center;
                // img {
                //   width: 35px;
                //   height: 35px;
                //   margin-right: 19px;
                // }
                span {
                  display: inline-block;
                  width: 233px;
                  line-height: 48px;
                  background: url(../assets/img/LoginBf.svg);
                  background-position: center left;
                  background-repeat: no-repeat;
                  background-size: 35px;
                  padding-left: 54px;
                  color: #56bd88;
                  text-align: justify;
                  font-size: 26px;
                  font-family: " PingFang SC";
                  font-style: normal;
                  font-weight: 500;
                  letter-spacing: 4.42px;
                }
              }
            }
          }
          .phoneTimeFooter {
            width: 702px;
            height: 588px;
          }
          .videoPhone {
            margin-top: 30px;
            img {
              width: 60px;
              height: 60px;
              float: right;
              margin-right: 24px;
            }
            video {
              width: 702px;
              height: 800px;
              // border: 1px solid red;
              border-radius: 20px;
              // object-fit: fill;
            }
          }
        }
      }
      #phoneCenTxtTwo {
        margin-bottom: 184px;
        .phoneCenTxtBriefTitle {
          color: #000;
          text-align: center;
          font-size: 40px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 500;
          letter-spacing: 6.8px;
          margin-bottom: 50px;
        }
        .phoneCenTxtTwoBackground {
          width: 702px;
          height: 386px;
          border-radius: 20px;
          background: #2d2d2d;
          position: relative;
          .phoneCenTxtTwoVideo {
            width: 686px;
            height: 370px;
            object-fit: fill;
            border-radius: 20px;
            margin-top: 8px;
            margin-left: 8px;
          }
          .phoneCenTxtTwoImg {
            position: absolute;
            width: 120px;
            height: 120px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .phoneCenTxtTwoSpan {
          color: #48555a;
          text-align: justify;
          font-size: 28px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          letter-spacing: 4.76px;
          margin-top: 30px;
        }
      }
      #phoneCenTxtThree {
        margin-bottom: 184px;
        .phoneCenTxtBriefTitle {
          color: #000;
          text-align: center;
          font-size: 40px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 500;
          letter-spacing: 6.8px;
        }
        .phoneCenTxtThreeVideo {
          margin-top: 50px;
          .phoneCenTxtThreeVideoOne {
            width: 702px;
            height: 472px;
            margin-bottom: 30px;
            position: relative;
            video {
              width: 702px;
              height: 472px;
              object-fit: fill;
              border-radius: 20px;
            }
            .phoneCenTxtThreeVideoOneImg {
              position: absolute;
              width: 120px;
              height: 120px;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .phoneCenTxtThreeVideoTwo {
            width: 702px;
            height: 472px;
            position: relative;
            video {
              width: 702px;
              height: 472px;
              object-fit: fill;
              border-radius: 20px;
            }
            .phoneCenTxtThreeVideoTwoImg {
              position: absolute;
              width: 120px;
              height: 120px;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        .phoneCenTxtThreeSpan {
          margin-top: 30px;
          color: #48555a;
          text-align: justify;
          font-size: 28px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          letter-spacing: 4.76px;
        }
      }
      #huangWeiPhone {
        display: block;
        width: 702px;
        margin: 0 auto;
        // padding-bottom: 216px;
        margin-bottom: 152px;
        #haungWeiTitle {
          color: #222;
          text-align: justify;
          font-family: "PingFang SC";
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 53px;
          letter-spacing: 3.2px;
          margin: 0;
        }
        .huangWeiImgOne {
          display: block;
          width: 511px;
          height: 84px;
          margin: 53px auto;
          margin-bottom: 23px;
        }
        #haungWeiH5 {
          color: #48555a;
          text-align: center;
          font-family: "PingFang SC";
          font-size: 36.639px;
          font-style: normal;
          font-weight: 400;
          line-height: 67px; /* 67.05px */
          letter-spacing: 7.328px;
          margin: 0;
        }
        .huangWeiImgTwo {
          width: 702px;
          height: 743px;
          margin-top: 28px;
          margin-bottom: 70px;
        }
        .huangWeiImgThree {
          width: 702px;
          height: 675px;
          margin-bottom: 70px;
        }
        .huangWeiImgFour {
          width: 702px;
          height: 702px;
        }
      }
      #phoneCenTxtFour {
        display: flex;
        width: 702px;
        height: 614px;
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 0px 8px 0px #b6e2ff;
        margin-bottom: 152px;
        overflow: hidden;
        .phoneCenTxtFourClass {
          align-items: flex-start;
          padding-top: 91px;
          padding-left: 16px;
          animation: moveReview 10s linear infinite;
          li {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // align-items: center;
            height: 425px;
            margin-right: 80px;
            img {
            }
            &:first-child {
              img {
                width: 162px;
              }
            }
            &:nth-child(2) {
              height: 434px;
              margin-top: -5px;
              // justify-content:flex-start;
              img {
                width: 136px;
                &:nth-child(2) {
                  margin-top: -12px;
                }
              }
            }
            &:nth-child(3) {
              height: 440px;
              img {
                width: 212px;
                &:nth-child(3) {
                  margin-top: 12px;
                }
              }
            }
            &:nth-child(4) {
              height: 440px;
              img {
                width: 128px;
              }
            }
            &:last-child {
              height: 473px;
              margin-top: -26px;
              img {
                width: 84px;
              }
            }
          }
        }
      }
      // #phoneCenTxtOne {
      //   width: 750px;
      //   position: relative;
      //   margin-bottom: 80px;
      //   #phoneCenTextOneBack {
      //     width: 100%;
      //     margin: 0 auto;
      //     .phoneCenTextOneTit {
      //       width: 216px;
      //       height: 59px;
      //       // display: flex;
      //       // flex-direction: column;
      //       position: relative;
      //       margin: 0 auto;
      //       margin-top: 39px;
      //       margin-bottom: 37px;

      //       .phoneOneText {
      //         position: absolute;
      //         font-style: normal;
      //         font-weight: 400;
      //         font-size: 32px;
      //         line-height: 59px;
      //         text-align: justify;
      //         letter-spacing: 0.485em;
      //         color: rgba(135, 181, 224, 0.3);
      //       }
      //       .phoneOneContent {
      //         position: absolute;
      //         left: 68px;
      //         font-weight: 600;
      //         font-size: 36px;
      //         line-height: 59px;
      //         text-align: justify;
      //         letter-spacing: 0.485em;
      //         color: #48555a;
      //       }
      //       .phoneCenTextOneTitLine {
      //         width: 107px;
      //         height: 0px;
      //         opacity: 1;
      //         border-bottom: 1px solid #48555a;
      //         position: absolute;
      //         bottom: 0;
      //         left: 88px;
      //         height: 1px;
      //         background: #000;
      //       }
      //       .phoneCenTextTwoTitLine {
      //         width: 107px;
      //         height: 0px;
      //         opacity: 1;
      //         border-bottom: 1px solid #48555a;
      //         position: absolute;
      //         bottom: -9px;
      //         left: 31px;
      //         height: 1px;
      //         background: #000;
      //       }
      //     }
      //     #phoneCenTextTitle {
      //       // width: 500px;
      //       margin: 0 auto;
      //       font-family: Arial, Helvetica, sans-serif;
      //       text-align: center;
      //       font-weight: 400;
      //       font-size: 28px;
      //       line-height: 40px;
      //       letter-spacing: 1px;
      //       color: #48555a;
      //       margin-top: 66px;
      //     }
      //     #phoneCenDivTwo {
      //       display: flex;
      //       flex-direction: row;
      //       margin-top: 23px;
      //       #phoneCenDivTwoOne {
      //         margin-top: 107px;
      //         .phoneCenDivTwoSpan {
      //           width: 66px;
      //           font-size: 36px;
      //           font-family: Arial, Helvetica, sans-serif;
      //           // font-weight: 500;
      //           color: rgba(134, 200, 236, 0.5);
      //           letter-spacing: 33px;
      //           writing-mode: tb-rl;
      //           margin-left: 20px;
      //         }
      //         .phoneCenDivTwoJiLu {
      //           display: block;
      //           font-size: 65px;
      //           font-family: Arial, Helvetica, sans-serif;
      //           font-weight: 400;
      //           color: #48555a;
      //           line-height: 119px;
      //           letter-spacing: 31px;
      //           margin-top: -160px;
      //           margin-left: 16px;
      //         }
      //         .phoneCenDivTwoRec {
      //           display: inline-block;
      //           font-size: 56px;
      //           font-family: Arial, Helvetica, sans-serif;
      //           font-weight: 500;
      //           color: #48555a;
      //           line-height: 103px;
      //           letter-spacing: 12px;
      //           border-bottom: 1px solid #000;
      //           margin-left: 16px;
      //         }
      //         .phoneCenDivTwoContent {
      //           display: block;
      //           // width: 750px;
      //           width: 702px;
      //           font-size: 28px;
      //           font-family: Arial, Helvetica, sans-serif;
      //           font-weight: 400;
      //           color: #48555a;
      //           line-height: 42px;
      //           letter-spacing: 0.17em;
      //           // margin-left: 398px;
      //           margin-top: 23px;
      //           text-indent: 2em;

      //           // text-align: justify;
      //           margin-left: 24px;
      //           margin-right: 24px;
      //           margin-bottom: 32px;
      //           // text-align: justify;
      //         }
      //       }
      //       img {
      //         width: 598px;
      //         height: 399px;
      //         // margin-top: 107px;
      //         margin-left: -630px;
      //       }
      //     }
      //   }
      //   #phoneCenTextImg {
      //     position: absolute;
      //     left: 0px;
      //     top: 500px;
      //     // top: 750px;
      //     // left: -350px;
      //     // z-index: -11;
      //     img {
      //       width: 750px;
      //       height: 576px;
      //     }
      //   }
      // }
      // .phoneCenTxtTwo {
      //   width: 750px;
      //   margin: 0 auto;
      //   position: relative;
      //   padding-bottom: 189px;
      //   .phoneCenTextTwoBack {
      //     width: 100%;
      //     margin: 0 auto;

      //     // display: flex;
      //     // flex-direction: column;
      //     // justify-content: center;
      //     .phoneCenTwoBackDiv {
      //       display: flex;
      //       flex-direction: row;
      //       width: 702px;
      //       margin: 0 auto;
      //       .phoneCenTwoBackDivImg {
      //         width: 612px;
      //         position: relative;
      //         height: 408px;
      //         #ctVideo {
      //           width: 612px;
      //           height: 408px;
      //           object-fit: cover;
      //         }
      //         #ctImg {
      //           width: 69px;
      //           height: 69px;
      //           position: absolute;
      //           top: calc(50% - 34.5px);
      //           left: calc(50% - 34.5px);
      //         }
      //         #ctVideoNext {
      //           width: 612px;
      //           height: 408px;
      //           object-fit: cover;
      //         }
      //         #ctImgNext {
      //           width: 69px;
      //           height: 69px;
      //           position: absolute;
      //           top: calc(50% - 34.5px);
      //           left: calc(50% - 34.5px);
      //         }
      //       }

      //       .phoneCenTwoBackDivOne {
      //         display: flex;
      //         flex-direction: column;
      //         justify-content: right;
      //         position: relative;
      //         width: 100%;
      //         .phoneCenDivTwoDivSpan {
      //           position: absolute;
      //           top: -39px;
      //           right: 0px;
      //           width: 66px;
      //           font-size: 48px;
      //           line-height: 88px;
      //           letter-spacing: 0.4em;
      //           font-family: Arial, Helvetica, sans-serif;
      //           // font-weight: 500;
      //           color: rgba(134, 200, 236, 0.5);
      //           // letter-spacing: 33px;
      //           writing-mode: tb-rl;
      //           // margin-left: 328px;
      //         }
      //         .phoneCenDivTwoDivJiLu {
      //           position: absolute;
      //           top: 112px;
      //           left: 24px;
      //           // display: inline-block;
      //           width: 66px;
      //           font-size: 65px;
      //           line-height: 119px;
      //           letter-spacing: 0.485em;
      //           font-family: Arial, Helvetica, sans-serif;
      //           font-weight: 400;
      //           color: #48555a;
      //           // margin-top: 190px;
      //         }
      //         .phoneCenDivTwoDivJiLu:after {
      //           display: inline-block;
      //           width: 100%;
      //           content: "";
      //         }
      //         .phoneCenDivTwoDivRec {
      //           width: 244px;
      //           height: 275px;

      //           background: rgba(219, 233, 246, 0.8);
      //           border-radius: 17px;
      //           position: absolute;
      //           top: -96px;
      //           right: -24px;
      //           z-index: -11;
      //           // display: inline-block;
      //           // font-size: 56px;
      //           // font-family: Arial, Helvetica, sans-serif;
      //           // font-weight: 500;
      //           // color: #48555a;
      //           // line-height: 103px;
      //           // letter-spacing: 12px;
      //           // border-bottom: 1px solid #000;
      //           // margin-top: -31px;
      //           // margin-left: -260px;
      //           // width: 384px;
      //           // text-align: right;
      //         }
      //       }
      //       .phoneCenTwoBackDivOneNext {
      //         .phoneCenDivTwoDivSpan {
      //           left: 0px;
      //         }
      //         .phoneCenDivTwoDivJiLu {
      //           left: 0px !important;
      //         }
      //         .phoneCenDivTwoDivRec {
      //           left: -24px;
      //         }
      //       }
      //     }
      //     .phoneCenDivTwoDivContent {
      //       display: block;
      //       // width: 100%;
      //       font-size: 28px;
      //       font-family: Arial, Helvetica, sans-serif;
      //       font-weight: 400;
      //       color: #48555a;
      //       line-height: 42px;
      //       letter-spacing: 0.17em;
      //       // margin-left: 398px;
      //       margin-top: 38px;
      //       text-indent: 2em;

      //       text-align: justify;
      //       margin-left: 24px;
      //       margin-right: 24px;
      //     }
      //   }
      //   .phoneCenTwoImg {
      //     position: absolute;
      //     top: 200px;
      //     left: -90px;
      //     height: 860px;
      //     z-index: -11;
      //     img {
      //       width: 750px;
      //     }
      //   }
      // }
      // #phoneCenTxtThree {
      //   width: 100%;
      //   margin: 0 auto;
      //   position: relative;
      //   padding-bottom: 222px;
      //   img {
      //     width: 100%;
      //     height: 568px;
      //   }
      //   span {
      //     display: inline-block;
      //     width: 706px;
      //     font-family: Arial, Helvetica, sans-serif;
      //     color: #ffffff;
      //     line-height: 46px;
      //     letter-spacing: 3px;
      //     font-style: normal;
      //     font-weight: 400;
      //     font-size: 28px;
      //     letter-spacing: 0.095em;
      //     opacity: 0.8;
      //     position: absolute;
      //     top: calc(50% - 284px);
      //     left: 17px;
      //   }
      // }
      // #cenTxtFour {
      //   padding-bottom: 220px;

      //   #cenTextFourBody {
      //     .FourBodyDiv {
      //       width: 100%;
      //       margin: 0 auto;
      //       display: flex;
      //       flex-direction: column;
      //       position: relative;

      //       .FourBody {
      //         display: flex;
      //         justify-content: space-between;
      //         margin-top: 124px;
      //         position: relative;
      //         .FourBodyOne {
      //           margin-left: 24px;
      //           .FourBodyOneTitle {
      //             font-size: 65px;
      //             font-family: Arial, Helvetica, sans-serif;
      //             font-weight: bold;
      //             color: #48555a;
      //             line-height: 119px;
      //             letter-spacing: 31px;
      //             padding-bottom: 64px;
      //           }
      //           .FourBodyOneSpan {
      //             font-size: 28px;
      //             font-family: Arial, Helvetica, sans-serif;
      //             font-weight: bold;
      //             color: #48555a;
      //             line-height: 29px;
      //             letter-spacing: 6px;
      //             margin-bottom: 48px;
      //             opacity: 0.8;
      //           }
      //           .FourBodyOneEnglish {
      //             display: none;
      //             // writing-mode: tb-rl;
      //             // font-size: 24px;
      //             // font-family: Arial, Helvetica, sans-serif;
      //             // font-weight: 400;
      //             // color: #dbe9f6;
      //             // line-height: 44px;
      //             // letter-spacing: 11px;
      //             // margin-top: 114px;
      //           }
      //           .FourBodyOneEnglishFooter {
      //             position: absolute;
      //             height: 398px;
      //             margin-top: -125px;
      //             z-index: -10;
      //           }
      //         }
      //         .FourBodyOneLiNian {
      //           display: flex;
      //           flex-direction: column;
      //           justify-content: end;
      //           text-align: right;
      //           margin-right: 24px;
      //           margin-top: 101px;
      //           .FourBodyOneTitleLiNian {
      //             margin-right: -31px;
      //           }
      //           // .FourBodyOneSpanLiNian {
      //           //   padding-right: 28px;
      //           // }
      //           .FourBodyOneEnglishLiNian {
      //             width: 44px;
      //             margin-left: 320px;
      //             margin-right: -9px;
      //           }
      //         }
      //         .FourBodyTwo {
      //           display: flex;
      //           .FourBodyTwoImgOne {
      //             position: absolute;
      //             top: 110px;
      //             right: 24px;
      //             width: 306px;
      //             height: 623px;
      //             background: #dbe9f6;
      //             border-radius: 49px 49px 49px 49px;
      //           }
      //           .FourBodyTwoImgOneFooter {
      //             // left: 361px;
      //           }
      //           .FourBodyTwoImgOneLiNian {
      //             left: 24px;
      //             margin-left: 24;
      //             top: 200px;
      //           }
      //           .FourBodyTwoImgTwo {
      //             display: none;
      //             // width: 574px;
      //             // height: 482px;
      //             // margin-left: -243px;
      //             // z-index: -9;
      //             // margin-top: 42px;
      //           }
      //           .FourBodyTwoImgTwoLiNian {
      //             margin-left: 0px;
      //             margin-top: 60px;
      //           }
      //           .FourBodyTwoImgTwoFooter {
      //             margin-top: 85px;
      //           }
      //         }
      //       }
      //       .posiImg {
      //         width: 752px;
      //         height: 305px;
      //         background: #dbe9f6;
      //         // position: absolute;
      //         // top: 590px;
      //         // left: 146px;
      //         z-index: -11;
      //       }
      //       .posiImgLiNian {
      //         margin-top: 170px;
      //         top: 590px;
      //         left: 270px;
      //       }
      //     }
      //     #FourBodyVideo {
      //       position: relative;
      //       display: flex;
      //       width: 726px;
      //       height: 606px;
      //       // align-items: center;
      //       // margin: 0 auto;
      //       margin-top: 306px;
      //       margin-bottom: 306px;
      //       background: linear-gradient(
      //         90.36deg,
      //         rgba(238, 248, 255, 0.8) 0.35%,
      //         rgba(245, 255, 249, 0.8) 112.93%
      //       );
      //       border-radius: 12px;
      //       #FourBodyVideoImg {
      //         width: 592px;
      //         height: 394px;
      //         position: relative;
      //         border-radius: 10px 10px 0px 0px;
      //         margin-left: 24px;
      //         margin-top: 44px;
      //         #FourctVideo {
      //           width: 592px;
      //           height: 394px;
      //           object-fit: cover;
      //           border-radius: 10px 10px 0px 0px;
      //         }
      //         #FourctImg {
      //           width: 52px;
      //           height: 52px;
      //           position: absolute;
      //           top: calc(50% - 26px);
      //           left: calc(50% - 26px);
      //         }
      //       }
      //       #FourBodyVideoSpan {
      //         position: absolute;
      //         top: 480px;
      //         left: 113px;
      //         width: 592px;
      //         font-style: normal;
      //         font-weight: 400;
      //         font-size: 28px;
      //         line-height: 51px;
      //         letter-spacing: 0.17em;
      //         color: #48555a;
      //         text-indent: 2em;
      //       }
      //     }
      //   }
      // }

      // .txtOne {
      //   width: 702px;
      //   margin: 64px auto;
      //   margin-bottom: 44px;
      //   position: relative;
      //   .txtGush {
      //     img {
      //       width: 702px;
      //       height: 134px;
      //     }
      //   }
      //   .txtSpan {
      //     position: absolute;
      //     width: 625px;
      //     top: 30px;
      //     left: 50%;
      //     transform: translateX(-50%);
      //     font-size: 25px;
      //     font-family: PingFang SC;
      //     font-weight: 300;
      //     color: #9297a3;
      //   }
      // }
      // .txtTwo {
      //   display: flex;
      //   align-items: center;
      //   width: 702px;
      //   margin: 0 auto;
      //   margin-bottom: 174px;
      //   .phoTxtDIv {
      //     .txtIvm {
      //       position: relative;
      //       width: 590px;
      //       height: 330px;

      //       video {
      //         width: 590px;
      //         height: 330px;
      //       }
      //       img {
      //         position: absolute;
      //         width: 42px;
      //         height: 42px;
      //         left: 50%;
      //         top: 50%;
      //         transform: translate(-50%, -50%);
      //       }
      //     }
      //     .phoSpn {
      //       width: 590px;
      //       font-size: 26px;
      //       font-family: PingFang SC;
      //       font-weight: 400;
      //       color: #4e4e4e;
      //       line-height: 42px;
      //       letter-spacing: 1px;
      //       text-align: justify;
      //       margin-top: 46px;
      //     }
      //   }
      //   span {
      //     width: 30px;
      //     font-size: 32px;
      //     font-family: PingFang SC;
      //     font-weight: 400;
      //     color: #4e4e4e;
      //     line-height: 48px;
      //     margin-left: 52px;
      //     margin-right: 27px;
      //   }
      //   .txtXux {
      //     width: 1px;
      //     height: 321px;
      //     background: #f08b5f;
      //   }
      // }
      // .txtThree {
      //   .threeOne {
      //     display: flex;
      //     align-items: center;
      //     width: 702px;
      //     margin: 0 auto;
      //     margin-bottom: 56px;
      //     .threeLeft {
      //       width: 25px;
      //       font-size: 26px;
      //       font-family: PingFang SC;
      //       font-weight: 300;
      //       color: #9297a3;
      //       line-height: 25px;
      //       text-align: center;
      //       margin-right: 94px;
      //     }
      //     .threeBor {
      //       width: 1px;
      //       height: 321px;
      //       background: #f08b5f;
      //     }
      //     .threeRight {
      //       width: 30px;
      //       font-size: 32px;
      //       font-family: PingFang SC;
      //       font-weight: 400;
      //       color: #4e4e4e;
      //       line-height: 48px;
      //       margin-left: 26px;
      //       margin-right: 138px;
      //     }
      //     img {
      //       width: 388px;
      //       height: 713px;
      //     }
      //   }
      //   .threeTwo {
      //     display: flex;
      //     flex-direction: column;
      //     width: 702px;
      //     margin: 0 auto;
      //     span {
      //       text-align: center;
      //       font-size: 26px;
      //       font-family: PingFang SC;
      //       font-weight: 400;
      //       color: #4e4e4e;
      //       line-height: 42px;
      //       margin-bottom: 82px;
      //     }
      //   }
      // }

      // #phoSplGs {
      //   width: 702px;
      //   padding-top: 180px;
      //   margin: 0 auto;
      //   #phogsHed {
      //     margin: 0 auto;
      //     text-align: center;
      //     span {
      //       &:first-child {
      //         font-size: 58px;
      //         font-family: PingFang SC;
      //         font-weight: 400;
      //         color: #4e4e4e;
      //         line-height: 84px;
      //       }
      //     }

      //     #phogsBk {
      //       display: flex;
      //       justify-content: center;
      //       align-items: center;
      //       padding-top: 24px;
      //       span {
      //         display: inline-block;
      //         font-size: 25px;
      //         // font-family: PingFang SC
      //         font-weight: 400;
      //         color: #9297a3;
      //         margin: 0 10px;

      //         &:first-child {
      //           width: 106px;
      //           height: 2px;
      //           border-bottom: 1px solid #f08b5f;
      //         }

      //         &:last-child {
      //           width: 106px;
      //           height: 2px;
      //           border-bottom: 1px solid #f08b5f;
      //         }
      //       }
      //     }
      //   }
      //   #phogsUl {
      //     width: 100%;
      //     display: flex;
      //     justify-content: center;
      //     ul {
      //       padding: 50px 0;
      //       li {
      //         font-size: 24px;
      //         font-family: PingFang SC;
      //         font-weight: 400;
      //         color: #9297a3;
      //         padding-right: 50px;
      //         &:last-child {
      //           padding: 0;
      //         }
      //       }

      //       a {
      //         color: #9297a3;
      //       }
      //     }
      //   }

      //   #phogsBoy {
      //     width: 100%;
      //     margin: 0 auto;
      //     position: relative;

      //     .byUl {
      //       width: 100%;
      //       height: 710px;
      //       display: flex;
      //       justify-content: space-between;
      //       flex-flow: wrap;
      //       margin: 0 auto;

      //       li {
      //         display: inline-block;
      //         width: 223px;
      //         height: 223px;
      //       }

      //       img {
      //         width: 223px;
      //         height: 223px;
      //       }
      //     }
      //     #phofugai {
      //       width: 223px;
      //       height: 223px;
      //       background-color: #6e717a;
      //       opacity: 0.9;
      //       color: #ffffff;
      //       position: absolute;
      //       top: 50.1%;
      //       left: 50.6%;
      //       transform: translate(-52%, -50.5%);
      //       div {
      //         width: 100%;
      //         position: absolute;
      //         top: 50%;
      //         left: 50%;
      //         transform: translate(-50%, -50%);
      //         span {
      //           display: block;
      //           font-weight: 400;
      //           color: #ffffff;
      //           text-align: center;
      //           &:first-child {
      //             font-size: 27px;
      //             font-family: PingFang SC;
      //             font-weight: 400;
      //             padding: 30px;
      //           }

      //           &:last-child {
      //             font-size: 13px;
      //             font-family: PingFang SC;
      //             font-weight: 400;
      //           }
      //         }
      //       }
      //     }
      //   }

      //   #phoadtTsgn {
      //     width: 100%;
      //     margin-top: 248px;
      //     .tsgnTit {
      //       width: 100%;
      //       display: flex;
      //       flex-direction: column;
      //       justify-content: center;
      //       span {
      //         font-size: 32px;
      //         font-family: PingFang SC;
      //         font-weight: bold;
      //         color: #363535;
      //         text-align: center;
      //         letter-spacing: 4px;
      //       }

      //       img {
      //         width: 100%;
      //         height: 1px;
      //         margin-top: -22px;
      //       }
      //       .imgText {
      //         display: flex;
      //         width: 100%;
      //         justify-content: center;
      //         font-size: 26px;
      //         font-family: PingFang SC;
      //         font-weight: 400;
      //         color: #ef9066;
      //         padding-top: 30px;
      //       }
      //     }
      //     .tsOne {
      //       width: 100%;

      //       justify-items: start;
      //       // border: 1px solid #000;
      //       #photsTxt {
      //         // padding-left: 210px;
      //         display: flex;
      //         flex-direction: row;
      //         div {
      //           display: flex;
      //           flex-direction: column;
      //           padding-top: 100px;
      //           img {
      //             width: 144px;
      //             height: 62px;
      //           }

      //           span {
      //             font-size: 30px;
      //             font-family: PingFang SC;
      //             font-weight: 200;
      //             color: #f08b5f;
      //             // line-height: 65px;
      //           }

      //           .tsTit {
      //             width: 159px;
      //             // letter-spacing: 28px;

      //             text-align: justify;
      //             font-size: 60px;
      //             font-weight: 400;
      //             color: #414141;
      //             line-height: 117px;
      //             margin: 126px 0;
      //             margin-bottom: 50px;
      //           }
      //         }
      //         #photsImg {
      //           // border: 1px solid #000;
      //           width: 445px;
      //           height: 413px;
      //           margin-left: 75px;
      //           margin-top: 130px;
      //         }
      //       }
      //       .tsSpn {
      //         display: block;
      //         // width: 522px;
      //         font-size: 26px;
      //         line-height: 42px;
      //         font-weight: 400;
      //         color: #5d5d5d;
      //         // padding-top: 64px;
      //         letter-spacing: 3px;
      //       }
      //     }
      //   }
      //   #phoadtCp {
      //     width: 702px;
      //     margin: 185px auto;
      //     // border: 1px solid #000;
      //     .cpOne {
      //       width: 100%;

      //       #phocpTxt {
      //         display: flex;
      //         justify-items: start;
      //         // padding-left: 79px;

      //         #phocpImg {
      //           width: 445px;
      //           height: 413px;
      //           // margin-top: -20px;
      //         }
      //         div {
      //           // width: 522px;
      //           display: flex;
      //           flex-direction: column;
      //           align-items: flex-end;
      //           // margin-left: 104px;
      //           img {
      //             width: 140px;
      //             height: 60px;
      //           }
      //           span {
      //             font-size: 30px;
      //             font-weight: 200;
      //             color: #f08b5f;
      //           }
      //           .tsTit {
      //             width: 200px;
      //             letter-spacing: 25px;
      //             font-size: 60px;
      //             font-family: ShiShangZhongHeiJianTi;
      //             font-weight: 400;
      //             line-height: 117px;
      //             color: #414141;
      //             text-align: right;
      //             margin: 121px 0;
      //             margin-right: -25px;
      //             margin-bottom: 66px;
      //           }
      //         }
      //       }
      //       .cpSpn {
      //         display: block;
      //         font-size: 26px;
      //         font-weight: 400;
      //         line-height: 42px;
      //         color: #5d5d5d;
      //         letter-spacing: 3px;
      //       }
      //     }
      //   }
      // }
    }
  }
}
</style>
