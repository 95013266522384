// 设置 rem 函数
export function setRem() {
  const baseSize = 16;

  // 设置页面根节点字体大小
  const str = document.documentElement.style.fontSize;
  const strLength: any = str.substring(0, str.length - 2);
  // console.log("window.innerWidth", window.innerWidth);

  // 当前页面宽度相对于 1920 宽的缩放比例
  let scale =
    window.innerWidth <= 750
      ? document.documentElement.clientWidth / 750
      : document.documentElement.clientWidth / 1920;

  var userAgentInfo = navigator.userAgent;
  var Agents = [
    "Android",
    "iPhone",

    "SymbianOS",
    "Windows Phone",

    "iPad",
    "iPod",
  ];
  var flagPc = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flagPc = false;
      break;
    }
  }
  // console.log(flagPc);

  var userAgent: any = window.navigator.userAgent.toLowerCase();
  const url = window.location.href;
  const dataUrl = url.split("/");
  // console.log(userAgent);
  // console.log(dataUrl);
  if (!flagPc) {
    scale = document.documentElement.clientWidth / 750;
  }
  if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
    scale = document.documentElement.clientWidth / 375;
  }
  if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
    scale = document.documentElement.clientWidth / 750;
  }

  const dataList = ["temShare", "timeShare", "workShare"];
  // console.log(dataUrl[dataUrl.length - 1].split("?")[0]);

  if (
    dataList.indexOf(dataUrl[dataUrl.length - 1]) !== -1 ||
    dataList.indexOf(dataUrl[dataUrl.length - 1].split("?")[0]) !== -1
  ) {
    scale = document.documentElement.clientWidth / 375;
  }

  if (
    dataUrl[dataUrl.length - 1] === "DownPro" ||
    dataUrl[dataUrl.length - 1] === "DownloadApp"
  ) {
    scale = document.documentElement.clientWidth / 375;
  }
  if (flagPc) {
    scale = document.documentElement.clientWidth / 1920;
  }

  // 分割线
  // ------------------
  // if (strLength > 118.3) {
  //   document.documentElement.style.fontSize =
  //     baseSize * Math.min(scale, 8) + "px";
  // } else {
  //   document.documentElement.style.fontSize = 9.96667 + "px";
  // }
  if (strLength > 72) {
    document.documentElement.style.fontSize =
      baseSize * Math.min(scale, 8) + "px";
  } else {
    document.documentElement.style.fontSize = 9.96667 + "px";
  }

  if (window.innerWidth <= 750) {
    if (/Mobile/i.test(navigator.userAgent)) {
      document.documentElement.style.fontSize =
        baseSize * Math.min(scale, 8) + "px";
      // console.log(baseSize * Math.min(scale, 8) + "px");
    } else {
      document.documentElement.style.fontSize = 40 * Math.min(scale, 16) + "px";
      // console.log();

      // document.documentElement.style.fontSize = 16 + "px";
    }
  }
  // if (strLength <= 37.5) {
  //   scale = document.documentElement.clientWidth / 375;
  // }
  // const regUl = document.getElementById("regUl") as HTMLElement;
  // if(regUl && regUl.clientWidth>=2429){
  //   regUl.style.width="269px"
  // }
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  // console.log("window.innerWidth", window.innerWidth);
  setRem();
};
window.onpopstate = function () {
  setRem();
};
