import { getBaseUrl, getApkUrl, addDown } from "../api/apk/index";

 async function downloadApp() {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    //判断iPhone|iPad|iPod|iOS
    window.open(
      "https://apps.apple.com/cn/app/%E5%92%B1%E7%99%BE%E7%A7%91/id1662344900"
    );
  } else {
    //判断Android
    const apkBase = await getBaseUrl();
    const apkUrl = await getApkUrl();
    window.alert = function () {
      return;
    };
    await addDown();
    window.open(apkBase + "/" + apkUrl.data.packageUrl);
  }
}
export  {downloadApp}