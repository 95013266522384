import request from "../../utils/request";

/**
 * 用户登录
 * @param params 要传的参数值
 * @returns 返回接口数据
 */
//验证码
export function getCode(params: object) {
  return request({
    url: "/auth/captcha/sms",
    method: "get",
    params,
  });
}
//登录
export function signLogIn(params: object) {
  return request({
    url: "/auth/token",
    method: "post",
    data: params,
  });
}

//获取用户信息
export function getUserInfo() {
  return request({
    url: "/user",
    method: "get",
  });
}

//微信绑定手机号并登录
export function reqbindPhone(params: object) {
  return request({
    url: "/auth/binding-phone",
    method: "post",
    data: params,
  });
}

//获取baseURl
export function getBaseUrl() {
  return request({
    url: "/file/baseUrl",
    method: "get",
  });
}
