<template>
  <div class="lodingDiv">
    <div class="is-loading"></div>
    <div class="is-loading"></div>
    <div class="is-loading"></div>
    <div class="is-loading"></div>
    <div class="is-loading"></div>
    <div class="is-loading"></div>
    <div class="is-loading"></div>
    <div class="is-loading"></div>
  </div>
</template>

<style lang="scss" scoped>
.lodingDivTwo {
  margin-top: 20px;
  margin-bottom: 20px;
}
.lodingDiv {
  display: flex;
  width: 1158px;
  margin: 0 auto;
  margin-top: 20px;
  align-items: center;
  flex-wrap: wrap;

  .is-loading {
    width: 281px;
    height: 187px;
    border-radius: 14px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 30px;

    animation: loading 1.4s ease infinite;
    -webkit-animation: loading 1.4s ease infinite;
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(25%, #f0f0f0),
      color-stop(37%, #e3e3e3),
      color-stop(63%, #f0f0f0)
    );
    background-image: -o-linear-gradient(
      left,
      #f0f0f0 25%,
      #e3e3e3 37%,
      #f0f0f0 63%
    );
    background-image: linear-gradient(
      90deg,
      #f0f0f0 25%,
      #e3e3e3 37%,
      #f0f0f0 63%
    );
    background-size: 400% 100%;
  }
  .loadingTwo {
    margin-left: 37px;
    width: 613px;
    height: 145px;
    .twoOne {
      width: 460px;
      height: 40px;
      animation: loading 1.4s ease infinite;
      -webkit-animation: loading 1.4s ease infinite;
      background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(25%, #f0f0f0),
        color-stop(37%, #e3e3e3),
        color-stop(63%, #f0f0f0)
      );
      background-image: -o-linear-gradient(
        left,
        #f0f0f0 25%,
        #e3e3e3 37%,
        #f0f0f0 63%
      );
      background-image: linear-gradient(
        90deg,
        #f0f0f0 25%,
        #e3e3e3 37%,
        #f0f0f0 63%
      );
      background-size: 400% 100%;
    }
    .twoTwo {
      margin-top: 23px;
      height: 80px;
      animation: loading 1.4s ease infinite;
      -webkit-animation: loading 1.4s ease infinite;
      background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(25%, #f0f0f0),
        color-stop(37%, #e3e3e3),
        color-stop(63%, #f0f0f0)
      );
      background-image: -o-linear-gradient(
        left,
        #f0f0f0 25%,
        #e3e3e3 37%,
        #f0f0f0 63%
      );
      background-image: linear-gradient(
        90deg,
        #f0f0f0 25%,
        #e3e3e3 37%,
        #f0f0f0 63%
      );
      background-size: 400% 100%;
    }
  }
}

@-webkit-keyframes loading {
  0% {
    background-position: 100% 50%;
  }

  to {
    background-position: 0 50%;
  }
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }

  to {
    background-position: 0 50%;
  }
}
</style>
