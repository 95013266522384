import axios from "axios";
import { ElMessage } from "element-plus";
import { Session, Local } from "./storage";
import router from "../router/index";

// 配置新建一个 axios 实例
const service = axios.create({
  baseURL: window.location.origin + "/remote-api",
  timeout: 500000,
  headers: {
    "Content-Type": "application/json",
  },
});

// 添加请求拦截器
service.interceptors.request.use(
  (config: any) => {
    // console.log("config", config);
    // var url = window.location.href;
    // console.log("url", url);
    // const urlList = url.split("?");
    // console.log("urlList", urlList);

    // var id = urlList[urlList.length];
    // console.log("id", id);

    // if (id) {
    //   console.log(id);
    // } else {
    if (Session.get("userInfo")) {
      const userInfo = Session.get("userInfo");
      // console.log("userInfos", userInfo);
      config.headers.token = userInfo;
    } else if (Local.get("userInfo")) {
      const userInfo = Local.get("userInfo");
      // console.log("userInfosLocal", userInfo);
      config.headers.token = userInfo;
    }
    // }

    return config;
  },
  (error) => {
    // 对请求错误做些什么
    // console.log("error", error);

    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    return response.data;
  },
  (error) => {
    // 对响应错误做点什么
    // console.log(" error.response", error.response);
    if (error.response?.data.code == "401") {
      Session.clear();
      Local.clear();
      ElMessage.error("登录信息过期请重新登录!");
      router.push({
        name: "HomePage",
      });
    } else {
      ElMessage.error("请求失败");
    }

    if (error.message.indexOf("timeout") != -1) {
      ElMessage.error("网络超时");
    } else if (error.message == "Network Error") {
      ElMessage.error("网络连接错误");
    } else {
      if (error.response.data) "";
      else ElMessage.error("接口路径找不到");
    }
    return Promise.reject(error);
  }
);

// 导出 axios 实例
export default service;
