<template>
  <div id="abtDiv">
    <div id="abtImg">
      <!-- <img
        src="../assets/img/image131.png"
        alt="咱百科"
        v-if="!userInfo.resLayout"
      />
      <img src="../assets/img/image131.png" alt="咱百科" v-else /> -->
      <video :src="videoList" autoplay loop muted v-if="!userInfo.resLayout" />
      <video :src="videoList" autoplay loop muted v-else />
      <div id="navAbtTitle">
        <div class="navAbt">
          <span @click="clickScroll('#abtCtnOne')">公司简介</span>
        </div>
        <div class="navAbt">
          <span @click="clickScroll('#abtCtnTwo')">团队介绍</span>
        </div>
        <div class="navAbt">
          <span @click="clickScroll('#abtCtnThree')">发展历程</span>
        </div>
        <div class="navAbt">
          <span @click="clickScroll('#abtCtnFour')">品牌故事</span>
        </div>
        <div class="navAbt">
          <span @click="clickScroll('#abtCtnFive')">文化理念</span>
        </div>
      </div>
    </div>
    <div id="abtCentent">
      <div id="abtCtnOne">
        <div class="abtCtntitle">公司简介</div>
        <div class="abtCtnOneVideo">
          <video
            id="videoTxt"
            src="https://wepedias.oss-cn-shenzhen.aliyuncs.com/public/front-end/website/%E5%92%B1%E7%99%BE%E7%A7%91%E5%B1%95%E7%A4%BA.mp4"
            controls
          ></video>
          <img
            class="imgClass"
            src="../assets/img/Group141.svg"
            alt="播放标识"
            @click="changeImg"
          />
        </div>
        <div class="abtCtnOnetext">
          咱百科APP隶属于深圳咱百科互联网科技有限公司，咱百科APP专注于为用户打造无偿使用的故事记录平台，这其中包括小程序和综合性网站。融合制作、储存、搜索、展示及分享功能于一身，并提供时间轴功能帮助用户记录精彩往事、家族故事。打造人生故事记录平台，通过云端技术记录用户人生故事。
        </div>
        <div class="esseayTextOne">
          <div class="esseayTextOneDiv">
            <img src="../assets/img/zbkEsseay.png" alt="咱百科" />
          </div>
          <div class="esseayTextTwoDiv">
            中文名称：咱百科<br />
            英文名称：Wepedias<br />
            所属公司：深圳咱百科互联网科技有限公司<br />
            创立时间：2021.08.09<br />
            所属行业：互联网<br />
            主营业务：咱百科APP<br />
            口号：咱百科，我们的故事百科<br />
            总部地点：深圳市南山区粤海街道创维半导体设计大厦裙楼402
          </div>
        </div>
      </div>
      <div id="abtCtnTwo">
        <div class="abtCtntitle">团队介绍</div>
        <div class="abtCtnTwotext">
          我们拥有一支对咱百科项目有坚定信念、专注的专业团队，开发人员在编程和区块链技术拥有丰富的经验，熟练搭建SpringBoot、SpringCloud等框架。在Android、IOS和各种小程序开发掌握先进的处理技术；除此之外还拥有毕业于全球知名商学院的管理层，精通资本运作、市场营销及对外宣传与公关。公司独立的视频制作团队熟练掌握图片美化、视频剪辑等专业拍摄和后期技术。团队会根据用户对软件的使用需求，不断更新产品的各项功能，让咱百科成为令人信赖的故事记录平台。
        </div>
        <img
          class="abtCtnTwoImg"
          src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/Maskgroup.png"
          alt="团队"
        />
      </div>
      <div id="abtCtnThree">
        <div class="abtCtntitle">发展历程</div>
        <img
          class="abtCtnThreeImg"
          src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/FrameAbt49.png"
          alt="发展历程"
          v-if="!userInfo.resLayout"
        />
        <img
          class="abtCtnThreeImg"
          src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/Group45524.png"
          alt="发展历程"
          v-else
        />
      </div>
      <div id="abtCtnFour">
        <div class="abtCtntitle">品牌故事</div>
        <div class="abtCtnFourCentent">
          <div class="abtCtnFourtext">
            咱百科APP自2015年开始设计和研发以来，历经五年市场调研，于2021年在深圳市将其技术再次进行试验和完善，APP的基本功能已投入使用。<br />
            咱百科APP专注于为用户打造无偿使用的人生故事记录平台，这其中包括小程序和综合性网站。融合制作、储存、搜索、展示及分享功能于一身，并提供时间轴功能帮助用户记录精彩往事、家族故事。<br />
            打造人生故事记录平台。
          </div>
          <img
            src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/zbkEsseayThree.png"
            alt="咱百科"
            v-if="!userInfo.resLayout"
          />
          <img
            src="https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/zbkAbThree.png"
            alt="咱百科"
            v-else
          />
        </div>
      </div>
      <div id="abtCtnFive">
        <div class="abtCtntitle">文化理念</div>
        <div class="abtCtnFiveDiv">
          <div class="abtCtnFiveDivOne">
            <!-- <div class="abtCtnFiveDivTitle">口号</div> -->
            <span
              >咱百科<br />
              我们的故事百科。</span
            >
          </div>
          <div class="abtCtnFiveDivTwo">
            <!-- <div class="abtCtnFiveDivTitle">愿景</div> -->
            <span
              >保存、展示每人的故事，感恩生活，不留遗憾，服务于个人、家庭以及全社会。</span
            >
          </div>
          <div class="abtCtnFiveDivThree">
            <!-- <div class="abtCtnFiveDivTitle">价值观</div> -->
            <span>留住昔日的光辉，珍惜当下的充实。</span>
          </div>
        </div>
        <span class="abtCtnFiveFout"
          >咱百科的使命是潜心打造人生故事百科全书平台，使每个人可以随时轻松记录、保存自己和家人的人生故事，连接过去、现在与未来，提供用户记录和存储生活故事的平台。</span
        >
      </div>
    </div>

    <!-- <div id="abtCet">
      <div class="abtTit">
        <span>公司介绍</span>
        <span class="imgText">company</span>
        <div class="cenTextOneTitLine"></div>
        <div class="cenTextTwoTitLine"></div>
      </div>
      <div id="abtTxtOne">
        <div id="abtTxtOneCentent">
        </div>
        <div id="abtTxtTwoCentent">
          <span>咱百科</span>
          <p>
            咱百科APP诞生于深圳市，于2021年8月9日成立，属于深圳市咱百科互联网科技有限公司旗下，主营业务是咱百科APP，一款记录人生故事的软件。
          </p>
          <div id="abtTxtOneInfo" @click="pushTo">
            <div id="abtTxtOneInfoText">查看详情</div>
            <img src="../assets/img/Group44820.svg" alt="咱百科" />
          </div>
        </div>
        <div id="abtTxtThreePosition">
          <img src="../assets/img/Group44852.png" alt="咱百科" />
        </div>
      </div>

      <div class="abtTit">
        <span>团队介绍</span>
        <span class="imgText">Team</span>
        <div class="cenTextOneTitLine"></div>
        <div class="cenTextTwoTitLine"></div>
      </div>
      <div id="abtTxt">
        <div>
          <div class="pImg">
            <p>
              我们拥有一支对咱百科项目有坚定信念、专注的专业团队，开发人员在编程和区块链技术拥有丰富的经验，熟练搭建SpringBoot、SpringCloud等框架。在Android、IOS和各种小程序开发掌握先进的处理技术；除此之外还拥有毕业于全球知名商学院的管理层，精通资本运作、市场营销及对外宣传与公关。公司独立的视频制作团队熟练掌握图片美化、视频剪辑等专业拍摄和后期技术。团队会根据用户对软件的使用需求，不断更新产品的各项功能，让咱百科成为令人信赖的故事百科平台。
            </p>
          </div>
        </div>
        <video
          class="videoTxt"
          src="https://wepedias.oss-cn-shenzhen.aliyuncs.com/public/front-end/website/%E5%92%B1%E7%99%BE%E7%A7%91%E5%B1%95%E7%A4%BA.mp4"
          controls
        ></video>
      </div>
    </div> -->
  </div>
</template>

<script lang="ts">
import { onMounted, ref, onUnmounted, onActivated } from "vue";
import { useStore } from "vuex";
// import { getTable } from "../api/chari/index";
import route from "../router/index";
// import styleClass from "../utils/styleChange";
import { getVideoLogin } from "../api/videoData/index";
// import { nextTick } from "process";
// import { getBaseUrl } from "../api/apk";

export default {
  name: "AboutWe",
  components: {},
  setup() {
    // const toId = ref("");
    const store = useStore();
    const state = store.state.allWeb;
    const userInfo = store.state.userInfo;
    const videoList = ref("");
    onMounted(async () => {
      // const baseUrl = await getBaseUrl();
      const dataLogin = await getVideoLogin("2");
      videoList.value =
        store.state.allWeb.publicLinks + "/" + dataLogin.data.materialUri;
      // if (!userInfo.resLayout) {
      // styleClass("#abtImg", 1, 60);
      // } else {
      const videoOne = document.querySelectorAll("video");
      videoOne.forEach((item: any) => {
        item.currentTime = 0;
      });
      // }
      const video = document.getElementById("videoTxt") as HTMLVideoElement;
      const imgClass = document.querySelector(".imgClass") as HTMLVideoElement;
      video?.addEventListener("play", () => {
        imgClass.style.display = "none";
      });
      video?.addEventListener("pause", () => {
        !userInfo.resLayout ? (imgClass.style.display = "block") : "";
      });
    });
    // const timeObj: any = ref();
    async function pushTo() {
      // const essayData = await getTable({ pageNum: "", pageSize: "", title: "" });
      // const data = essayData.data.records.filter((item: any) => {
      //   return item.title == "咱百科";
      // });
      // toId.value = data[0].id;
      route.push({
        name: "CompanyInfo",
      });
    }
    function changeImg() {
      const video = document.getElementById("videoTxt") as HTMLVideoElement;
      video.controls = true;
      if (video.paused) {
        video.play();
      }
    }
    function clickScroll(params: string) {
      const mbElement = document.querySelector("#hearde") as HTMLElement;
      let headerOffset = mbElement.offsetHeight + 10;
      // console.log("headerOffset",headerOffset);
      var elementData = document.querySelector(params) as HTMLElement;
      var elementPosition = elementData?.offsetTop;
      window.scrollTo({
        top: elementPosition - headerOffset,
        behavior: "smooth",
      });
    }

    onUnmounted(() => {
      const hearde = document.querySelector("#hearde") as any;
      const ftbtn = document.querySelectorAll(".ftBtnSpan") as any;
      hearde?.classList.remove("hearderStyle");
      ftbtn.forEach((element: any) => {
        element.classList.remove("ftbtnStyle");
      });
    });
    onActivated(() => {
      // nextTick(() => {
      const videoId = document
        .querySelector("#abtImg")
        ?.querySelector("video") as HTMLVideoElement;
      videoId ? videoId.play() : "";
      // });
    });
    return {
      state,
      userInfo,
      videoList,
      changeImg,
      pushTo,
      clickScroll,
    };
  },
};
</script>

<style scoped lang="scss">
// #abtDiv {
//   background: url(../assets/img/videoBack.webp);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   // background-position: right top;
//   // background-attachment: fixed;
// }
@media only screen and (min-width: 751px) {
  :deep(.el-carousel__container) {
    height: 220px;
    .el-carousel__item,
    .is-active,
    .is-animating {
      height: 220px;
    }
  }
  #abtDiv {
    width: 100%;
    letter-spacing: 2px;
    margin-top: 73px;

    #abtImg {
      height: 1021px;
      width: 1920px;
      position: relative;
      background: #d9d9d9;
      #navAbtTitle {
        width: 1150px;
        position: absolute;
        bottom: -61px;
        left: calc(50% - 575px);
        background: #ffffff;
        box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.1);
        border-radius: 16px;

        .navAbt {
          display: inline-block;
          width: 230px;
          height: 138px;

          span {
            display: block;
            font-family: "PingFang SC";
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            text-align: center;
            color: #2d2d2d;
            margin-top: 61px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      img {
        height: 1021px;
        width: 100%;
      }
      video {
        height: 1021px;
        width: 100%;
        object-fit: fill;
      }
      .ImgTb {
        position: absolute;
        top: 50%;
        left: 203px;
        transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        p {
          letter-spacing: 13px;
          font-size: 78px;
          font-weight: bold;
          color: #ffffff;
          margin-bottom: 18px;
          margin-top: 0px;
        }

        span {
          display: inline-block;
          font-size: 46px;
          font-family: RTWS YueGothic Demo;
          font-weight: 300;
          color: #ffffff;
          height: 80px;
          line-height: 80px;
        }

        .spanTxt {
          border-bottom: 1px solid #fff;
          margin-right: 20px;
        }
      }
    }

    // -----
    #abtCentent {
      width: 1094px;
      margin: 249px auto;
      margin-bottom: 0px;

      #abtCtnOne {
        margin-bottom: 166px;
        .abtCtntitle {
          font-family: "PingFang SCBold";
          font-weight: 500;
          font-size: 40px;
          line-height: 40px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #000000;
          margin-bottom: 36px;
        }
        .abtCtnOneVideo {
          position: relative;
          width: 1094px;

          #videoTxt {
            width: 1094px;
            height: 602px;
            object-fit: fill;
            background: #bed3bd;
            border-radius: 16px;
          }
          .imgClass {
            position: absolute;
            width: 126px;
            height: 126px;
            top: calc(50% - 63px);
            left: calc(50% - 63px);
          }
        }
        .abtCtnOnetext {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #2d2d2d;
          margin: 36px 0px;
        }
        .esseayTextOne {
          width: 1094px;
          height: 780px;
          // margin-bottom: 72px;
          position: relative;
          background: #ffffff;
          border-radius: 18px;
          background: linear-gradient(124.25deg, #d3ecff 0%, #c3ffe1 98.71%),
            linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.8),
              rgba(255, 255, 255, 0.8)
            );
          z-index: -6;

          &::after {
            content: " ";
            position: absolute;
            left: 3px;
            top: 3px;
            width: 1088px;
            height: 774px;
            background: #fff;
            border-radius: 18px;
            z-index: -5;
          }
          .esseayTextOneDiv {
            padding-left: 48px;
            padding-top: 48px;
            img {
              width: 446px;
              height: 201px;
            }
          }
          .esseayTextTwoDiv {
            width: 660px;
            margin-left: 90px;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 52px;
            text-align: justify;
            letter-spacing: 0.24em;
            color: #48555a;
          }
        }
      }
      #abtCtnTwo {
        margin-bottom: 166px;
        .abtCtntitle {
          font-family: "PingFang SCBold";
          font-weight: 500;
          font-size: 40px;
          line-height: 40px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #000000;
          margin-bottom: 36px;
        }
        .abtCtnTwotext {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #2d2d2d;
        }
        .abtCtnTwoImg {
          width: 1094px;
          height: 602px;
          background: #bed3bd;
          border-radius: 16px;
          margin-top: 36px;
        }
      }
      #abtCtnThree {
        position: relative;
        height: 784px;
        margin-bottom: 169px;
        .abtCtntitle {
          font-family: "PingFang SCBold";
          font-weight: 500;
          font-size: 40px;
          line-height: 40px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #000000;
          margin-bottom: 36px;
        }
        .abtCtnThreeImg {
          position: absolute;
          width: 1920px;
          height: 714px;
          left: -412px;
        }
      }
      #abtCtnFour {
        margin-bottom: 168px;
        .abtCtntitle {
          font-family: "PingFang SCBold";
          font-weight: 500;
          font-size: 40px;
          line-height: 40px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #000000;
          margin-bottom: 39px;
        }
        .abtCtnFourCentent {
          display: flex;
          align-items: center;
          .abtCtnFourtext {
            width: 526px;
            margin-right: 90px;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            text-align: justify;
            letter-spacing: 0.24em;
            color: #48555a;
          }
          img {
            width: 478px;
            height: 294px;
            border-radius: 8px;
          }
        }
      }
      #abtCtnFive {
        margin-bottom: 168px;
        .abtCtntitle {
          font-family: "PingFang SCBold";
          font-weight: 500;
          font-size: 40px;
          line-height: 40px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #000000;
          margin-bottom: 45px;
        }
        .abtCtnFiveDiv {
          display: flex;
          div {
            position: relative;
            width: 359px;
            height: 428px;
            border-right: 1px solid rgba(225, 242, 233, 1);
            border-radius: 16px;
          }
          span {
            width: 303px;
            position: absolute;
            top: 160px;
            left: 28px;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            text-align: justify;
            letter-spacing: 0.24em;
            color: #48555a;
          }
          .abtCtnFiveDivOne {
            background: url(https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/MaskgroupOne.png);
            background-repeat: no-repeat;
            background-size: 100%;
          }
          .abtCtnFiveDivTwo {
            background: url(https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/MaskgroupTwo.png);
            margin: 0 8px;
            background-repeat: no-repeat;
            background-size: 100%;
          }
          .abtCtnFiveDivThree {
            background: url(https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/MaskgroupThree.png);
            background-repeat: no-repeat;
            background-size: 100%;
          }
        }
        .abtCtnFiveFout {
          display: inline-block;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #48555a;
          margin-top: 28px;
        }
      }
    }
    // ------

    // #adtTsgn {
    //   width: 100%;
    //   // border: 1px solid #FF3030
    //   padding-top: 48px;
    //   .tsgnTit {
    //     width: 100%;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;

    //     span {
    //       // display: block
    //       font-size: 42px;
    //       // font-family: PingFang SC
    //       font-weight: bold;
    //       color: #363535;
    //       text-align: center;
    //       // margin: 0 56px
    //     }

    //     img {
    //       width: 100%;
    //       height: 66px;
    //       margin-top: -22px;
    //     }
    //   }

    //   .tsOne {
    //     width: 100%;
    //     display: flex;
    //     justify-items: start;
    //     #tsTxt {
    //       padding-left: 210px;
    //       div {
    //         display: flex;
    //         flex-direction: column;
    //         padding-top: 100px;
    //         img {
    //           width: 140px;
    //           height: 60px;
    //         }

    //         span {
    //           font-size: 30px;
    //           // font-family: PingFang SC
    //           font-weight: 200;
    //           color: #f08b5f;
    //           // margin-left: -30px
    //           // padding-top: 5px
    //         }

    //         .tsTit {
    //           width: 200px;
    //           letter-spacing: 25px;
    //           font-size: 72px;
    //           font-family: ShiShangZhongHeiJianTi;
    //           font-weight: 400;
    //           color: #414141;
    //           padding-top: 200px;
    //         }
    //       }

    //       .tsSpn {
    //         display: block;
    //         width: 625px;
    //         font-size: 24px;
    //         // font-family: PingFang SC
    //         font-weight: 500;
    //         color: #5d5d5d;
    //         padding-top: 67px;
    //         letter-spacing: 3px;
    //       }
    //     }

    //     #tsImg {
    //       width: 1038px;
    //       height: 1082px;
    //       margin-left: -110px;
    //       margin-top: -20px;
    //     }
    //   }
    // }

    // #adtCp {
    //   width: 100%;
    //   // border: 1px solid #FF3030
    //   padding-top: 285px;
    //   .cpOne {
    //     width: 100%;
    //     display: flex;
    //     justify-items: start;
    //     #cpImg {
    //       width: 854px;
    //       height: 839px;
    //       padding-left: 210px;
    //     }

    //     #cpTxt {
    //       // padding-top: 100px
    //       padding-left: 20px;
    //       div {
    //         display: flex;
    //         flex-direction: column;
    //         align-items: flex-end;
    //         padding-left: 200px;

    //         img {
    //           width: 140px;
    //           height: 60px;
    //         }

    //         span {
    //           font-size: 30px;
    //           // font-family: PingFang SC
    //           font-weight: 200;
    //           color: #f08b5f;
    //           // margin-left: -30px
    //           // padding-top: 5px
    //         }

    //         .tsTit {
    //           width: 200px;
    //           letter-spacing: 25px;
    //           font-size: 72px;
    //           font-family: ShiShangZhongHeiJianTi;
    //           font-weight: 400;
    //           color: #414141;
    //           padding-top: 200px;
    //           text-align: right;
    //         }
    //       }

    //       .cpSpn {
    //         display: block;
    //         width: 625px;
    //         font-size: 24px;
    //         // font-family: PingFang SC;
    //         font-weight: 500;
    //         color: #5d5d5d;
    //         padding-top: 25px;
    //         letter-spacing: 3px;
    //       }
    //     }
    //   }
    // }

    // #adtJz {
    //   width: 100%;
    //   // border: 1px solid #FF3030
    //   padding-top: 285px;
    //   padding-bottom: 195px;
    //   .jzOne {
    //     width: 100%;
    //     display: flex;
    //     justify-items: start;
    //     #jzTxt {
    //       padding-left: 210px;
    //       div {
    //         display: flex;
    //         padding-top: 100px;
    //         flex-direction: column;
    //         img {
    //           width: 140px;
    //           height: 60px;
    //         }

    //         span {
    //           font-size: 30px;
    //           // font-family: PingFang SC
    //           font-weight: 200;
    //           color: #f08b5f;
    //           // margin-left: -30px
    //           // padding-top: 5px
    //         }

    //         .tsTit {
    //           width: 200px;
    //           letter-spacing: 25px;
    //           font-size: 72px;
    //           font-family: ShiShangZhongHeiJianTi;
    //           font-weight: 400;
    //           color: #414141;
    //           padding-top: 200px;
    //         }
    //       }

    //       .jzSpn {
    //         display: block;
    //         width: 624px;
    //         font-size: 24px;
    //         // font-family: PingFang SC;
    //         font-weight: 500;
    //         color: #5d5d5d;
    //         padding-top: 30px;
    //         letter-spacing: 3px;
    //       }
    //     }

    //     #jzImg {
    //       width: 1033px;
    //       height: 1077px;
    //       margin-left: -130px;
    //       margin-top: -20px;
    //     }
    //   }
    // }
  }
}
@media only screen and (max-width: 750px) {
  .el-carousel__container {
    height: 242px;
    .el-carousel__item,
    .is-active,
    .is-animating {
      height: 242px;
    }
  }
  #abtDiv {
    width: 750px;
    letter-spacing: 2px;
    margin-top: 91px;
    #navAbtTitle {
      display: none;
    }
    #abtImg {
      width: 100%;
      height: 242px;
      position: relative;
      margin-bottom: 90px;
      img {
        height: 310px;
        width: 100%;
      }
      video {
        height: 310px;
        width: 100%;
        object-fit: fill;
      }
      .ImgTb {
        height: 134px;
        position: absolute;
        top: 100px;
        left: 23px;
        transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        p {
          letter-spacing: 13px;
          font-size: 56px;
          font-weight: bold;
          color: #ffffff;
          // margin-bottom: 15px;
          margin: 0px;
          // line-height: 11px;
        }

        span {
          display: inline-block;
          font-size: 32px;
          font-family: RTWS YueGothic Demo;
          font-weight: 300;
          color: #ffffff;
          height: 35px;
          padding-bottom: 7px;
          // line-height: 7px;
        }

        .spanTxt {
          border-bottom: 1px solid #fff;
          margin-right: 20px;
        }
      }
    }

    // -----
    #abtCentent {
      width: 702px;
      margin: 200px auto;
      margin-bottom: 0px;

      #abtCtnOne {
        margin-bottom: 100px;
        .abtCtntitle {
          font-family: "PingFang SCBold";
          font-weight: 500;
          font-size: 46px;
          line-height: 40px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #000000;
          margin-bottom: 60px;
          text-align: center;
        }
        .abtCtnOneVideo {
          position: relative;
          width: 702px;

          #videoTxt {
            width: 702px;
            height: 502px;
            object-fit: fill;
            background: #bed3bd;
            border-radius: 16px;
          }
          .imgClass {
            display: none;
            // position: absolute;
            // width: 126px;
            // height: 126px;
            // top: calc(50% - 63px);
            // left: calc(50% - 63px);
          }
        }
        .abtCtnOnetext {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 500;
          font-size: 26px;
          line-height: 40px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #2d2d2d;
          margin: 36px 0px;
        }
        .esseayTextOne {
          width: 702px;
          height: 780px;
          // margin-bottom: 72px;
          position: relative;
          background: #ffffff;
          border-radius: 18px;
          background: linear-gradient(124.25deg, #d3ecff 0%, #c3ffe1 98.71%),
            linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.8),
              rgba(255, 255, 255, 0.8)
            );
          z-index: -6;

          &::after {
            content: " ";
            position: absolute;
            left: 3px;
            top: 3px;
            width: 696px;
            height: 774px;
            background: #fff;
            border-radius: 18px;
            z-index: -5;
          }
          .esseayTextOneDiv {
            padding-left: -12px;
            padding-top: 48px;
            img {
              width: 446px;
              height: 201px;
            }
          }
          .esseayTextTwoDiv {
            width: 660px;
            margin-left: 30px;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 52px;
            text-align: justify;
            letter-spacing: 0.24em;
            color: #48555a;
          }
        }
      }
      #abtCtnTwo {
        margin-bottom: 100px;
        .abtCtntitle {
          font-family: "PingFang SCBold";
          font-weight: 500;
          font-size: 46px;
          line-height: 40px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #000000;
          margin-bottom: 60px;
          text-align: center;
        }
        .abtCtnTwotext {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 500;
          font-size: 26px;
          line-height: 40px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #2d2d2d;
        }
        .abtCtnTwoImg {
          width: 702px;
          height: 402px;
          background: #bed3bd;
          border-radius: 16px;
          margin-top: 36px;
        }
      }
      #abtCtnThree {
        position: relative;
        height: 1675px;
        margin-bottom: 169px;
        .abtCtntitle {
          font-family: "PingFang SCBold";
          font-weight: 500;
          font-size: 46px;
          line-height: 40px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #000000;
          margin-bottom: 60px;
          text-align: center;
        }
        .abtCtnThreeImg {
          position: absolute;
          width: 750px;
          height: 1675px;
          left: -25px;
        }
      }
      #abtCtnFour {
        margin-bottom: 168px;
        .abtCtntitle {
          font-family: "PingFang SCBold";
          font-weight: 500;
          font-size: 46px;
          line-height: 40px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #000000;
          margin-bottom: 60px;
          text-align: center;
        }
        .abtCtnFourCentent {
          // display: flex;
          // align-items: center;
          .abtCtnFourtext {
            width: 702px;
            // margin-right: 90px;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 40px;
            text-align: justify;
            letter-spacing: 0.24em;
            color: #48555a;
          }
          img {
            width: 702px;
            height: 494px;
            border-radius: 8px;
            margin-top: 30px;
          }
        }
      }
      #abtCtnFive {
        margin-bottom: 168px;
        .abtCtntitle {
          font-family: "PingFang SCBold";
          font-weight: 500;
          font-size: 46px;
          line-height: 40px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #000000;
          margin-bottom: 60px;
          text-align: center;
        }
        .abtCtnFiveDiv {
          // display: flex;
          // flex-wrap: wrap;
          div {
            position: relative;
            display: inline-block;
            width: 347px;
            height: 414px;
            border-right: 1px solid rgba(225, 242, 233, 1);
            border-radius: 16px;
          }
          span {
            width: 303px;
            position: absolute;
            top: 155px;
            left: 28px;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            text-align: justify;
            letter-spacing: 0.24em;
            color: #48555a;
          }
          .abtCtnFiveDivOne {
            background: url(https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/MaskgroupOne.png);
            background-repeat: no-repeat;
            background-size: 100%;
            margin-right: 4px;
          }
          .abtCtnFiveDivTwo {
            background: url(https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/MaskgroupTwo.png);
            background-repeat: no-repeat;
            background-size: 100%;
            margin: 0;
            padding: 0;
          }
          .abtCtnFiveDivThree {
            border: none;
            width: 702px;
            height: 301px;
            background: url(https://wepedias-test.oss-cn-shenzhen.aliyuncs.com/public/statics/imgs/Group45525.png);
            background-repeat: no-repeat;
            background-size: 100%;
            // margin-top: 13px;
            span {
              width: 551px;
              top: 146px;
              left: 37px;
            }
          }
        }
        .abtCtnFiveFout {
          display: inline-block;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 26px;
          line-height: 40px;
          text-align: justify;
          letter-spacing: 0.17em;
          color: #48555a;
          margin-top: 10px;
        }
      }
    }
    // ------

    #abtCet {
      width: 702px;
      margin: 0 auto;
      padding-top: 41px;
      // border: 1px solid #B23AEE
      margin-top: -3px;
      margin-bottom: 1px;
      .abtTit {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding-bottom: 0px;
        position: relative;
        span {
          // display: block
          font-size: 32px;
          font-weight: bold;
          color: #363535;
          text-align: center;
          letter-spacing: 4px;
          // margin: 0 56px
        }

        img {
          width: 100%;
          height: 1px;
          margin-top: -22px;
        }
        .imgText {
          position: absolute;
          display: flex;
          width: 100%;
          // height: 22px;
          justify-content: center;
          font-style: normal;
          text-align: center;
          letter-spacing: 0.485em;
          color: rgba(135, 181, 224, 0.3);
          z-index: -10;
          font-weight: 200;
          // padding-top: 21px;
        }
        .cenTextOneTitLine {
          width: 107px;
          height: 0px;
          opacity: 1;
          border: 1px solid #000000;
          position: absolute;
          bottom: -11px;
          left: calc(50% - 25px);
          // height: 1px;
          background: #000;
        }
        .cenTextTwoTitLine {
          width: 107px;
          height: 0px;
          opacity: 1;
          border: 1px solid #000000;
          position: absolute;
          bottom: -18px;
          left: calc(50% - 55px);
          // height: 1px;
          background: #000;
        }
      }

      #abtTxt {
        display: flex;
        flex-direction: column-reverse;
        width: 702px;
        position: relative;
        margin: 0 auto;
        padding-bottom: 109px;

        img {
          display: none;
        }
        .abtImg {
          display: block;
          width: 702px;
          height: 560px;
          position: absolute;
          // bottom: 0;
          left: 0;
          z-index: -10;
          margin-top: 10px;
        }
        .pImg {
          width: 636px;
          margin: 0 auto;
          // background: #95b8f2;
          // margin-left: 44px;
          // position: relative;
          // overflow: hidden;
          // text-align-last: justify
          // background-repeat: no-repeat;
          // background-size: 100% 100%;
          // padding: 0 10px;

          p {
            width: 636px;
            // height: 523px;
            display: block;
            margin: 0 auto;
            // margin-top: 10px;
            // border: 1px solid #000
            // height: 538px;
            font-size: 28px !important;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 400;
            color: #48555a;
            letter-spacing: 0.185em;
            text-align: justify;
            line-height: 45px;
            margin-top: 40px;
            // margin-bottom: 45px;
          }
          // .bulBlock {
          //   // height: 58px;
          //   // margin: 0;
          //   margin-top: 107px;
          //   margin-bottom: 12px;
          //   ul {
          //     display: flex;
          //     flex-direction: row;
          //     align-items: flex-end;
          //     justify-content: space-between;
          //     height: 100%;
          //     padding: 0;
          //     padding-left: 181px;
          //     margin: 0;
          //     list-style: none;
          //     li {
          //       width: 21px;
          //       height: 21px;
          //       background: #dedfff;
          //       border: 1px solid #f9ffff;
          //     }
          //   }
          // }
        }
        .videoTxt {
          display: block;
          width: 750px;
          height: 421px;
          // margin: 0 auto;
          background: #e1e1e1;
          border-radius: 12px;
          margin-bottom: 21px;
          margin-left: -20px;
          margin-top: 50px;
        }
      }
      #abtTxtOne {
        width: 702px;
        // height: 464px;
        margin: 0 auto;
        // background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 0px 8px #b6e2ff;
        border-radius: 12px;
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 84px;
        margin-top: 50px;
        #abtTxtOneCentent {
          img {
            width: 644.85px;
            height: 402.39px;
            margin-left: 13px;
            margin-right: 18px;
          }
        }
        #abtTxtTwoCentent {
          // height: 274px;
          padding-bottom: 50px;

          span {
            display: block;
            font-style: normal;
            font-weight: 550;
            font-size: 40px;
            line-height: 64px;
            letter-spacing: 0.3em;
            color: #48555a;
            margin-top: 70px;
            text-align: center;
          }
          p {
            width: 645px;
            // height: 162px;
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 32px;
            letter-spacing: 0.185em;
            // text-align: justify;
            color: #48555a;
            margin: 0;
            margin-top: 55px;
          }
          #abtTxtOneInfo {
            display: flex;
            align-items: center;
            justify-content: end;
            margin-top: 33px;
            &:hover {
              cursor: pointer;
            }
            #abtTxtOneInfoText {
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 39px;
              // text-align: justify;
              letter-spacing: 0.29em;
              color: #48555a;
              z-index: 111;
            }
            img {
              width: 29.05px;
              height: 21.53px;
              padding-right: 10px;
            }
          }
        }
        #abtTxtThreePosition {
          position: absolute;
          top: 180;
          right: 0;
          z-index: -1;
          img {
            width: 855.48px;
            height: 771px;
          }
        }
      }
      // #posiAbtCet {
      //   width: 750px;
      //   height: 100%;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   z-index: -10;
      // }
    }

    // #adtTsgn {
    //   width: 100%;
    //   // border: 1px solid #FF3030
    //   padding-top: 48px;
    //   .tsgnTit {
    //     width: 100%;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;

    //     span {
    //       // display: block
    //       font-size: 42px;
    //       // font-family: PingFang SC
    //       font-weight: bold;
    //       color: #363535;
    //       text-align: center;
    //       // margin: 0 56px
    //     }

    //     img {
    //       width: 100%;
    //       height: 66px;
    //       margin-top: -22px;
    //     }
    //   }

    //   .tsOne {
    //     width: 100%;
    //     display: flex;
    //     justify-items: start;
    //     #tsTxt {
    //       padding-left: 210px;
    //       div {
    //         display: flex;
    //         flex-direction: column;
    //         padding-top: 100px;
    //         img {
    //           width: 140px;
    //           height: 60px;
    //         }

    //         span {
    //           font-size: 30px;
    //           // font-family: PingFang SC
    //           font-weight: 200;
    //           color: #f08b5f;
    //           // margin-left: -30px
    //           // padding-top: 5px
    //         }

    //         .tsTit {
    //           width: 200px;
    //           letter-spacing: 25px;
    //           font-size: 72px;
    //           font-family: ShiShangZhongHeiJianTi;
    //           font-weight: 400;
    //           color: #414141;
    //           padding-top: 200px;
    //         }
    //       }

    //       .tsSpn {
    //         display: block;
    //         width: 625px;
    //         font-size: 24px;
    //         // font-family: PingFang SC
    //         font-weight: 500;
    //         color: #5d5d5d;
    //         padding-top: 67px;
    //         letter-spacing: 3px;
    //       }
    //     }

    //     #tsImg {
    //       width: 1038px;
    //       height: 1082px;
    //       margin-left: -110px;
    //       margin-top: -20px;
    //     }
    //   }
    // }

    // #adtCp {
    //   width: 100%;
    //   // border: 1px solid #FF3030
    //   padding-top: 285px;
    //   .cpOne {
    //     width: 100%;
    //     display: flex;
    //     justify-items: start;
    //     #cpImg {
    //       width: 854px;
    //       height: 839px;
    //       padding-left: 210px;
    //     }

    //     #cpTxt {
    //       // padding-top: 100px
    //       padding-left: 20px;
    //       div {
    //         display: flex;
    //         flex-direction: column;
    //         align-items: flex-end;
    //         padding-left: 200px;

    //         img {
    //           width: 140px;
    //           height: 60px;
    //         }

    //         span {
    //           font-size: 30px;
    //           // font-family: PingFang SC
    //           font-weight: 200;
    //           color: #f08b5f;
    //           // margin-left: -30px
    //           // padding-top: 5px
    //         }

    //         .tsTit {
    //           width: 200px;
    //           letter-spacing: 25px;
    //           font-size: 72px;
    //           font-family: ShiShangZhongHeiJianTi;
    //           font-weight: 400;
    //           color: #414141;
    //           padding-top: 200px;
    //           text-align: right;
    //         }
    //       }

    //       .cpSpn {
    //         display: block;
    //         width: 625px;
    //         font-size: 24px;
    //         // font-family: PingFang SC;
    //         font-weight: 500;
    //         color: #5d5d5d;
    //         padding-top: 25px;
    //         letter-spacing: 3px;
    //       }
    //     }
    //   }
    // }

    // #adtJz {
    //   width: 100%;
    //   // border: 1px solid #FF3030
    //   padding-top: 285px;
    //   padding-bottom: 195px;
    //   .jzOne {
    //     width: 100%;
    //     display: flex;
    //     justify-items: start;
    //     #jzTxt {
    //       padding-left: 210px;
    //       div {
    //         display: flex;
    //         padding-top: 100px;
    //         flex-direction: column;
    //         img {
    //           width: 140px;
    //           height: 60px;
    //         }

    //         span {
    //           font-size: 30px;
    //           // font-family: PingFang SC
    //           font-weight: 200;
    //           color: #f08b5f;
    //           // margin-left: -30px
    //           // padding-top: 5px
    //         }

    //         .tsTit {
    //           width: 200px;
    //           letter-spacing: 25px;
    //           font-size: 72px;
    //           font-family: ShiShangZhongHeiJianTi;
    //           font-weight: 400;
    //           color: #414141;
    //           padding-top: 200px;
    //         }
    //       }

    //       .jzSpn {
    //         display: block;
    //         width: 624px;
    //         font-size: 24px;
    //         // font-family: PingFang SC;
    //         font-weight: 500;
    //         color: #5d5d5d;
    //         padding-top: 30px;
    //         letter-spacing: 3px;
    //       }
    //     }

    //     #jzImg {
    //       width: 1033px;
    //       height: 1077px;
    //       margin-left: -130px;
    //       margin-top: -20px;
    //     }
    //   }
    // }
  }
}
</style>
