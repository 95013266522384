function getElement(valueNum: number, flag: boolean) {
  const classAll = document
    .getElementById("hearul")
    ?.querySelectorAll(".viBack") as any;
  // console.log("classAll",classAll);
    
  if (flag) {
    classAll
      ? classAll[valueNum]
          ?.querySelector("a")
          .classList.add("router-link-active")
      : "";
  } else {
    classAll
      ? classAll[valueNum]
          ?.querySelector("a")
          .classList.remove("router-link-active")
      : "";
  }
}
export default getElement;
