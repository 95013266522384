import { createStore } from "vuex";

export default createStore({
  state: {
    avatar: "", //用户的头像
    nickname: "", //用户的名称
    login: false, //判断用户是否登录
    timeFlage: false, //官网时间轴判断
    // birthday:"",//用户的生日
    // address:"",//用户的地址
    // phoneNumber:"",//用户的手机号
  },
  //一般写操作数据的地方
  mutations: {
    updateUser(state, data) {
      state.avatar = data.avatar;
      state.nickname = data.nickname;
    },
  },
});
