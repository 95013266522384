import request from "../../utils/request";

//获取地址
export function getBaseUrl() {
  return request({
    url: "/file/baseUrl",
    method: "get",
  });
}
//获取apk
export function getApkUrl() {
  return request({
    url: "/version/up-date",
    method: "get",
  });
}

//点击一次添加下载次数
export function addDown() {
  return request({
    url: "/scan/qr-code",
    method: "get",
  });
}