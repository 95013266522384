import { createStore } from 'vuex'; //引入vuex
import allWeb from './modules/allWeb'; 
import userInfo from './modules/userInfo'; 
const store = createStore({
    modules: {
      allWeb,
      userInfo,
    },
})

export default store; //导出
