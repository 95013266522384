import request from "../../utils/request";

//获取
export function gettimeList(params: object) {
  return request({
    url: "/timeline",
    method: "get",
    params,
  });
}
//获取评论
export function gettimenewest(
  id: number | string,
  pageNum: number | string,
  pageSize: number | string
) {
  return request({
    url: `/timeline/${id}/comment`,
    method: "get",
    params: {
      pageNum,
      pageSize,
    },
  });
}

//回复时间轴评论
export function postTimenewest(params: object) {
  return request({
    url: `/timeline/comment`,
    method: "post",
    data: params,
  });
}

//删除时间轴评论
export function deleteTimenewest(id: number | string) {
  return request({
    url: `/timeline/${id}`,
    method: "delete",
  });
}

//时间轴详情
export function getTimeInfo(id: number | string) {
  return request({
    url: `/timeline/detail/${id}`,
    method: "get",
  });
}
