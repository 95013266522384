import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";

import routerIndex from "../router/index";
import { Session, Local } from "../utils/storage";
import { ElMessage } from "element-plus";
import HomePage from "../views/homePage.vue";
import AboutWeOne from "../views/aboutWe.vue";
import Charitable from "../views/charitable.vue";

import MudioSheet from "../views/mudioSheet.vue";
import ContaCctUs from "../views/contactUs.vue";
import TimeAxis from "../views/timeAxis.vue";
const routes = [
  // () => import("../views/aboutWe.vue")
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: {
      title: "我们的故事百科-咱百科",
      content: {
        keywords: "记录,故事,时间轴",
        description:
          "一个全球性首创的故事百科全书平台,共享他们的宝贵生平故事,永久收藏及保存其珍贵照片,视频及个人资料;咱百科的使命是潜心打造人生故事百科全书平台,使每个人可以随时轻松记录、保存自己和家人的人生故事,连接过去、现在与未来",
      },
    },
  },
  {
    path: "/AboutWe",
    name: "AboutWe",
    component: AboutWeOne,
    meta: {
      title: "关于我们-咱百科",
      content: {
        keywords: "深圳,专业团队,公司",
        description: "深圳市咱百科互联网科技有限公司旗下，主营业务是咱百科APP",
      },
    },
  },
  {
    path: "/Charitable",
    name: "Charitable",
    component: Charitable,
    meta: {
      title: "公益-咱百科",
      content: {
        keywords: "慈善,捐赠,环境保护,动物",
        description:
          "咱百科致力于推动社会发展，通过展示近期慈善活动，增加善款筹集机会，带动全社会关注公益慈善活动，传递爱与希望，让世界变得更加美好。",
      },
    },
  },
  {
    path: "/CharitableMore",
    name: "CharitableMore",
    component: () => import("../views/charitableMore/index.vue"),
    meta: {
      title: "公益详情-咱百科",
      content: {
        keywords: "公益,救助,资金援助",
        // description: "",
      },
    },
  },
  {
    path: "/Charitable/textCentent",
    name: "TextCentent",
    component: () => import("../views/chariInfoAndabout/textCentent.vue"),
    meta: {
      title: "文章详情-咱百科",
      content: {
        keywords: "文章,名人,作者",
        // description: "",
      },
    },
  },
  {
    path: "/Charitable/textVideo",
    name: "TextVideo",
    component: () => import("../views/chariInfoAndabout/textVideo.vue"),
    meta: {
      title: "视频详情-咱百科",
      content: {
        keywords: "原创,转载,视频",
         // description: "",
      },
    },
  },

  {
    path: "/MudioSheet",
    name: "MudioSheet",
    component: MudioSheet,
    meta: {
      title: "媒体中心-咱百科",
      content: {
        keywords: "英雄,模板样片,军人荣耀",
        description:
          "咱百科专注于帮助用户记录生活，将回忆生成故事，用户可以给自己的人生故事和家族故事设置阅读权限或进行加密，制作专属用户个人的生平故事。",
      },
    },
  },
  {
    path: "/MudioSheetMore",
    name: "MudioSheetMore",
    component: () => import("../views/mediaCenter/mediaCenterMore.vue"),
    meta: {
      title: "媒体中心-咱百科",
      content: {
        keywords: "更多,操作视频",
         // description: "",
      },
    },
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    component: ContaCctUs,
    meta: {
      title: "联系我们-咱百科",
      content: {
        keywords: "联系方式,官方,手机号",
         // description: "",
      },
    },
  },
  {
    path: "/TimeAxis",
    name: "TimeAxis",
    component: TimeAxis,
    meta: {
      title: "时间轴-咱百科",
      content: {
        keywords: "时间轴,朋友圈,家族",
        description:
          "咱百科拥有大量个人化生活模板，提供便利的云端共享平台供您上传图片和视频，制作个人专栏，分享您的生活故事。",
      },
    },
  },
  {
    path: "/PrivacyPolicy",
    name: "PrivacyPolicy",
    component: () => import("../views/privacyPolicy.vue"),
    meta: {
      title: "隐私政策-咱百科",
      content: {
        keywords: "用户,个人信息,权限申请",
        description:
          "根据《中华人民共和国个人信息保护法》及相关法律法规规定保护您的个人信息及隐私安全。",
      },
    },
  },
  {
    path: "/userAgreement",
    name: "userAgreement",
    component: () => import("../views/userAgreement.vue"),
    meta: {
      title: "用户协议-咱百科",
      content: {
        keywords: "软件用户协议,相关规定",
        description:
          "《咱百科用户协议》是您与公司之间就您注册、登录、使用“咱百科”软件及相关服务所订立的协议，本协议适用于公司的所有产品和/或服务。",
      },
    },
  },
  {
    path: "/temShare",
    name: "temShare",
    component: () => import("../views/phoneShare/temShare.vue"),
    meta: {
      title: "模板分享-咱百科",
      content: {
        keywords: "",
         // description: "",
      },
    },
  },
  {
    path: "/timeShare",
    name: "timeShare",
    component: () => import("../views/phoneShare/timeShare.vue"),
    meta: {
      title: "时间轴分享-咱百科",
      content: {
        keywords: "",
         // description: "",
      },
    },
  },
  {
    path: "/workShare",
    name: "workShare",
    component: () => import("../views/phoneShare/workShare.vue"),
    meta: {
      title: "作品分享-咱百科",
      content: {
        keywords: "",
         // description: "",
      },
    },
  },
  {
    path: "/Register",
    name: "Register",
    component: () => import("../components/Register.vue"),
    meta: {
      title: "用户注册-咱百科",
      content: {
        keywords: "注册,绑定",
         // description: "",
      },
    },
  },
  {
    path: "/DownPro",
    name: "DownPro",
    component: () => import("../components/down.vue"),
    meta: {
      title: "咱百科APP下载-咱百科",
      content: {
        keywords: "APP,软件下载",
         // description: "",
      },
    },
  },
  {
    path: "/DownloadApp",
    name: "DownloadApp",
    component: () => import("../components/downApp.vue"),
    meta: {
      title: "咱百科APP下载-咱百科",
      content: {
        keywords: "APP,软件下载",
         // description: "",
      },
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(),
  routes,
});

// router.afterEach((to: any, from, next) => {
//   console.log("to", to);
//   console.log("from", from);

// });

//路由导航守卫
router.beforeEach((to: any, from, next) => {
  // console.log("router", router);
  // console.log("to", to);
  // console.log("from", from);

  if (to.matched.length === 0) {
    //未匹配到的时候跳转到首页
    ElMessage.error("找不到页面,返回首页!");
    next({ name: "HomePage" });
  } else {
    if (to.meta.content) {
      const head = document.getElementsByTagName("head") as any;
      const meta = document.createElement("meta") as any;
      document
        ?.querySelector('meta[name="keywords"]')
        ?.setAttribute("content", to.meta.content.keywords);
      document
        ?.querySelector('meta[name="description"]')
        ?.setAttribute("content", to.meta.content.description);
      meta.content = to.meta.content;
      head[0].appendChild(meta);
    }
    // 路由发生变化修改页面title
    if (to.meta.title) {
      document.title = to.meta.title as any;
    }

    let userInfo: any;
    Session.get("userInfo")
      ? (userInfo = Session.get("userInfo"))
      : (userInfo = Local.get("userInfo"));
    const url = to.fullPath;
    const urlList = url?.split("?");
    const id = urlList.length > 1 ? urlList[urlList.length - 1] : "";
    // console.log("url ", url);
    // console.log("urlList", urlList);
    // console.log("to", id);
    Session.set("timeFlage", false);
    Local.set("timeFlage", false);
    // console.log("toID", Boolean(id));
    if (to.path === "/TimeAxis" && id) {
      Session.set("timeFlage", true);
      Local.set("timeFlage", true);
      next();
    } else if (to.path === "/TimeAxis" && !userInfo) {
      routerIndex.push("/");
    } else {
      next();
    } //匹配到的时候跳转相应的路由
  }
});

export default router;
